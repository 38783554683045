import PropTypes from 'prop-types';
import { Paper, Typography } from '@mui/material';


export default function SearchNotFound({ searchQuery = '', ...other }) {
    return (
        <Paper {...other}>
            <Typography gutterBottom align="center" variant="subtitle1">
            Pas trouvé
            </Typography>
            {searchQuery && (<Typography variant="body2" align="center">
            Aucun résultat trouvé pour &nbsp;
                <strong>&quot;{searchQuery}&quot;</strong>. Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.
            </Typography>)}
        </Paper>
    );
}

SearchNotFound.propTypes = {
    searchQuery: PropTypes.string,
};

