import api from "./api";

const getAdminHutService = async (params, hutId) => {
    const response = await api.get(`api/hut-service-list/${hutId}`, { params });
    return response;
};

const createAdminHutService = async (body) => {
    const response = await api.post(`api/add-hut-service`, body);
    return response;
};

const updateAdminHutService = async (body, id) => {
    const response = await api.put(`api/update-hut-service/${id}`, { ...body });
    return response;
};

const deleteAdminHutService = async (id) => {
    const response = await api.delete(`api/delete-hut-service/${id}`);
    return response;
};

export {
    getAdminHutService,
    createAdminHutService,
    updateAdminHutService,
    deleteAdminHutService,
};