import PropTypes from 'prop-types';
import { FormControl, FormLabel, TextField, Grid, Typography } from '@mui/material';
import { Form, FormikProvider, useFormik } from "formik";
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { fDate } from 'src/utils/formatTime';

// ----------------------------------------------------------------------

CampaignForm.propTypes = {
  sx: PropTypes.object,
  limit: PropTypes.number,
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default function CampaignForm({ handleSubmitCampaingForm, selectedCampaing }) {

  const CampaignFormFormSchema = Yup.object().shape({
    subject: Yup.string().required('Merci de saisir un Sujet'),
    body: Yup.string().required('Merci de saisir un body')
  });

  const formik = useFormik({
    initialValues: {
      id: selectedCampaing?.id || '',
      subject: selectedCampaing?.subject || '',
      body: selectedCampaing?.body || '',
    },
    enableReinitialize: true,
    validationSchema: CampaignFormFormSchema,
    onSubmit: handleSubmitCampaingForm
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;


  return (

    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Grid container direction={"row"} spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Sujet</FormLabel>
              <TextField
                {...getFieldProps('subject')}
                fullWidth
                size="small"
                autoComplete="off"
                type={"text"}
                error={Boolean(touched.subject && errors.subject)}
                helperText={(touched.subject && errors.subject)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Corps</FormLabel>
              <TextField
                {...getFieldProps('body')}
                fullWidth
                size="small"
                autoComplete="off"
                type={"text"}
                multiline
                rows={8}
                maxRows={4}
                error={Boolean(touched.body && errors.body)}
                helperText={(touched.body && errors.body)}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              type="submit"
              fullWidth={false}
              variant="contained"
              loading={isSubmitting}
              loadingIndicator="Loading..."
              size="small"
            >
              Envoyer
            </LoadingButton>
            <Typography component={"span"} sx={{ ml: 2 }}>La dernière campagne email a été envoyé le {selectedCampaing?.updatedAt ? fDate(selectedCampaing?.updatedAt) : null}</Typography>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
