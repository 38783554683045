import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@mui/material';
import _ from 'lodash';

function ScheduleCheckbox({ label, handleOnClick, value, status = null, schedule = [] }) {
    return (
        <FormControlLabel
            label={label}
            control={
                <Checkbox
                    disabled={status != null && status.trim() !== "active"}
                    onClick={(e) => handleOnClick(e.target.checked, value)}
                    disableRipple
                    defaultChecked={_.includes(schedule, value)}
                    checked={_.includes(schedule, value)}
                    size='small'
                />}

        />
    );
}

export default ScheduleCheckbox;

ScheduleCheckbox.propTypes = {
    label: PropTypes.any,
    value: PropTypes.string,
    status: PropTypes.string,
    handleOnClick: PropTypes.func,
    schedule: PropTypes.array,
};
