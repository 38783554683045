import { Helmet } from 'react-helmet-async';
import { Grid, Container } from '@mui/material';
import AddEditInformationForm from 'src/components/admin/information/AddEditInformationForm';
import { useCallback, useEffect, useState } from 'react';
import { getUserDetails } from 'src/services/api/user.api';
import { getCities } from 'src/services/api/admin.api';
import _ from 'lodash';
import { updateAdminUser } from 'src/services/admin.service';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

export default function AdminInformationPage() {
  const [selectedItem, setSelectedItem] = useState(null);
  const [cities, setCities] = useState([]);
  const { id } = useParams()

  const getCitiesData = useCallback(
    async (hutId) => {
      const res = await getCities();
      if (res.status && (await res.data)) {
        setCities(await res.data);
      }
    },
    []
  );

  const getInformation = async (id) => {
    const res = await getUserDetails(id);
    if (res.status && (await res.data)) {
      setSelectedItem(await res.data);
    }
  };

  useEffect(() => {
    (async () => {
      if (id) {
        await getInformation(id);
        await getCitiesData();
      }
    })();
  }, [id]);

  const latLngStringToJSON = (str) => {
    if (!str) return null
    const latLng = str.split(',').map((s) => Number(s))
    const lat = latLng[0] || null
    const lng = latLng[1] || null
    return {
      type: 'Point',
      coordinates: [lng, lat],
    }
  }

  const handleSubmit = useCallback(async (values, action) => {
    const updateValue = _.pick(values, [
      'cityId',
      'login',
      'phone',
      'shortDescriptions',
      'descriptions',
      'openingPeriod',
      'pricingText',
      'websiteUrl',
      'facebookUrl',
      'instagramUrl',
      'addressLine1',
      'addressLine2',
      'zip',
      'status',
      'language',
      'names',
      'lngLatPoint',
      'no_booking_text',
      'business_link',
    ]);
    const res = await updateAdminUser(
      {
        ...updateValue,
        name: updateValue.names?.fr || null,
        names: updateValue.names,
        shortDescriptions: updateValue.shortDescriptions,
        descriptions: updateValue.descriptions,
        lngLatPoint: latLngStringToJSON(updateValue.lngLatPoint)
      },
      values.id
    );
    if (res.status && res.data) {
      toast.success('enregistré avec succès');
      getInformation(values.id);
    }
  }, []);
  return (
    <>
      <Helmet>
        <title> Admin Information | Beach Booker - Pro </title>
      </Helmet>
      <Container maxWidth={false}>
        <Grid container spacing={3} sx={{ mt: 1, mb: 2 }}>
          <Grid item xs={12}>
            <AddEditInformationForm values={selectedItem} cities={cities} handleSubmit={handleSubmit} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
