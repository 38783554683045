import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useCallback, useEffect, useState } from 'react';
import { Grid, Container, Button, Stack } from '@mui/material';
import { CheckBoxColumn, DataTable, ScheduleColumn } from '../../components/dataTable';
import {
    createAdminBeach,
} from '../../services/api/admin-beach.api';
import { toast } from 'react-toastify';
import AddBeachDialog from 'src/components/admin/beach/add-beach-dialog/AddBeachDialog';
import { useAuth } from 'src/context/AuthContext';
import { getAdminAllBeach } from 'src/services/api/admin.api';

export default function AdminAllBeachPage() {
    const { user } = useAuth();
    const [action, setAction] = useState(null);
    const [queryFilter, setQueryFilter] = useState();
    const [data, setData] = useState();
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();

    const getDataList = useCallback(async (queryFilter) => {
        const res = await getAdminAllBeach(queryFilter, user?.id);
        if (res.status && res.data && res.data.data) {
            setData(res.data.data);
            setTotal(res.data.total);
        } else {
            setData([]);
            setTotal(0);
        }
    }, [user]);

    useEffect(() => {
        if (queryFilter) {
            getDataList(queryFilter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryFilter]);

    const handleOnCloseDialog = useCallback(() => {
        setAction(null);
    }, []);

    const handleBook = useCallback((id) => {
        navigate(`/beach/${id}`, { replace: true });
    }, [navigate]);

    const columns = [
        {
            name: 'name',
            label: 'Nom',
            isSearchable: true,
            isSortable: true,
        },
        {
            name: 'status',
            label: 'Statut',
            isSearchable: false,
            isSortable: true,
            render: (row) => {
                return `Plan ${row.status}`;
            },
        },
        {
            name: 'schedule',
            label: 'Planification',
            isSearchable: false,
            isSortable: true,
            render: (row) => <ScheduleColumn row={row} />,
        },
        {
            name: 'halfday',
            label: '½ journée',
            isSearchable: false,
            isSortable: true,
            render: (row) => <CheckBoxColumn checked={`${row.halfday}`} />,
        },
        {
            name: '',
            label: 'Action',
            props: { align: 'center' },
            render: (row) => (
                <Stack direction={'row'} spacing={2} justifyContent="center">
                    <Button onClick={() => handleBook(row.id)} size="small" variant="outlined">
                        Livre
                    </Button>
                </Stack>
            ),
        },
    ];

    const handleDataTableChange = useCallback((filter) => {
        setQueryFilter(filter);
    }, []);

    const handleFormSubmit = useCallback(
        async (values) => {
            const res = await createAdminBeach(values);
            if (res.status) {
                toast.success(res.message);
                getDataList(queryFilter);
                handleOnCloseDialog();
            } else {
                toast.error(res.error);
            }
        },
        [getDataList, handleOnCloseDialog, queryFilter]
    );

    return (
        <>
            <Helmet>
                <title> All Beach | Beach Booker - Pro </title>
            </Helmet>
            <Container maxWidth={false} sx={{ mt: 5 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DataTable data={data} columns={columns} initialLoading={true} totalRow={total} onChange={handleDataTableChange} />
                    </Grid>
                </Grid>
            </Container>
            {action === 'add' && <AddBeachDialog handleFormSubmit={handleFormSubmit} onClose={handleOnCloseDialog} />}
        </>
    );
}
