import PropTypes from 'prop-types';
import { useState } from 'react';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import FormDialog from '../../../components/dialog/FormDialog';
import { Box, Tab, Grid } from '@mui/material';
import BeachIconImage from './BeachIconImage';
import _ from 'lodash';
import { BEACH_ITEM_DATA } from 'src/data/admin-beach.data';



function AddBeachItemDialog({ onClose, handleFormSubmit, loading }) {
    const [value, setValue] = useState('Transat');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <FormDialog
            handleClose={onClose}
            header={`Choisissez l'objet à ajouter`}
            maxWidth={"md"}
            width={"md"}
            fullWidth
            withAction={(
                <LoadingButton
                    type="submit"
                    variant='contained'
                    onClick={() => handleFormSubmit("empty")}
                    loading={loading}
                >
                    Save
                </LoadingButton>
            )}
        >
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Transat" value="Transat" />
                        <Tab label="Bed" value="Bed" />
                        <Tab label="Chemin" value="Chemin" />
                        <Tab label="Bar" value="Bar" />
                        <Tab label="Cabane" value="Cabane" />
                        <Tab label="Piscine" value="Piscine" />
                        <Tab label="Douche" value="Douche" />
                        <Tab label="Vide" value="Vide" />
                    </TabList>
                </Box>
                <TabPanel value="Transat">
                    <Grid container spacing={2} direction={"row"}>
                        {_.filter(BEACH_ITEM_DATA, { type: "Transat" }).map((ele) => (
                            <BeachIconImage
                                src={ele.img}
                                name={ele.name}
                                key={ele.name}
                                handleOnClick={handleFormSubmit}
                            />
                        ))}
                    </Grid>
                </TabPanel>
                <TabPanel value="Bed">
                    <Grid container spacing={2} direction={"row"}>
                        {_.filter(BEACH_ITEM_DATA, { type: "Bed" }).map((ele) => (
                            <BeachIconImage
                                src={ele.img}
                                name={ele.name}
                                key={ele.name}
                                handleOnClick={handleFormSubmit}
                            />
                        ))}
                    </Grid>
                </TabPanel>
                <TabPanel value="Chemin">
                    <Grid container spacing={2} direction={"row"}>
                        {_.filter(BEACH_ITEM_DATA, { type: "Chemin" }).map((ele) => (
                            <BeachIconImage
                                src={ele.img}
                                name={ele.name}
                                key={ele.name}
                                handleOnClick={handleFormSubmit}
                            />
                        ))}
                    </Grid>
                </TabPanel>
                <TabPanel value="Bar">
                    <Grid container spacing={2} direction={"row"}>
                        {_.filter(BEACH_ITEM_DATA, { type: "Bar" }).map((ele) => (
                            <BeachIconImage
                                src={ele.img}
                                name={ele.name}
                                key={ele.name}
                                handleOnClick={handleFormSubmit}
                            />
                        ))}
                    </Grid>
                </TabPanel>
                <TabPanel value="Cabane">
                    <Grid container spacing={2} direction={"row"}>
                        {_.filter(BEACH_ITEM_DATA, { type: "Cabane" }).map((ele) => (
                            <BeachIconImage
                                src={ele.img}
                                name={ele.name}
                                key={ele.name}
                                handleOnClick={handleFormSubmit}
                            />
                        ))}
                    </Grid>
                </TabPanel>
                <TabPanel value="Piscine">
                    <Grid container spacing={2} direction={"row"}>
                        {_.filter(BEACH_ITEM_DATA, { type: "Piscine" }).map((ele) => (
                            <BeachIconImage
                                src={ele.img}
                                name={ele.name}
                                key={ele.name}
                                handleOnClick={handleFormSubmit}
                            />
                        ))}
                    </Grid>
                </TabPanel>
                <TabPanel value="Douche">
                    <Grid container spacing={2} direction={"row"}>
                        {_.filter(BEACH_ITEM_DATA, { type: "Douche" }).map((ele) => (
                            <BeachIconImage
                                src={ele.img}
                                name={ele.name}
                                key={ele.name}
                                handleOnClick={handleFormSubmit}
                            />
                        ))}
                    </Grid>
                </TabPanel>
                <TabPanel value="Vide">
                    <Grid container spacing={2} direction={"row"}>
                        {_.filter(BEACH_ITEM_DATA, { type: "Vide" }).map((ele) => (
                            <BeachIconImage
                                src={ele.img}
                                name={ele.name}
                                key={ele.name}
                                handleOnClick={handleFormSubmit}
                            />
                        ))}
                    </Grid>
                </TabPanel>
            </TabContext>
        </FormDialog>
    );
}

export default AddBeachItemDialog;

AddBeachItemDialog.propTypes = {
    onClose: PropTypes.func,
    handleFormSubmit: PropTypes.func,
};
