import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';

function BeachIconImage({ src, handleOnClick, name, width = 75, height = 75, sx, className, rowIndex, columnIndex, isDragDisabledCheck = true, isDragOptions = false }) {
    if (isDragOptions) {
        return (
            <Draggable
                key={`col-${rowIndex}-${columnIndex}`}
                draggableId={`col-${rowIndex}-${columnIndex}`}
                index={columnIndex}
                isDragDisabled={isDragDisabledCheck}
            >
                {(provided, snapshot) => {
                    return (
                        <Grid
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            item
                            sx={{
                                minHeight: "52px",
                                backgroundColor: snapshot.isDragging
                                    ? "#263B4A"
                                    : "inherit",
                            }}
                        >
                            <Box
                                className={className}
                                component="img"
                                alt={name}
                                src={`/assets/beach/${src}`}
                                onClick={() => handleOnClick && handleOnClick(name)}
                                sx={{
                                    width: width,
                                    height: height,
                                    border: "1px solid #aaa",
                                    cursor: "pointer",
                                    ...sx
                                }}
                            />
                        </Grid>)
                }}
            </Draggable>
        );
    } else {
        return (
            <Grid
                item
                sx={{
                    minHeight: "52px"                
                }}
            >
                <Box
                    className={className}
                    component="img"
                    alt={name}
                    src={`/assets/beach/${src}`}
                    onClick={() => handleOnClick && handleOnClick(name)}
                    sx={{
                        width: width,
                        height: height,
                        border: "1px solid #aaa",
                        cursor: "pointer",
                        ...sx
                    }}
                />
            </Grid>
        );
    }

}

export default BeachIconImage;

BeachIconImage.propTypes = {
    src: PropTypes.any,
    name: PropTypes.string,
    className: PropTypes.string,
    handleOnClick: PropTypes.func,
};
