import PropTypes from 'prop-types';
import { Box } from '@mui/material';

function RestaurantIconImage({ src, handleOnClick, name, width = 75, height = 75, sx, className }) {
    return (

        <Box
            className={className}
            component="img"
            alt={name}
            src={`/assets/restaurant/${src}`}
            onClick={() => handleOnClick && handleOnClick(name)}
            sx={{
                width: width,
                height: height,
                border: "1px solid #aaa",
                cursor: "pointer",
                ...sx
            }}
        />
    );
}

export default RestaurantIconImage;

RestaurantIconImage.propTypes = {
    src: PropTypes.any,
    name: PropTypes.string,
    className: PropTypes.string,
    handleOnClick: PropTypes.func,
};
