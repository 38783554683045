import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from 'src/context/AuthContext';
import { searchCustomerList } from 'src/services/api/customer.api';
import { useCallback, useEffect, useState } from 'react';

export default function CustomerAutocomplete({ handleOnchangeCustomer }) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [newInputValue, setNewInputValue] = useState("a");
    const loading = open && options.length === 0;
    const { user } = useAuth();

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            let userId = user?.id
            if (user && user.superadmin) {
                userId = null
            }
            let results = await searchCustomerList(userId, { search: newInputValue });
            if (results && results.status && results.data) {
                setOptions((prevStat) => [...prevStat, ...results.data])
            }
            if (active) {

            }
        })();

        return () => {
            active = false;
        };
    }, [loading, newInputValue, user]);

    const handleInputChange = useCallback((value) => {
        setNewInputValue(value)
    }, []);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            id="customer-asynchronous-demo"
            fullWidth
            size="small"
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            getOptionLabel={(option) => option.firstname + " " + option.lastname}
            options={options}
            loading={loading}
            onChange={handleOnchangeCustomer}
            onInputChange={async (event, newInputValue) => {
                handleInputChange(newInputValue)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Client existant"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}