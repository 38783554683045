import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';
import { LoadingButton } from '@mui/lab';
import FormDialog from '../../dialog/FormDialog';
import AddEditAdminForm from './AddEditAdminForm';


function AddEditAdminDialog({ onClose, selectedItem, handleFormSubmit, cities, loading }) {
    const formRef = useRef(null);


    const handleSubmitFormTrigger = useCallback(
        () => {
            const form = formRef?.current;
            console.log(form, 'form');
            if (form.isValid) {
                form.submitForm();
            }else {
                form.setFieldTouched("name");
                form.setFieldTouched("login");
                form.setFieldTouched("cityId");
            }
        },
        [formRef],
    )


    return (
        <FormDialog
            handleClose={onClose}
            header={`${selectedItem?.id ? "Modifier une plage" : "Ajouter une plage"}`}
            maxWidth={"xs"}
            width={"xs"}
            fullWidth
            withAction={(
                <LoadingButton
                    type="submit"
                    variant='contained'
                    onClick={handleSubmitFormTrigger}
                    loading={loading}
                >
                    Sauvegarder
                </LoadingButton>
            )}
        >
            <AddEditAdminForm
                cities={cities}
                onSubmit={handleFormSubmit}
                ref={formRef}
                values={selectedItem}
            />
        </FormDialog>
    );
}

export default AddEditAdminDialog;

AddEditAdminDialog.propTypes = {
    selectedItem: PropTypes.object,
    onClose: PropTypes.func,
    handleFormSubmit: PropTypes.func,
    cities: PropTypes.array,
};
