import { FormGroup } from '@mui/material';
import { SCHEDULE_DATA } from 'src/data/admin-beach.data';
import ScheduleCheckbox from '../admin/beach/ScheduleCheckbox';

export default function ScheduleColumn({ row }) {
    const scheduleData = row.schedule ? JSON.parse(row.schedule)?.schedule : [];
    return (
        <>
            <FormGroup row={true} >
                {SCHEDULE_DATA.map(({ value, label }) => (
                    <ScheduleCheckbox
                        value={value}
                        schedule={scheduleData}
                        label={label}
                        status={"true"}
                    />
                ))}
            </FormGroup>
        </>
    );
}
