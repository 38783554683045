import PropTypes from 'prop-types';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, ListItemText, TextField, IconButton, Button } from '@mui/material';
import { bgBlur } from '../../../utils/cssStyles';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import { StyledNavItem } from '../../../components/nav-section/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from 'src/context/AuthContext';
// ----------------------------------------------------------------------

const NAV_WIDTH = 0;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 60;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  background: '#fff',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  borderBottom: '2px solid #000',
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ data, withMenu = true }) {
  let location = useLocation();
  const { setSearchGlobal, searchGlobal, setSearchClick } = useAuth()

  return (
    <StyledRoot>
      <StyledToolbar>
        {(location.pathname === '/beach' || location.pathname === '/restaurant') && (
          <Stack direction={"row"} spacing={2}>
            <TextField
              value={searchGlobal}
              size='small'
              placeholder='Votre recherche'
              sx={{ width: "520px" }}
              onKeyDown={(ev) => {
                console.log(`Pressed keyCode ${ev.key}`);
                if (ev.key === 'Enter') {
                  setSearchClick(searchGlobal)
                  ev.preventDefault();
                }
              }}
              onChange={(e) => {
                console.log(e.key, 'e.key');
                setSearchGlobal(e.target.value)
              }}
            />
            <Button
              type="button"
              onClick={() => setSearchClick(searchGlobal)}
              size='small'
              sx={{
                background: "#000",
                ":hover": {
                  background: "#000"
                }
              }}
              startIcon={<SearchIcon size="large" sx={{ color: "#fff", ml: 1.5 }} />}
            />
          </Stack>
        )}

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <AccountPopover />
        </Stack>
      </StyledToolbar>
      {withMenu && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'space-between',
            pt: 2,
            pb: 2,
            color: '#000',
          }}
        >
          {data.map((item) => (
            <NavItem key={item.title} item={item} />
          ))}
        </Box>
      )}
    </StyledRoot>
  );
}

function NavItem({ item }) {
  const { title, path } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        fontSize: "1rem",
        fontWeight: 700,
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
        },
      }}
    >
      <ListItemText textAlign="center" disableTypography primary={title} />
    </StyledNavItem>
  );
}
