

export default function BulletIconOn() {
    return (
        <svg version="1.1" id="Calque_1" x="0px" y="0px"
            width="24" height="24" viewBox="12.902 127.601 767.897 767.401"
            enableBackground="new 12.902 127.601 767.897 767.401" xmlSpace="preserve">
            <g>
                <g>
                    <g>
                        <path fill="#010002" d="M668.548,239.952C593.515,165.316,495.059,127.601,397,127.601c-98.059,0-196.515,37.318-271.548,112.351
                   c-150.066,150.066-150.066,393.03,0,542.699C200.485,857.684,298.544,895.002,397,895.002s196.515-37.318,271.548-112.351
                   C818.217,632.982,818.217,390.018,668.548,239.952z M397,175.638c52.404,0,102.823,12.307,148.478,34.936l-73.842,73.842
                   c-24.217-7.94-49.228-12.704-74.239-12.704c-23.025,0-45.654,4.367-67.887,10.719l-75.033-74.636
                   C298.544,187.151,346.978,175.638,397,175.638z M96.074,659.978C73.445,614.323,61.138,563.904,61.138,511.5
                   c0-50.022,11.116-98.456,32.157-142.523l75.033,75.033c-13.895,46.449-13.101,96.074,1.985,142.126L96.074,659.978z M397,847.362
                   c-48.037,0-94.486-10.322-136.965-29.378l75.43-75.431c20.247,5.161,40.494,9.132,61.138,9.132
                   c23.026,0,45.655-4.367,67.888-11.116l75.033,75.032C495.456,835.849,447.021,847.362,397,847.362z M532.774,647.274
                   c-36.128,36.127-84.562,56.374-135.774,56.374s-99.647-19.851-135.774-56.374c-36.127-36.127-56.374-84.562-56.374-135.774
                   s19.85-99.647,56.374-135.774c36.524-36.127,84.561-55.977,135.774-55.977s99.646,19.85,135.774,56.374
                   c36.127,36.127,56.374,84.561,56.374,135.774C589.148,563.11,568.901,611.147,532.774,647.274z M626.069,579.387
                   c12.307-42.082,13.101-86.546,1.984-129.024l75.431-75.43c39.303,88.928,38.509,190.957-2.779,279.091L626.069,579.387z"/>
                    </g>
                </g>
            </g>
            <g>
                <ellipse fill="#FFFFFF" cx="396.844" cy="512.037" rx="149.318" ry="147.112" />
                <path fill="#EE7922" d="M396.844,349.478c-91.209,0-164.03,72.085-164.03,161.824c0,89.738,73.556,161.823,164.03,161.823
           c90.474,0,164.03-72.085,164.03-161.823C561.609,421.563,488.053,349.478,396.844,349.478z M491.731,460.548L491.731,460.548
           L372.571,582.651c-1.471,1.471-2.942,2.206-5.149,2.206c-2.207,0-4.413-0.735-5.148-2.206l-58.109-61.788l-1.472-1.471
           c-1.471-1.472-2.207-3.678-2.207-5.148s0.735-3.678,2.207-5.149l10.298-10.297c2.943-2.942,7.356-2.942,10.298,0l0.736,0.734
           l41.191,43.397c1.471,1.472,3.679,1.472,5.149,0L470.4,440.688h0.734c2.942-2.942,7.355-2.942,10.3,0l10.297,10.297
           C494.673,453.193,494.673,457.606,491.731,460.548z"/>
            </g>
        </svg>
    );
}

