/* eslint-disable consistent-return */
import {
  getPlaceServices,
  getHutList,
  updateServiceAPI,
  deleteServiceAPI,
  createServiceAPI,
} from './api/admin.api';
import api from './api/api';

const placeServices = async (params) => {
  try {
    const res = await getPlaceServices(params);
    return res;
  } catch (e) {
    console.log(e, 'e');
  }
};

const getPriceListCategories = async (id) => {
  try {
    const res = await api.get(`/api/get-price-list-categories/${id}`);
    return res;
  } catch (err) {
    if (err.response) {
      console.log(err.response.data.message);
    } else {
      console.log(err, 'e');
    }
  }
};

const saveService = async (props) => {
  console.log('call the api', props);
  try {
    const response = await api.post(`api/add-hut-service`, {
      name: props.name,
      description: props.description,
      icon: props.icon,
      disabled: props.disabled,
      hidden: props.hidden,
      private: props.private,
      hutId: props.hutId,
    });
    console.log(response.message);
    return response;
  } catch (e) {
    console.log(e, 'e');
  }
};

const getAllAdminList = async (params) => {
  try {
    const res = await getHutList(params);
    return res;
  } catch (e) {
    console.log(e, 'e');
  }
};

const createService = async (body) => {
  try {
    const res = await createServiceAPI(body);
    return res;
  } catch (e) {
    console.log(e, 'e');
  }
};

const updateService = async (body, id) => {
  try {
    const res = await updateServiceAPI(body, id);
    return res;
  } catch (e) {
    console.log(e, 'e');
  }
};

const deleteService = async (id) => {
  try {
    const res = await deleteServiceAPI(id);
    return res;
  } catch (e) {
    console.log(e, 'e');
  }
};

export {
  createService,
  updateService,
  deleteService,
  getAllAdminList,
  placeServices,
  saveService,
  getPriceListCategories,
};
