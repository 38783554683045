/* eslint-disable no-undef */
import React from 'react'
import { useDrop } from 'react-dnd'
import RestaurantIconImage from './RestaurantIconImage'
import { BEACH_RESTAURANT_ITEM_DATA } from 'src/data/admin-restaurant.data'
import { Typography } from '@mui/material'

export const DropItem = ({ id, name, type, rowIndex, columnIndex, selected, handleOnClick, handleEditValue }) => {
    console.log(selected, 'selected');
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: "pet",
        drop: () => ({ rowIndex, columnIndex }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }))
    const isActive = canDrop && isOver;

    return (
        <div className='basket-card' ref={drop} data-testid="dustbin" style={{ opacity: isActive ? 0.1 : 1 }}>
            <RestaurantIconImage
                width={50}
                height={50}
                src={_.find(BEACH_RESTAURANT_ITEM_DATA, { name: type })?.img}
                name={type}
                sx={{ border: selected ? "1px solid #69bed0" : "none" }}
                handleOnClick={handleOnClick}
            />
            <Typography
                {...(id ? { onClick: () => handleEditValue(rowIndex, columnIndex, id) } : {})}
                textAlign={"center"}
                sx={{ cursor: "pointer" }}
            >{id && id} &nbsp;</Typography>
        </div>
    )
}