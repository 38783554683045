import { Button, Stack } from '@mui/material';


export default function AddItemButton({ isBookAvailable, handleNew, handleCancel }) {

    return (
        <Stack item>
            {!isBookAvailable && (
                <Button
                    onClick={handleNew}
                    variant="contained"
                >
                    Nouvelle Réservation
                </Button>
            )}
            {isBookAvailable && (
                <Stack direction="row" spacing={2}>
                    <Stack item>
                        <Button
                            onClick={() => handleNew("add")}
                            variant="contained"
                            sx={{ width: "75px" }}
                        >
                            Confirmer
                        </Button>
                    </Stack>
                    <Stack>
                        <Button
                            onClick={handleCancel}
                            variant="contained"
                            sx={{ width: "75px" }}
                        >
                            Annuler
                        </Button>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
}


