import { LoadingButton } from '@mui/lab';
import { Box, Button, ButtonGroup, Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function BeachEditContentHeader({
  children,
  handleRow,
  handleColumn,
  handleSave,
  loading,
  handleAll,
  handleIsDragAndDrop,
  handlePrintMap,
  printLoading,
}) {
  const navigate = useNavigate();
  const [selectedAll, setSelectAll] = useState(false);

  return (
    <>
      <Box className="sea-container-admin">
        <Box sx={{ background: '#69bed0', height: '80px' }} display={'flex'}>
          <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ ml: 2 }}>
            <Stack item>
              <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button onClick={() => handleRow('add')}>Ajouter une ligne</Button>
                <Button onClick={() => handleRow('delete')}>Supprimer une ligne</Button>
              </ButtonGroup>
            </Stack>
            <Stack item>
              <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button onClick={() => handleColumn('add')}>Ajouter une colonne</Button>
                <Button onClick={() => handleColumn('delete')}>Supprimer une colonne</Button>
              </ButtonGroup>
            </Stack>
            <Stack item>
              <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button
                  onClick={() => {
                    setSelectAll((prevState) => !prevState);
                    handleAll(!selectedAll ? 'select' : 'unSelect');
                  }}
                >
                  tout sélectionner
                </Button>
              </ButtonGroup>
            </Stack>
            <Stack item direction={'row'} spacing={2}>
              <LoadingButton loading={loading} variant="contained" size="small" onClick={handleSave}>
                Sauvegarder
              </LoadingButton>
              <Button variant="contained" size="small" onClick={() => navigate(-1)}>
                Quitter
              </Button>
              <Button variant="contained" size="small" onClick={handleIsDragAndDrop}>
                Drag&Drop
              </Button>
              <LoadingButton loading={printLoading} variant="contained" size="small" onClick={handlePrintMap}>
                Imprimer
              </LoadingButton>
            </Stack>
          </Stack>
        </Box>
        <Box
          component="div"
          sx={{
            height: '100px',
            backgroundImage: `url(/assets/beach/beachbooker_beach_sea.svg)`,
            backgroundRepeat: 'no-repeat',
            width: '100%',
          }}
        />
      </Box>
      <Box direction={'row'} width={'100%'} sx={{ ml: 5, mt: 30 }}>
        {children}
      </Box>
    </>
  );
}

BeachEditContentHeader.propTypes = {
  handleRow: PropTypes.func,
  handleColumn: PropTypes.array,
  handleSave: PropTypes.array,
  handleIsDragAndDrop: PropTypes.func,
  handlePrintMap: PropTypes.func,
  printLoading: PropTypes.bool,
};
