import { useCallback, useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Container, Stack, Typography } from '@mui/material';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import AddEditServiceForm from '../../components/admin/service/AddEditServiceForm';
import { createService, deleteService, placeServices, updateService } from '../../services/admin-service.service';
import BackButton from '../../components/shared/BackButton';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';


export default function AdminServices() {
    const [adminServices, setAdminServices] = useState([])
    const [loading, setLoading] = useState(false)
    const formRef = useRef(null);

    const getPlaceService = useCallback(async () => {
        setAdminServices([]);
        const res = await placeServices({ private: false });
        if (res && res.data) {
            setAdminServices(res.data);
        }
    }, []);

    useEffect(() => {
        getPlaceService();
    }, [getPlaceService]);

    const onSaveData = useCallback(async (values) => {
        const serviceData = values.services;
        setLoading(true);
        if (serviceData.length > 0) {
            for await (const [key, value] of Object.entries(serviceData)) {
                const service = value;
                if (service.id && service.deleted === true) {
                    // delete data
                    await deleteService(service.id);
                    console.log("delete data");
                } else if (service.id && !service.deleted) {
                    // update data
                    const inputValue = _.pick(service, ["icon", "name"])
                    await updateService(inputValue, service.id);
                    console.log("update data");
                } else {
                    const inputValue = _.pick(service, ["icon", "name"])
                    await createService(inputValue);
                    // add Data
                    console.log("add Data");
                }
            }
        }
        toast.success("enregistré avec succès")
        getPlaceService()
        setLoading(false);
    }, [getPlaceService]);

    const handleSubmit = useCallback(() => {
        const form = formRef?.current;
        if (form.isValid) {
            form.submitForm();
        }
    }, [formRef]);

    return (
        <>
            <Helmet>
                <title> Services | Beach Booker - Pro </title>
            </Helmet>
            <Container maxWidth={false} sx={{ mt: 5 }}>
                <Typography variant='h3' sx={{ mb: 1 }}>Prestations de service</Typography>
                <Grid container direction={'row'} justifyContent={'space-between'}>
                    <Grid item>
                        <Stack direction="row" spacing={2}>
                            <AddEditServiceForm
                                data={adminServices}
                                onSubmit={onSaveData}
                                ref={formRef}
                            />
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction="row" spacing={2}>
                            <BackButton />
                            <LoadingButton
                                startIcon={<SaveAsIcon />}
                                variant="contained"
                                loading={loading}
                                onClick={handleSubmit}
                            >
                                Sauvegarder les modifications
                            </LoadingButton>
                        </Stack>
                    </Grid>
                </Grid>

            </Container>
        </>
    );
}
