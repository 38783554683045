import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';

const Loginlogo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 60,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg version="1.1" id="Layer_1" x="0px" y="0px" width="200px" height="200px" viewBox="0 0 200 200" enableBackground="new 0 0 200 200" >  <image id="image0" width="200" height="200" x="0" y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JQAAgIMAAPn/AACA6QAAdTAAAOpgAAA6mAAAF2+SX8VGAAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAABcSAAAXEgFnn9JSAAA01klEQVR42u2dedglR13vP9XnvNu826yZmUwmkz3sCSog4JXd
EFDBq+xRUC74EHHlkUVRQPCyREXkihgVQUUQEJBFkS0Jq2xhEVkSApkJmWSWzLzz7ts5df+o7j5d
3VXd1aerzzKZ7/P0M3O6q39V3W/9+rfWr4SUkiKceuV9Ctt4gUj+kOFhuuYJyUevQl97hUIdMq9N
qrlkElgHNmxdtKTgxz/0k7SLhzoJ7ADuCVwO7AJWQ/rFf2wdK8ASsADcBRwE7ghpLVV4YwOBm7/0
l4Vtmv0e5N0cAfBw4LnAG4HP2hoK4LLt89x4YqaIQZZRE7gFzAAPAh4GbPcw3uPA94EfAF8Avg3c
BBzhNGAYE8QZCeKBfvcS5H8B7wV2Ak8APmDroikkixsNLnnfo9k9sYbDny2JhwMvBq7w+v4UbgY+
D3wCuBH4Zg191AIXCRL0e5CnEcaAHwXuX+Kex6KYA2Atr2EbQSAEz774ECubpf9s1wNPAv4AJWF8
4mLgl4C3Ae8B/hK4Ehj13E9fcIZBqmE78DPAq4EPgvgXEO8CXk7xux0H9sQSByES/88cbSnYNrbB
sy66jaOrYwSirDnBAvAqlDp3tKb3cSlwNfB24P3A0xzew0BjqAffJ8ygvsbXIrkByVtBvBiCx4C4
EMlFSF4MPFS/LTPpp0DsK9PxSqvBgall/uj+3+XkWtcf6LcDz0bZDXVhG0qK/DXwMeDxNfZVK84w
iDsC4DeAryF5C5LnAPcBsT22OTof9RHU1zQP48DW6IeUxUerDbMjG9xn6zybspJR9iHg14G5mt/Z
NPBI4J+BfwQurLk/7zjDIO74ZSR/huR8YEpJgYAc635nAb0RYCqa/K6YWx/hsecc5YX3/h5HV8eq
+BbejVIF13vw7maAq1C20K+iPg5DgTMMEkHmHuNIHg40MoyRcraF5wIk5yZpGCTCqJQdCVIW500v
s3tiraokeQPw9z18y+cAbwauBc7tYb9d4wyDaLAayRMgph0YI3lul04jg1mKpYwR82tNnnrJQa7c
d5S7VkeresBfgnLP9hK/CPwbKj4z0DjDIDFE3iFBtAEzY2A6J6YKOtyFcg0XSa/MIYDV1VGuOPsI
l84ust6uxCIngd8BFnv8wi8H3oliloHFGQaJEelC5B+GWzrnNYkxY+siPGatzOaA5Y0mj7vgh5yz
ZYWVzUbVh78B+KsaX64Ne8J+f6cPfTvhDINocJyxxmaZr/g28o1RnYFKSpEAyeLiBC+77CbOn16u
aouAsg2+Uctrzcck8MfAi6gnX6IS7m4Mcn/UH+OPgft1RcHIGNm/q5TMSsme2Eul3xcQ5UZVkCIb
7YDLtp9iemSzbOqJCd9HMUk/MA68AvitPvVvxd2FQXYDrwWuQxmlL0G5HH8601IYZ6w6acq5Sjfs
uG1HsCcIjuLBiyOAU2sjvO2hX2W80e6Wz5J4P/Dp6mS6whhwDfCsPvVvxOnOIFMo//t1wAtRniOB
RCDZBrwMJeKz0FWaEWRoUBczRoQGdjVqFMnueEaXVK/S7uPJxib3nF30wSB3AP8CbPbgb2NCA3g9
8Og+9Z/B6cwg90MZgP+IWhuRggCCe0FwlnoNySPDAAEwYlORLepNE8mMxcBvoJi1a/UqTeyvHvR1
TqyN+FDi30d/bJEIW1EpKhf1cQwxTkcGGQWej0odv0q7IgGpBfpGMHmbzG5e41Q2ModiiCbKlWtC
AGw1e7/KH20CBIKrzr+d1XblP+lh4COo9ST9wgXAX6AcHX3F6cYg9wLeilp8dCDrok2s1eioTpOF
7twE0hHxzoXMveOk7YxOmwmk2J0TRCyFtoStoxs8/9JbWWl5+ZP+E/Vl/Lrisah8sb7idGKQp9FJ
sTbEJkKpkZ38Z7l2YPUUmaVIgGR7hym0r/72Mv26oC0Fd62Nstpq+FCzvo1aMdhPCOAFwCP6OYjT
gUEmUd6Pa1GLdxITNlKlhF0ySC40Gb6mw3BvUbqJLZq+Fc/LnddbAZdtm+d19/8WJ9ZHfJD8VwoW
cfUAM8DvobyQfcGwM8g9UUblC4ApfXKGj5aXGqLO76IsihkjOjdj6b8oyt6VN2siaLF/y6qPoCEo
O+SYD0IV8Sjgqf3qfJgZ5AqUIf6Y0G0bIlKnsNsSejxja9bwzbnPxevUabcT8zueLU3TpVsJm23h
Kxx9HJWC0m9EqlZf1pIMI4M0geeh1jNcpBnf6WzbNDKTUYDNU+JquKev6bbPtvBId3qedYyDhQ/3
ewAh9qOW8vYcw8YgW1BpIm9CMq0zh3MaOilJsRc3V5LMpZlJWgSUy3lWu66k3T7zeKodAsFqq8HC
ZrObNesmfBo45YOQBzwHYzyrXgwTg8wieROSF3YYI+GZsk1Yc6Ztp5lkr5RMaMZ4dm41gJEMTZML
Wac/StbeCIBtHeeB6NruSB+rrYAH7pjjqeceZmHDiw/gCPAl/3/KrjAN/HavOx0WBtmD5G3AM7WJ
6L42I9uk45maQr38LI3O0eykmqT7yLVbxog9WREziECpXdgZustjrRVwwcwij9p9nKXqKfCgUk4+
6YOQJ/w8kaeyRxgGBjkPVYnjCRm3bRr5qlSnmX5vYhJbIbX/ZVUp211bkJyVGpdAsiMjhTxAALQC
ljYbBH4sdQl80c/ovGA7ak17zzDoDHIx8HYQj3Ry22pwzptqaIuXigzymHYhc4BivD2pKzPA2b19
jZVwMyr9ZFDwBDwHWfMwyAxyD+AfIHhIec+UU7ZtMmdqv5Wu0c7IgT6eJohp9LjELFRbTdhjzAPf
6vcgEjgH+N+96mxQGeQSEH8PwY8D7oE5V8aA1CRmX5aO6ciBbTwyVN86jDaF1MtyutTEcj2Q3vlu
Cfi6X5KVMI4qRNeTuTuIDHIe8Bbgx8upUxnvlGvuVIOqlc9t41Hn016sGRJpJjLjAfN1eMMG8B2f
BD3gHvTIWB80BtkN/D2Sh1olRrHb1qZK2VzBAZIZ2hAfLutXTeORKbetarc79SznSomQUiCl98kM
ApY2Gyy3Gj7/uIcYLIVwP6oyfu0YJAaZRfK3qAJtHVS3MbLIepXc61PZVSlbu53AZEINOjczfo86
1uJ6gyfvP8zP7buThZYXVy/A7fQ//T2JMTK1j+vBoDBIE7WGQ18j7sfGyD+vfrutD89XpTpt9HYT
wHQq1V1/AI9YbwsumFng0ulF1qsvnoqwhFqOO0i4NybnimcMAoMIVFl+vYBY+XiG5d7EOXtqyFYQ
I8YgZCc9JDseax6Whgkp2ZL4vS0evGfmiEZGO2C9HfhU3pYZjMzeJC6lB6kn/WYQgUo8/M34jNHO
yKLL9Rmdczr9SdSXPu89NXMj+IbxKB4QEyC2JBhiRx2MUTNOoeyQQcIMPTDU3RJ26vh7qnn/OFSF
8XHXPjyoUxiYbhQV1JtPD7CwHwtjJGjM0vGSBUg5TEHCCGuo9PdBw31RXsja1s/3U4LcA7gGyS4X
ty34Yg4jxkivWjMGCg3XjWPUxr8NtessqJwvwwKtOty83osULvgm6AEHqLmwQ78YZArJG5GRDmn+
w5YokFCQyFc4cbYg2e+89iN1PVSldLdtZ+DjSBnlek1D0h7xm81bOP5qmK9OwjsupMsK+a7o1zbQ
LyMuDlaq1pSbmuOaTNhpOwqpvTpcQiG2frKDjxhkC8n6WsNlisz1ewAG7MfP9tZW9INBnkxslHvw
TFnPOSUTJt/DDCXQUaVcBs9M2HY3KjnS7Vm6RdszPYU5VPp7vz6qJoxT8u9WFr1+2EuBP1Eu1Sz8
2RilmAMQSoI4Sw1hu2DDnpD2AdLbIw+PFFkGVileGtBrlC+6UQK9ZJBRENdgCO74UaeghEqlt5cI
HErLSFmaMcI2ItKTt6K8LsOIFiova9BQq1fQkUG8eER+A/ip9MmupYYfxkhCW7fhFKoobBSPazL8
MUvkGBkeyRFhk/4Vtc7DLDW6enslQS5H7SIUL1utbGsA5Rkj57wMK7/HV/LK/zgyRqfZZPjsW5EG
CeKnjlV99BRaDCaDjKM+Ov1jkCqBXyGYBv4Q2FtKWqSv+ZcY6d9bUC97xfDQQfG7EnnMOI0qlK2v
hAsnch3CZPgEVNcYocZwRS8kyFOk5PHGK/1RpWz9jkvJDBGD6EhUo8N8Of9ZdqKChcoWSXzh70YT
uS40qSEqmiReJy6WkheR9tyAfwPcJQXd1i+g9iwXe1GlbnCbugVLgTuYBfaBmEqel+a21XH34rp2
ncTrZJARVAUKfSOUeuMZnXscU00SbtspnINOzowRtVc7SoVRdFnY/gxKYJMamaROBnkA8Gvxr97E
Mxxohqey8YxRTPWxXOgXP2MT2COTGcPDxxgBg+mi3qDGt1kXg4yjNspU2yAPlq1hjmfABFKa9yt0
oGmWJrER3kBt7jNlbiv8/omlZ3oKDQYrih5hhRq9a3U98JVEqwPryZ1KwN3WsLuWJSg37A79giik
mccYCYwAP4I0bXtQm33pG83wOQYNJ+m7BCnX/QSCP/Rua2TuLys1DH3IDMGzXWjZr+nMmjDCmygJ
skWnMTTMAUo9nKhMxT9qLWpXhwT5RSSXa2e6VaX82hjpiybkG+lWCWSIZ2TbzpZxHlRCPd/TbQym
ilXrQi7fD7wdlVKi4FuVSp8vIy06F3IgdCO9qLktnuGmdg2boV7rwqQusQws1tmBbwb5ReDSfnmm
oHQaerqPCZRjYdXQINB+lolnyC5tmW5Rz6KpWtPKu8RtKBukNvgM0c8iuSrUtzvQpEaXwT5HlSpT
iK2wckimCMMOzGpWx8WZ8BBpQ7PaJMnxWJ5lOCTJdHUS3vEDBkLFcvsDPgVVq8hwTx9sDRepkW2y
A5WSbjb83GyNLO3C9+fbWI8+FN44b5yal7Z2iR9S81p5XyrWFGpzE+Xl6IGtIfP66I45ACZJ1rAy
2A1d2Rq5zz0UnqytwAX9HoQB/wOs19mBLwnyUwge4l1q5PbbJWPY6KpzW0i7Yk3DqWKEu7iqK6At
gaDFlkbLZ/7FFmpeudcFVoCb6u7EhwRpAo9BiuKlmJWDfGWN75w+zP3MoNZuOJJ3VKVsH44abI+p
ZotPHjmLjxzdyVTDG4tMo/blGCTcHB61wtFIz62/dDGIK3JvNxqiwtlg7d4zlcOAZikwhhRnxVXa
c5+pLHMkSwJRC3MAjAdtvnJqhq/NTzMWeGOQ80hXfek/voUy0muFDwnyMOB869X+xDNKJhRqnqn8
7b2qqFKWtj4rkbYljIk240HbJw8e8EfKG75ED1Y4VmWQ7eRth1VvaogBpdPQTfEMu7+/dDyjWJ0a
gjK9E8D9+j2IFG4DPtOLjqoyyMXAIzJnfdsanQsWdJGGbo+CT5raFNLLnO+d1KgZk8Bl/R5ECgeB
/+5FR1UYRAA/q9GoHM9I3JO9kDMMSkgNsK4F7wQLt6DSGJKd5C8gN41/eKVGEmfRg20GSqANXI95
abR3VGGQrcAvxL96bmt0s3hJj4Jb2p6DUrOSDKKKNrhmI58ejBHhchKevQHAceAdveqsShzkR4BL
Oj/Lfcm92xjWvg2qlLUbAZKtZAKetn48MYUnxpFAELSZarZUPKQ6GsBP+hmdN3yOHm5LXUWCPLHz
324WLdWsSpWtHNJpP4WpyESG0GAxB8CWRpvPHN/Bmw/uZ+uIFwfPJMpLOShYA/6ylx12m6w4QVwl
0eL2zJUalRIKC/vJTUOXhrZ6QuEMMrGhj0zfVxzPcN5dzbPK1RSSw2tj/M/CFKOBF+I9227ZEZ8B
PtnLDruVIA8CoXzjvU8otPbjaGOE56zxjB1YXb2ePFM12SISxSRj/mIgVzI4hRokcA01l/lJw9EG
SU0mwWOQblXKe5QiUs7WyI9npCogOsQ+XB/J1cjvFlF030/+Y4BikEHBB4Dret1pNxKkgdrLPFfJ
77ut4ZKGbu9n1ji0WqSGn9kcSY+pprckxfsD9/FDqjIWgddSc+auCd0wyEVEqc/9jIKnpEBplSpf
Os0UtnN5pNzxGN5RBTSR3LE6xgeP7mKLnxysJzE4RRr+CpVa0nN04+Z9CIbEtQp7Z7j2q7X3Ji20
8/FYZjCsOKosLYwfDkeaBRgJJN9fmuBvbjuHA+OrVaXIFHAF/d8mHNSajzfSp8ry3UiQBxEVhCNn
0rjvnWG413SudDwjn17mvCbBZsJ3s1EquFeGMXLHXx4SxSSzzU0fKtZjGQzv1RLwSlTuVV9QlkHG
CfXSStFv6I2NUSbQp7efDd9NtKNS8fYHfZAahqf3hacwGNHztwH/2s8BlGWQC6Vkb02r+QznXZmj
kjplarsd5c1aSTS2zz9XG6PMs5dEW8LSZsMHk/wESo3uN/4LJT36ummP4wY60WuX9waxy9CggEJf
4hk55wrjGftR0jJ/b/AqjJF+3goIhOTI2ji/8q17Md2sNJ8C4KnUvO+fA+5A1Xa+s8/jKCtBxD1I
ln/xnjtVot5UDYyReKSzSGwX595PiYmelmQVJclMY9OH9PhR4Geqk6mETeDlqIzdvqMMgwiSe330
wtZwjR10b2vYHifaMs2tH1MfTs/upwq7BN5zZHdVl9Mo8Ezg3OojqoQ/Ba7t8xhiOLp5JajSk/al
td3YGFVUqXozbaeIjFQfxrdnaZEmPdtocc3BAzRFJcIPBp7hb2Rd4R9Q+1kODMpIkD1YizuX/RKW
YAz36Ld+T05bB9ftKLZatGVdti7MUWFejwdtXnrLhYxUY45JlM6/tQqRivg34LfoQ7Q8D2UY5ACw
Tz/VxfZo/nKmDOe9pqDvM5/3rEpVlCZjQZvrT2xnXQpGu2eSZ6ICg/3Ch4HnUXOd3W5QhkF2QzJH
yVNqiBepUdrGsKPTbleWVpdFG2pgDgnsHNnged+5J4fXxqqU+LkceFX3I6mMD6H2sryjj2OwogyD
hBmudcYz6J/UyN6/FQhAtAva2cdUwntXdjluQ0iOro/yw9Ux2t1vxLMVtQCpH1sbSOCdqO0yai1A
XQWujo8AOM+6cAlyJEEnd0qTGkULl2w043vTC68MTcssXMpKrK3IuINEdfccGunFVA79SFmeOSSw
bXSN1956Hp85tZUtjVY5AgpbUDlO/QgKrgNvAH6FAWYOcJYgYhLYXUs8w38UXJ3udn1GfF6cE76f
FtH+IC5qXlkvXhcYD9p8c2GWm5a3MNlodaOpjaHUqqu6GkA1HAn7/n996Ls0XLN5JyhMAbcwha19
P4uwuQX6DiAtq+l8q1IlZrgEppvrfPzEPj56YgcHxlfLMsgY8BqUx6jX+DzKjfvxPvTdFVxtkEnU
BjkW9CieYWlfy7oMtWiqYb6/fCBUli1CZ8GWoM3nTm7n3UfPYs/oetnb9wJ/Ajy93G2VsYlSqf4C
ONTjvivB1QbZioqDpBDaDdLBxsDRxtDO5+/OVMnGgHA8wtZ2kijdRLs/p2CDpR9fzAEwGrS5bW2c
GxdKF6e+ArVstdfM8TnUluAvYciYA9xVrEnSno46NrDMMsZqaChn8qI82BhF7WdQEfWEEVk+6bKq
SpXEWNDmxoUZXvr9Czm7WHoIVLrMRcDVwLPoXQp7C1V5/fXAP1GU9DnAcFWxRohrRZUxwKlqaxwK
x6jtbuSdOcxtp5HsBW6t1daIGopig10Aa1JwcmOEbSMbRc2nUctmnwNcSm/Xd3weeBnwdbIbog4V
XBlkHBjtWTyjc/4YijEvgNpsDds5QRT7KSE1ulKnHJgjGlBLClbaAdspFERLqDjD+4F7oeoo/zSq
1lXd+DHgPag9BL8AfBn4Svi77ynsZeBa9idWcbzlTsXncxnpBDDeM8bQzgvIzcdKnSq9tl4qxnBk
DoBNKdgzss4jt53k64tTRTZIC8UkS8Cnw+PPgWej3Lt1LqkdD49twH2B/4Oqivh5VBr7p4CvMYCp
JWk4GekSRmP7UyaOGJGxnmAOU7vkuchAljaaICUnpCx4iZZ7O4FH4dA2Mabkc0i2hW2l1i7x3CrQ
ZzH0k+0jj0JapZLux3pbcM+JJZ6993YOr411k95+O/BHwJNRmbO9xBjwcNRajw8Abwd+k+QSigGE
6zu2R5FNW5HlenfyV6+CNo/uRAWWDI1s48Gs3pWJyncQqVgCqb8rYwTc2k9CWogUw5aAAE61mlw+
tcAz997BfKvroodfQ+U//SZwqlsiFTCFKkr356g1569jsLZYiOHGIJKJQretbXIk25d32/4AuDXT
MpcxyqSrQIHb9vzwXINQHTVKDFs/0UOJPIYVpY51GXDexAqXTy2w2Kq0Bn0VFZd4Gv11v94P+F3g
34FX0//lvhpcJUjCVhFZqWGD9oVOn0ucssczTqDcrC3tflM/lJAa0XMUF8XW9issZYDbvFN5DOtw
CAkn1kd51u47eOpZRzm1WXmbyf9AMcn3qxKqiPOAFwI3oOwkH/tnVkY5NVYKcFErXL/QFHqm1lCV
RVr2fhylhnbeOelyWo1RSKvUyIxJOkiN6L2IBHO7H20ZMN1oc97YCqN+ClV/Dvgl+lh/KkSAskmu
Bd6LSsXv+4AcYImCu9oalr9gAXNsIllHsoo0lH7Jj4JbzicnpaWd/twzXTFGrtRIS67ulKRjGyP8
/oEf8KPTCyy2vHxsPws8l/7YJGkEqOIRH0PZSlv6OZBiyMR+cK6qS+Z84pRbisgiKgK7SrI2kskI
t/RjlWS57aIxCqTabWqGZMn9supU7gdDaCTKHEhYaTV52OwcW5sbvgpWfwR4EYOz7HUn8GbUmpW+
FJNwVbHUCyuMZ3jNnTqBskGWiBikPlsjAc0In0QtvW1nnj85W52kBmQkWUymnIoVHfOtJr+9/xBT
jRYb3S+aSuNvgbf6IuYJz0IFPR/U645dJchmYTwjfT7Z3IUxsvcuIsUSUhxDihWXfnLiGeZ2MTrx
DKk/p0CyHYnMxDPSbttM7CNiTJF6T9UZIzoCBCfXx3jx/kNM+ts0pwW8FLjRDzlveDDwz6iMgJ7B
VYKsxf/zrUrZJcBq2O+cuX8TjYIYi12VMrdV7t3Z+FcZGyPzrpLvJDlOQ7C0xLHRFjxu24n8SH55
HEN5lJarEvKMC4C/QzkUeoIyDNKqRZWynhcrKA/WKrlerOhH2TT0nHhGp20AbEfKtrtnCiPDGhlD
cxh0J0UEgpV2g7+55CafahbAJxigAm4J7ERlCT+5F525MsgqkrUixgBHxoAcSRBPogUkC6Ena81+
f1kbo1BqJNFAygMI0S6OZwgzw2JhjJhGcWaBC/aPrrG9uYnr63fEa4Dv+SXpBdtRhvsT6+7I0QYR
ayA64rZ7z1TORM5MlCjFZIN0ynTaZes22XGUGskHChAiiuw2zW1tkqBDRm9LSsKE/8ruj3Y7YEdz
kzddcAtHNkZ81OhN/g36WRIoDztRTPLIOjtxlSCngCOVpIZ1Ilu+oJ0kxTbKk5WdWM7xjFJSI21r
TIVjkEbGiGlkGSNjhBtVQseshAK0pGC2uclDp+dZk143hnovKkYyiDgb5Qa+vK4OXL1YC0juypz2
YmsYzqnzCwlj9Hi38QxnqWHKtFWYDtvKJE2dhokxgk7bDGMEOh0PWJcBl25Z4df23snt66M+905b
QOn8nrU3b7gYZSudVZWQCa7vcYVw2WQ6a9sKkys27YLNT3df6vxXHNddpgX92N22dsYwZdqqYwew
BURLdx2n3bYiJTFISIekFEncH12roF5Fh5CwsNnkXhPLPGPXUebbXqXIJ1A5UoOKB6CY2KuXAtwZ
ZBGY82xj5NFYRXIiMSHn0wqOBwM8nzFiiO0gdiGj6LJNlUpcs6pSyedPMEyFWIiW6dsOuHBilQdO
LTK/2fQ5W+aAf/RHrhY8HXiBb6JuC6YkG1JyML8ROULY1TCOzy2AOJy4skhBukduGrrJAAfXeMYo
km3ApnM8Q7+fbGqMriZWCINkhOJdGyM8bedxrtp1jLnqmb5JfJLBCx6m8bt4NtrLyOHbS1OPJUf6
nKUtEE6sVSRziatLmNI9wvbOjAEY4xkZupqN0QRmCGv0usczwv8bpQax5JDpaxWPlgzY2mhz7tg6
I0LiIvQdcSsqNX6QcRbwCpJFDiuiDIMcp7PraweuKlUeY2Tdtuso4zDCApJ2ehKXVqdcg326JBgB
MQO0ZEZNtHmxErYGiWup9yIz1/wcxzea/N6+23nU7CmW/doi/8GAVmFP4CdQqpYXDbPM2ztE8uWU
sTUKvVgZz5RikKQXK7loCuyM0b2tgUUSjAJ7pEz2nxecFFilUYIxYubIGPy+JInwleGbxBcYfDUL
1IIrL4mNZRjkMHA0X0UqUl0S55ITMUtzGb3ixWFC6eW85NXutrXZGrZ4xoSU4oB6VTYjPLxmtTU6
6pRMX0P45g398ItN4KMMTjq8DftQ6+0r1wJzjYOA5CgyVdMo6bZNxwas7tiku1NYpBB3IVnsuGrF
nJSirTGGtR8LY2ht0y7XzrgiGyPhth0BcTaE6/K1r32Qcj0n6QbxM6rLSW9W51oNk7hufBhbIY3B
whPxYLCXkSBrwHeBLlWp9Jfa0KZzfs7Qt75oythPQpVKp6HHyPE2xb+1aw1Uyvu4blN1o0oFOn2B
/pHxeAgJK62Alt8ERoBbgK/6JloDxoFfp+K+i2UtuFuQYqO8KmWbrJZz2Vquy+Qt2sqTGDFMjOAU
z2igvFjN+JpJlUp5pmT6WpoxIuaoSYTMtZq87sAhLp1Y8Z3lC2r7gsLapwOARwGPqELANVkxOr6B
WiuQuEbBpBc2JrBIAoGUYjFFawXJmtXOKKxOmGbQUvGMAMQeYKtZanQYIGtjpJgjyRjSxrD+3L37
xzYZ9+vqjfCf6F7GQUWAkiJTVQiUwc1E1c7d4hkOHqxO+9Btuwkck/pkXCH9B8lbvGR0rybPlYpn
CBBjSDGaHGthPCPJBMluTIyh2UL+js22qIM5QM2Db9dD2jseQYVt5soyyFHge07xjOictV3ilB7P
WCNbfmYz7Du6oZt4BknmcItnYI5naG1N8QyLfWZTzRDImo6aVDgJXFcH4Zpwdbc3lo8iSW6EVBke
UzzDzdYwuWw3IZs5DJwsZWtYjHBFIsi2NcYzLLZWxtZIMU1a4zEyjOpXUttXvm5cB0Mz9EejKtyX
Rhk3b/Q6Pgssmt22ODCGSLlRM31sIsMgoY4FO2OYxmBz24aPbMzKDVKMobtmpRTxhNbdtpHLNum2
FVlacdug84WvyYsVebLmWo06AoYA3yQp1Qcbk6jKKKVRnkEkX1DxkMqqlN62gxbm4mXzWbo2G8On
KhUYaBjctrGNkaal/9YkRo1eLICVdsCrzjnMbMNbxZMkTqCKYA8LHk9YKbMMuknUWQHxZcBZlbJG
v8HEXOtIeZf69JM8Fu1xh+h3UpWyGODpSWlMQ6cz0bVHytof8Wmr7RHREB2pY6JRAzak4CFTi4yK
WjShFsMRD4lwLsrtWwpl3bzR1/CjSIMdYnHbmmka2qtP/kmEOGZw3c5pNE1OAXLjGanxdBnPSDJB
bjxDaMyhP3dtxnPmza+0gzqkR/QkX679IfxhCiVFSqHbBQPXo9SgHfYmXe/TN4851+dOEBLNp5o2
vm3qVOpa7vqMtNFskBpJdQoLk5GiZZUYOa7wwcch1DIErynDNeJHUJH1Odcbun2w24AvAu62hjVu
kolnJKLomjvoTpIVDq3qVHjNImFKr8+wxjMw9GmzNXIkxvAyB6g1QuXXCfUP5wIPLHNDN0Z6dLzX
2dYoyp3S2y5k7lVHWHZIN8KlbcLHvyM6ae9UQTwjeS3NGDl5aFlbw8YYAt2DVudRG5ZRC6mGBTsp
mQZfZU3mx1FLY6dLq1JAJqGwgzvj3/pXehloSUmgG9/p/iKjIDCMIbwmhaFb/VqafFYVEuHzpCRP
KVUqSWMoRckmg7+AKo37obY1d8olc1yTLkzH7VKKjzipUraCz6TbijaIg51JJ0hUUVmWqoBd1DbV
X/JrGWQns+aaxaJKBdqpDv2UVIp/Bx1VKs/4tuZwSfTnGToJsgLcVGcHNeACYK9r4yrG1Qbwbu1M
sQGut826aFuotSCGiS+WiCss2jxT5MQzOu2yqlTKMxWPzx4rcY5nmFRApMYcdaWZZNQ9/2gxXDYI
wAHgEtfGVb0PX0Alrrka4GTbapJAYt/haBHESXsaSVE8w5IaklapCuIZYI1npGSnbTxSoy+TzF7n
UR/maqXuHzsosRlPFSMdJHcgeV+uSmWiEyMjCSSIeYt6sAaGCosp75FO1388Q5MaOiMdBZbs45Ep
+olUE20QQ6ViwWBs2VYWnhnEjg1UpQvlYSqqUhjD5G0KVazMepNowgmJRGeegvUZkGKM1DV3t21C
apglTAvEzUgxV2xrJBjN5IauAQutWsMUJxiOxVNJXIQqxlEIH2/uq8D1njaXWZBSHIndxVk36KIb
3bQ6lZIauW5bnfEc4xkbSG4hVjfybQ1zykt9xxO2zlMjFtALbAwDduBYO8tRxZJ5xymk/Li9QAJo
k06bkOnM3mAexEJWSgiQoo0UC9kiEZ02yWzb9DWNMdLn4zGqa5qBKxPtNaTGprxv851njGyNoPN8
qWfvvKv6DHSB4EW7j9VphqyjKl8OE7biuHOuG4Mk3bPm44PAd0qoUgCpaLsAmCdaWps1LiXxHyLp
lrUtdU1l2sYSwzbR1evIsTEM7eNzbaS8DclC0tWsr/VIe8TUUfd6EAmcbDVq7IE26f1bBh97gW0u
Dasa6dHxPSQf7NyQVGsSvyNycQRcpNsukKzBmzU4F7QvPibGoMMERlUq0d6kSnUTz0BuopYir2Zp
gYkxonanAVoMfp2sNLbjuE7dp/X2DuBggY1RVCDhSI73RUmQWCVJ2Rhxn93EMwzu2AxM8YzoocQS
iDtArMjsMyXG0TmXSUep+6gPw8ggY/g10p3ciV8F8TGT1LAWSMjQ51CmX82LpSSILjUwu23zVKTw
X3MauuHZM8HH6KHi36ck4odSVV/JPmOKMTQhVO/k7QXapJdgDz6a9NCL1YHkzcAx+6RM/N+ofoi7
dKM7o80tVo5npPV+Z1sj4bLNxjNWkJxCBTNTzxS3SdkatX/Ze4l6Q5H+EeCYh9jlgimbGBdfAfGB
+LZYckDWLonOkVQD5gpGMqd5t0rHMxJ6f2lbA3LiGWvAOogTiYuJPlO0MvSTD1HXURuGUYIAOHku
nLio5Cb1r0HVRd1htkeiSWachKd0Upmv7NGMjQHmL3FCzZPG82nkMUV0Y5AI8GntVpFshONvgwhk
Lu2i855Rbx8S6qoLUSuchEMdIdbvAX+XNZw77k+z6gLIRPX4TMxAAOIksK7ZGDa3bdrNWlmVSjOH
RmsZlSazLBEbxcxh6XM4JYizujJgcJJ6Po305PEGJIcyE8OqugCINRCHNTpZ0muI5MKpFC1NlaLz
nzKqVFwd3qhKkZlwipmPhzy91HnxFlXK9g6GF+prNFyQpPabsaGuBzsMvFaPc1gM905C4UJ4dNT3
bLxlDclqhpbNbZvLGCYvWkL9S9IqiGeAiArdrQCbVhvD8g7yExX8HDVC4KjPDxA2cXRN18n57wI+
ZdbtOx6oxDxbQmXsFjyYWIl/JSa681LX/HiGTsvkUIAMM0pYDMdyAimyL94aYKx98vYCAWqF3jBh
A0cGcdMdu9vn7jjwWtQe1hPGYF4MAbAIciN7Xmu7SVTEWlufkUBXRrguNTp0UrS0eIaGKBfpDpQU
ye8zImcKbNaBwur3lTCMDLKIY3pM3brjdcDbTMyhRcKVNDmCDFpq96XwyBohGyDu7KhlLkY4pWwN
W3pMJiWlQ6uFZFlVJOIUUeq3bWUjKbWndhu6djRQkelhwh04ZiA7eh+6/guuAH+K5AoQ54MpJhDT
P0R6XUFW/dggXC+iMYZtfKYVfWCQGun+TFIDMrlTiv4yiOO0BQi5AmLT5lbV1Ckt+7leDtkW1KrH
NVG7OQ0TjuO4v0kvvA/fk4jXSViNy/GHBZy1gs+CEwjaul2fSd9uS8SCjGnkuW07KpjmtpVRPKNT
ECLjUk6cy2wlkM1vWiTas0+KFSTLacmnpdpEGdBWN7VfSODVR7fXKaRGqLBBTZ8wh7J5C1FHoNCE
t4J4NJKf1yZ09F8ZBwl115uex054fdGqRmlIB/oS8QybXWC3MSwTWURR9CjLeI2EDSKTEkkk+0hL
oqqvNx8fXqi82WseZqi4D2AfcBuODNIr//UqkpeCOAikUkPiyRJGoZPI2CAtVHUTvY21xq7U7o+k
BplBkGdjWCL1sY2xnnrZx3XXqkg9q0lNq/eYCmoNdG9n+Ny8N+P4WeplgOc7wMsRoSs3mz17zIFG
m6TumFtvKvqt/s1GwSMalvO5gT5t3OtJppUy2iI5oT7aMoWH38ULjktXBwjLqDidExyNdA98pObH
W5E8GMRztWtq8mTrK5kn0F2Je1Id6OkhWZdt55qD8Z2FWY1bAeYSauh8SDLhATCpVBaVcPgw2+8B
lMQholJVDuhDioB4MfCV+KeaWLpk6LTVjWJ1HDMXoDYwR0Y6ZGMcbsxhi7yDlJyQksWERjOPoG1e
qGU4l5RUdR31oQHsqbUH/zgEHHRt3I8cmpPA1SCOJuIZi+GR8hoZsQhInTEiWyNao26PQZRbn5FW
s7LxDCJ/emdCLoZFHPQ+bVKjTsaon0HGKVGlcEDwP5QoMuGmYvl0RSpaXwSeLxGvRTILvA7basIs
o6wiWQc5FrXzqkrZJAiYvV8iHdlnjsjZkMcU2tiGVr1qMlwSZImSm/70nkE6eDfwDVQU9tsYi48Z
+11VhxjzbmPk2AUyPZk7btul1G2HkWyCGMvQyjBGdH5orfUx4Ox+D6IEDgOfL3NDv/P4v2u9YlcP
NlBBx9nitPFS8QwrLaPLVtGSEO7I26F7hMxaA2F5nog5hlaC7AbO7/cgSuC/KbmfyQDn8VuLoa1J
xMlcG0N2Fc9I0Asv5cUzFK11svtjLKFRFErhyk2UHFrsw7H4wQBgDfgoJa2ywWQQKZDtwGZ0riCj
/bnN8QyjSlUczyBpa2QCiuZ4xhrZvcKXUbER1badpp/1uA0x7t3vAZTAUeDDZW/qt4plRr7Nswac
qiGeoS7ZbQ0yE1rZGqfihurezrqWNK1MANP5mbt7jcBM0K6LDRvA/eshXQtuAH5Y9qYBZBCBbDfy
Pq4bxNXkE/ekW3Vta4hs35kVjPG9LeL1KSQZZL5DP5IWFnUqXqTlHyNC8q6FSVbqcbKMA/etg3AN
2ADe0s2Ng8cgUal++4zZBJak61e4jNtWlKa1CSId4FwHcQS4dyq/Xf+/TD5iPd/4cSF509wMS+2A
MeGdBfcBl9YycP/4MvCpbm4cOAZpy8K8t44EieAnnpFDK9kgcZ9knTj1hcSMlyesK6EyEqM+N6+U
sDVoc7yeXMIHMTwLpd6EY5GGNAaKQdotp+FsEEVCu4hnZM4VpqFbaCnj+yTm3LDshhxGVWqo3bw/
0e8BOOKbwL93e/NAMUgJQ/WomwHuEM/IXZ+RkxqicApzfaXUXu8Gl3M8oKFkjlHgIf0ehCPeiNoF
qysMCINI2ptjZRgkmxrv5JkKz0cu21S7cChYJU5mWYWwLdvsGOkmWyO1TqUOSGBCyLr8+PcF9tdD
2iu+CnygCoEBYZDSuBOVcjLela1hYwKjNMlLZ5FLloohR3WVysXN6/cFjQrJl1ZGmW+LOljwMUCt
yxQ9oAX8BWqudI3+BwqFJF6f7o6bQHzTLDVSzBHvgoWdOVzS0JP0OxnEc3HkPrGVnJTih+p0Qo/T
Mo6TtPDOHACzgeQ1J2f5wXqTpn8P1sMZ/I/rJ6koPej7QwqJbDWQG6WzFW5Due1+zKpKCYskiH+D
VZXKTFqjBGiF48i2i3TemClUoYhMH9n7vKGNYCZo0/RP+hIGP4I+B7yeCrZHhP5LkHZp6QFqPci7
QNwOltSQsJUxpaSoiLVRYqRoK4vkiE5XRscyUibWhCRes435hgdXMPgp7u8EPu6DUP8YRIBsNZGt
kXCOiJIHX5KSfzbmTAnMTGBcG57ImdIWNqUXY6Wgdt2dz+6YKwCxCFEJ0iSzGl5C8prHI16y5Zf/
BMr+GGT16lvAH+Np7/b+SpBqdaHa4Yu4XmcMzOpURqWy2QBRekjS05Qdc9hi3mJCLKGq1Xf6h2zf
4bU6FhAuS8G69C6fHgBc5pekVywAr6SLnCsb+q9iVcMphHgeghuty1qzqhExY5jS0OMi1uYouJY0
jJi3SLgFot2yMsynZyDL0pIz/5AI9jTbXDM3zXUr40z4NdAfDZzrk6BnvAWlXnnDsDMIqP3Znwzi
Q9n0d4sEMK7PKIhqa8E+AYgNpDyml2eMoVLyC9eskDlX9RiRklvWmxzcaDLilzl2Alf6JOgZNwB/
4Jvo6cAgALcAT0a9oLvsuVNJ71Hiy5tvaxCVIE21nwdxrHNOpPWcuSwtEiqVfzevRLCr0ebDSxO8
d3GCKb8M8kjggT4JesStwNU41tstg9OFQUDVp3oVkmeAuEFngLTUSDBFxtYIGSI6SMQz9AoqCyBW
LKpOO6l+xV0hDIzhT70aF/CV9VE+tjLGrmbbZ3hlCngSg7l68C7guSjj3DtOJwaJ8J/A05G8HMly
hjE0VSqrgklN9TFJmPieBeybsEjitero/WTcyH4ggS2B5NBmk08sj7HFr/R4APB4nwQ9YQ14AfCx
ujo4HRkEVPWKVyK5EvhYGVVKIY8x4omurT2XaEJJShl5uGyMkbVJqhxjSL651uTVJ6fY12z53HZ2
EngOMNGzv54bNoHfA/6hzk5OVwYBpVR9Cvg54DeQ8mCB29aQN5VSveJ7gMjW0ewOTcW63ezmzfOy
VTvuajW4daPhO3r+YJR9N0jYRNmbbwCfmmQWpzODRFgC3gjiYcC1xOkHacaIziWj5tG/xmDfbTl9
SqIkOZvUiK9Vn80BcKod8PzjM2xvSJ8zZgJ4KYNVvX0d+L/Aa+hyEVQZ3B0YJMJB4FeBJyLFB6Vk
zmhrZIKJIbJu4aOZr7guUeZzU+ptsZYuIIGvrNWyTeAvAw+rg3CXWABeArysVx3enRgkwqcl/Czw
bBD/DmIta5tYpUaiDacK+lm22hpG+0N0dUgpmBbwkhPTBH5VqwtR0mNQcBvqA/dnvez07sggEd4L
/AJSXgXi/RC0jJIASEuJkJ/mNU9x1pbobKqTydUiJUW6n9mjQnLtwgSjwmtaSRP4U2BvvX8CZ/wX
8AzgHb3u+O7MIKBiJ+8BnoWSKm9Xta4i6JO34wIWgJgrULFOIVkvlRfWRVrJtgDeuTjBil9T9bfC
9zEIeDPwFODT/ej87s4gEU6hFvY/D3g48CbFACGy8YyTqDL6KWgT+BSIu7TL1rww2dUxK9q89OQk
J6Vg1J/4eBzw+3gVSF3hVuDpwG+TqfzfO5xhEB0LwGeB30G5N1+C5BsSNhN1uDaQvILiTVjWiRdO
gX0NiinOUnyIMEL/nY0RVqS3ZbU/Bvw5/d2Ucw34a1RqyztQS6v7hkHO6+8n1lB7Kr4GeAOInwKu
RNVAeBdqOWeRUqOqLtrywiA/OTIHEtjTaPP7c5P811qTWT/7oF+GcoNfXMsbLcYyamuC16Ei47XG
N1xxhkGKsQL8W3iYIQW0MxO9DaxlbQ3ILd7ggBHg4GbAbZsNXwGKBwB/Q3/WeiyiKh/+HfB2BoQx
IpxhEB8wxzRW0BbuiFzGcC2uKIFdjTb/tLCF962Ms7/RqjqjngRcAxzo8Vu7EyUx3gP8Cz0I+nWD
MwxSFVKANJpyK6gv4s8j5bia2jbGcJckY0Jy4/oIN6yNsiOolLF7DsoAvhpViLoXWEe5bD+NWjN+
fY/67RpnGKRefBT4ZRBXA/cBtkUXMtssOHCJBCYF3NJq8J+ro5zf6CopcRL4JeBXUEZ53TiMKuD2
eeBLKO/f7ZUo9hBnGKRetFBLQK8HtqO2K7tASs4D7gtiL6pK+jYc8p1GheS/1xu8fn6CfW7METHd
TlSpnitQbtz71fCcJ8LjGIoJvhsetwDHiWoYDxnOMEhvcGd4RIt6RlCJgCOoxUgHkHy7iEgALEjB
TRtNdjWc1asxYDb8/xdRBvEa5prCZRE6IphHMcZqSDfcaHX48f8BUHMVdCNte8oAAAAldEVYdGRh
dGU6Y3JlYXRlADIwMjMtMDQtMDlUMDg6NDA6NTArMDA6MDCjzWYFAAAAJXRFWHRkYXRlOm1vZGlm
eQAyMDIzLTA0LTA5VDA4OjQwOjUwKzAwOjAw0pDeuQAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAAMjAy
My0wNC0wOVQwODo0MDo1MCswMDowMIWF/2YAAAAASUVORK5CYII=" />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Loginlogo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Loginlogo;
