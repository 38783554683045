import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Container, Typography, AppBar, Toolbar } from '@mui/material';
import { LoginForm } from '../sections/auth/login';
import Loginlogo from '../components/logo/Loginlogo';


const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '93vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));


export default function LoginPage() {

    return (
        <>
            <Helmet>
                <title> Connexion</title>
            </Helmet>
            <Container fixed>
                <AppBar sx={{ background: '#000', height: '100px' }}>
                    <Toolbar sx={{ justifyContent: "center" }}>
                        <Loginlogo />
                    </Toolbar>
                </AppBar>
                <Container maxWidth="sm">
                    <StyledContent>
                        <LoginForm />
                    </StyledContent>
                </Container>

                <AppBar position="fixed" sx={{ background: '#000', top: 'auto', bottom: 0 }}>
                    <Toolbar sx={{ justifyContent: "end" }}>
                        <Typography>@2020</Typography>
                    </Toolbar>
                </AppBar>
            </Container>
        </>
    );
}
