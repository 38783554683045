import PropTypes from 'prop-types';
import FormDialog from '../../dialog/FormDialog';
import { Button } from '@mui/material';


function AddBeachWarningDialog({ onClose, message = "Merci de sélectionnez une seule zone ou objet avant de spécifier son identifiant." }) {
    return (
        <FormDialog
            handleClose={onClose}
            header={`Choisissez l'objet à ajouter`}
            maxWidth={"sm"}
            width={"sm"}
            fullWidth
            withAction={(
                <Button
                    type="submit"
                    variant='contained'
                    onClick={onClose}
                >
                    D'accord
                </Button>
            )}
        >
            <span dangerouslySetInnerHTML={{__html: message}} />
        </FormDialog>
    );
}

export default AddBeachWarningDialog;

AddBeachWarningDialog.propTypes = {
    onClose: PropTypes.func,
    message: PropTypes.string,
};
