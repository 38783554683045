import PropTypes from 'prop-types';

export default function LanguageColumn({ language }) {
    const languageSelected = language === "fr_EN" ? "EN" : "FR"
    return languageSelected;
}

LanguageColumn.propTypes = {
    language: PropTypes.any,
};
