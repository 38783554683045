import { FormControl, TextField, Grid, Typography, Box, Button } from '@mui/material';
import { Field, Form, FormikProvider, useFormik } from "formik";
import { LoadingButton } from '@mui/lab';
import BulletIconOff from '../icons/BulletIconOff';
import BulletIconOn from '../icons/BulletIconOn';
import Iconify from '../iconify/Iconify';
import DateRangeDefaultField from '../form/formik/DateRangeDefaultField';
import { useCallback } from 'react';

export default function FilterForm({ handleSubmitForm }) {

    const formik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            phone: '',
            email: '',
            fidelity: null,
            dateRange: null
        },
        // validationSchema: FilterFormSchema,
        onSubmit: handleSubmitForm
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, values } = formik;

    const handleChangeDatepicker = useCallback((value) => {
        setFieldValue("dateRange", value)
    }, [setFieldValue]);

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
                <Grid container direction={"row"} spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                        <Iconify icon="entypo:lifebuoy" />
                        <Typography component={"span"} sx={{ ml: 1 }}>Client qui a comme</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                {...getFieldProps('firstname')}
                                size="small"
                                autoComplete="off"
                                placeholder='Prénom'
                                type={"text"}
                                error={Boolean(touched.firstname && errors.firstname)}
                                helperText={(touched.firstname && errors.firstname)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                {...getFieldProps('lastname')}
                                fullWidth
                                size="small"
                                autoComplete="off"
                                placeholder='Nom'
                                error={Boolean(touched.lastname && errors.lastname)}
                                helperText={(touched.lastname && errors.lastname)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                {...getFieldProps('email')}
                                fullWidth
                                size="small"
                                autoComplete="off"
                                placeholder='E-mail'
                                type={"email"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                {...getFieldProps('phone')}
                                fullWidth
                                size="small"
                                autoComplete="off"
                                placeholder='Téléphone'
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <BulletIconOff />
                        <Typography component={"span"} sx={{ ml: 1 }}>Client qui a déjà réservé &nbsp;</Typography>
                        <FormControl>
                            <TextField
                                {...getFieldProps('fidelity')}
                                size="small"
                                autoComplete="off"
                                type={"number"}
                            />
                        </FormControl>
                        <Typography component={"span"} sx={{ ml: 1 }}>fois et plus &nbsp;</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Box component="span" sx={{ mt: 20 }}>
                            <BulletIconOn />
                        </Box>
                        <Typography component={"span"} sx={{ ml: 1, mt: 5 }}>Client qui a déjà réservé dans la période &nbsp;</Typography>
                        <FormControl fullWidth>
                            <Field
                                size="small"
                                component={DateRangeDefaultField}
                                name="dateRange"
                                handleChange={handleChangeDatepicker}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        type="button"
                        variant="contained"
                        onClick={() => {
                            formik.resetForm()
                            setFieldValue("fidelity", '')
                        }}
                        size="small"
                        sx={{ mt: 2}}
                    >
                        Réinitialiser
                    </Button>

                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        loadingIndicator="Loading..."
                        size="small"
                        sx={{ mt: 2, ml: 3 }}
                    >
                        Filtre
                    </LoadingButton>
                </Grid>
            </Form>
        </FormikProvider>
    );
}
