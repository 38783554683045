import api from "./api/api";

const submitPriceCategory = async (props) => {
    try {
        const res = await api.post(`api/price-list-categories`, props);
        return res;
    } catch (err) {
        if (err.response) {
            console.log('error', err.response.data.message);
        } else {
            console.log(err, 'error');
        }
    }
};

const deletePriceCategory = async (id) => {
    const response = await api.delete(`api/delete-price-list-categories/${id}`);
    return response;
};

const updatePriceCategory = async (id, body) => {
    const response = await api.put(`api/update-price-list-categories/${id}`, body);
    return response;
};

export { submitPriceCategory, deletePriceCategory, updatePriceCategory };
