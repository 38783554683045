import PropTypes from 'prop-types';
import { FormControl, MenuItem } from '@mui/material';
import { Field } from 'formik';
import { Select } from 'formik-mui';

const SelectIconComponent = ({
    name,
}) => (
    <FormControl sx={{ minWidth: 220 }}>
        <Field
            size={"small"}
            component={Select}
            name={name}
            placeholder="select icon"
        >
            <MenuItem value="">-none-</MenuItem>
            <MenuItem value="bell">
                bell
            </MenuItem><MenuItem value="check">
                check
            </MenuItem><MenuItem value="checkboxOn">
                checkboxOn
            </MenuItem><MenuItem value="chevronDown">
                chevronDown
            </MenuItem><MenuItem value="chevronLeft">
                chevronLeft
            </MenuItem><MenuItem value="chevronRight">
                chevronRight
            </MenuItem><MenuItem value="chevronUp">
                chevronUp
            </MenuItem><MenuItem value="clock">
                clock
            </MenuItem><MenuItem value="cocktail">
                cocktail
            </MenuItem><MenuItem value="concierge">
                concierge
            </MenuItem><MenuItem value="copy">
                copy
            </MenuItem><MenuItem value="dots">
                dots
            </MenuItem><MenuItem value="export">
                export
            </MenuItem><MenuItem value="handicap">
                handicap
            </MenuItem><MenuItem value="lifeBuoy">
                lifeBuoy
            </MenuItem><MenuItem value="lifeBuoyThin">
                lifeBuoyThin
            </MenuItem><MenuItem value="magnifier">
                magnifier
            </MenuItem><MenuItem value="minus">
                minus
            </MenuItem><MenuItem value="parking">
                parking
            </MenuItem><MenuItem value="pilotisHorizontal">
                pilotisHorizontal
            </MenuItem><MenuItem value="pilotisVertical">
                pilotisVertical
            </MenuItem><MenuItem value="pin">
                pin
            </MenuItem><MenuItem value="pinFilled">
                pinFilled
            </MenuItem><MenuItem value="plus">
                plus
            </MenuItem><MenuItem value="pool">
                pool
            </MenuItem><MenuItem value="shower">
                shower
            </MenuItem><MenuItem value="star">
                star
            </MenuItem><MenuItem value="sunbed">
                sunbed
            </MenuItem><MenuItem value="sunbedColored">
                sunbedColored
            </MenuItem><MenuItem value="sunbedDuo">
                sunbedDuo
            </MenuItem><MenuItem value="sunbedSolo">
                sunbedSolo
            </MenuItem><MenuItem value="trash">
                trash
            </MenuItem><MenuItem value="turtle">
                turtle
            </MenuItem><MenuItem value="tv">
                tv
            </MenuItem><MenuItem value="umbrella">
                umbrella
            </MenuItem><MenuItem value="umbrellaCheckmark">
                umbrellaCheckmark
            </MenuItem><MenuItem value="user">
                user
            </MenuItem><MenuItem value="waterSports">
                waterSports
            </MenuItem><MenuItem value="website">
                website
            </MenuItem><MenuItem value="wifi">
                wifi
            </MenuItem><MenuItem value="xmark">
                xmark
            </MenuItem>
        </Field>
    </FormControl>
)

export default SelectIconComponent;

SelectIconComponent.propTypes = {
    name: PropTypes.string,
};
