import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Field, Form, Formik } from 'formik';
import { FormControl, FormControlLabel, FormLabel, MenuItem, Stack, Checkbox } from '@mui/material';
import { object, string } from 'yup';
import { Select, TextField } from 'formik-mui';


const AddEditAdminForm = forwardRef((props, ref) => {
    const {
        values: selectedData,
        onSubmit,
        cities,
    } = props;
    const defaultValues = {
        id: '',
        name: '',
        login: '',
        cityId: '',
        admin: false,
        isPartner: false,
        notifications: false,
        language: 'fr_FR',
    };

    const validationSchema = object().shape({
        name: string().label("Nom de la paillote").required(),
        login: string().email().required(),
        cityId: string().required(),
    });

    return (
        <Formik
            enableReinitialize={true}
            innerRef={ref}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validator={() => ({})}
            initialValues={({ ...defaultValues, ...selectedData, ...{ name: selectedData && selectedData.names ? JSON.parse(selectedData.names).fr : null } })}
        >
            {({ getFieldProps, values, errors }) => (
                <Form autoComplete="off" noValidate>
                    <Stack spacing={3} sx={{ mt: 2 }}>
                        <Stack direction="row">
                            <FormControl fullWidth>
                                <FormLabel>Nom de la paillote</FormLabel>
                                <Field
                                    size="small"
                                    component={TextField}
                                    name="name"
                                    fullWidth
                                    required
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction="row">
                            <FormControl fullWidth>
                                <FormLabel>Pseudo (email)</FormLabel>
                                <Field
                                    size="small"
                                    type="email"
                                    component={TextField}
                                    name="login"
                                    fullWidth
                                    required
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction="row">
                            <FormControl fullWidth>
                                <FormLabel>Ville</FormLabel>
                                <Field
                                    size="small"
                                    component={Select}
                                    name="cityId"
                                    fullWidth
                                    required
                                >
                                    {cities && cities.length > 0 && (
                                        cities.map((city, index) => (
                                            <MenuItem value={city.id} key={`city_${index}`}>{city.name}</MenuItem>
                                        ))
                                    )}
                                </Field>
                            </FormControl>
                        </Stack>
                        {values.id && (<>
                            <Stack>
                                <Stack item>
                                    <FormControlLabel
                                        control={<Checkbox size="small" {...getFieldProps('admin')} checked={values.admin} />}
                                        label="Administrateur"
                                    />
                                </Stack>
                                <Stack item>
                                    <FormControlLabel
                                        control={<Checkbox size="small" {...getFieldProps('isPartner')} checked={values.isPartner} />}
                                        label="Partenaire"
                                    />
                                </Stack>
                                <Stack item>
                                    <FormControlLabel
                                        control={<Checkbox size="small" {...getFieldProps('notifications')} checked={values.notifications} />}
                                        label="Notifications"
                                    />
                                </Stack>
                            </Stack>
                            <Stack direction="row">
                                <FormControl sx={{ minWidth: 150 }}>
                                    <FormLabel>Langue</FormLabel>
                                    <Field
                                        size="small"
                                        component={Select}
                                        name="language"
                                    >
                                        <MenuItem value={"fr_FR"} key={`fr_FR`}>FR</MenuItem>
                                        <MenuItem value={"en_US"} key={`en_US`}>EN</MenuItem>
                                    </Field>
                                </FormControl>
                            </Stack>
                        </>
                        )}
                    </Stack>
                </Form>
            )}
        </Formik>
    );
});

export default AddEditAdminForm;

AddEditAdminForm.propTypes = {
    values: PropTypes.object,
    onSubmit: PropTypes.func,
    cities: PropTypes.array,
};
