import api from "./api";

const getAdminBeachList = async (params, hutId) => {
    const response = await api.get(`api/beach-list/${hutId}`, { params });
    return response;
};

const createAdminBeach = async (body) => {
    const response = await api.post(`api/add-beach`, body);
    return response;
};

const updateAdminBeach = async (body, id) => {
    const response = await api.put(`api/update-beach/${id}`, { ...body });
    return response;
};

const deleteAdminBeach = async (id) => {
    const response = await api.delete(`api/delete-beach/${id}`);
    return response;
};

const getAdminBeachDetails = async (id, params) => {
    const response = await api.get(`api/beach-detail/${id}`, { params });
    return response;
};

export {
    getAdminBeachDetails,
    getAdminBeachList,
    createAdminBeach,
    updateAdminBeach,
    deleteAdminBeach
};