import api from "./api";
export const fileConfig = {
    headers: {
        'content-type': 'multipart/form-data'
    }
}
const getAdminRestaurantList = async (params, hutId) => {
    const response = await api.get(`api/restaurant-list/${hutId}`, { params });
    return response;
};

const createAdminRestaurant = async (body) => {
    const response = await api.post(`api/add-restaurant`, body);
    return response;
};

const updateAdminRestaurant = async (body, id) => {
    // let formData = new FormData();
    // Object.keys(body).forEach(fieldName => {
    //     console.log(fieldName, body[fieldName]);
    //     formData.append(fieldName, body[fieldName]);
    // })
    const response = await api.post(`api/update-restaurant/${id}`, body, fileConfig);
    return response;
    //return true
};

const deleteAdminRestaurant = async (id) => {
    const response = await api.delete(`api/delete-restaurant/${id}`);
    return response;
};

const getAdminRestaurantDetails = async (id, params) => {
    const response = await api.get(`api/restaurant-detail/${id}`, { params });
    return response;
};

export {
    getAdminRestaurantList,
    createAdminRestaurant,
    updateAdminRestaurant,
    deleteAdminRestaurant,
    getAdminRestaurantDetails
};