import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Card, CardActions, CardContent, CardMedia, Divider } from '@mui/material';

const AdminHutCard = ({
    imageName,
    onClick,
    title
}) => (
    <Card sx={{ maxWidth: 345 }}>
        <CardMedia
            sx={{ height: 140 }}
            image={`/assets/admin/${imageName}`}
            title={title}
        />
        <CardContent>
            <Typography gutterBottom variant="h5" component="div">
                {title}
            </Typography>
            <Divider sx={{ width: "90px", borderBottomWidth: "thick", background: "#000" }} />
        </CardContent>
        <CardActions sx={{ mb: 2, ml: 2 }}>
            <Button
                onClick={onClick}
                size="small"
                variant="contained"
            >
                Administrer
            </Button>
        </CardActions>
    </Card>
)

export default AdminHutCard;

AdminHutCard.propTypes = {
    onClick: PropTypes.func,
    imageName: PropTypes.string,
    title: PropTypes.string,
};
