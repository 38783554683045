/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import ListIcon from '@mui/icons-material/List';
import update from "immutability-helper";
import { Container, Box, Grid, Typography, Stack, IconButton, Button, Alert, CircularProgress, Divider, TextField } from '@mui/material';
import BeachSidebar from '../components/beach/sidebar/BeachSidebar';
import BeachContentHeader from '../components/shared/BeachContentHeader';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import AddEditBeachReservationDialog from 'src/components/beach/add-edit-beach-reservation-dialog/AddEditBeachReservationDialog';
import { useAuth } from 'src/context/AuthContext';
import { getAdminRestaurantList, getAdminRestaurantDetails } from 'src/services/api/admin-restaurant.api';
import moment from 'moment';
import { createCustomer, createPayment, updateCustomer } from '../services/api/customer.api';
import AddBeachWarningDialog from '../components/admin/beach/AddBeachWarningDialog';
import { toast } from 'react-toastify';
import { deleteReservationRestaurant, getRestaurantReservationsCustomersPaymentsForADate, getRestaurantReservationsCustomer, updateRestaurantReservation, getHutRestaurantReservationsForAPositionOnADate, createRestaurantReservation, searchReservationByCustomer } from '../services/api/restaurant.api';
import TableViewIcon from '@mui/icons-material/TableView';
import RestaurantIconImage from 'src/components/admin/restaurant/RestaurantIconImage';
import { BEACH_RESTAURANT_ITEM_DATA } from 'src/data/admin-restaurant.data';
import RestaurantReservationTable from 'src/components/admin/restaurant/RestaurantReservationTable';
import DatePickerComponent from 'src/components/shared/DatePickerComponent';
import { useNavigate, useParams } from 'react-router-dom';
import AdminAllRestaurantPage from './admin/AdminAllRestaurantPage';
import AddItemButton from 'src/components/shared/AddItemButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import DeleteDialog from 'src/components/shared/DeleteDialog';
import NoResultDialog from 'src/components/shared/NoResultDialog';
import { useReactToPrint } from "react-to-print";
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';

const DEFAULT_AVAILABLE_TABLE = {
    twoSeat: 0,
    fourSeat: 0,
    sixSeat: 0,
}

export default function RestaurantPage() {
    const colors = ['#EFF8FA', '#B1DEE7', '#73C3D3', '#3BA5BA', '#276E7C', '#14373E', '#E0F2F5', '#92D0DD', '#45AFC4', '#22606D', '#0A1B1F', '#A2D7E2', '#3697AB', '#18454E', '#C1E4EB', '#31899B', '#050E10', '#54B6C9', '#D0EBF0', '#1D525D', '#2C7C8C', '#0F292F', '#69BED0', '#83CAD8'];
    const [selectedBox, setSelectedBox] = useState([]);
    const [warning, setWarning] = useState(null);
    const [selectedEditItem, setSelectedEditItem] = useState(null);
    const [date, setDate] = useState(new Date());
    const [isBookAvailable, setIsBookAvailable] = useState(false);
    const [openBookingForm, setOpenBookingForm] = useState(null);
    const [reservationSelected, setReservationSelected] = useState(null);
    const [reservationInformation, setReservationInformation] = useState('');
    const [beach, setBeach] = useState(null);
    const [getLoading, setGetLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [viewType, setViewType] = useState("list");
    const [filterType, setFilterType] = useState("both"); //both, noon, evening
    const [availableTable, setAvailableTable] = useState({ ...DEFAULT_AVAILABLE_TABLE })
    const [isAdminBook, setIsAdminBook] = useState(false);
    const [isZoom, setIsZoom] = useState(false);
    const { id = null } = useParams();
    const navigate = useNavigate();
    const [isDeleted, setIsDeleted] = useState(false);
    const { user, isSearchClickValue, setSearchGlobal, setSearchClick } = useAuth();
    const [isNoResult, setIsNoResult] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const [modifiedItem, setModifiedItem] = useState(null);

    const getSearchReservation = useCallback(async (value) => {
        if (value) {
            let params = { search: value, date: moment(date).format("YYYY-MM-DD"), type: "restaurant" }
            const searchParams = new URLSearchParams(params);
            const res = await searchReservationByCustomer(searchParams);
            if (res && res.status && res.data && res.data.length > 0) {
                const selectedReservation = res.data[0];
                if (selectedReservation && selectedReservation.restaurant_reservation.length > 0) {
                    const spots = beach.plan;
                    for (let i = 0; i < spots.length; i++) {
                        for (let j = 0; j < spots[i].length; j++) {
                            console.log(spots[i][j]?.reservationId, 'spots[i][j]?.reservationId');
                            if (spots[i][j]?.reservationId == selectedReservation?.restaurant_reservation[0]?.id) {
                                handleDisplayDetails(i, j);
                            }
                        }
                    }
                }
            } else {
                setIsNoResult(true);
            }
        }
    }, [date, beach]);

    useEffect(() => {
        if (isSearchClickValue) {
            getSearchReservation(isSearchClickValue)
        }
        return () => {
            setIsNoResult(false);
            setSearchGlobal('');
            setSearchClick(null)
        };
    }, [isSearchClickValue]);

    useEffect(() => {
        if (id) {
            setIsAdminBook(true)
        } else {
            setIsAdminBook(false)
        }
    }, [id]);

    const getBeachBeachReservationsCustomersPayments = useCallback(async (beachId, selectedDateObj, filterTypeData) => {
        console.log(filterTypeData, 'filterTypeData 1');
        let filterTypeLatest = filterTypeData ? filterTypeData : filterType
        console.log(filterTypeLatest, 'filterTypeLatest here');
        const resCustomersPaymentsData = await getRestaurantReservationsCustomersPaymentsForADate(beachId, moment(selectedDateObj).format("YYYY-MM-DD"), { filterType: filterTypeLatest });
        if (resCustomersPaymentsData && resCustomersPaymentsData.status) {
            const reservationsCustomersPayments = resCustomersPaymentsData.data.map((ele) => {
                ele.details = ele.details ? JSON.parse(ele.details) : [];
                return ele;
            })
            return reservationsCustomersPayments;
        }
        return []
    }, [filterType]);

    const getAllHutRestaurants = useCallback(async (dateObject, idData, filterTypeData = null) => {
        const hutId = user?.id;
        setGetLoading(true)
        await setBeach(null)
        if (idData) {
            const resBeach = await getAdminRestaurantDetails(idData);
            if (resBeach.status && resBeach.data) {
                let activeBeach = resBeach.data;
                if (activeBeach && activeBeach.plan && (activeBeach.status && activeBeach.status.trim() == "active")) {
                    const planData = activeBeach.plan ? JSON.parse(activeBeach.plan)?.plan : [];
                    const _spotPlanArray = planData
                    if (planData.length > 0) {
                        for await (const [i, IValue] of Object.entries(planData)) {
                            for await (const [j, JValue] of Object.entries(IValue)) {
                                _spotPlanArray[i][j].selected = false;
                            }
                        }
                    }
                    const scheduleData = activeBeach.schedule ? JSON.parse(activeBeach.schedule)?.schedule : [];
                    const latestBeach = { ...activeBeach, plan: _spotPlanArray, schedule: scheduleData };
                    await setBeach(latestBeach);
                    await refreshReservationsCustomersPayments(latestBeach, dateObject, filterTypeData)
                    setGetLoading(false)
                } else {
                    setBeach(null)
                    setGetLoading(false)
                }
            }
            setGetLoading(false)
        } else {
            let param = {}
            const resBeach = await getAdminRestaurantList(param, hutId);
            if (resBeach.status && resBeach.data) {
                const restaurantData = resBeach.data;
                let activeBeach = null;
                for (let i = 0; i < restaurantData.length; i++) {
                    if (restaurantData[i].status && restaurantData[i].status.trim() == 'active') {
                        if ((restaurantData[i].schedule) && (restaurantData[i].plan)) {
                            const scheduleData = restaurantData[i].schedule ? JSON.parse(restaurantData[i].schedule)?.schedule : [];
                            for (let j = 0; j < scheduleData.length; j++) {
                                if (scheduleData[j] === moment(dateObject).format('dddd').toLowerCase()) {
                                    activeBeach = restaurantData[i]
                                }
                            }
                        }
                    }
                }
                if (activeBeach) {
                    const planData = activeBeach.plan ? JSON.parse(activeBeach.plan)?.plan : [];
                    const _spotPlanArray = planData
                    if (planData.length > 0) {
                        for await (const [i, IValue] of Object.entries(planData)) {
                            for await (const [j, JValue] of Object.entries(IValue)) {
                                _spotPlanArray[i][j].selected = false;
                            }
                        }
                    }
                    const scheduleData = activeBeach.schedule ? JSON.parse(activeBeach.schedule)?.schedule : [];
                    const latestBeach = { ...activeBeach, plan: _spotPlanArray, schedule: scheduleData };
                    await setBeach(latestBeach);
                    await refreshReservationsCustomersPayments(latestBeach, dateObject, filterTypeData)
                    setGetLoading(false)
                } else {
                    setBeach(null)
                    setGetLoading(false)
                }
            }
            setGetLoading(false)
        }
        setGetLoading(false)
    }, [user, filterType, viewType]);

    const refreshReservationsCustomersPayments = async (reservationObj, dateObjet, filterTypeData) => {
        let reservationsCustomersPayments = undefined;
        if (reservationObj) {
            if (reservationObj.plan) {
                // Get an object with reservations, customers and payments
                // This array is order by date and id. This is important because we want the last object overiding the previous one when there is an out/nosho and the sunbed can be reserved again.
                let _gridPlans = [...reservationObj.plan];
                reservationsCustomersPayments = await getBeachBeachReservationsCustomersPayments(reservationObj.id, dateObjet, filterTypeData);

                if (reservationsCustomersPayments) {
                    // Add loyalty to this object
                    for (let i = 0; i < reservationsCustomersPayments.length; i++) {
                        if (reservationsCustomersPayments[i].customer_id) {
                            let reservationsResponse = await getRestaurantReservationsCustomer(reservationObj.id, reservationsCustomersPayments[i].customer_id)
                            if (reservationsResponse && reservationsResponse.status && reservationsResponse.data) {
                                reservationsCustomersPayments[i].loyalty = reservationsResponse.data.length
                            }
                        }

                    }
                    // Add spot id to this object

                    for (let i = 0; i < reservationsCustomersPayments.length; i++) {
                        for (let j = 0; j < reservationsCustomersPayments[i].details.length; j++) {
                            reservationsCustomersPayments[i].details[j].id = _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].id
                        }
                    }
                    // We have a color palette for each group but we duplicate it if there are to many reservations
                    while (reservationsCustomersPayments.length > colors.length) {
                        colors = colors.concat(colors.slice(0))
                    }


                    // For each reservations we display the spots reserved
                    for (let i = 0; i < reservationsCustomersPayments.length; i++) {
                        for (let j = 0; j < reservationsCustomersPayments[i].details.length; j++) {

                            let type = _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].type
                            let reservationId = reservationsCustomersPayments[i].id;
                            let newType = '';
                            console.log(reservationsCustomersPayments[i].details[j].status, "reservationsCustomersPayments[i].details[j].status");
                            if (type && type.includes(reservationsCustomersPayments[i].details[j].status)) {
                                newType = type.substring(0, type.lastIndexOf("_"))
                            } else {
                                newType = type.substring(0, type.lastIndexOf("_")) + "_" + reservationsCustomersPayments[i].details[j].status
                            }

                            // If "In", we set the right type (and icon) and available to false
                            if (reservationsCustomersPayments[i].status && reservationsCustomersPayments[i].status.trim() == 'in') {
                                newType += "_in"
                                _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].available = false
                            } else if (reservationsCustomersPayments[i].status && reservationsCustomersPayments[i].status.trim() == 'out') {
                                // If "Out", we set to the old type and available to true (the spot can be reserved again)
                                newType = type
                                _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].available = true
                            }
                            // If "Noshow", we set to the old type and available to true (the spot can be reserved again)
                            else if (reservationsCustomersPayments[i].status && reservationsCustomersPayments[i].status.trim() == 'noshow') {
                                newType = type
                                _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].available = true
                            } else {
                                // TODO for now it's always "unpaid" because se don't manage payment                                
                                const statusImage = (reservationsCustomersPayments[i].payment && reservationsCustomersPayments[i].payment.status) ? reservationsCustomersPayments[i].payment.status.trim() : "unpaid";
                                newType += "_" + statusImage;
                                // if (reservationsCustomersPayments[i].details[j].status == "reserved_full_day") {
                                //     newType += type
                                // } else {

                                // }
                                _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].available = false
                            }
                            // New type
                            _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].type = newType
                            // We set a color group
                            _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].color = colors[i]
                            _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].reservationId = reservationId
                        }
                    }
                    // console.log(_gridPlans, '_gridPlans');
                    setBeach(prevState => ({
                        ...prevState,
                        plan: [..._gridPlans],
                    }));
                    await refreshAvailabilities(_gridPlans);
                }
            }
        }
    }

    const refreshAvailabilities = useCallback(async (restaurantPlan) => {
        let twoSeatCountData = 0;
        let fourSeatCountData = 0;
        let sixSeatCountData = 0;
        const spots = restaurantPlan
        if (spots.length > 0) {
            if (spots) {
                for await (const [i, IValue] of Object.entries(spots)) {
                    for await (const [j, JValue] of Object.entries(IValue)) {
                        if (spots[i][j].type.startsWith("dining_table_2") && !spots[i][j].type.includes("unavailable")) {
                            twoSeatCountData++
                        } else if (spots[i][j].type.startsWith("dining_table_4") && !spots[i][j].type.includes("unavailable")) {
                            fourSeatCountData++
                        } else if (spots[i][j].type.startsWith("dining_table_6") && !spots[i][j].type.includes("unavailable")) {
                            sixSeatCountData++
                        }
                    }

                }
            }
            // We substract the reservations (sunbed reserved)
            // Note : we can't do this with reservation object because spots can be moved and be reserved again (out or noshow status)
            for await (const [row, IValue] of Object.entries(spots)) {
                for await (const [col, colValue] of Object.entries(spots[row])) {
                    const spot = spots[row][col]
                    if (!spot.available && (spot.type.startsWith("dining_table_2") || spot.type.startsWith("dining_table_4") || spot.type.startsWith("dining_table_6"))) {
                        if (spot.type.startsWith("dining_table_2")) {
                            if (spot.type.includes("reserved_full_day")) {
                                twoSeatCountData--
                            }
                        } else if (spot.type.startsWith("dining_table_4")) {
                            if (spot.type.includes("reserved_full_day")) {
                                fourSeatCountData--
                            }
                        } else if (spot.type.startsWith("dining_table_6")) {
                            if (spot.type.includes("reserved_full_day")) {
                                sixSeatCountData--
                            }
                        }
                    }
                }
            }
        }
        const tableCountData = {
            twoSeat: twoSeatCountData,
            fourSeat: fourSeatCountData,
            sixSeat: sixSeatCountData
        }
        setAvailableTable(tableCountData);
    }, []);

    useEffect(() => {
        setBeach(null)
        setTimeout(() => {
            if (id) {
                getAllHutRestaurants(date, id)
            } else {
                getAllHutRestaurants(date)
            }
        }, 1000);

    }, [date, user, id, filterType, viewType]);

    const handleChangeDate = useCallback((item) => {
        const latestDate = moment(item).endOf('day').toDate();
        setDate(latestDate);
    }, []);

    const handleNew = useCallback((type = null) => {
        if (isBookAvailable && selectedBox.length > 0) {
            setOpenBookingForm("add")
        } else {
            if (type == "add") {
                toast.error("Veuillez sélectionner un élément");
            } else {
                setIsBookAvailable((prevState) => !prevState);
            }
        }
    }, [isBookAvailable, selectedBox]);

    const handleOnClickBox = useCallback(async (rowIndex, columnIndex) => {
        if (isBookAvailable) {
            let _gridArray = [...beach.plan];
            const existingVal = _gridArray[rowIndex][columnIndex]
            _gridArray[rowIndex][columnIndex] = { ...existingVal, selected: existingVal?.selected ? !existingVal?.selected : true };
            const index = selectedBox.findIndex((item) => item.rowIndex === rowIndex && item.columnIndex === columnIndex);

            if (index !== -1) {
                const latestFacilityList = update(selectedBox, {
                    $splice: [[selectedBox.indexOf({ rowIndex, columnIndex }), 1]],
                });
                setSelectedBox(latestFacilityList);
            } else {
                setSelectedBox([...selectedBox, { rowIndex, columnIndex }]);
            }
            await setBeach(prevState => ({
                ...prevState,
                plan: [..._gridArray],
            }));
        }
    }, [isBookAvailable, selectedBox, beach, setBeach]);

    /* Check if there is already a reservation (concurrency problem) */
    const checkIfAlreadyReservedOnADate = async (date, details, beachId) => {
        // Sometimes the UI is not refresh and n people can reserved the same spot from distinc devices (concurrency problems)
        var alreadyReserved = false
        for (let i = 0; i < details.length; i++) {
            let reservationOnThisSpot = await getHutRestaurantReservationsForAPositionOnADate(beachId, details[i].position.x, details[i].position.y, date, user?.id);
            if (reservationOnThisSpot.status && reservationOnThisSpot.data.length > 0) {
                // Reservations can have Out or Noshow status, so we only check if there are reservations without status or with status equal to In
                if ((reservationOnThisSpot.data.status !== 'out') && (reservationOnThisSpot.data.status !== 'noshow')) {
                    alreadyReserved = true;
                    break
                }
            }
        }
        return alreadyReserved
    }

    const refresh = useCallback(async (filterTypeData = null) => {
        setReservationSelected(null)
        setReservationInformation(null);
        setSelectedEditItem(null);
        setSelectedBox([]);
        setIsBookAvailable(false);
        setLoading(false);
        setOpenBookingForm(false)
        await getAllHutRestaurants(date, id, filterTypeData)
    }, [user, date, id]);

    const reserveOnADate = async (Rdate, reservationDetails, comment, source, restaurantId, customerId, paymentId, reservation_time, isMailSend = false) => {
        let newReservation = {
            date: Rdate,
            details: JSON.stringify(reservationDetails),
            comment: comment,
            source: source,
            restaurant_id: restaurantId,
            customer_id: customerId,
            paymentId: paymentId,
            restaurant: true,
            reservation_time: reservation_time ? reservation_time : null,
            isMailSend: isMailSend
        }
        const res = await createRestaurantReservation(newReservation);
        if (res && res.status_code == 404) {
            toast.error(res.message);
            return true;  
        } else {
            if (res.status && res.data) {
                toast.success("Réservation créée avec succès");
            }
        }
    }

    const getActiveBeachOnADate = useCallback(async (dateData) => {
        const hutId = user.id
        let latestBeach = null;
        let beachesRes = await getAdminRestaurantList({}, hutId);
        if (beachesRes.status && beachesRes.data.length > 0) {
            const beaches = beachesRes.data
            for await (const [i, IValue] of Object.entries(beaches)) {
                if (beaches[i].status === 'active') {
                    const activeBeach = beaches[i];
                    const planData = activeBeach.plan ? JSON.parse(activeBeach.plan)?.plan : [];
                    const scheduleData = activeBeach.schedule ? JSON.parse(activeBeach.schedule)?.schedule : [];
                    if ((planData) && (scheduleData)) {
                        for (let j = 0; j < scheduleData.length; j++) {
                            if (scheduleData[j] === moment(dateData).format('dddd').toLowerCase()) {
                                latestBeach = { ...activeBeach, plan: planData, schedule: scheduleData }
                            }
                        }
                    }
                }
            }
        }

        return latestBeach
    }, [user]);

    const reserve = useCallback(async (values, action) => {
        setLoading(true)
        if (beach) {
            if (beach.plan) {
                if (values.id) {
                    let customer = {
                        firstname: values.firstname,
                        lastname: values.lastname,
                        phone: values.phone,
                        email: values.email
                    }
                    await updateCustomer(values.customerId, customer);
                    let reservation = {
                        comment: values.comment,
                        customer_id: values.customerId,
                        reservation_time: values.reservation_time ? values.reservation_time : null
                    }
                    await updateRestaurantReservation(values.id, reservation);
                    setOpenBookingForm("null")
                    setSelectedEditItem(null);
                    setLoading(false);
                    refresh();
                    // to do :: update code here
                } else {
                    let customer = null;
                    let newCustomer = {
                        firstname: values.firstname,
                        lastname: values.lastname,
                        phone: values.phone,
                        email: values.email
                    }
                    if (values.customerId) {
                        // update customer here
                        const resCustomerUpdate = await updateCustomer(values.customerId, newCustomer);
                        if (resCustomerUpdate.status && resCustomerUpdate.data) {
                            customer = resCustomerUpdate.data;
                        }
                    } else {
                        // create customer here if not exists
                        newCustomer.notifications = true
                        newCustomer.language = 'fr'
                        newCustomer.status = ''
                        newCustomer.member = false
                        newCustomer.hutId = user.id
                        const resCustomerCreate = await createCustomer(newCustomer);
                        if (resCustomerCreate.status && resCustomerCreate.data) {
                            customer = resCustomerCreate.data;
                        }
                    }

                    /* Manage payment TODO */
                    const newPayment = {
                        type: 'on_site',
                        status: 'unpaid',
                        flag: 2,
                    }
                    const payment = await createPayment(newPayment)

                    /* Manage Reservation */
                    // Multiple reservation
                    if (values.multiple) {
                        // Get the current spots selected
                        let reservationDetails = [];
                        for await (const [i, IValue] of Object.entries(beach.plan)) {
                            for await (const [j, JValue] of Object.entries(IValue)) {
                                if (beach.plan[i][j].selected === true) {
                                    reservationDetails.push({ position: { x: i, y: j, col: j, row: i }, type: beach.plan[i][j].type, status: "reserved_full_day" })
                                }
                            }
                        }
                        // We need to check if this multiple reservation is possible or not
                        let ok = true
                        let errorMessage = ''
                        // First check: the start date can be different fron the current date
                        // A customer can select spots on the current date but reserve fo another date range
                        // Thus we check if the beach plan is the same between the current date and the start date
                        // If not we display an error message
                        let startDate = values.dates[0];
                        let dates = values.dates;
                        if (date !== startDate) {
                            let currentDateBeach = await getActiveBeachOnADate(date)
                            let startDateBeach = await getActiveBeachOnADate(startDate)
                            // currentDateBeach and currentDateBeach can be undefined (no active beach on the day)
                            if ((currentDateBeach) && (startDateBeach)) {
                                if (currentDateBeach.id !== startDateBeach.id) {
                                    ok = false
                                    errorMessage += 'Vous avez sélectionné des emplacements le ' + moment(date).format("DD-MM-YYYY") + ' mais votre réservation débute à une autre date. Or le plan de plage n\'est plus le même le ' + moment(startDate).format("DD-MM-YYYY") + '.<br/>'
                                    const modalErrorMessage = "L'un des emplacements vient d'être réservé par une autre personne au même instant. Merci d'effectuer une nouvelle réservation sur d'autres emplacements."
                                    setWarning(modalErrorMessage);
                                }
                            }
                        }
                        // Second check: if a spot is not available on a date (in the date range)
                        for await (const [i, IValue] of Object.entries(dates)) {
                            let alreadyReserved = await checkIfAlreadyReservedOnADate(moment(dates[i]).format("YYYY-MM-DD"), reservationDetails, beach.id);
                            if (alreadyReserved) {
                                ok = false
                                errorMessage += 'Une des places est non disponible le ' + moment(dates[i]).format("DD-MM-YYYY") + '.<br/>'
                            }
                        }
                        if (errorMessage) {
                            setWarning(errorMessage);
                            setLoading(false)
                            return false;
                        }

                        // Third check: if a spot is not the same type (beach plan can change)
                        // Fourth check: if there is no active beach plan
                        for await (const [i, IValue] of Object.entries(dates)) {
                            let activeBeach = await getActiveBeachOnADate(dates[i]);
                            if (activeBeach) {
                                for await (const [j, IValue] of Object.entries(reservationDetails)) {
                                    // Test if the beach plan have at least the same size (lines + columns)
                                    if ((activeBeach.plan.length >= reservationDetails[j].position.x) && (activeBeach.plan[0].length >= reservationDetails[j].position.y)) {
                                        if (activeBeach.plan[reservationDetails[j].position.x][reservationDetails[j].position.y].type != reservationDetails[j].type) {
                                            ok = false
                                            errorMessage += 'Une des places n\'est plus du même type le ' + moment(dates[i]).format("DD-MM-YYYY") + ' (plan de plage différent).<br/>'
                                        }
                                    } else {
                                        ok = false
                                        errorMessage += 'Il n\'y a pas d\'emplacement pour l\'une des places le ' + moment(dates[i]).format("DD-MM-YYYY") + ' (plan de plage différent).<br/>'
                                    }
                                }
                            } else {
                                ok = false
                                errorMessage += 'Il n\'y a pas de plan de plage actif le ' + moment(dates[i]).format("DD-MM-YYYY") + '.<br/>'
                            }
                        }
                        if (errorMessage) {
                            setWarning(errorMessage);
                            setLoading(false)
                            return false;
                        }
                        if (ok) {
                            for await (const [i, IValue] of Object.entries(values.dates)) {
                                let activeBeach = await getActiveBeachOnADate(dates[i])
                                await reserveOnADate(dates[i], reservationDetails, values?.comment, 'direct', activeBeach.id, customer.id, payment.id, values.reservation_time, values?.isMailSend)
                            }
                            setLoading(false);
                            setOpenBookingForm(false);
                            action.resetForm();
                            await refresh(filterType);
                        }
                    } else {  // Classic reservation
                        let reservationDetails = [];
                        for await (const [i, IValue] of Object.entries(beach.plan)) {
                            for await (const [j, JValue] of Object.entries(IValue)) {
                                if (beach.plan[i][j].selected === true) {
                                    reservationDetails.push({ position: { x: i, y: j, col: j, row: i }, type: beach.plan[i][j].type, status: "reserved_full_day" })
                                }
                            }
                        }
                        let alreadyReserved = await checkIfAlreadyReservedOnADate(moment(date).format("YYYY-MM-DD"), reservationDetails, beach.id)
                        if (alreadyReserved.status && alreadyReserved.data.length > 0) {
                            const modalErrorMessage = "L'un des emplacements vient d'être réservé par une autre personne au même instant. Merci d'effectuer une nouvelle réservation sur d'autres emplacements."
                            setLoading(false);
                            setWarning(modalErrorMessage);
                        } else {
                            await reserveOnADate(date, reservationDetails, values?.comment, 'direct', beach.id, customer.id, payment.id, values.reservation_time, values?.isMailSend)
                            setLoading(false);
                            setOpenBookingForm(false);
                            action.resetForm();
                            await refresh(filterType);
                        }
                    }
                }
            }
            setLoading(false)
        }
        setLoading(false)
    }, [beach, date, refresh, user, filterType]);

    const handleFormSubmit = useCallback(async (values, action) => {
        await reserve(values, action);
    }, [reserve]);

    const disableSpots = async () => {
        if (beach) {
            if (beach.plan) {
                const spots = beach.plan;
                for (let i = 0; i < spots.length; i++) {
                    for (let j = 0; j < spots[i].length; j++) {
                        spots[i][j].disabled = true
                    }
                }
                setBeach(prevState => ({
                    ...prevState,
                    plan: [...spots],
                }));
            }
        }
    }

    const enabledSpots = async () => {
        if (beach && beach.plan) {
            const spots = beach.plan;
            for (let i = 0; i < spots.length; i++) {
                for (let j = 0; j < spots[i].length; j++) {
                    spots[i][j].disabled = spots[i][j]?.available ? true : false
                }
            }
            setBeach(prevState => ({
                ...prevState,
                plan: [...spots],
            }));
        }
    }

    const handleEditBooking = useCallback(async (selectedData) => {
        // 123
        setModifiedItem(selectedData)
        if (selectedData && selectedData.details.length > 0) {
            await enabledSpots()
            setReservationSelected(null)
            setReservationInformation(null);
            setSelectedEditItem(null);
            setSelectedBox([]);
            setLoading(false);
            setOpenBookingForm(false)
            setIsBookAvailable(true);

            let reservationDetails = selectedData.details;
            console.log(reservationDetails, 'reservationDetails');
            const selectedBoxes = [];
            for (let i = 0; i < reservationDetails.length; i++) {
                let rowIndex = Number(reservationDetails[i].position.x);
                let columnIndex = Number(reservationDetails[i].position.y)
                let _gridArray = [...beach.plan];
                const existingVal = _gridArray[rowIndex][columnIndex];
                _gridArray[rowIndex][columnIndex] = { ...existingVal, selected: true, available: true, disabled: false };
                selectedBoxes.push({ rowIndex, columnIndex })
                await setBeach(prevState => ({
                    ...prevState,
                    plan: [..._gridArray],
                }));
            }
            setSelectedBox(selectedBoxes)
            console.log(selectedBox, 'selectedBox latest');
        }
    }, [beach, setBeach]);

    const handleUpdateBooking = useCallback(async (selectedData) => {
        // 123        
        let reservationDetails = [];
        for await (const [i, IValue] of Object.entries(beach.plan)) {
            for await (const [j, JValue] of Object.entries(IValue)) {
                if (beach.plan[i][j].selected === true) {
                    console.log(beach.plan[i][j]);
                    reservationDetails.push({
                        position: { x: i, y: j, col: j, row: i },
                        type: beach.plan[i][j].type,
                        status: "reserved_full_day",
                    })
                }
            }
        }
        let alreadyReserved = await checkIfAlreadyReservedOnADate(moment(date).format("YYYY-MM-DD"), reservationDetails, beach.id)
        if (alreadyReserved.status && alreadyReserved.data.length > 0) {
            const modalErrorMessage = "L'un des emplacements vient d'être réservé par une autre personne au même instant. Merci d'effectuer une nouvelle réservation sur d'autres emplacements."
            setLoading(false);
            setWarning(modalErrorMessage);
        } else {
            setLoading(true);
            let reservation = {
                details: JSON.stringify(reservationDetails),
                comment: modifiedItem?.comment,
                date: moment(modifiedItem?.date).format("YYYY-MM-DD")
            }
            await updateRestaurantReservation(modifiedItem?.id, reservation);
            toast.success("Réservation mise à jour avec succès.");
            setModifiedItem(null)
            refresh()
        }
    }, [selectedBox, modifiedItem]);

    const handleDisplayDetails = useCallback(async (line, column) => {
        if (beach) {
            const spots = beach.plan;
            // Disable all spots (sunbed and double sundeb)
            if ((!spots[line][column].available) && ((spots[line][column].type.startsWith("dining_table_2")) || (spots[line][column].type.startsWith("dining_table_4")) || (spots[line][column].type.startsWith("dining_table_6")))) {
                await disableSpots()
            }
            const reservationsCustomersPayments = await getBeachBeachReservationsCustomersPayments(beach.id, date, filterType);
            // Get reservations details
            for (let i = 0; i < reservationsCustomersPayments.length; i++) {
                for (let j = 0; j < reservationsCustomersPayments[i].details.length; j++) {
                    // The one selected : line, column
                    if ((reservationsCustomersPayments[i].details[j].position.x == line) && (reservationsCustomersPayments[i].details[j].position.y == column)) {
                        // If not out or noshow
                        if ((reservationsCustomersPayments[i].status !== 'out') && (reservationsCustomersPayments[i].status !== 'noshow')) {
                            setReservationSelected(reservationsCustomersPayments[i])
                            // Title
                            //this.reservationInformationsTitle = "Réservation Nº " + reservationsCustomersPayments[i].id
                            // Date
                            let infos = "Faite le " + moment(reservationsCustomersPayments[i].createdAt).format('DD/MM/YYYY')
                            infos += "<br/>"
                            infos += "Pour le <strong>" + moment(reservationsCustomersPayments[i].date).format('DD/MM/YYYY') + "</strong>"
                            infos += "<br/>"
                            infos += "Time <strong>" + reservationsCustomersPayments[i]?.reservation_time + "</strong>"
                            infos += "<br/><br/>"
                            // Name
                            infos += "<strong>" + ([reservationsCustomersPayments[i]?.customer?.firstname || '', reservationsCustomersPayments[i]?.customer.lastname || ''].join(' ').trim() || '-') + "</strong>"
                            // Phone
                            if (reservationsCustomersPayments[i]?.customer && reservationsCustomersPayments[i]?.customer.phone) {
                                infos += "<br/>"
                                infos += reservationsCustomersPayments[i]?.customer.phone
                            }
                            // Email
                            if (reservationsCustomersPayments[i]?.customer.email) {
                                infos += "<br/>"
                                infos += reservationsCustomersPayments[i]?.customer.email
                            }
                            // Comment
                            if (reservationsCustomersPayments[i]?.customer.comment) {
                                infos += "<br/><br/>"
                                infos += reservationsCustomersPayments[i]?.customer.comment
                            }
                            // Spots count
                            infos += "<br/><br/>"
                            let spotCount = ''
                            if (reservationsCustomersPayments[i].details.length === 1) {
                                spotCount = reservationsCustomersPayments[i].details.length + " place réservée : "
                            } else if (reservationsCustomersPayments[i].details.length > 1) {
                                spotCount = reservationsCustomersPayments[i].details.length + " places réservées : "
                            }
                            infos += spotCount
                            // Spots (type, status, id/emplacement) and enable them
                            infos += "<ul>"
                            const spotsData = beach.plan;
                            for (let k = 0; k < reservationsCustomersPayments[i].details.length; k++) {
                                let type = ''
                                if (reservationsCustomersPayments[i].details[k].type.startsWith("dining_table_2")) {
                                    type = "Dining table 2 seat"
                                } else if (reservationsCustomersPayments[i].details[k].type.startsWith("dining_table_4")) {
                                    type = "Dining table 4 seat"
                                } else if (reservationsCustomersPayments[i].details[k].type.startsWith("dining_table_6")) {
                                    type = "Dining table 6 seat"
                                }
                                let status = ''
                                if (reservationsCustomersPayments[i].details[k].status === "reserved_full_day") {
                                    status = "journée"
                                } else if (reservationsCustomersPayments[i].details[k].status === "reserved_half_day") {
                                    status = "½ journée"
                                }
                                let location = ''
                                if (spots[reservationsCustomersPayments[i].details[k].position.x][reservationsCustomersPayments[i].details[k].position.y].id) {
                                    location = spots[reservationsCustomersPayments[i].details[k].position.x][reservationsCustomersPayments[i].details[k].position.y].id
                                } else {
                                    location = "[" + (reservationsCustomersPayments[i].details[k].position.x + 1) + ", " + (reservationsCustomersPayments[i].details[k].position.y + 1) + "]"
                                }
                                infos += "<li>" + type + " " + status + " " + location + "</li>"
                                // Enabled this spot
                                spotsData[reservationsCustomersPayments[i].details[k].position.x][reservationsCustomersPayments[i].details[k].position.y].disabled = false
                            }
                            setBeach(prevState => ({
                                ...prevState,
                                plan: [...spotsData],
                            }));
                            infos += "</ul>"
                            // Loyalty
                            let reservations = await getRestaurantReservationsCustomer(beach.id, reservationsCustomersPayments[i].customer_id);
                            let loyalty = reservations?.data.length || 0
                            infos += "Fidélité : " + loyalty
                            // Type (not managed TODO v2)
                            let type = ''
                            if (reservationsCustomersPayments[i].type === "online") {
                                infos += "<br/><br/>"
                                type = "en ligne"
                                let status = ''
                                if (reservationsCustomersPayments[i].pstatus === "unpaid") {
                                    status = "<span style='color:var(--beachbooker-orange)'>non payée<span>"
                                } else if (reservationsCustomersPayments[i].pstatus === "paid") {
                                    status = "<span style='color:green'>payée<span>"
                                }
                                infos += "Paiement " + type
                                infos += "<br/>État : " + status
                            } else if (reservationsCustomersPayments[i].type === "on_site") {
                                // TODO ?
                            }
                            setReservationInformation(infos)
                            console.log(infos, 'infos');
                            // Display informations
                            // this.reservationInformations = infos
                            // this.showSidebarInformations()
                            // Display In / Out / NoShow
                            // this.showHeaderStatus()
                        }
                    }
                }
            }
        }
    }, [beach, date, filterType]);

    const handleDeleteReservation = useCallback(async (id = null) => {
        let beachReserveId = id ? id : reservationSelected?.id;
        const res = await deleteReservationRestaurant(beachReserveId);
        if (res && res.status) {
            toast.success("Supprimer avec succès")
        }
        setIsDeleted(null)
        refresh()
    }, [reservationSelected]);

    const handleHeaderClick = useCallback(async (value) => {
        await updateRestaurantReservation(reservationSelected.id, { status: value });
        refresh()
    }, [reservationSelected]);

    const handleEditReservation = useCallback((row) => {
        setOpenBookingForm("add");
        setSelectedEditItem(row)
    }, []);

    const handleZoom = useCallback(() => {
        setIsZoom((prevState) => !prevState);
    }, []);

    const handleDeleteDialog = useCallback((id) => {
        setIsDeleted(id)
    }, []
    );

    const printMapRef = useRef(null);
    const reactToPrintContent = useCallback(() => {
        console.log(printMapRef.current, 'printMapRef.current');
        return printMapRef.current;
    }, [printMapRef]);

    const handleBeforePrint = useCallback(() => {
        setPrintLoading(true)
    }, []);

    const handleAfterPrint = useCallback(() => {
        setPrintLoading(false)
    }, []);

    const handlePrintMap = useReactToPrint({
        ...viewType == "table" && {
            pageStyle: `@media print {
            @page {
              size: 500mm 500mm;
              margin: 0;
            }
          }`},
        content: reactToPrintContent,
        documentTitle: "beachMap",
        copyStyles: true,
        // onBeforeGetContent: handleOnBeforeGetContent,
        onBeforePrint: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true
    });

    return (
        <>
            <Helmet>
                <title> Beach | Beach Booker - Pro </title>
            </Helmet>
            <Container maxWidth={false} sx={{ padding: "0 !important" }}>
                {user && user.superadmin && !id ? (
                    <AdminAllRestaurantPage />
                ) : (
                    <Box sx={{ minHeight: `calc(100vh - 134px) !important`, display: 'flex' }}>
                        <BeachSidebar>
                            <Stack direction="row" alignItems="center" justifyContent="center">
                                <Scrollbar>
                                    {modifiedItem && (
                                        <>
                                            <Box direction={"row"}>
                                                <div><b>RESERVATION NO : {modifiedItem?.id} </b></div>
                                                <div>&nbsp;</div>
                                            </Box>
                                            <Stack direction={"row"} sx={{ mt: 2 }} size="small" justifyContent={"center"}>
                                                <DatePicker
                                                    value={modifiedItem?.date ? moment(modifiedItem?.date, "YYYY-MM-DD").toDate() : null}
                                                    onChange={(date) => setModifiedItem(prevState => ({
                                                        ...prevState,
                                                        date: date,
                                                    }))}
                                                    label="Booking Date"
                                                    size="small"
                                                    renderInput={(params) => <TextField size='small' {...params} />}
                                                />
                                            </Stack>
                                            <Stack direction={"row"} sx={{ mt: 2 }} size="small" justifyContent={"center"}>
                                                <TextField
                                                    value={modifiedItem?.comment || null}
                                                    onChange={(e) => setModifiedItem(prevState => ({
                                                        ...prevState,
                                                        comment: e.target.value,
                                                    }))}
                                                    label="Comment"
                                                    size="small"
                                                />
                                            </Stack>
                                            <Stack direction={"row"} sx={{ mt: 2 }} size="small" justifyContent={"center"}>
                                                <Button variant="contained" onClick={() => handleUpdateBooking(modifiedItem)}>Mise à jour</Button>
                                            </Stack>
                                            <Stack direction={"row"} sx={{ mt: 2 }} size="small" justifyContent={"center"}>
                                                <Button variant="contained" onClick={() => {
                                                    setModifiedItem(null)
                                                    refresh()
                                                }}>Retour</Button>
                                            </Stack>
                                        </>
                                    )}
                                    {!modifiedItem && (<>
                                        {reservationSelected ? (<>
                                            <Box direction={"row"}>
                                                <div><b>RÉSERVATION NON : {reservationSelected?.id} </b></div>
                                                <div>&nbsp;</div>
                                                {reservationInformation && (<div dangerouslySetInnerHTML={{ __html: reservationInformation }} />)}
                                            </Box>
                                            <Stack direction={"row"} sx={{ mt: 2 }} size="small" justifyContent={"center"}>
                                                <Button variant="contained" onClick={refresh}>Retour</Button>
                                            </Stack>
                                            <Stack direction={"row"} sx={{ mt: 2 }} size="small" justifyContent={"center"}>
                                                <Button variant="contained" onClick={() => handleEditBooking(reservationSelected)}>Modifier</Button>
                                            </Stack>
                                            <Stack direction={"row"} sx={{ mt: 2 }} size="small" justifyContent={"center"}>
                                                <Button variant="contained" onClick={() => handleDeleteDialog(reservationSelected?.id)}>Supprimer</Button>
                                            </Stack>
                                        </>
                                        ) : (
                                            <>
                                                <AddItemButton
                                                    handleNew={handleNew}
                                                    handleCancel={refresh}
                                                    isBookAvailable={isBookAvailable}
                                                />
                                                <Stack item sx={{ mt: 2 }}>
                                                    <DatePickerComponent
                                                        handleChangeDate={handleChangeDate}
                                                        date={date}
                                                        className='date-range-beach'
                                                    />
                                                </Stack>
                                                <Stack item sx={{ mt: 2 }}>
                                                    <Typography color="#fff">DISPONIBILITÉS</Typography>
                                                    <Divider sx={{ width: "50px", height: "5px", background: "#fff" }}></Divider>
                                                </Stack>
                                                <Stack item sx={{ mt: 2 }}>
                                                    <Typography variant="body1"><span style={{ color: "#fff" }}>Tables 2 places :</span> {availableTable.twoSeat || 0}</Typography>
                                                    <Typography variant="body1"><span style={{ color: "#fff" }}>Tables 4 places :</span> {availableTable.fourSeat || 0}</Typography>
                                                    <Typography variant="body1"><span style={{ color: "#fff" }}>Tables 6 places :</span> {availableTable.sixSeat || 0}</Typography>
                                                </Stack>
                                                {!isAdminBook && (
                                                    <Stack item sx={{ mt: 4, textAlign: "center" }}>
                                                        <Stack direction={"row"} spacing={1}>
                                                            {viewType === "list" ? (
                                                                <IconButton onClick={() => setViewType("table")}>
                                                                    <TableViewIcon sx={{ color: "#000" }} fontSize="large" />
                                                                </IconButton>
                                                            ) : (
                                                                <IconButton onClick={() => setViewType("list")}>
                                                                    <ListIcon sx={{ color: "#000" }} fontSize="large" />
                                                                </IconButton>
                                                            )}
                                                            <IconButton onClick={handleZoom}>{isZoom ? <FullscreenIcon sx={{ color: "#000" }} fontSize="large" /> : <FitScreenIcon fontSize="large" sx={{ color: "#000" }} />}</IconButton>
                                                        </Stack>
                                                        {user && beach && user.id == beach.hut_id && (
                                                            <Stack direction={"row"} sx={{ mt: 2 }} justifyContent={"center"}>
                                                                <Button onClick={() => navigate(`/admin/hut/restaurant/${beach.id}`)} size='small' variant="contained" >Modifier</Button>
                                                            </Stack>
                                                        )}
                                                        <Stack direction={"row"} sx={{ mt: 2 }} justifyContent={"center"}>
                                                            <LoadingButton loading={printLoading} onClick={handlePrintMap} size='small' variant="contained">Imprimer</LoadingButton>
                                                        </Stack>
                                                    </Stack>
                                                )}
                                            </>
                                        )}
                                    </>)}
                                </Scrollbar>
                            </Stack>
                        </BeachSidebar>

                        <BeachContentHeader
                            type={"Restaurant"}
                            sx={{ mt: 10.5, ml: 0 }}
                            setFilterType={setFilterType}
                            filterType={filterType}
                            reservationSelected={reservationSelected}
                            handleHeaderClick={handleHeaderClick}
                        >
                            {!beach && !getLoading && (
                                <Grid container direction={"row"}>
                                    <Grid item xs={12}>
                                        <Alert variant="filled" color="warning">Il n'y a aucun plan de plage actif sur cette journée.</Alert>
                                    </Grid>
                                </Grid>
                            )}
                            {beach && (
                                <div ref={printMapRef}>
                                    <Grid container direction={"row"} sx={{ mt: 1, ml: 1, pr: "30px" }}>
                                        {viewType === "list" &&
                                            <>
                                                <Grid item xs={12} sx={{ zoom: isZoom ? "120%" : "100%" }}>
                                                    {getLoading && (
                                                        <Box sx={{ display: 'flex', mt: 20, ml: 20 }}>
                                                            <CircularProgress />
                                                        </Box>
                                                    )}
                                                    {!getLoading && beach?.plan.length && beach?.plan.map((row, rowIndex) => (
                                                        <Grid
                                                            container
                                                            wrap="nowrap"
                                                            sx={{ overflow: 'auto' }}
                                                            direction={"row"}
                                                            spacing={0.5}

                                                        >
                                                            {row.map((column, columnIndex) => {
                                                                let imgSrc;
                                                                let isClickable = true
                                                                if (column.hasOwnProperty('available') && column.available === false) {
                                                                    isClickable = false
                                                                    imgSrc = "beachbooker_restaurant_" + column.type + ".svg";
                                                                } else {
                                                                    imgSrc = !modifiedItem ? _.find(BEACH_RESTAURANT_ITEM_DATA, { name: column.type })?.img : "beachbooker_restaurant_" + column.type + ".svg";
                                                                }

                                                                return (
                                                                    <Grid item sx={{ minHeight: "52px", paddingLeft: "0px !important" }}>
                                                                        <RestaurantIconImage
                                                                            width={50}
                                                                            height={50}
                                                                            src={imgSrc}
                                                                            name={column.type}
                                                                            className={`${column.disabled && column.disabled === true ? "disabled" : null} logo-img`}
                                                                            sx={{
                                                                                border: "none",
                                                                                ...!isBookAvailable && { cursor: "not-allowed" },
                                                                                ...(!column.isAvailable) && { background: column.color },
                                                                                background: column.selected ? "#ec6935" : "inherit",
                                                                                ...(!column.disabled && reservationInformation) && { background: "#ec6935" },
                                                                            }}
                                                                            handleOnClick={() => {
                                                                                if (column.type && (column.type.startsWith("dining_table"))) {
                                                                                    if (!isClickable) {
                                                                                        if (!modifiedItem) {
                                                                                            handleDisplayDetails(rowIndex, columnIndex)
                                                                                        }
                                                                                    } else {
                                                                                        handleOnClickBox(rowIndex, columnIndex)
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                        <Typography
                                                                            fontSize={".7em"}
                                                                            textAlign={"center"}
                                                                            sx={{ mt: "-5px", color: "#2c3e50" }}
                                                                        >{column.id} &nbsp;</Typography>
                                                                    </Grid>
                                                                )
                                                            })}
                                                        </Grid>

                                                    ))}
                                                </Grid>
                                            </>
                                        }
                                        {viewType === "table" &&
                                            <RestaurantReservationTable
                                                date={date}
                                                restaurant={beach}
                                                filterType={filterType}
                                                handleClickDelete={handleDeleteReservation}
                                                handleEditReservation={handleEditReservation}
                                            />
                                        }
                                    </Grid>
                                </div>
                            )}
                        </BeachContentHeader>
                    </Box>
                )}
            </Container >
            {openBookingForm === "add" && (
                <AddEditBeachReservationDialog
                    loading={loading}
                    date={date}
                    type={"restaurant"}
                    selectedEditItem={selectedEditItem}
                    handleFormSubmit={handleFormSubmit}
                    onClose={() => {
                        refresh();
                    }}
                />
            )
            }
            {
                warning && (
                    <AddBeachWarningDialog
                        message={warning}
                        onClose={() => setWarning(null)}
                    />
                )
            }
            {isDeleted && (
                <DeleteDialog
                    title='Supprimer la réservation'
                    message='Voulez-vous vraiment supprimer ?'
                    onClose={() => {
                        setIsDeleted(null)
                    }}
                    handleClickDelete={() => {
                        handleDeleteReservation(isDeleted)
                    }}
                />
            )}
            {isNoResult && (
                <NoResultDialog
                    title='Recherche'
                    message="Il n'y a aucun résultat pour cette recherche"
                    onClose={() => {
                        setIsNoResult(false)
                    }}
                />
            )}
        </>
    );
}
