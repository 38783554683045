import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

export default function NameColumn({ name = null }) {
    return (
        <Typography variant="subtitle2" noWrap>
            {name}
        </Typography>
    );
}

NameColumn.propTypes = {
    name: PropTypes.string,
};
