/* eslint-disable no-undef */
import React from 'react'
import { useDrop } from 'react-dnd'
import { Typography } from '@mui/material'
import BeachIconImage from './BeachIconImage'
import { BEACH_ITEM_DATA } from 'src/data/admin-beach.data'

export const DropItemBeach = ({ id, name, type, rowIndex, columnIndex, selected, handleOnClick, handleEditValue }) => {
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: "pet",
        drop: () => ({ rowIndex, columnIndex }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }))
    const isActive = canDrop && isOver;

    return (
        <div className='basket-card' ref={drop} data-testid="dustbin" style={{ opacity: isActive ? 0.1 : 1 }}>
            <BeachIconImage
                width={50}
                height={50}
                src={_.find(BEACH_ITEM_DATA, { name: type })?.img}
                name={type}
                sx={{ border: selected ? "1px solid #69bed0" : "none" }}
                handleOnClick={handleOnClick}
            />
            <Typography
                {...(id ? { onClick: () => handleEditValue(rowIndex, columnIndex, id) } : {})}
                textAlign={"center"}
                fontSize={".7em"}
                sx={{ cursor: "pointer", mt: "-5px", color: "#2c3e50"  }}
            >{id && id} &nbsp;</Typography>
        </div >
    )
}