import { FormHelperText, MenuItem, Select, Stack } from '@mui/material';
import { ErrorMessage } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import Editor from 'react-simple-wysiwyg';

const EditorFieldWithLanguage = ({
    form: { setFieldValue },
    field: { name, value },
    inputFormat,
    label,
    handleCancel,
    onClose,
    ...props
}) => {
    const [selectedLangValue, setSelectedLangValue] = useState("fr")
    const [selectedValue, setSelectedValue] = useState(value ? value : null);

    const onChange = useCallback(
        (event) => {
            setSelectedValue({ ...selectedValue, [selectedLangValue]: event.target.value })
            setFieldValue(name, { ...selectedValue, [selectedLangValue]: event.target.value });
        },
        [selectedValue, selectedLangValue, setFieldValue, name]
    );

    useEffect(() => {
        setSelectedValue(value)
    }, [value]);

    const handleSelectLangauage = useCallback((e) => {
        setSelectedValue({ ...selectedValue, [e.target.value]: selectedValue[e.target.value] ? selectedValue[e.target.value] : null })
        setSelectedLangValue(e.target.value)
    }, [selectedValue]);

    return (
        <>
            <Stack direction={"row"} className='rsw-editor-custome' sx={{ width: "100%" }}>
                <Editor
                    onChange={onChange}
                    value={selectedValue && selectedValue[selectedLangValue] ? selectedValue[selectedLangValue] : null}
                    style={{ width: "100%" }}
                />
                <Select
                    onChange={handleSelectLangauage}
                    value={selectedLangValue}
                >
                    <MenuItem value="fr">FR</MenuItem>
                    <MenuItem value="en">EN</MenuItem>
                </Select>
            </Stack>
            <FormHelperText color="error"> <ErrorMessage name={name} /></FormHelperText>
        </>
    );
}

export default EditorFieldWithLanguage