import api from "./api";

const getBeachList = async (body) => {
    const response = await api.get(`api/beach-list`, body);
    return response;
};

const getBeachDetail = async (id) => {
    const response = await api.get(`api/beach-detail/${id}`);
    return response;
};

const createPayment = async (body) => {
    const response = await api.post(`api/create-payment`, body);
    return response;
};

const createReservation = async (body) => {
    const response = await api.post(`api/create-reservation`, body);
    return response;
};

const updateBeachReservation = async (id,body) => {
    const response = await api.put(`api/update-reservation-beach/${id}`, body);
    return response;
};

const updateCustomer = async (id, body) => {
    const response = await api.put(`api/huts/1/customers`, body);
    return response;
};

const searchCustomerList = async (hutId, params) => {
    const response = await api.get(`api/search-customer/${hutId}`, { params });
    return response;
};

const reservationBeachDetail = async (reservationId, params) => {
    const response = await api.get(`api/reservation-beach-detail/${reservationId}`, { params });
    return response;
};

const reservationBeachCustomerPayments = async (beachId, date, params) => {
    const response = await api.get(`api/reservation-beach-customer-payments/${beachId}/${date}`, { params });
    return response;
};

const hutReservationBeach = async (hutId, params) => {
    const response = await api.get(`api/hut-reservation-beach/${hutId}`, { params });
    return response;
};

const getHutBeachReservationsForAPositionOnADate = async (beachId, x, y, date, hutId) => {
    const response = await api.get(`api/check-reservation/${beachId}/${x}/${y}/${date}`);
    return response;
}

const getBeachBeachReservationsCustomersPaymentsForADate = async (beachId, date) => {
    const response = await api.get(`api/reservation-beach-customer-payments/${beachId}/${date}`);
    return response;
}

const getHutBeachReservationsForACustomer = async (beachId, customerId) => {
    const response = await api.get(`api/reservation-beach-customers/${beachId}/${customerId}`);
    return response;
}

const deleteReservationBeach = async (id) => {
    const response = await api.delete(`api/delete-reservation-beach/${id}`);
    return response;
};


const reservationTableDetail = async (beachId, date, params) => {
    const response = await api.get(`api/reservation-detail/${beachId}/${date}`, { params });
    return response;
};

export {
    getBeachDetail,
    reservationBeachDetail,
    reservationTableDetail,
    deleteReservationBeach,
    hutReservationBeach,
    getHutBeachReservationsForACustomer,
    reservationBeachCustomerPayments,
    getBeachBeachReservationsCustomersPaymentsForADate,
    getHutBeachReservationsForAPositionOnADate,
    getBeachList,
    createReservation,
    createPayment,
    updateCustomer,
    searchCustomerList,
    updateBeachReservation
};