import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography } from '@mui/material';
import AddEditRestaurantForm from 'src/components/admin/restaurant/AddEditRestaurantForm';

export default function AdminRestaurant() {

    return (
        <>
            <Helmet>
                <title> Restaurant | Beach Booker - Pro </title>
            </Helmet>
            <Container maxWidth={false}>
                <Typography variant='h3' sx={{ mb: 1 }}>Restaurant</Typography>
                <Grid container direction={'row'} justifyContent={'space-between'}>
                    <Grid item>
                        <AddEditRestaurantForm />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
