import api from "./api";


const createRestaurantPayment = async (body) => {
    const response = await api.post(`api/create-payment`, body);
    return response;
};

const createRestaurantReservation = async (body) => {
    const response = await api.post(`api/create-restaurant-reservation`, body);
    return response;
};


const getHutRestaurantReservationsForAPositionOnADate = async (restaurantId, x, y, date, hutId) => {
    const response = await api.get(`api/check-reservation-restaurant/${restaurantId}/${x}/${y}/${date}`);
    return response;
}

const updateRestaurantReservation = async (id, body) => {
    const response = await api.put(`api/update-reservation-restaurant/${id}`, body);
    return response;
};


const getRestaurantReservationsCustomersPaymentsForADate = async (restaurantId, date, params) => {
    const response = await api.get(`api/reservation-restaurant-customer-payments/${restaurantId}/${date}`, {params});
    return response;
}

const getRestaurantReservationsCustomer = async (restaurantId, customerId) => {
    const response = await api.get(`api/reservation-restaurant-customers/${restaurantId}/${customerId}`);
    return response;
}

const deleteReservationRestaurant = async (id) => {
    const response = await api.delete(`api/delete-reservation-restaurant/${id}`);
    return response;
};

const restaurantReservationTableDetail = async (restaurantId, date, params) => {
    const response = await api.get(`api/reservation-restaurant-all-detail/${restaurantId}/${date}`, { params });
    return response;
};

const searchReservationByCustomer = async (params) => {
    const response = await api.get(`api/reservation-search`, { params });
    return response;
}

export {
    searchReservationByCustomer,
    restaurantReservationTableDetail,
    createRestaurantPayment,
    createRestaurantReservation,
    getHutRestaurantReservationsForAPositionOnADate,
    getRestaurantReservationsCustomersPaymentsForADate,
    deleteReservationRestaurant,
    getRestaurantReservationsCustomer,
    updateRestaurantReservation
};