import { Link, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useCallback, useEffect, useState } from 'react';
import { Grid, Container, Button, Stack, Alert } from '@mui/material';
import { CheckBoxColumn, DataTable, ScheduleColumn } from '../../components/dataTable';
import {
    createAdminRestaurant,
    deleteAdminRestaurant,
    getAdminRestaurantList,
    updateAdminRestaurant,
} from '../../services/api/admin-restaurant.api';
import { toast } from 'react-toastify';
import AddRestaurantDialog from 'src/components/admin/restaurant/add-restaurant-dialog/AddRestaurantDialog';

export default function AdminRestaurantPage() {
    const [action, setAction] = useState(null);
    const [queryFilter, setQueryFilter] = useState();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [warningMessage, setWarningMessage] = useState(null);
    const navigate = useNavigate();
    const { id: hutId } = useParams()

    const getDataList = useCallback(async (queryFilter) => {
        const res = await getAdminRestaurantList(queryFilter, hutId);
        if (res && res.data && res.data.data) {
            setData(res.data.data);
            setTotal(res.data.total);
        } else {
            setData([]);
            setTotal(0);
        }
    }, [hutId]);

    useEffect(() => {
        getDataList(queryFilter);
    }, [getDataList, queryFilter]);

    const handleOnCloseDialog = useCallback(() => {
        setAction(null);
    }, []);

    const handleDelete = useCallback(
        async (id) => {
            const isDeleted = window.confirm('Etes-vous sûr que vous voulez supprimer ?');
            if (isDeleted === true) {
                const res = await deleteAdminRestaurant(id);
                if (res.status) {
                    setData(data.filter((item) => item.id !== id));
                    toast.success('supprimé avec succès');
                } else {
                    toast.error('Erreur lors de la suppression');
                }
            }
        },
        [data]
    );

    const handleActivateBeach = useCallback(
        async (row) => {
            const beach = row;
            const beaches = data;
            let beachSchedule = [false, false, false, false, false, false, false];
            let beachesSchedule = [false, false, false, false, false, false, false];
            let beachCanBeActivate = true;
            console.log(beach);
            const beachScheduleData = beach.schedule ? JSON.parse(beach.schedule)?.schedule : [];
            // If it's a new beach plan, 0 day are active but if not there may be so we check
            if (beachScheduleData) {
                for (let i = 0; i < beachScheduleData.length; i++) {
                    if (beachScheduleData[i] === 'monday') {
                        beachSchedule[0] = true;
                    } else if (beachScheduleData[i] === 'tuesday') {
                        beachSchedule[1] = true;
                    } else if (beachScheduleData[i] === 'wednesday') {
                        beachSchedule[2] = true;
                    } else if (beachScheduleData[i] === 'thursday') {
                        beachSchedule[3] = true;
                    } else if (beachScheduleData[i] === 'friday') {
                        beachSchedule[4] = true;
                    } else if (beachScheduleData[i] === 'saturday') {
                        beachSchedule[5] = true;
                    } else if (beachScheduleData[i] === 'sunday') {
                        beachSchedule[6] = true;
                    }
                }
            }

            // We get every day already active in all beach plans
            for (let i = 0; i < beaches.length; i++) {
                if (beaches[i].status === 'active' && beaches[i].id !== beach.id) {
                    const beachsScheduleData = beaches[i].schedule ? JSON.parse(beaches[i].schedule)?.schedule : [];
                    if (beachsScheduleData) {
                        for (var j = 0; j < beachsScheduleData.length; j++) {
                            if (beachsScheduleData[j] === 'monday') {
                                beachesSchedule[0] = true;
                            } else if (beachsScheduleData[j] === 'tuesday') {
                                beachesSchedule[1] = true;
                            } else if (beachsScheduleData[j] === 'wednesday') {
                                beachesSchedule[2] = true;
                            } else if (beachsScheduleData[j] === 'thursday') {
                                beachesSchedule[3] = true;
                            } else if (beachsScheduleData[j] === 'friday') {
                                beachesSchedule[4] = true;
                            } else if (beachsScheduleData[j] === 'saturday') {
                                beachesSchedule[5] = true;
                            } else if (beachsScheduleData[j] === 'sunday') {
                                beachesSchedule[6] = true;
                            }
                        }
                    }
                }
            }

            // We compare days already active to days enabled in the current beach plan
            for (let i = 0; i < beachSchedule.length; i++) {
                if (beachSchedule[i] === true && beachesSchedule[i] === true) {
                    beachCanBeActivate = false;
                }
            }
            if (beachCanBeActivate) {
                handleChangeStatus('active', row.id);
            } else {
                setWarningMessage(
                    "Impossible d'activer ce plan plage car celui-ci est planifié sur des jours déjà réservés par l'un des autres plans de plage actifs. Merci donc de bien vouloir modifier ce plan avant de l'activer."
                );
                setTimeout(() => {
                    setWarningMessage(null);
                }, 3000);
            }
        },
        [data]
    );

    const handleCheckBoxChange = useCallback(async (e, row, type) => {
        console.log(e.target.checked);
        await updateAdminRestaurant({ [type]: e.target.checked }, row.id)
        toast.success('Enregistré avec succès');
    }, []);

    const columns = [
        {
            name: 'name',
            label: 'Nom',
            isSearchable: true,
            isSortable: true,
            render: (row) => {
                return (
                    <Link className='edit-link' to={`/admin/hut/restaurant/${row.id}`}>
                        {row.name}
                    </Link>
                );
            },
        },
        {
            name: 'status',
            label: 'Status',
            isSearchable: false,
            isSortable: true,
            render: (row) => {
                return `Plan ${row.status}`;
            },
        },
        {
            name: 'schedule',
            label: 'Planification',
            isSearchable: false,
            isSortable: true,
            render: (row) => <ScheduleColumn row={row} />,
        },
        {
            name: 'is_show_customer ',
            label: 'Disponible B2C',
            isSearchable: false,
            isSortable: false,
            render: (row) => <CheckBoxColumn key={row.id} handleChange={(e) => handleCheckBoxChange(e, row, "is_show_customer")} checked={`${row?.is_show_customer}`} />,
        },
        // {
        //     name: 'halfday',
        //     label: '½ journée',
        //     isSearchable: false,
        //     isSortable: true,
        //     render: (row) => <CheckBoxColumn checked={`${row.halfday}`} />,
        // },
        {
            name: '',
            label: 'Action',
            props: { align: 'center' },
            render: (row) => (
                <Stack direction={'row'} spacing={2} justifyContent="center">
                    {row?.status && row?.status.toLowerCase() == "inactive" && (
                        <Button size="small" variant="outlined" onClick={() => handleActivateBeach(row)}>
                            Active
                        </Button>
                    )}
                    {row?.status && row?.status.toLowerCase() == "active" && (
                        <Button size="small" variant="outlined" onClick={() => handleChangeStatus('inactive', row.id)}>
                            InActive
                        </Button>
                    )}
                    <Button size="small" color="error" variant="outlined" onClick={() => handleDelete(row.id)}>
                        Delete
                    </Button>
                </Stack>
            ),
        },
    ];

    const handleDataTableChange = useCallback((filter) => {
        setQueryFilter(filter);
    }, []);

    const handleFormSubmit = useCallback(
        async (values) => {
            const res = await createAdminRestaurant(values);
            if (res.status) {
                toast.success(res.message);
                getDataList(queryFilter);
                handleOnCloseDialog();
            } else {
                toast.error(res.error);
            }
        },
        [getDataList, handleOnCloseDialog, queryFilter]
    );

    const handleChangeStatus = useCallback(
        async (value, id) => {
            const res = await updateAdminRestaurant({ status: value }, id);
            if (res.status) {
                getDataList(queryFilter);
                toast.success('sauvegarde réussie');
            }
        },
        [getDataList, queryFilter]
    );

    return (
        <>
            <Helmet>
                <title> Restaurant | Beach Booker - Pro </title>
            </Helmet>
            <Container maxWidth={false} sx={{ mt: 5 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container direction={'row'} justifyContent={'space-between'}>
                            <Grid item>
                                <Button onClick={() => setAction('add')} variant="contained">
                                    Ajouter un restaurant
                                </Button>
                            </Grid>
                            <Grid item>{warningMessage && <Alert severity="warning">{warningMessage}</Alert>}</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <DataTable
                            data={data}
                            columns={columns}
                            initialLoading
                            totalRow={total}
                            onChange={handleDataTableChange}
                        />
                    </Grid>
                </Grid>
            </Container>
            {action === 'add' && <AddRestaurantDialog hutId={hutId} handleFormSubmit={handleFormSubmit} onClose={handleOnCloseDialog} />}
        </>
    );
}
