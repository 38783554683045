import { Box, Drawer, Stack } from '@mui/material';
import Scrollbar from 'src/components/scrollbar/Scrollbar';

export default function DragDropSidebar({ isDragAndDrop, children, width="250px" }) {
    return (
        <Drawer
            anchor={"right"}
            open={isDragAndDrop}
            variant="persistent"
        >
            <Box sx={{ py: 2, px: 2, width: width }} >
                <Stack direction="row" alignItems="center" justifyContent="center">
                    <Scrollbar>
                        {children}
                    </Scrollbar>
                </Stack>
            </Box>
        </Drawer>
    );
}
