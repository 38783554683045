/* eslint-disable array-callback-return */
import { Helmet } from 'react-helmet-async';
import { Grid, Container, Alert, Typography, AlertTitle } from '@mui/material';
import CampaignForm from '../components/marketing/CampaignForm';
import FilterForm from '../components/marketing/FilterForm';
import { useCallback, useEffect, useState } from 'react';
import { searchMarketingCustomerList, updateMarketing, bodyListMarketing, createMarketing, sendMarketingMail } from 'src/services/api/customer.api';
import { useAuth } from 'src/context/AuthContext';
import MarketingCustomerTable from 'src/components/marketing/MarketingCustomerTable';
import _ from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';

export default function MarketingPage() {
    const [data, setData] = useState([]);
    const { user } = useAuth();
    const [selected, setSelected] = useState([]);
    const [selectedCampaing, setSelectedCampaing] = useState(null);

    const getBodyData = useCallback(async (id) => {
        const res = await bodyListMarketing(id);
        if (res && res.status && res.data) {
            setSelectedCampaing(res.data)
        }
    }, []);

    useEffect(() => {
        if (user) {
            getBodyData(user.id);
        }
    }, [getBodyData, user]);

    const handleSubmitForm = useCallback(async (values, actions) => {
        actions.setSubmitting(true)
        if (user) {
            setData([])
            let searchValue = values;
            if (values && values.dateRange) {
                console.log(values.dateRange, 'values.dateRange');
                searchValue.from = moment(values.dateRange.startDate).format("YYYY-MM-DD");
                searchValue.to = moment(values.dateRange.endDate).format("YYYY-MM-DD");
            }
            const res = await searchMarketingCustomerList(user.id, searchValue);
            if (res.status && res.data && res.data.length > 0) {
                setData(res.data);
            }
        }

        actions.setSubmitting(false);
    }, [user]);

    const handleSubmitCampaingForm = useCallback(async (values, actions) => {
        if (selected && selected.length > 0) {
            if (selectedCampaing && selectedCampaing.id) {
                await updateMarketing(user.id, { subject: values.subject, body: values.body, hutId: user.id })
            } else {
                await createMarketing({ subject: values.subject, body: values.body, hutId: user.id })
            }
            let bcc = ''
            let emails = []
            selected.map((ele) => {
                const customer = _.find(data, { id: ele });
                if (customer && customer.email) {
                    bcc += customer.email + ', '
                    emails.push(customer.email)
                }
            })

            if (bcc) {
                bcc = bcc.slice(0, -2)
            }
            if (emails.length > 0) {
                const body = {
                    subject: values.subject,
                    body: values.body,
                    to: emails,
                    admin_email: user?.login
                }
                const res = await sendMarketingMail(body);
                if (res && res.message) {
                    toast.success(res.message);
                }
            }
            // var link = "mailto:" + user.login
            //     + "?bcc=" + bcc
            //     + "&subject=" + encodeURIComponent(values.subject)
            //     + "&body=" + encodeURIComponent(values.body)
            // getBodyData(user.id);
            // window.open(link)
        } else {
            toast.error("Sélectionnez au moins un client");
        }

    }, [selectedCampaing, selected, user, getBodyData, data]);

    return (
        <>
            <Helmet>
                <title> Marketing | Beach Booker - Pro </title>
            </Helmet>
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Alert severity="primary">
                            <AlertTitle>Choisissez les utilisateurs destinataires de votre message</AlertTitle>
                            1) Spécifiez les critères de recherche puis cliquez sur Filtrer <br />
                            2) Saisissez votre sujet et votre message puis Cliquez sur Envoyer <br />
                        </Alert>
                        <FilterForm
                            handleSubmitForm={handleSubmitForm}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CampaignForm
                            handleSubmitCampaingForm={handleSubmitCampaingForm}
                            selectedCampaing={selectedCampaing}
                        />
                    </Grid>
                    {data.length == 0 && (
                        <Grid item xs={12}>
                            <Alert severity="warning" icon={false} sx={{ ".MuiAlert-message": { ml: 50 } }}>
                                <Typography align="center" paragraph={false} variant='subtitle2'>
                                    Il n'y a aucun client avec ces filtres. N'oubliez pas d'appuyer sur le bouton Filtrer pour mettre à jour vos recherche.
                                </Typography>
                            </Alert>
                        </Grid>
                    )}
                    {data.length > 0 && (
                        <Grid item xs={12}>
                            <MarketingCustomerTable
                                setSelected={setSelected}
                                selected={selected}
                                rows={data}
                            />
                        </Grid>
                    )}
                </Grid>
            </Container>
        </>
    );
}
