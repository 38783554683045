import { Box, FormHelperText } from '@mui/material';
import { ErrorMessage } from 'formik';
import { useCallback } from 'react';
import Editor from 'react-simple-wysiwyg';

const EditorField = ({
    form: { setFieldValue },
    field: { name, value },
    inputFormat,
    label,
    handleCancel,
    onClose,
    ...props
}) => {
    const onChange = useCallback(
        (event) => {
            setFieldValue(name, event.target.value);
        },
        [setFieldValue, name]
    );

    return (
        <Box>
            <Editor
                onChange={onChange}
                value={value}
            />
            <FormHelperText color="error"> <ErrorMessage name={name} /></FormHelperText>
        </Box>
    );
}

export default EditorField;