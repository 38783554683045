import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import BackButton from '../../components/shared/BackButton';
import moment from 'moment';

export default function AdminWhiteLabel() {
    const { user } = useAuth();

    return (
        <>
            <Helmet>
                <title> marque blanche | Beach Booker - Pro </title>
            </Helmet>
            <Container maxWidth={false}>
                <Typography variant='h3' sx={{ mb: 1 }}>marque blanche</Typography>
                <Grid container direction={'row'} justifyContent={'space-between'} spacing={4}>
                    <Grid item xs={12}>
                        <Typography>
                            Le lien vers la marque blanche :&nbsp;
                            <a
                                href={`${process.env.REACT_APP_B2C_APP_URL}/place/${user?.id}?dfrom=${moment().format("YYYY-MM-DD")}`}
                                target="_blank"
                                style={{ color: "#ec6935" }} rel="noreferrer"
                            >
                                {`${process.env.REACT_APP_B2C_APP_URL}/place/${user?.id}`}
                            </a>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <BackButton />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
