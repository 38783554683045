import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Container, Stack, Typography, IconButton, Box } from '@mui/material';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import BackButton from '../../components/shared/BackButton';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import DeleteIcon from '@mui/icons-material/Delete';
import update from 'immutability-helper';
import RestoreIcon from '@mui/icons-material/Restore';
import Dropzone from 'react-dropzone';
import { createAdminImage, deleteAdminImage, getAdminGalleryList } from 'src/services/api/admin-galary.api';

export default function AdminHutGallery() {
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    const getImageAllListing = useCallback(async (id) => {
        setPhotos([]);
        const res = await getAdminGalleryList(id);
        if (res && res.status && res.data && res.data.length > 0) {
            setPhotos(res.data);
        } else {
            setPhotos([]);
        }
    }, []);

    useEffect(() => {
        getImageAllListing(id);
    }, [getImageAllListing, id]);

    const handleSubmit = useCallback(async () => {
        setLoading(true);
        if (photos && photos.length > 0) {
            for await (const [i, value] of Object.entries(photos)) {
                if (value.deleted) {
                    await deleteAdminImage(value.id);
                }
                if (value.added) {
                    let formData = new FormData();
                    formData.append('image', value.file);
                    formData.append('hutId', id);
                    await createAdminImage(formData);
                }
            }
        }
        toast.success('enregistré avec succès');
        getImageAllListing(id);
        setLoading(false);
    }, [photos, getImageAllListing, id]);

    const handleDeletePhotos = useCallback(
        (service, value) => {
            setPhotos(
                update(photos, {
                    [photos.findIndex((item) => item.id === service.id)]: {
                        deleted: { $set: value },
                    },
                })
            );
        },
        [photos]
    );

    const handleDropFile = (acceptedFiles) => {
        const url = acceptedFiles.map((file) => URL.createObjectURL(file));
        console.log(url, 'url');
        setPhotos((prevState) => [
            ...prevState,
            {
                id: '',
                added: true,
                file: acceptedFiles[0],
                url: url,
            },
        ]);
    };

    return (
        <>
            <Helmet>
                <title> Gallery | Beach Booker - Pro </title>
            </Helmet>
            <Container maxWidth={false} sx={{ mt: 5 }}>
                <Grid container direction={'row'} justifyContent={'space-between'} spacing={3}>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent={'space-between'}>
                            <Stack item>
                                <Typography variant="h3">Photos</Typography>
                            </Stack>
                            <Stack item>
                                <Stack direction={'row'} spacing={2}>
                                    <BackButton />
                                    <LoadingButton
                                        startIcon={<SaveAsIcon />}
                                        variant="contained"
                                        loading={loading}
                                        onClick={handleSubmit}
                                    >
                                        Sauvegarder les modifications
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        {photos &&
                            photos.length > 0 &&
                            photos.map((ele, index) => (
                                <Stack
                                    direction={'row'}
                                    spacing={2}
                                    sx={{ padding: 2, width: '100%', background: '#f1f1f1', borderRadius: 2, mb: 2 }}
                                >
                                    <Stack item width={'0.5%'}>
                                        <Typography component={'span'} variant="body2" alignContent={'center'}>
                                            {index + 1}.
                                        </Typography>
                                    </Stack>
                                    <Stack item width={'90%'}>
                                        <Box
                                            component="img"
                                            alt={'dev'}
                                            src={ele.url}
                                            sx={{
                                                width: '197px',
                                                height: '138px',
                                                border: '1px solid #aaa',
                                            }}
                                        />
                                    </Stack>
                                    <Stack item width={'10%'}>
                                        {!ele.deleted && (
                                            <IconButton
                                                size="small"
                                                disableRipple
                                                color="primary"
                                                aria-label="dle"
                                                onClick={() => handleDeletePhotos(ele, true)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                        {ele.deleted && (
                                            <IconButton
                                                size="small"
                                                disableRipple
                                                color="primary"
                                                aria-label="restore"
                                                onClick={() => handleDeletePhotos(ele, false)}
                                            >
                                                <RestoreIcon />
                                            </IconButton>
                                        )}
                                    </Stack>
                                </Stack>
                            ))}
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 15 }}>
                        <Box
                            width={'100%'}
                            sx={{ background: '#f5f5f5', border: '2px dashed #ccc', borderRadius: '5px', padding: 4 }}
                            textAlign={'center'}
                        >
                            <Dropzone onDrop={handleDropFile}>
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <p>Faites glisser et déposez des fichiers ici, ou cliquez pour sélectionner des fichiers</p>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
