import { forwardRef, useEffect, useState } from 'react';
import RestoreIcon from '@mui/icons-material/Restore';
import PropTypes from 'prop-types';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Stack, IconButton, Button, Grid, InputAdornment, Typography, MenuItem, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField, Select } from 'formik-mui';
import { strToId } from '../../../utils/common';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from 'src/context/AuthContext';

const AddEditPriceForm = forwardRef(({ onSubmit, selectedPriceCategory }, ref) => {
    const { user } = useAuth();
    const defaultPriceListItems = {
        id: '',
        name: 'New Item',
        price: 0,
        oldPrice: 0,
        type: 'sunbed',
        description: '',
        hutId: user?.id,
    };
    const defaultCategory = {
        name: 'New Category',
        deleted: false,
        parentId: null,
        priceListItems: [{ ...defaultPriceListItems }],
    };
    const defaultValues1 = {
        id: '',
        priceListCategories: [{ ...defaultCategory }],
    };

    const [defaultValues, setDefaultValues] = useState(defaultValues1);

    useEffect(() => {
        if (selectedPriceCategory.length > 0) {
            setDefaultValues({
                id: '',
                priceListCategories: selectedPriceCategory?.length > 0 ? selectedPriceCategory : [{ ...defaultCategory }],
            });
        } else {
            setDefaultValues({
                id: '',
                priceListCategories: [{ ...defaultCategory }],
            })
        }
    }, [selectedPriceCategory]);

    return (
        <Formik enableReinitialize onSubmit={onSubmit} innerRef={ref} initialValues={defaultValues}>
            {({ values, setFieldValue }) => (
                <Form autoComplete="off" noValidate style={{ width: '100%' }}>
                    <FieldArray
                        name="priceListCategories"
                        render={({ push }) => (
                            <Box>
                                <>
                                    {values.priceListCategories.length > 0 &&
                                        values.priceListCategories.map((element, indexGroup) => (
                                            <>
                                                <Stack
                                                    key={indexGroup}
                                                    sx={{
                                                        mt: 3,
                                                        paddingTop: 2,
                                                        paddingBottom: 2,
                                                        borderRadius: 0.5,
                                                        background: !element.deleted ? '#f1f1f1' : '#ff50503a',
                                                    }}
                                                    direction={{ md: 'row' }}
                                                >
                                                    <Grid container direction={'row'} sx={{ padding: 1 }}>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                size={'small'}
                                                                component={TextField}
                                                                name={`priceListCategories.${indexGroup}.name`}
                                                                placeholder="value"
                                                                type="text"
                                                                fullWidth
                                                                onKeyPress={(e) => setFieldValue(`services.${indexGroup}.key`, strToId(e.target.value))}
                                                                required
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    {!element.deleted && (
                                                        <IconButton
                                                            size="small"
                                                            disableRipple
                                                            color="primary"
                                                            aria-label="close"
                                                            onClick={() => {
                                                                if (window.confirm('Voulez-vous vraiment supprimer ?') === true) {
                                                                    setFieldValue(`priceListCategories.${indexGroup}.deleted`, true);
                                                                }
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                    {element.deleted && (
                                                        <IconButton
                                                            size="small"
                                                            disableRipple
                                                            color="primary"
                                                            aria-label="close"
                                                            onClick={() => setFieldValue(`priceListCategories.${indexGroup}.deleted`, false)}
                                                        >
                                                            <RestoreIcon />
                                                        </IconButton>
                                                    )}
                                                </Stack>
                                                <Stack sx={{ background: '#f8f9fa', padding: 4 }}>
                                                    <FieldArray
                                                        name={`priceListCategories.${indexGroup}.priceListItems`}
                                                        render={({ push, remove }) => (
                                                            <>
                                                                {values.priceListCategories[indexGroup].priceListItems &&
                                                                    values.priceListCategories[indexGroup].priceListItems.map((element, index) => (
                                                                        <Grid
                                                                            sx={{ mt: 2, borderRadius: 1, border: '1px solid #00000020', background: '#fff' }}
                                                                            container
                                                                            key={`list${index}`}
                                                                            spacing={2}
                                                                            direction={'row'}
                                                                            justifyContent={'center'}
                                                                            alignItems={'center'}
                                                                        >
                                                                            <Grid item xs={12}>
                                                                                <Stack direction={'row'} spacing={2}>
                                                                                    <Typography variant="body1" sx={{ fontWeight: 700 }}>
                                                                                        #{index}
                                                                                    </Typography>
                                                                                    <Field
                                                                                        size={'small'}
                                                                                        component={TextField}
                                                                                        name={`priceListCategories[${indexGroup}].priceListItems[${index}].name`}
                                                                                        placeholder="value"
                                                                                        type="text"
                                                                                        fullWidth
                                                                                        InputProps={{
                                                                                            endAdornment: (
                                                                                                <Field
                                                                                                    sx={{ mr: -1.8, width: '100px' }}
                                                                                                    size={'small'}
                                                                                                    component={Select}
                                                                                                    name={`priceListCategories[${indexGroup}].priceListItems[${index}].type`}
                                                                                                    onChange={(e) =>
                                                                                                        setFieldValue(
                                                                                                            `priceListCategories.${indexGroup}.type`,
                                                                                                            e.target.value
                                                                                                        )
                                                                                                    }
                                                                                                    defaultValues="sunbed"
                                                                                                >
                                                                                                    <MenuItem value="">
                                                                                                        {' '}
                                                                                                        <em>None</em>
                                                                                                    </MenuItem>
                                                                                                    <MenuItem value="sunbed">Sunbed</MenuItem>
                                                                                                    <MenuItem value="double_sunbed">Double sunbed</MenuItem>
                                                                                                </Field>
                                                                                            ),
                                                                                        }}
                                                                                    />
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        disableRipple
                                                                                        color="primary"
                                                                                        onClick={() => {
                                                                                            if (window.confirm('Voulez-vous vraiment supprimer ?') === true) {
                                                                                                remove(`priceListCategories[${indexGroup}].priceListItems[${index}`);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                </Stack>
                                                                            </Grid>
                                                                            <Grid item xs={12} container direction={'row'} spacing={2} sx={{ mb: 3 }}>
                                                                                <Grid item xs={6}>
                                                                                    <Grid direction={'column'} spacing={2} container>
                                                                                        <Grid item>
                                                                                            <Field
                                                                                                size={'small'}
                                                                                                component={TextField}
                                                                                                name={`priceListCategories[${indexGroup}].priceListItems[${index}].price`}
                                                                                                placeholder="value"
                                                                                                type="text"
                                                                                                fullWidth
                                                                                                InputProps={{
                                                                                                    startAdornment: (
                                                                                                        <InputAdornment position="start">Prix *</InputAdornment>
                                                                                                    ),
                                                                                                }}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item>
                                                                                            <Field
                                                                                                size={'small'}
                                                                                                component={TextField}
                                                                                                name={`priceListCategories[${indexGroup}].priceListItems[${index}].oldPrice`}
                                                                                                placeholder="value"
                                                                                                type="text"
                                                                                                fullWidth
                                                                                                InputProps={{
                                                                                                    startAdornment: (
                                                                                                        <InputAdornment position="start">Ancien prix *</InputAdornment>
                                                                                                    ),
                                                                                                }}
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <Field
                                                                                        size={'small'}
                                                                                        component={TextField}
                                                                                        name={`priceListCategories[${indexGroup}].priceListItems[${index}].description`}
                                                                                        placeholder="Description"
                                                                                        type="text"
                                                                                        fullWidth
                                                                                        multiline
                                                                                        sx={{ width: '95%' }}
                                                                                        rows={4}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    ))}
                                                                <Stack direction={'row'} justifyContent={'center'} sx={{ mt: 2 }}>
                                                                    <Button
                                                                        variant="outlined"
                                                                        startIcon={<AddIcon />}
                                                                        onClick={() => push({ ...defaultPriceListItems })}
                                                                    >
                                                                        Ajouter un item
                                                                    </Button>
                                                                </Stack>
                                                            </>
                                                        )}
                                                    />
                                                </Stack>
                                            </>
                                        ))}

                                    <Stack direction={'row'} justifyContent={'center'} sx={{ mt: 2 }}>
                                        <Button variant="outlined" onClick={() => push(defaultCategory)} startIcon={<AddIcon />}>
                                            ajouter une catégorie
                                        </Button>
                                    </Stack>
                                </>
                            </Box>
                        )}
                    />
                </Form>
            )}
        </Formik>
    );
});

export default AddEditPriceForm;

AddEditPriceForm.propTypes = {
    onSubmit: PropTypes.func,
    data: PropTypes.array,
};
