import { Toolbar, Typography, Box } from "@mui/material";


export default function Footer() {
    return (
        <Box
            sx={{
                flexGrow: 1,
                justifyContent: "space-between",
                display: "flex",
                mb: 2,
                background: '#000',
                color: "#fff"
            }}
        >
            <Toolbar>
                <Typography>V.1</Typography>
            </Toolbar>
            <Toolbar>
                <Typography>@2020</Typography>
            </Toolbar>
        </Box>
    )
}