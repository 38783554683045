/* eslint-disable eqeqeq */
import { useCallback, useEffect, useState } from 'react';
import { Grid, Box, Button } from '@mui/material';
import { DataTable, NoteColumn } from '../../dataTable';
import ActionMoreMenu from 'src/components/shared/ActionMoreMenu';
import { fDate } from 'src/utils/formatTime';
import { reservationTableDetail } from 'src/services/api/beach.api';
import moment from 'moment';
import DeleteDialog from 'src/components/shared/DeleteDialog';
import { CSVLink } from "react-csv";
import { pick } from 'lodash';

export default function BeachReservationTable({ date, beach, handleClickDelete, handleEditReservation, isResetReservationData }) {
    const [queryFilter, setQueryFilter] = useState();
    const [selectedItem, setSelectedItem] = useState();
    const [isDeleted, setIsDeleted] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [exportData, setExportData] = useState([]);

    const handleEdit = useCallback((row) => {
        handleEditReservation(row);
    }, [handleEditReservation]);

    const handleDelete = useCallback(
        (id) => {
            setIsDeleted(true)
            setSelectedItem(id);
        },
        [setIsDeleted]
    );

    const findSeaNumber = useCallback((row = 0, col = 1) => {
        const plan = beach.plan;
        if (plan.length > 0) {
            const selectedRow = plan[row][col];
            if (selectedRow && selectedRow.id) {
                return selectedRow.id
            } else {
                return `[${(Number(row))}, ${(Number(col))}]`
            }
        }
        return `[${(Number(row))}, ${(Number(col))}]`
    }, [beach]);

    const columns = [
        {
            name: 'id',
            label: 'Nº',
            isSearchable: true,
            isSortable: true,
        },
        {
            name: 'customers.firstname',
            label: 'Prénom',
            isSearchable: true,
            isSortable: true,
            render: (row) => row.customer?.firstname

        },
        {
            name: 'customers.lastname',
            label: 'Nom',
            isSearchable: true,
            isSortable: true,
            render: (row) => row.customer?.lastname
        },
        {
            name: 'customers.phone',
            label: 'Téléphone',
            isSearchable: false,
            isSortable: true,
            render: (row) => row.customer?.phone
        },
        {
            name: 'date',
            label: 'Date',
            isSearchable: false,
            isSortable: true,
            render: (row) => `Réservé le ${fDate(row?.date)}`
        },
        {
            name: 'restaurant',
            label: 'Restaurant',
            isSortable: false,
            render: (row) => {
                if (row.restaurant === null || !row.restaurant) {
                    return "Non"
                } else {
                    return "Oui"
                }
            }
        },
        {
            name: 'customers.comment',
            label: 'Commentaire client',
            isSortable: false,
            render: (row) =>
                <NoteColumn
                    withSave={false}
                    value={row?.customer?.comment || null}
                    row={row}
                />,
        },
        {
            key: 'details',
            label: 'Détails',
            sortable: true,
            render: (row) => {
                const detailsData = row.details ? JSON.parse(row.details) : [];
                if (detailsData.length > 0) {
                    return (
                        <ul>
                            {detailsData.map((ele) => (
                                <li>
                                    {ele.type.startsWith('double_sunbed') && (
                                        <span>Bed </span>
                                    )}
                                    {ele.type.startsWith('sunbed') && (
                                        <span>Transat </span>
                                    )}
                                    {ele.status == "reserved_full_day" && (
                                        <span>journée </span>
                                    )}

                                    {ele.status == "reserved_half_day" && (
                                        <span>½ journée </span>
                                    )}
                                </li>
                            ))}
                        </ul>
                    )
                } else {
                    return null
                }
            },
        },
        {
            name: 'location',
            label: 'Emplacement',
            render: (row) => {
                const detailsData = row.details ? JSON.parse(row.details) : [];
                if (detailsData.length > 0) {                    
                    let i = 0;
                    return (
                        detailsData.map((ele) => {
                            i++;
                            const number = findSeaNumber(ele.position.x, ele.position.y);
                            return number ? `${number} ${detailsData.length !== i ? '/' : ''}` : '';
                        }))
                } else {
                    return null
                }
            },
        },

        {
            name: 'past_reservation_count',
            label: 'Fidélité',
            isSortable: true,

        },

        {
            name: 'comment',
            label: 'Note',
            isSortable: false,
            render: (row) =>
                <NoteColumn
                    withSave={true}
                    value={row.comment}
                    row={row}
                />,
        },

        // {
        //     name: 'status',
        //     label: 'Statut',
        //     isSortable: false,
        //     render: (row) => {
        //         if (row.status === "in") {
        //             return (<span style={{ color: "green" }}>In</span>)
        //         }
        //         if (row.status === "out") {
        //             return "Out"
        //         }
        //         if (row.status === "noshow") {
        //             return (<span style={{ color: "orange" }}>No show</span>)
        //         }
        //     }
        // },
        {
            name: 'is_member',
            label: 'Statut',
            isSortable: false,
            render: (row) => row?.is_member ? "Yes" : "No"
        },
        {
            name: '',
            label: 'Action',
            props: { align: 'center' },
            render: (row) => (
                <ActionMoreMenu
                    row={row}
                    onClickDelete={handleDelete}
                    onClickEdit={handleEdit}
                />
            ),
        }
    ];

    const handleDataTableChange = useCallback(
        async (filter) => {
            setQueryFilter(filter);
            const res = await reservationTableDetail(beach.id, moment(date).format("YYYY-MM-DD"), { ...filter });
            if (res && res.data && res.data.data) {
                setData(res.data.data);
                setTotal(res.data.total)
                const exportData = res.data.data.map(user => {
                    const data = pick(user, ['id'])
                    return {
                        ...data,
                        "Prénom": user?.customer?.firstname,
                        "Nom de famille": user?.customer?.lastname,
                        "Téléphone": user?.customer?.phone,
                        "Date": `${fDate(user?.date)}`,
                        "Temps": user?.reservation_time,
                        "Date de crÃ©ation": fDate(user.createdAt),
                        "Date de modification": fDate(user.updatedAt)
                    };
                });
                setExportData(exportData || []);
            } else {
                setData([])
                setTotal(0)
                setExportData([]);
            }
        },
        [date, beach]
    );


    useEffect(() => {
        if (queryFilter) {
            handleDataTableChange(queryFilter);
        }
    }, [handleDataTableChange, queryFilter, isResetReservationData]);

    return (
        <>

            <Grid item xs={12}>
                <Box justifyContent={"flex-end"} display={"flex"} sx={{ mt: 1, mb: 1 }}>
                    {exportData && (
                        <CSVLink
                            className="csv-button"
                            filename={`beach_booker_fichier_plage.csv`}
                            data={exportData}
                        >
                            <Button variant="contained">Exporter la liste</Button>
                        </CSVLink>
                    )}
                </Box>
                <Box width={"99%"}>
                    <DataTable
                        data={data}
                        hasFilter={false}
                        columns={columns}
                        initialLoading
                        totalRow={total}
                        onChange={handleDataTableChange}
                    />
                </Box>
            </Grid>
            {isDeleted && (
                <DeleteDialog
                    title='Suppression de la réservation'
                    message='Souhaitez-vous vraiment supprimer cette réservation ?'
                    onClose={() => {
                        setSelectedItem(null);
                        setIsDeleted(false)
                    }}
                    handleClickDelete={() => {
                        handleClickDelete(selectedItem)
                        setSelectedItem(null);
                        setIsDeleted(false)
                    }}
                />
            )}
        </>
    );
}
