import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormDialogTitle from './FormDialogTitle';


const useStyles = makeStyles(theme => ({
    paper: {
        width: ({ width }) => width,
    },
}));


const FormDialog = ({
    handleClose,
    children,
    handleSubmit,
    header,
    icon,
    width,
    subHeader,
    withAction,
    ...props
}) => {

    const classes = useStyles({ width: width || 500 });

    const onClose = () => {
        handleClose();
    };

    return (
        <Dialog
            onClose={onClose}
            open
            classes={{
                scrollPaper: classes.topScrollPaper,
                paperScrollBody: classes.topPaperScrollBody,
                paper: classes.paper
            }}
            {...props}
        >
            <FormDialogTitle id="customized-dialog-title" onClose={onClose}>
                <Typography>
                    <IconButton style={{ backgroundColor: 'transparent' }} size="small">{icon}</IconButton>
                    {header}
                </Typography>
                {subHeader}
            </FormDialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Fermer</Button>
                {withAction}
            </DialogActions>
        </Dialog>
    );
}

export default FormDialog;

FormDialog.propTypes = {
    handleClose: PropTypes.any,
    children: PropTypes.any,
    width: PropTypes.string
};
