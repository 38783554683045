import PropTypes from 'prop-types';
import { Box, AppBar, Toolbar, Typography, Button } from '@mui/material';
import AccountPopover from '../header/AccountPopover';

export default function AdminHeader({ data }) {
    return (

        <header>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="fixed" sx={{ background: "#fff", height: "76px", padding: 0.5, boxShadow: "none" }}>
                    <Toolbar>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                mt: 1.5,
                                fontFamily: 'monospace',
                                fontWeight: 300,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',

                            }}
                        >
                            <Box
                                component="img"
                                alt="logo"
                                src="/assets/beachbooker_header_home_logo.png"
                                sx={{ mb: 1, width: "50px", height: "50px" }}
                            />
                        </Typography>
                        <Typography sx={{ flexGrow: 1, color: "#000" }} variant="h4">Administration</Typography>
                        <Button color="inherit">
                            <AccountPopover />
                        </Button>
                    </Toolbar>
                </AppBar>
            </Box>
        </header>
    );
}

AdminHeader.propTypes = {
    onOpenNav: PropTypes.func,
};