import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Field, Form, Formik } from 'formik';
import { FormControl, FormLabel, Stack } from '@mui/material';
import { object, string } from 'yup';
import { TextField } from 'formik-mui';
import { useAuth } from '../../../../context/AuthContext';


const AddBeachForm = forwardRef((props, ref) => {
    const { onSubmit, hutId } = props;
    const defaultValues = {
        name: '',
        hutId: hutId,
        status: 'inactive',
    };

    const validationSchema = object().shape({
        name: string().label("Nom de la paillote").required(),
    });

    return (
        <Formik
            enableReinitialize
            innerRef={ref}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={defaultValues}
        >
            {() => (
                <Form autoComplete="off" noValidate>
                    <Stack spacing={3} sx={{ mt: 1, mb: 1 }}>
                        <Stack direction="row">
                            <FormControl fullWidth>
                                <FormLabel>Nom de la paillote</FormLabel>
                                <Field
                                    size="small"
                                    component={TextField}
                                    name="name"
                                    fullWidth
                                    required
                                />
                            </FormControl>
                        </Stack>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
});

export default AddBeachForm;

AddBeachForm.propTypes = {
    values: PropTypes.object,
    onSubmit: PropTypes.func,
    cities: PropTypes.array,
};
