

export default function BulletIconOff() {
    return (
        <svg
            width="24" height="24" viewBox="0 0 200.000000 200.000000"
        >
            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M883 1960 c-324 -45 -603 -240 -743 -520 -143 -286 -142 -609 5 -887
131 -249 347 -418 624 -490 130 -34 315 -36 444 -4 297 71 533 262 662 533
122 256 118 580 -8 833 -134 269 -377 458 -669 520 -88 19 -237 26 -315 15z
m207 -121 c92 -11 169 -31 239 -61 l45 -19 -93 -93 -93 -94 -52 14 c-62 17
-186 18 -257 3 l-51 -11 -95 93 -94 94 28 12 c56 25 161 53 228 62 99 12 101
12 195 0z m54 -379 c133 -42 256 -156 307 -287 33 -86 38 -221 11 -310 -23
-74 -81 -171 -128 -214 -164 -151 -415 -172 -600 -51 -32 22 -71 52 -85 68
-168 183 -172 472 -10 655 120 137 327 193 505 139z m-735 -355 c-13 -75 -7
-212 11 -259 11 -29 8 -32 -82 -123 -107 -108 -101 -108 -142 20 -61 194 -54
378 19 571 l18 48 94 -94 94 -94 -12 -69z m1384 188 c52 -147 63 -343 28 -488
-22 -87 -47 -155 -59 -155 -4 0 -47 40 -96 89 l-88 89 11 51 c13 63 14 181 1
236 l-9 40 92 93 c50 50 94 92 97 92 3 0 14 -21 23 -47z m-824 -891 c56 -3
116 0 149 8 l55 12 94 -93 94 -94 -28 -12 c-122 -54 -292 -78 -428 -62 -76 9
-229 50 -244 65 -3 3 38 47 89 99 66 66 100 93 110 89 8 -4 57 -9 109 -12z"/>
            </g>
        </svg>
    );
}

