import { Field, Form, Formik } from 'formik';
import { Stack, FormControl, Button, FormLabel } from '@mui/material';
import { TextField } from 'formik-mui';
import { useCallback, useState } from 'react';
import { AddEditRestaurantSchema } from '../../../validations/AddEditRestaurantSchema';
import BackButton from "../../shared/BackButton";


const defaultValuesData = {
    id: '',
    spotsCount: '',
    sittingCount: '',
};

const AddEditRestaurantForm = () => {
    const [defaultValues, setDefaultValues] = useState(defaultValuesData);

    const handleSubmit = useCallback((values) => {
        console.log(values, 'values');
    }, []);

    return (
        <Formik
            enableReinitialize
            validationSchema={AddEditRestaurantSchema}
            onSubmit={handleSubmit}
            initialValues={defaultValues}
        >
            {() => (
                <Form autoComplete="off" noValidate>
                    <Stack spacing={3} sx={{ mt: 2, width: "500px", maxWidth: "500px" }}>
                        <Stack direction="row">
                            <FormControl fullWidth>
                                <FormLabel>Nombre de place :</FormLabel>
                                <Field
                                    size="small"
                                    component={TextField}
                                    name="spotsCount"
                                    fullWidth
                                    placeholder="Nombre de place"
                                    required
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction="row">
                            <FormControl fullWidth>
                                <FormLabel>Nombre de service :</FormLabel>
                                <Field
                                    size="small"
                                    component={TextField}
                                    name="sittingCount"
                                    fullWidth
                                    placeholder="Nombre de service"
                                    required
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Button
                                variant="contained"
                                type="submit"
                            >
                                Valider
                            </Button>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <BackButton />
                        </Stack>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
}

export default AddEditRestaurantForm;
