import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Link, Stack } from '@mui/material';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function InputFileUpload({ name, value, handleOnChange }) {

  return (<>
    <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
      Upload Menu
      <VisuallyHiddenInput
        type="file"
        accept=".pdf"
        onChange={handleOnChange}
      />
    </Button>
    {value && (
      <Stack sx={{ alignItems: "center" }}>
        <Link target="_blank" href={value}>Menu Download</Link>
      </Stack>
    )}
  </>
  );
}