/* eslint-disable eqeqeq */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import update from "immutability-helper";
import { Container, Box, Stack, Button, TextField, Checkbox, FormGroup, FormControlLabel, Grid, Typography, IconButton } from '@mui/material';
import BeachEditSidebar from '../../components/admin/beach/edit-beach/BeachEditSidebar';
import BeachEditContentHeader from '../../components/admin/beach/edit-beach/BeachEditContentHeader';
import AddBeachItemDialog from '../../components/admin/beach/AddBeachItemDialog';
import { getAdminBeachDetails, updateAdminBeach } from '../../services/api/admin-beach.api';
import AddBeachValueDialog from '../../components/admin/beach/AddBeachValueDialog';
import Iconify from '../../components/iconify/Iconify';
import { BEACH_ITEM_DATA, INITIAL_SPOT, INITIAL_SPOTS, SCHEDULE_DATA } from 'src/data/admin-beach.data';
import AddBeachWarningDialog from '../../components/admin/beach/AddBeachWarningDialog';
import { toast } from 'react-toastify';
import ScheduleCheckbox from 'src/components/admin/beach/ScheduleCheckbox';
import DragDropSidebar from 'src/components/admin/restaurant/DragDropSidebar';
import { MovableItemBeach } from 'src/components/admin/beach/MovableItemBeach';
import { DropItemBeach } from 'src/components/admin/beach/DropItemBeach';
import CloseIcon from '@mui/icons-material/Close';
import { useReactToPrint } from "react-to-print";
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';


export default function AdminEditBeachPage() {
    const { id } = useParams();
    const [isDragAndDrop, setIsDragAndDrop] = useState(false);
    const [isAddItem, setIsAddItem] = useState(false);
    const [isAddValue, setIsAddValue] = useState(false);
    const [isWarning, setIsWarning] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedBeachMap, setSelectedBeachMap] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [spots, setSpots] = useState([]);
    const [schedule, setSchedule] = useState([]);
    const [selectedBox, setSelectedBox] = useState([]);
    const [printLoading, setPrintLoading] = useState(false);

    const [selectedValue, setSelectedValue] = useState(null);

    const getBeachDetails = useCallback(async (id) => {
        const res = await getAdminBeachDetails(id);
        if (res.status && res.data) {
            const planData = res.data.plan != null ? JSON.parse(res.data.plan)?.plan : INITIAL_SPOTS;
            const scheduleData = res.data.schedule != null ? JSON.parse(res.data.schedule)?.schedule : [];
            setSpots(planData);
            setSchedule(scheduleData);
            const updatedResponse = {
                ...res.data,
                schedulingStartDate: res.data.start_date ? moment(res.data.start_date) : null,
                schedulingEndDate: res.data.end_date ? moment(res.data.end_date) : null
            }
            setSelectedItem(updatedResponse)
        }
    }, []);

    useEffect(() => {
        setSelectedBeachMap(selectedItem?.is_map_book)
    }, [selectedItem]);

    useEffect(() => {
        getBeachDetails(id)
    }, [getBeachDetails, id]);

    const handleRow = useCallback((type) => {
        if (type == "add") {
            if (spots.length > 0) {
                const newRow = []
                for (let i = 0; i < spots[0].length; i++) {
                    newRow.push(INITIAL_SPOT);
                }
                setSpots((prevHistory) => [...prevHistory, newRow]);
            }
        } else {
            setSpots((previousArr) => (previousArr.slice(0, -1)));
        }
    }, [spots]);

    const handleColumn = useCallback((type) => {
        if (spots.length > 0) {
            const latestSpot = spots.map((row) => {
                if (type == "add") {
                    const newRows = [...row, INITIAL_SPOT];
                    return newRows;
                } else {
                    const newRows = row.slice(0, -1)
                    return newRows;
                }
            });
            setSpots(latestSpot);
        }
    }, [spots]);

    const handleSave = useCallback(async () => {
        const updatedValue = { ...selectedItem };
        updatedValue.plan = { plan: spots };
        updatedValue.schedule = { schedule };
        updatedValue.start_date = updatedValue.schedulingStartDate ? moment(updatedValue.schedulingStartDate).format() : null;
        updatedValue.end_date = updatedValue.schedulingEndDate ? moment(updatedValue.schedulingEndDate).format() : null;
        delete updatedValue.schedulingEndDate;
        delete updatedValue.schedulingStartDate;
        delete updatedValue.restauant;
        setLoading(true)
        const res = await updateAdminBeach(updatedValue, id)
        setLoading(false)
        if (res.status) {
            toast.success("enregistré avec succès")
            setSelectedBox([]);
        }
    }, [selectedItem, spots, schedule, id]);

    const handleSelectItem = useCallback((value) => {
        setIsAddItem(false)
        if (selectedBox.length > 0) {
            let _gridArray = [...spots];
            selectedBox.map(({ rowIndex, columnIndex }) => {
                const existingVal = _gridArray[rowIndex][columnIndex]
                _gridArray[rowIndex][columnIndex] = { ...existingVal, type: value, selected: false };
            });
            setSpots(_gridArray);
        }
        setSelectedBox([])
    }, [selectedBox, spots]);

    const handleSelectValue = useCallback((value) => {
        setIsAddValue(false)
        if (selectedValue) {
            let _gridArray = [...spots];
            _gridArray[selectedValue.rowIndex][selectedValue.columnIndex].id = value;
            setSpots(_gridArray);
        } else {
            let isNumber = false
            if (!isNaN(value)) {
                isNumber = true
            }
            if (selectedBox.length > 0) {
                let _gridArray = [...spots];
                let latestValue = isNumber ? Number(value) : value;
                selectedBox.map(({ rowIndex, columnIndex }) => {
                    const existingVal = _gridArray[rowIndex][columnIndex];
                    if (isNumber) {
                        if ((existingVal.type.startsWith("double_sunbed") || existingVal.type.startsWith("sunbed"))) {
                            _gridArray[rowIndex][columnIndex] = { ...existingVal, id: latestValue, selected: false, checked: false };
                            latestValue++;
                        } else {
                            _gridArray[rowIndex][columnIndex] = { ...existingVal, id: null, selected: false, checked: false };
                        }
                    } else {
                        _gridArray[rowIndex][columnIndex] = { ...existingVal, id: value, selected: false, checked: false };
                    }
                });
                setSpots(_gridArray);
                setSelectedBox([])
            }
        }
        setSelectedValue(null);
        setSelectedBox([])
    }, [selectedBox, spots, selectedValue]);


    const handleAddSelectItem = useCallback((type) => {
        if (selectedBox.length > 0) {
            if (type == "item") {
                setIsAddItem(true)
            } else {
                setIsAddValue(true)
            }
        } else {
            setIsWarning(true)
        }
    }, [selectedBox]);

    const handleOnClickBox = useCallback((rowIndex, columnIndex) => {
        let _gridArray = [...spots];
        const existingVal = _gridArray[rowIndex][columnIndex]
        _gridArray[rowIndex][columnIndex] = { ...existingVal, selected: existingVal?.selected ? !existingVal?.selected : true };
        const index = selectedBox.findIndex((item) => item.rowIndex === rowIndex && item.columnIndex === columnIndex);

        if (index !== -1) {
            const latestFacilityList = update(selectedBox, {
                $splice: [[selectedBox.indexOf({ rowIndex, columnIndex }), 1]],
            });
            setSelectedBox(latestFacilityList);
        } else {
            setSelectedBox([...selectedBox, { rowIndex, columnIndex }]);
        }
        setSpots(_gridArray);
    }, [selectedBox, spots]);


    const handleChangeSchedule = useCallback((checked, value) => {
        console.log(checked, value);
        let _gridSchedule = [...schedule];
        if (checked && !_gridSchedule.includes(value)) {
            _gridSchedule.push(value);
        } else {
            _gridSchedule.splice(_gridSchedule.indexOf(value), 1);
        }
        setSchedule(_gridSchedule);
    }, [schedule]);

    const printMapRef = useRef(null);

    const reactToPrintContent = useCallback(() => {
        return printMapRef.current;
    }, [printMapRef]);

    const handleBeforePrint = useCallback(() => {
        console.log(printMapRef.current, 'printMapRef.current');
        console.log("`onBeforePrint` called"); // tslint:disable-line no-console
        setPrintLoading(true)
    }, []);

    const handleAfterPrint = useCallback(() => {
        console.log(printMapRef.current, 'printMapRef.current');
        console.log("`onBeforePrint` called"); // tslint:disable-line no-console
        setPrintLoading(false)
    }, []);

    const handlePrintMap = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "beachMap",
        copyStyles: true,
        // onBeforeGetContent: handleOnBeforeGetContent,
        onBeforePrint: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true
    });

    const handleAll = useCallback(async (checked) => {
        let _spotArray = [...spots];
        let _selectedBoxArray = [];
        if (_spotArray.length > 0) {
            for await (const [i, IValue] of Object.entries(_spotArray)) {
                for await (const [j, JValue] of Object.entries(IValue)) {
                    _spotArray[i][j].selected = checked == "unSelect" ? false : true;
                }
            }

            for await (const [i, IValue] of Object.entries(_spotArray)) {
                for await (const [j, JValue] of Object.entries(IValue)) {
                    if (_spotArray[i][j].selected == true) {
                        _selectedBoxArray.push({ rowIndex: i, columnIndex: j })
                    }
                }
            }
            setSelectedBox(_selectedBoxArray);
            setSpots(_spotArray);
        }
    }, [spots]);

    const handleSelectAllRow = useCallback(async (e, rowIndex) => {
        let checked = e.target.checked;
        let _selectedBoxArray = [];
        let _spotArray = [...spots];
        // let selectedRow = _spotArray[rowIndex];

        for await (const [i, IValue] of Object.entries(_spotArray)) {
            for await (const [j, JValue] of Object.entries(IValue)) {
                if (i == rowIndex) {
                    _spotArray[i][j] = { ..._spotArray[i][j], selected: checked }
                }
            }
        }

        for await (const [i, IValue] of Object.entries(_spotArray)) {
            for await (const [j, JValue] of Object.entries(IValue)) {
                if (_spotArray[i][j].selected == true) {
                    _selectedBoxArray.push({ rowIndex: i, columnIndex: j })
                }
            }
        }
        setSelectedBox(_selectedBoxArray);
        setSpots(_spotArray);
    }, [spots]);

    const handleSelectAllColumn = useCallback(async (e, rowIndex, columnIndex) => {
        let checked = e.target.checked;
        let _selectedBoxArray = [];
        let _spotArray = [...spots];

        for await (const [i, IValue] of Object.entries(_spotArray)) {
            for await (const [j, JValue] of Object.entries(IValue)) {
                if (columnIndex == j) {
                    _spotArray[i][j] = { ..._spotArray[i][j], selected: checked };
                }
            }
        }
        for await (const [i, IValue] of Object.entries(_spotArray)) {
            for await (const [j, JValue] of Object.entries(IValue)) {
                if (_spotArray[i][j].selected == true) {
                    _selectedBoxArray.push({ rowIndex: i, columnIndex: j })
                }
            }
        }
        setSelectedBox(_selectedBoxArray);
        setSpots(_spotArray);
    }, [spots]);


    const handleIsDragAndDrop = useCallback(() => {
        setIsDragAndDrop((preState) => !preState);
    }, []);

    const handleDropItem = useCallback(async ({ rowIndex, columnIndex, id, name }) => {
        let _gridArray = [...spots];
        const existingVal = _gridArray[rowIndex][columnIndex]
        _gridArray[rowIndex][columnIndex] = { ...existingVal, type: name, selected: false };
        setSpots(_gridArray);
    }, [spots]);

    const handleEditValue = (rowIndex, columnIndex, value) => {
        setIsAddValue(true)
        setSelectedValue({ rowIndex, columnIndex, value })
    }
    console.log(selectedItem, 'selectedItem');
    return (
        <>
            <Helmet>
                <title>Modifier la plage | Beach Booker - Pro </title>
            </Helmet>
            <Container maxWidth={false} sx={{ padding: "0 !important" }}>
                <Box sx={{ minHeight: `calc(100vh - 134px) !important`, display: 'flex' }}>
                    <DragDropSidebar
                        width="300px"
                        isDragAndDrop={isDragAndDrop}
                    >
                        <Stack direction={"row"}>
                            <Stack item>
                                <Typography variant="h6">Sélectionnez l'icône Glisser-déposer </Typography>
                            </Stack>
                            <Stack item>
                                <IconButton onClick={() => setIsDragAndDrop(false)}><CloseIcon /></IconButton>
                            </Stack>
                        </Stack>

                        <Box display={"flex"} sx={{ padding: 2 }}>
                            <Grid container spacing={1}>
                                {BEACH_ITEM_DATA.map((ele) => {
                                    return ele.name != "initial_state" ?
                                        <Grid item xs={4}>
                                            <MovableItemBeach
                                                imgData={ele.img}
                                                name={ele.name}
                                                key={ele.name}
                                                type={ele.type}
                                                handleDropItem={handleDropItem}
                                            />
                                        </Grid> : null
                                })}
                            </Grid>
                        </Box>
                    </DragDropSidebar>
                    <BeachEditSidebar>
                        <Stack direction={"column"} spacing={2} sx={{ mt: 1 }}>
                            <Stack item>
                                <Button size="small" variant="contained" onClick={() => handleAddSelectItem("item")}>Spécifier un élément</Button>
                            </Stack>
                            <Stack item>
                                <Button size="small" variant="contained" onClick={() => handleAddSelectItem("value")}>Spécifier un numéro</Button>
                            </Stack>
                        </Stack>
                        <Stack item sx={{ mt: 2 }}>
                            <TextField
                                sx={{ background: "#fff" }}
                                size="small"
                                name={"name"}
                                value={selectedItem?.name || ''}
                                onChange={(e) => setSelectedItem(prevState => ({
                                    ...prevState,
                                    name: e.target.value,
                                }))}
                            />
                        </Stack>
                        <Stack item sx={{ mt: 2 }}>

                        </Stack>
                        <Stack item sx={{ mt: 2 }}>
                            <FormGroup row={true} >
                                {SCHEDULE_DATA.map(({ value, label }) => (
                                    <ScheduleCheckbox
                                        value={value}
                                        schedule={schedule}
                                        label={label}
                                        status={selectedItem?.status}
                                        handleOnClick={handleChangeSchedule}
                                    />
                                ))}
                            </FormGroup>
                        </Stack>

                        <Stack item sx={{ mt: 1 }}>
                            <FormGroup row={true}>
                                <FormControlLabel
                                    control={<Checkbox
                                        disableRipple
                                        checked={selectedItem?.halfday || false}
                                        size='small'
                                        onChange={(e) => setSelectedItem(prevState => ({
                                            ...prevState,
                                            halfday: e.target.checked,
                                        }))}
                                    />}
                                    label="Réservation ½ journée"
                                />
                            </FormGroup>
                        </Stack>

                        <Stack item sx={{ mt: 2 }}>
                            <FormGroup row={true}>
                                <FormControlLabel
                                    control={<Checkbox
                                        disableRipple
                                        checked={selectedBeachMap}
                                        value={selectedBeachMap}
                                        size='small'
                                        onChange={(e) => {
                                            setSelectedBeachMap(e.target.checked);
                                            setSelectedItem(prevState => ({
                                                ...prevState,
                                                is_map_book: e.target.checked,
                                            }))
                                        }}
                                    />}
                                    label="Afficher la carte en B2C"
                                />
                            </FormGroup>
                        </Stack>

                        <Stack item sx={{ mt: 1 }}>
                            <FormGroup row={true}>
                                <FormControlLabel
                                    control={<Checkbox
                                        disableRipple
                                        checked={selectedItem?.is_member || false}
                                        size='small'
                                        onChange={(e) => setSelectedItem(prevState => ({
                                            ...prevState,
                                            is_member: e.target.checked,
                                        }))}
                                    />}
                                    label="Member"
                                />
                            </FormGroup>
                        </Stack>

                        <Stack direction={"column"} sx={{ mt: 2 }} spacing={2}>
                            <Stack item>
                                <Typography alignContent={"center"} sx={{ fontSize: ".8em" }} component={"span"}>
                                    <Iconify width={15} icon="eva:radio-button-on-fill" />
                                    Sélectionnez les zones sur le plan de plage.
                                </Typography>
                            </Stack>
                            <Stack item>
                                <Typography alignContent={"center"} sx={{ fontSize: ".8em" }} component={"span"}>
                                    <Iconify width={15} icon="eva:radio-button-on-fill" />
                                    &nbsp;Cliquez sur le bouton Spécifier un élément et sélectionnez l'objet souhaité.
                                </Typography>
                            </Stack>
                            <Stack item>
                                <Typography alignContent={"center"} sx={{ fontSize: ".8em" }} component={"span"}>
                                    <Iconify width={15} icon="eva:radio-button-on-fill" />
                                    &nbsp;Spécifier un identifiant.
                                </Typography>
                            </Stack>
                            <Stack item>
                                <Typography alignContent={"center"} sx={{ fontSize: ".8em" }} component={"span"}>
                                    <Iconify width={15} icon="eva:radio-button-on-fill" />
                                    &nbsp;Ajouter ou supprimer des lignes ou/et des colonnes à votre plan de plage.
                                </Typography>
                            </Stack>
                        </Stack>

                        <Stack direction={"column"} sx={{ mt: 4 }} spacing={2}>
                            <Stack item>
                                <FormGroup row={true}>

                                    <FormControlLabel
                                        control={
                                            <DatePicker
                                                inputFormat="DD/MM/YYYY"
                                                value={selectedItem?.schedulingStartDate}
                                                onChange={(date) => setSelectedItem(prevState => ({
                                                    ...prevState,
                                                    schedulingStartDate: date,
                                                }))}
                                                label="Date d’ouverture"
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        }
                                    />
                                </FormGroup>
                            </Stack>
                            <Stack item>
                                <FormGroup row={true}>
                                    <FormControlLabel
                                        control={
                                            <DatePicker
                                                inputFormat="DD/MM/YYYY"
                                                value={selectedItem?.schedulingEndDate}
                                                onChange={(date) => setSelectedItem(prevState => ({
                                                    ...prevState,
                                                    schedulingEndDate: date,
                                                }))}
                                                label="Date de fermeture"
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        }
                                    />
                                </FormGroup>
                            </Stack>
                        </Stack>

                    </BeachEditSidebar>

                    <BeachEditContentHeader
                        handleIsDragAndDrop={handleIsDragAndDrop}
                        handlePrintMap={handlePrintMap}
                        printLoading={printLoading}
                        handleRow={handleRow}
                        handleColumn={handleColumn}
                        handleSave={handleSave}
                        loading={loading}
                        handleAll={handleAll}
                    >
                        <div ref={printMapRef}>
                            {spots.length && spots.map((row, rowIndex) => (
                                <Grid container wrap="nowrap" sx={{ overflow: 'auto' }} direction={"row"} spacing={0.4} alignItems={"center"}>
                                    <Grid item sx={{ mt: -1.5, height: 50 }}>
                                        <Typography sx={{ ...rowIndex == 0 && { mt: 2.5 } }}>
                                            <Checkbox defaultChecked={false} onClick={(e) => handleSelectAllRow(e, rowIndex)} size='small' disableRipple></Checkbox>
                                            {rowIndex + 1}
                                        </Typography>
                                    </Grid>
                                    {row.map((column, columnIndex) => {
                                        return (
                                            <>
                                                <Grid item sx={{ minHeight: "45px", paddingLeft: "0px !important", mb: 0.8 }}>
                                                    {rowIndex == 0 && (
                                                        <Checkbox onClick={(e) => handleSelectAllColumn(e, rowIndex, columnIndex)} size='small' disableRipple></Checkbox>
                                                    )}
                                                    <DropItemBeach
                                                        type={column.type}
                                                        id={column.id}
                                                        rowIndex={rowIndex}
                                                        columnIndex={columnIndex}
                                                        selected={column.selected}
                                                        handleOnClick={() => handleOnClickBox(rowIndex, columnIndex)}
                                                        handleEditValue={handleEditValue}
                                                    />
                                                </Grid>
                                            </>
                                        )
                                    }
                                    )}
                                </Grid>
                            ))}
                        </div>
                    </BeachEditContentHeader>
                </Box>
            </Container >
            {isAddItem && (
                <AddBeachItemDialog
                    handleFormSubmit={handleSelectItem}
                    onClose={() => setIsAddItem(false)}
                />
            )
            }
            {isAddValue && (
                <AddBeachValueDialog
                    selectedValue={selectedValue}
                    handleFormSubmit={handleSelectValue}
                    onClose={() => setIsAddValue(false)}
                />
            )}
            {isWarning && (
                <AddBeachWarningDialog onClose={() => setIsWarning(false)} />
            )}
        </>
    );
}
