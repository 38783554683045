import api from './api';

const API_PREFIX = 'api';

const getHutList = async (params) => {
  console.log(params, 'params');
  const response = await api.get(`${API_PREFIX}/hut-list`, { params });
  return response;
};

const savingServices = async (params) => {
  console.log(params, 'params from admin api');
  const response = await api.post(`${API_PREFIX}/add-hut-service`, { params });
  console.log('error', response.data);
  return response;
};

// Services API
const getPlaceServices = async (params) => {
  const response = await api.get(`api/service-list/`, { params });
  return response;
};

const createServiceAPI = async (body) => {
  const response = await api.post(`api/add-service`, body);
  return response;
};

const updateServiceAPI = async (body, id) => {
  const response = await api.put(`api/update-service/${id}`, body);
  return response;
};

const deleteServiceAPI = async (id) => {
  const response = await api.delete(`api/delete-service/${id}`);
  return response;
};

// City API
const getCities = async () => {
  const response = await api.get(`api/city-list/`);
  return response;
};

const createCityAPI = async (body) => {
  const response = await api.post(`api/add-city`, body);
  return response;
};

const updateCityAPI = async (body, id) => {
  const response = await api.put(`api/update-city/${id}`, body);
  return response;
};

const deleteCityAPI = async (id) => {
  const response = await api.delete(`api/delete-city/${id}`);
  return response;
};

// End of City API

const deleteHutAdmin = async (id) => {
  const response = await api.delete(`api/delete-hut/${id}`);
  return response;
};

const createHutAdmin = async (body) => {
  const response = await api.post(`api/signup`, body);
  return response;
};

const updateHutAdmin = async (body, id) => {  
  const response = await api.post(`api/update-profile`, { ...body, login_user_id: id });
  return response;
};

const updateHutAdminFromList = async (body, id) => {
  const response = await api.post(`api/update-profile`, { ...body, login_user_id: id });
  return response;
};

const getAdminAllBeach = async (params) => {
  const response = await api.get(`api/all-beach-list/`, {params});
  return response;
};

const getAdminAllRestaurants = async (params) => {
  const response = await api.get(`api/all-restaurant-list/`, {params});
  return response;
};

export {
  getAdminAllBeach,
  getAdminAllRestaurants,
  updateHutAdminFromList,
  createServiceAPI,
  deleteServiceAPI,
  updateServiceAPI,
  getPlaceServices,
  createCityAPI,
  updateCityAPI,
  deleteCityAPI,
  getCities,
  getHutList,
  deleteHutAdmin,
  createHutAdmin,
  updateHutAdmin,
  savingServices,
};
