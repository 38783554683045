/* eslint-disable consistent-return */
import { useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LoginPage from './pages/LoginPage';
import StatisticsPage from './pages/StatisticsPage';
import MarketingPage from './pages/MarketingPage';
import CustomerPage from './pages/CustomerPage';
import RestaurantPage from './pages/RestaurantPage';
import BeachPage from './pages/BeachPage';
import WelcomePage from './pages/WelcomePage';
import ProfilePage from './pages/ProfilePage';
import SettingsPage from './pages/SettingsPage';
import AdminPage from './pages/admin/AdminPage';
import AdminHutPage from './pages/admin/AdminHutPage';
import AdminDashboardLayout from './layouts/dashboard/AdminDashboardLayout';
import AdminBeachPage from './pages/admin/AdminBeachPage';
import AdminInformationPage from './pages/admin/AdminInformationPage';
import BeachDashboardLayout from './layouts/dashboard/BeachDashboardLayout';
import AuthGuard from './guards/AuthGuard';
import GuestGuard from './guards/GuestGuard';
import AdminServices from './pages/admin/AdminServices';
import AdminCities from './pages/admin/AdminCities';
import AdminRestaurant from './pages/admin/AdminRestaurant';
import AdminWhiteLabel from './pages/admin/AdminWhiteLabel';
import AdminPriceList from './pages/admin/AdminPriceList';
import AdminEditBeachPage from './pages/admin/AdminEditBeachPage';
import AdminHutServices from './pages/admin/AdminHutServices';
import AdminHutGallery from './pages/admin/AdminHutGallary';
import AdminRestaurantPage from './pages/admin/AdminRestaurantPage';
import AdminEditRestaurantPage from './pages/admin/AdminEditRestaurantPage';
import AdminAllBeachPage from './pages/admin/AdminAllBeachPage';
import AdminAllRestaurantPage from './pages/admin/AdminAllRestaurantPage';

// ----------------------------------------------------------------------

export default function Router(props) {

  const routes = useRoutes([
    {
      path: 'login',
      element: <GuestGuard><LoginPage /></GuestGuard>,
    },
    {
      path: '/',
      element: (<AuthGuard><DashboardLayout /></AuthGuard>),
      children: [
        { path: 'statistics', element: <StatisticsPage /> },
        { path: 'marketing', element: <MarketingPage /> },
        { path: 'customers', element: <CustomerPage /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'settings', element: <SettingsPage /> },
      ],
    },
    {
      path: '/',
      element: (<AuthGuard><BeachDashboardLayout /></AuthGuard>),
      children: [
        { path: 'restaurant/:id?', element: <RestaurantPage /> },
        { path: 'beach/:id?', element: <BeachPage /> },
      ],
    },
    {
      path: '/admin',
      element: (<AuthGuard><AdminDashboardLayout /></AuthGuard>),
      children: [
        { path: '', element: <AdminPage /> },
        { path: 'services', element: <AdminServices /> },
        { path: 'cities', element: <AdminCities /> },
        { path: 'restaurant', element: <AdminRestaurant /> },
        { path: 'hut/:id', element: <AdminHutPage /> },
        { path: 'hut/:id/information', element: <AdminInformationPage /> },
        { path: 'hut/:id/beach', element: <AdminBeachPage /> },
        { path: 'hut/:id/restaurant', element: <AdminRestaurantPage /> },
        { path: 'hut/:id/whitelabel', element: <AdminWhiteLabel /> },
        { path: 'hut/:id/services', element: <AdminHutServices /> },
        { path: 'hut/:id/pricelist', element: <AdminPriceList /> },
        { path: 'hut/:id/gallery', element: <AdminHutGallery /> },
        { path: 'hut/beach/:id', element: <AdminEditBeachPage /> },
        { path: 'hut/restaurant/:id', element: <AdminEditRestaurantPage /> },
        { path: 'list/beach', element: <AdminAllBeachPage /> },
        { path: 'list/restaurant', element: <AdminAllRestaurantPage /> },
      ],
    },
    {
      path: '/welcome',
      element: <AuthGuard><WelcomePage /></AuthGuard>,
    },
  ]);

  return routes;
}
