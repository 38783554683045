import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Container, Stack, Typography, TextField, IconButton, Button, Box, Select, MenuItem } from '@mui/material';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { placeServices } from '../../services/admin-service.service';
import BackButton from '../../components/shared/BackButton';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import update from 'immutability-helper';
import RestoreIcon from '@mui/icons-material/Restore';
import AddIcon from '@mui/icons-material/Add';
import { createHutService, deleteHutService, getAllHutServiceList } from 'src/services/admin-hut-service.service';


export default function AdminHutServices() {
    const [adminServices, setAdminServices] = useState([]);
    const [hutServices, setHutServices] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    const getPlaceService = useCallback(async (hutData = []) => {
        setAdminServices([]);
        const res = await placeServices({ private: false });
        if (res && res.data && res.data.length > 0) {
            let latestHutService = res.data;
            if (hutData.length > 0) {
                let latestHutsService = _.map(hutData, "name");
                latestHutService = latestHutService.map((ele) => {
                    return !_.includes(latestHutsService, ele.name) ? ele : null
                }).filter((ele) => ele)
            }
            setAdminServices(latestHutService);
        } else {
            setAdminServices([]);
        }
    }, []);

    const getHutService = useCallback(async () => {
        setHutServices([]);
        const res = await getAllHutServiceList(null, id);
        let hutData = []
        if (res.status && res.data.length > 0) {
            hutData = res.data;
        }
        setHutServices(hutData);
        getPlaceService(hutData);
    }, [getPlaceService, id]);

    useEffect(() => {
        getHutService();
    }, [getHutService]);


    const handleDeleteHutService = useCallback(
        (service, value) => {
            setHutServices(
                update(hutServices, {
                    [hutServices.findIndex((item) => item.id === service.id)]: {
                        deleted: { $set: value },
                    },
                })
            );
        },
        [hutServices]
    );

    const handleMovedService = useCallback((service) => {
        const latestFacilityList = update(adminServices, {
            $splice: [[adminServices.indexOf(service), 1]],
        });
        setAdminServices(latestFacilityList);
        const newData = update(hutServices, {
            $push: [{ ...service, isMoved: true }],
        });
        setHutServices(newData);
    },
        [hutServices, adminServices]
    );

    const handleSubmit = useCallback(async () => {
        setLoading(true)
        if (hutServices.length) {
            for await (const value of hutServices) {
                if (value.deleted) {
                    await deleteHutService(value.id);
                }
                if (value.isMoved || value.isAdded) {
                    await createHutService({
                        name: value.name || null,
                        icon: value.icon,
                        hutId: id,
                        disabled: false,
                        hidden: false,
                        private: false
                    });
                }
            }
            setLoading(false);
            toast.success("enregistré avec succès")
            getHutService();
        } else {
            setLoading(false)
            toast.error("Veuillez d'abord sélectionner le service");
        }
    }, [getHutService, hutServices, id]);

    const [customService, setCustomService] = useState({
        name: '',
        icon: '',
        id: null,
    });

    const onCustomAdd = useCallback(() => {
        setHutServices(
            update(hutServices, {
                $push: [
                    {
                        name: customService.name,
                        icon: customService.icon,
                        isAdded: true
                    },
                ],
            })
        );
    }, [customService, hutServices]);

    const handleChangeValue = useCallback((name, value, index) => {
        let hutServicesObject = hutServices[index];
        hutServices[index] = { ...hutServicesObject, [name]: value };
        setHutServices(hutServices);
    }, [hutServices]);

    return (
        <>
            <Helmet>
                <title> Services | Beach Booker - Pro </title>
            </Helmet>
            <Container maxWidth={false} sx={{ mt: 5 }}>
                <Grid container direction={'row'} justifyContent={'space-between'} spacing={3}>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent={'flex-end'}>
                            <BackButton />
                            <LoadingButton startIcon={<SaveAsIcon />} variant="contained" loading={loading} onClick={handleSubmit}>
                                Sauvegarder les modifications
                            </LoadingButton>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mb: 1 }}>
                            Services de la cabane
                        </Typography>
                        {hutServices &&
                            hutServices.length > 0 &&
                            hutServices.map((ele, index) => (
                                <Stack
                                    direction={'row'}
                                    spacing={2}
                                    sx={{ padding: 2, width: '100%', background: '#f1f1f1', borderRadius: 2, mb: 2 }}
                                >
                                    <Stack item sx={{ mt: 0.8 }}>
                                        {ele.icon && (
                                            <Box
                                                component="img"
                                                src={`/assets/service/${ele.icon}.svg`}
                                            />
                                        )}
                                    </Stack>
                                    <Stack item width={'48%'}>
                                        {ele.isAdded ? (
                                            <Select
                                                size={'small'}
                                                key={`${index}selectIcon`}
                                                labelId="demo-simple-select-label"
                                                id={`simple-select-${index}`}
                                                defaultValue={ele.icon ? ele.icon : customService.icon}
                                                fullWidth
                                                onChange={(e) => {
                                                    handleChangeValue("icon", e.target.value, index)
                                                }}
                                            >
                                                <MenuItem value="">-none-</MenuItem>
                                                <MenuItem value="bell">
                                                    bell
                                                </MenuItem><MenuItem value="check">
                                                    check
                                                </MenuItem><MenuItem value="checkboxOn">
                                                    checkboxOn
                                                </MenuItem><MenuItem value="chevronDown">
                                                    chevronDown
                                                </MenuItem><MenuItem value="chevronLeft">
                                                    chevronLeft
                                                </MenuItem><MenuItem value="chevronRight">
                                                    chevronRight
                                                </MenuItem><MenuItem value="chevronUp">
                                                    chevronUp
                                                </MenuItem><MenuItem value="clock">
                                                    clock
                                                </MenuItem><MenuItem value="cocktail">
                                                    cocktail
                                                </MenuItem><MenuItem value="concierge">
                                                    concierge
                                                </MenuItem><MenuItem value="copy">
                                                    copy
                                                </MenuItem><MenuItem value="dots">
                                                    dots
                                                </MenuItem><MenuItem value="export">
                                                    export
                                                </MenuItem><MenuItem value="handicap">
                                                    handicap
                                                </MenuItem><MenuItem value="lifeBuoy">
                                                    lifeBuoy
                                                </MenuItem><MenuItem value="lifeBuoyThin">
                                                    lifeBuoyThin
                                                </MenuItem><MenuItem value="magnifier">
                                                    magnifier
                                                </MenuItem><MenuItem value="minus">
                                                    minus
                                                </MenuItem><MenuItem value="parking">
                                                    parking
                                                </MenuItem><MenuItem value="pilotisHorizontal">
                                                    pilotisHorizontal
                                                </MenuItem><MenuItem value="pilotisVertical">
                                                    pilotisVertical
                                                </MenuItem><MenuItem value="pin">
                                                    pin
                                                </MenuItem><MenuItem value="pinFilled">
                                                    pinFilled
                                                </MenuItem><MenuItem value="plus">
                                                    plus
                                                </MenuItem><MenuItem value="pool">
                                                    pool
                                                </MenuItem><MenuItem value="shower">
                                                    shower
                                                </MenuItem><MenuItem value="star">
                                                    star
                                                </MenuItem><MenuItem value="sunbed">
                                                    sunbed
                                                </MenuItem><MenuItem value="sunbedColored">
                                                    sunbedColored
                                                </MenuItem><MenuItem value="sunbedDuo">
                                                    sunbedDuo
                                                </MenuItem><MenuItem value="sunbedSolo">
                                                    sunbedSolo
                                                </MenuItem><MenuItem value="trash">
                                                    trash
                                                </MenuItem><MenuItem value="turtle">
                                                    turtle
                                                </MenuItem><MenuItem value="tv">
                                                    tv
                                                </MenuItem><MenuItem value="umbrella">
                                                    umbrella
                                                </MenuItem><MenuItem value="umbrellaCheckmark">
                                                    umbrellaCheckmark
                                                </MenuItem><MenuItem value="user">
                                                    user
                                                </MenuItem><MenuItem value="waterSports">
                                                    waterSports
                                                </MenuItem><MenuItem value="website">
                                                    website
                                                </MenuItem><MenuItem value="wifi">
                                                    wifi
                                                </MenuItem><MenuItem value="xmark">
                                                    xmark
                                                </MenuItem>
                                            </Select>
                                        ) : (
                                            <TextField
                                                size={'small'}
                                                key={`${index}Icon`}
                                                disabled={ele.icon ? true : false}
                                                onChange={(e) =>
                                                    setCustomService({
                                                        icon: e.target.value,
                                                        name: customService.name,
                                                    })
                                                }
                                                value={ele.icon ? ele.icon : customService.icon}
                                                fullWidth
                                            />
                                        )}
                                    </Stack>
                                    <Stack item width={'48%'}>
                                        <TextField
                                            type="text"
                                            fullWidth
                                            size={'small'}
                                            key={`${index}Name`}
                                            disabled={ele.icon ? true : false}
                                            onChange={(e) => {
                                                handleChangeValue("name", e.target.value, index)
                                            }}
                                            defaultValue={ele.name ? ele.name : customService.name}
                                        />
                                    </Stack>
                                    <Stack item width={'4%'}>
                                        {!ele.deleted && (
                                            <IconButton
                                                size="small"
                                                disableRipple
                                                color="primary"
                                                aria-label="dle"
                                                onClick={() => handleDeleteHutService(ele, true)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                        {ele.deleted && (
                                            <IconButton
                                                size="small"
                                                disableRipple
                                                color="primary"
                                                aria-label="restore"
                                                onClick={() => handleDeleteHutService(ele, false)}
                                            >
                                                <RestoreIcon />
                                            </IconButton>
                                        )}
                                    </Stack>
                                </Stack>
                            ))}

                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" width="100%" my={3}>
                            <Button variant="contained" onClick={onCustomAdd} sx={{ color: '#fff' }} startIcon={<AddIcon />}>
                                Ajouter personnalisé
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h4" sx={{ mb: 1 }}>
                            Services généraux
                        </Typography>
                        {adminServices &&
                            adminServices.length > 0 &&
                            adminServices.map((ele) => (
                                <Stack
                                    direction={'row'}
                                    spacing={2}
                                    sx={{ padding: 2, width: '100%', background: '#f1f1f1', borderRadius: 2, mb: 2 }}
                                >
                                    <Stack item width={'4%'}>
                                        <IconButton
                                            onClick={() => handleMovedService(ele)}
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                        >
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </Stack>
                                    <Stack item>
                                        {ele.icon && (
                                            <Box
                                                component="img"
                                                sx={{mt:1}}
                                                src={`/assets/service/${ele.icon}.svg`}
                                            />
                                        )}
                                    </Stack>
                                    <Stack item width={'48%'}>
                                        <TextField size={'small'} disabled value={ele.icon} fullWidth />
                                    </Stack>
                                    <Stack item width={'48%'}>
                                        <TextField fullWidth size={'small'} disabled value={ele.name} />
                                    </Stack>
                                </Stack>
                            ))}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
