import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';
import { LoadingButton } from '@mui/lab';
import FormDialog from '../../../dialog/FormDialog';
import AddBeachForm from './AddBeachForm';


function AddBeachDialog({ onClose, handleFormSubmit, loading, hutId }) {
    const formRef = useRef(null);


    const handleSubmitFormTrigger = useCallback(
        () => {
            const form = formRef?.current;
            if (form.isValid) {
                form.submitForm();
            }
        },
        [formRef],
    )


    return (
        <FormDialog
            handleClose={onClose}
            header={`Créer ce plan de plage`}
            maxWidth={"xs"}
            width={"xs"}
            fullWidth
            withAction={(
                <LoadingButton
                    type="submit"
                    variant='contained'
                    onClick={handleSubmitFormTrigger}
                    loading={loading}
                >
                    Sauvegarder
                </LoadingButton>
            )}
        >
            <AddBeachForm
                hutId={hutId}
                onSubmit={handleFormSubmit}
                ref={formRef}                
            />
        </FormDialog>
    );
}

export default AddBeachDialog;

AddBeachDialog.propTypes = {
    onClose: PropTypes.func,
    hutId: PropTypes.string,
    handleFormSubmit: PropTypes.func,    
};
