import { Box, FormHelperText, InputAdornment, TextField, Select as MUISelect, MenuItem } from '@mui/material';
import { ErrorMessage } from 'formik';
import { useEffect } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import * as React from 'react';
import Iconify from 'src/components/iconify/Iconify';


const lifeBoatIcon = () => {
    return (
        <InputAdornment position="start">
            <Iconify icon="entypo:lifebuoy" />
        </InputAdornment>
    );
};

const NamesField = ({
    form: { setFieldValue },
    field: { name, value },
    inputFormat,
    label,
    handleCancel,
    onClose,
    placeholder,
    ...props
}) => {

    const [selectedLangValue, setSelectedLangValue] = useState("fr")
    const [updateValue, setUpdateValue] = useState(1)
    const [selectedValue, setSelectedValue] = useState(value ? value : null);

    const onChange = useCallback(
        (event) => {
            setSelectedValue({ ...selectedValue, [selectedLangValue]: event.target.value })
            setFieldValue(name, { ...selectedValue, [selectedLangValue]: event.target.value });
            setUpdateValue(updateValue + 1)
        },
        [selectedValue, selectedLangValue, setFieldValue, name]
    );
 

    useEffect(() => {
        setSelectedValue(value)
    }, [value]);

    const handleSelectLangauage = useCallback((e) => {
        setSelectedValue({ ...selectedValue, [e.target.value]: selectedValue[e.target.value] ? selectedValue[e.target.value] : null })
        setSelectedLangValue(e.target.value)
    }, [selectedValue]);

    return (
        <Box>
            <TextField
                size="small"
                onChange={onChange}
                fullWidth
                name={name}
                value={selectedValue && selectedValue[selectedLangValue] ? selectedValue[selectedLangValue] : ''}
                InputProps={{
                    startAdornment: lifeBoatIcon(),
                    endAdornment: (
                        <MUISelect
                            onChange={handleSelectLangauage}
                            value={selectedLangValue}
                            sx={{ mr: -1.8 }}
                        >
                            <MenuItem value="fr">FR</MenuItem>
                            <MenuItem value="en">EN</MenuItem>
                        </MUISelect>
                    ),
                }}
            />
            <FormHelperText color="error"> <ErrorMessage name={name} /></FormHelperText>
        </Box>
    );
}

export default NamesField;