import { useState } from 'react';
import { Calendar } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';


export default function DatePickerComponent({ handleChangeDate, date, className }) {
    const [locale, setLocale] = useState('fr');

    return (
        <>
            <Calendar
                className={className}
                showMonthAndYearPickers={true}
                onChange={handleChangeDate}
                locale={locales[locale]}
                date={date}
            />
        </>

    );
}


