/* eslint-disable consistent-return */
import {
  getAdminHutService,
  createAdminHutService,
  updateAdminHutService,
  deleteAdminHutService
} from './api/admin-hut-service.api';

const getAllHutServiceList = async (params, hutId) => {
  try {
    const res = await getAdminHutService(params, hutId);
    return res;
  } catch (e) {
    console.log(e, 'e');
  }
};

const createHutService = async (body) => {
  try {    
    const res = await createAdminHutService(body);
    return res;
  } catch (e) {
    console.log(e, 'e');
  }
};

const updateHutService = async (body, id) => {
  try {
    const res = await updateAdminHutService(body, id);
    return res;
  } catch (e) {
    console.log(e, 'e');
  }
};

const deleteHutService = async (id) => {
  try {
    const res = await deleteAdminHutService(id);
    return res;
  } catch (e) {
    console.log(e, 'e');
  }
};

export {
  createHutService,
  updateHutService,
  deleteHutService,
  getAllHutServiceList,
};
