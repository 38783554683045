/* eslint-disable consistent-return */
import { getCities } from "./api/admin.api";


const citiesList = async (params) => {
    try {
        const res = await getCities(params);        
        return res;
    } catch (e) {
        console.log(e, "e");
    }
};



export {
    citiesList
};