import PropTypes from 'prop-types';
import { Card, CardActions, CardContent, CardHeader, Icon, Popover, Typography } from '@mui/material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { updateBeachReservation } from 'src/services/api/beach.api';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

export default function NoteColumn({ row, value, withSave = false }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (value) {
            setSelectedValue(value);
        } else {
            setSelectedValue(null)
        }
    }, [value]);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handleSave = useCallback(async () => {
        if (row.id) {
            setLoading(true)
            await updateBeachReservation(row.id, { comment: selectedValue });
            toast.success("Supprimer avec succès");
            handleClose()
            setLoading(false);
        }
    }, [row, selectedValue]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Icon onClick={handleClick}>
                <ChatBubbleIcon
                    color={value ? "warning" : "inherit"}
                />
            </Icon>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Card sx={{ minWidth: 200 }}>
                    <CardHeader
                        disableTypography={true}
                        title={<Typography sx={{ mt: "-20px" }}>Commentaire</Typography>}
                        sx={{ bgcolor: "#dee2e6", color: "#000", height: "40px" }}
                    />
                    <CardContent>
                        <textarea
                            value={selectedValue}
                            onChange={handleChange}
                        />
                    </CardContent>
                    {withSave && (
                        <CardActions sx={{ ml: 2 }}>
                            <LoadingButton
                                loading={loading}
                                size="small"
                                variant="contained"
                                color="inherit"
                                onClick={handleSave}
                                sx={{ background: "pink", color: "blue", ":hover": { background: "#f6d7dc" } }}
                            >
                                Sauvegarder
                            </LoadingButton>
                        </CardActions>
                    )}
                </Card>
            </Popover>
        </>
    );
}

NoteColumn.propTypes = {
    row: PropTypes.object,
};
