import moment from 'moment';

const { default: api } = require('./api/api');

const API_PREFIX = 'api';

const DashboardStatistics = async (data) => {
  try {
    const startDate = moment(data.start_date).format('YYYY-MM-DD');
    const endDate = moment(data.end_date).format('YYYY-MM-DD');
    console.log('dashboard data', startDate, endDate, data.hutId, data.type);
    const res = await api.get(
      `${API_PREFIX}/statistics-detail/${data.id}?type=${data.type}&start_date=${startDate}&end_date=${endDate}`
    );
    console.log('response', res.data);
    return res;
  } catch (err) {
    if (err.response) {
      console.log(err.response.data.message);
    } else {
      console.log(err.message);
    }
  }
};

export { DashboardStatistics };
