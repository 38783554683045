import { Stack, Button } from '@mui/material';


export default function ActionMoreMenu({ onClickDelete, onClickEdit, row }) {
    return (
        <>
            <Stack key={row.id} direction={'row'} spacing={1} justifyContent="center">
                <Button size="small" variant="outlined" onClick={() => onClickEdit(row)}>
                    Modifier
                </Button>
                <Button size="small" color="error" sx={{ minWidth: "50px !important", width: "70px !important" }} variant="outlined" onClick={() => onClickDelete(row.id)}>
                    Supprimer
                </Button>
            </Stack>
        </>
    );
}
