import { forwardRef, useEffect, useState } from 'react';
import RestoreIcon from '@mui/icons-material/Restore';
import PropTypes from 'prop-types';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Stack, IconButton, Button, Grid, InputAdornment } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField } from 'formik-mui';
import { strToId } from '../../../utils/common';


const AddEditCityForm = forwardRef(({
    onSubmit,
    data
}, ref) => {

    const defaultCity = {
        name: 'New City',
        country: 'France',
        key: 'new_city',
        id: '',
        deleted: false
    };

    const defaultValues1 = {
        id: '',
        cities: [{ ...defaultCity }]
    };

    const [defaultValues, setDefaultValues] = useState(defaultValues1)

    useEffect(() => {
        setDefaultValues(prevState => ({
            ...prevState,
            cities: data,
        }));
    }, [data]);

    return (
        <Formik
            enableReinitialize
            onSubmit={onSubmit}
            innerRef={ref}
            initialValues={defaultValues}
        >
            {({ values, setFieldValue, isSubmitting }) => (
                <Form autoComplete="off" noValidate>
                    <FieldArray
                        name="cities"
                        render={({ remove, push, index }) => (
                            <Stack spacing={2} key={`cityRow${index}`}>
                                <>
                                    {values && values.cities.length > 0 &&
                                        values.cities.map((element, index) => (
                                            <Stack key={index} sx={{ padding: 3, borderRadius: 2, background: !element.deleted ? "#f1f1f1" : "#ff50503a" }} direction={{ xs: 'column', md: 'row' }}
                                                spacing={2}>
                                                <Grid container direction={"row"} spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Field
                                                            size={"small"}
                                                            component={TextField}
                                                            name={`cities.${index}.name`}
                                                            placeholder="value"
                                                            type="text"
                                                            fullWidth
                                                            onInput={(e) => setFieldValue(`cities.${index}.key`, strToId(e.target.value))}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Field
                                                            size={"small"}
                                                            component={TextField}
                                                            name={`cities.${index}.country`}
                                                            placeholder="value"
                                                            type="text"
                                                            fullWidth
                                                            required
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">Comté*</InputAdornment>,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Field
                                                            size={"small"}
                                                            component={TextField}
                                                            name={`cities.${index}.key`}
                                                            placeholder="value"
                                                            type="text"
                                                            fullWidth
                                                            required
                                                            disabled
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">Clé d'URL*</InputAdornment>,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                {!element.deleted && (
                                                    <IconButton
                                                        size="small"
                                                        disableRipple
                                                        color="primary"
                                                        aria-label="close"
                                                        onClick={() => {
                                                            if (window.confirm("Voulez-vous vraiment supprimer ?") === true) {
                                                                setFieldValue(`cities.${index}.deleted`, true)
                                                            }
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                )}
                                                {element.deleted && (
                                                    <IconButton
                                                        size="small"
                                                        disableRipple
                                                        color="primary"
                                                        aria-label="close"
                                                        onClick={() => setFieldValue(`cities.${index}.deleted`, false)}
                                                    >
                                                        <RestoreIcon />
                                                    </IconButton>
                                                )}
                                            </Stack>
                                        ))
                                    }

                                    <Stack direction={'row'} justifyContent={"center"}>
                                        <Button sx={{ mb: 2 }} variant="outlined" onClick={() => push(defaultCity)}>
                                            Ajouter un item
                                        </Button>
                                    </Stack>
                                </>
                            </Stack>
                        )}
                    />
                </Form>
            )}
        </Formik>
    )
})

export default AddEditCityForm;

AddEditCityForm.propTypes = {
    onSubmit: PropTypes.func,
    data: PropTypes.array,
};
