/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import { useCallback, useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Container, Button, Stack, Typography } from '@mui/material';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { getPriceListCategories } from '../../services/admin-service.service';
import BackButton from '../../components/shared/BackButton';
import AddEditPriceForm from '../../components/admin/price/AddEditPriceForm';
import { deletePriceCategory, submitPriceCategory, updatePriceCategory } from 'src/services/admin-price-category';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';


export default function AdminPriceList() {
    const { id: hutId } = useParams()
    const [selectedPriceCategory, setSelectedPriceCategory] = useState([]);
    const formRef = useRef(null);

    const getPriceCategories = useCallback(async (id) => {
        const res = await getPriceListCategories(id);
        if (res && res.data) {
            let priceListSelected = [];
            res.data.map((ele) => {
                priceListSelected.push({
                    ...ele.price_categories,
                    parentId: ele.id,
                    priceListItems: ele.price_categories.price_list_items,
                });
            });
            setSelectedPriceCategory(priceListSelected);
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (hutId) {
                await getPriceCategories(hutId);
            }
        })();
    }, [getPriceCategories, hutId]);

    const onSaveData = useCallback(async (values) => {
        const categoryData = values;
        await categoryData.priceListCategories.map(async (element, index) => {
            if (element.deleted && element.parentId) {
                await deletePriceCategory(element.parentId);
            } else if (element.parentId && element.parentId != '') {
                const response = {
                    name: element.name,
                    index: index,
                    priceListItems: element.priceListItems.length > 0 ? JSON.stringify(element.priceListItems) : [],
                }
                await updatePriceCategory(element.parentId, response);
            } else {
                await submitPriceCategory({
                    name: element.name,
                    hutId: hutId,
                    disabled: false,
                    index: index,
                    priceListItems: element.priceListItems.length > 0 ? JSON.stringify(element.priceListItems) : [],
                });
            }
        });
        toast.success("Success");
        await getPriceCategories(hutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hutId]);

    const handleSubmit = useCallback(() => {
        const form = formRef?.current;
        if (form.isValid) {
            form.submitForm();
        }
    }, [formRef]);

    return (
        <>
            <Helmet>
                <title> Services | Beach Booker - Pro </title>
            </Helmet>
            <Container maxWidth={false}>
                <Grid container justifyContent={'space-between'} spacing={2} direction={'row'} sx={{ mt: 2 }}>
                    <Grid item>
                        <Typography variant="h3" sx={{ mb: 1 }}>
                            Tarifs
                        </Typography>
                    </Grid>
                    <Grid item>
                        <BackButton />
                        <Button startIcon={<SaveAsIcon />} variant="contained" onClick={handleSubmit} sx={{ ml: 2 }}>
                            Sauvegarder les modifications
                        </Button>
                    </Grid>
                </Grid>
                <Grid container sx={{ mb: 3 }}>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            <AddEditPriceForm
                                selectedPriceCategory={selectedPriceCategory}
                                onSubmit={onSaveData}
                                ref={formRef}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
