import { useCallback, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Container,
  Typography,
  Stack,
  Button,
  List,
  ListItem,
  Box,
  MenuItem,
  Select,
} from '@mui/material';
import ProfileDetail from '../components/profile/ProfileDetail';
import { fDate } from '../utils/formatTime';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import { updateHutAdmin } from 'src/services/api/admin.api';
import Iconify from 'src/components/iconify/Iconify';
import { Formik } from 'formik';
import { object, string } from 'yup';

export default function ProfilePage() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const getType = useCallback(() => {
    let type = 'Client';
    if (user && user.superadmin) {
      type = 'Super administrateur';
    }
    if (user && user.admin) {
      type = 'Administrateur';
    }
    return type;
  }, [user]);

  useLayoutEffect(() => { }, [user]);

  const handleSaveChanges = async (e) => {
    const response = await updateHutAdmin(
      {
        login: e.email,
        name: e.name,
        status: e.status,
      },
      user?.id
    );
    toast.success(response.message);
    return user;
  };

  const validationSchema = object().shape({
    name: string().required('Nom de la paillote').label(),
    email: string().required(),
    status: string().required(),
  });

  return (
    <>
      <Helmet>
        <title> Page de profil | Beach Booker - Pro </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography>
              Si vous souhaitez changer l'un de ces paramètres, merci de contacter{' '}
              <strong>
                <a href="mailto:contact@beachbooker.fr" target="_blank" rel="noreferrer">
                  contact@beachbooker.fr
                </a>
              </strong>
            </Typography>
            <Stack direction={'row'} sx={{ mt: 5 }}>
              <Button variant="contained" onClick={() => navigate(-1)}>
                Revenir sur la page précédente
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Formik
              enableReinitialize
              onSubmit={handleSaveChanges}
              validationSchema={validationSchema}
              initialValues={{
                name: user && user?.name ? user.name : '',
                email: user && user?.login ? user.login : '',
                status: user && user?.status ? user.status : '',
                type: getType().toString(),
              }}
            >
              {({ handleChange, handleSubmit, errors, touched, values }) => (
                <form onSubmit={handleSubmit}>
                  <List>
                    <ListItem>
                      <ProfileDetail
                        label={'Nom'}
                        onChange={handleChange('name')}
                        value={values.name}
                        error={errors.name}
                        touched={touched.name}
                      />
                    </ListItem>
                    <ListItem>
                      <ProfileDetail
                        label={'Email'}
                        onChange={handleChange('email')}
                        value={values.email}
                        error={errors.email}
                        touched={touched.email}
                      />
                    </ListItem>
                    <ListItem>
                      <ProfileDetail label={'Type'} value={values.type} />
                    </ListItem>
                    <ListItem>
                      <Box width="100%" display="flex" alignItems="center">
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <Iconify icon="entypo:lifebuoy" />
                          <Typography sx={{ ml: 1, mr: 1 }}>status : </Typography>
                        </Box>
                        <Select
                          onChange={handleChange('status')}
                          value={values.status}
                          error={errors.status}
                          touched={touched.status}
                          sx={{ flex: 1 }}
                          fullWidth
                          variant="outlined"
                          size="small"
                        >
                          <MenuItem value="none" disabled>
                            Veuillez sélectionner
                          </MenuItem>
                          <MenuItem value="active">active</MenuItem>
                          <MenuItem value="inactive">inactive</MenuItem>
                        </Select>
                      </Box>
                    </ListItem>
                    <ListItem>
                      <ProfileDetail label={'Date de création'} value={fDate(user?.createdAt)} />
                    </ListItem>
                  </List>
                  <Box display="flex" justifyContent="end" width="100%" mr={'10px'}>
                    <Button type="submit" variant="contained">
                      Mettre à jour le profil
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
