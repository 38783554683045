import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, Typography } from '@mui/material';
import AdminHutCard from '../../components/admin/hut/AdminHutCard';
import { useAuth } from 'src/context/AuthContext';

export default function AdminHutPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { id } = useParams()

  const handleOnClickCard = useCallback(
    (linkTo) => {
      if (user && user.superadmin) {
        navigate(`/admin/list/${linkTo}`);
      } else {
        navigate(`/admin/hut/${id}/${linkTo}`);
      }
    },
    [navigate, user, id]
  );

  return (
    <>
      <Helmet>
        <title> Customer | Beach Booker - Pro </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ mb: 2 }} textAlign="center">
              {user?.name} {id}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <AdminHutCard
              imageName="beachbooker_admin_informations.jpg"
              title="Information Plage"
              onClick={() => navigate(`/admin/hut/${id}/information`)}
            />
          </Grid>
          <Grid item xs={3}>
            <AdminHutCard
              imageName="beachbooker_admin_beach.jpg"
              title="Plage"
              onClick={() => handleOnClickCard('beach')}
            />
          </Grid>
          <Grid item xs={3}>
            <AdminHutCard
              imageName="beachbooker_admin_restaurant.jpg"
              title="Restaurant"
              onClick={() => handleOnClickCard('restaurant')}
            />
          </Grid>
          <Grid item xs={3}>
            <AdminHutCard
              imageName="beachbooker_admin_whitelabel.jpg"
              title="Marque blanche"
              onClick={() => navigate(`/admin/hut/${id}/whitelabel`)}
            />
          </Grid>

          <Grid item xs={3}>
            <AdminHutCard
              imageName="beachbooker_admin_plage_services.jpg"
              title="Services"
              onClick={() => navigate(`/admin/hut/${id}/services`)}
            />
          </Grid>

          <Grid item xs={3}>
            <AdminHutCard
              imageName="beachbooker_admin_price_list.jpg"
              title="Tarifs"
              onClick={() => navigate(`/admin/hut/${id}/pricelist`)}
            />
          </Grid>

          <Grid item xs={3}>
            <AdminHutCard
              imageName="beachbooker_admin_gallery.jpg"
              title="Photos"
              onClick={() => navigate(`/admin/hut/${id}/gallery`)}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
