import PropTypes from 'prop-types';
import Label from '../label/Label';

export default function StatusColumn({ status }) {
    const isActive = status === "active" ? true : false
    return (
        <Label
            variant={"filled"}
            color={(isActive ? 'success' : 'error')}
        >
            {isActive ? 'Active' : 'Inactive'}
        </Label>
    );
}

StatusColumn.propTypes = {
    status: PropTypes.string,
};
