import { Box, Button, Dialog, DialogActions, DialogContent, FormHelperText, Stack, TextField } from '@mui/material';
import { ErrorMessage } from 'formik';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import { getLabelName } from 'src/components/statistics/tab-panel/DateRangeInput';
import * as locales from 'react-date-range/dist/locale';


const DateRangeDefaultField = ({
    form: { setFieldValue },
    field: { name, value },
    inputFormat,
    label,
    handleChange,
    handleCancel,
    onClose,
    ...props
}) => {

    const [selectedValue, setSelectedValue] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [isOpen, setIsOpen] = useState(false);
    const textRef = useRef();

    useEffect(() => {
        console.log(value, 'value');
        if (value && value != '') {
            setSelectedValue(value);
        } else {
            if (textRef.current) {
                textRef.current.value = null
            }
        }
    }, [value]);

    const onChange = useCallback(
        (event) => {
            const { selection } = event;
            setSelectedValue(selection);
            setFieldValue(name, selection);
        },
        [setFieldValue, name]
    );


    return (
        <Box sx={{ zIndex: 1500, position: "relative" }}>

            <TextField
                inputRef={textRef}
                size="small"
                sx={{ width: "50%" }}
                onClick={() => setIsOpen((prevState) => !prevState)}
                defaultValue={null}
                value={value && selectedValue?.startDate ? `Depuis ${moment(selectedValue.startDate).format("DD/MM/YYYY")} pour ${moment(selectedValue.endDate).format("DD/MM/YYYY")}` : null}
            />
            {isOpen && (
                <Dialog open={true} width={"xs"} fullWidth hideBackdrop>
                    <DialogContent>
                        <DateRangePicker
                            autoFocus={true}
                            onChange={onChange}
                            ranges={[selectedValue]}
                            showSelectionPreview={false}
                            showDateDisplay={false}
                            showMonthAndYearPickers={false}
                            retainEndDateOnFirstSelection={true}
                            staticRanges={[
                                ...defaultStaticRanges.map((ele) => {
                                    return {
                                        ...ele,
                                        label: getLabelName(ele.label)
                                    }
                                }),
                            ]}
                            months={2}
                            inputRanges={[]}
                            direction="horizontal"
                            id="date-range-picker"
                            className="dateRangePickerClass"
                            locale={locales['fr']}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                            <Button
                                onClick={() => {
                                    handleChange(selectedValue);
                                    setIsOpen(false)
                                }}
                                variant="contained"
                                size="small"
                            >
                                Sauvegarder
                            </Button>
                            <Button
                                onClick={() => setIsOpen(false)}
                                variant="outlined"
                                size="small"
                            >
                                Annuler
                            </Button>
                        </Stack>
                    </DialogActions>
                </Dialog>
            )}

            <FormHelperText color="error"> <ErrorMessage name={name} /></FormHelperText>
        </Box>
    );
}

export default DateRangeDefaultField;