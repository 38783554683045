/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import ListIcon from '@mui/icons-material/List';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import update from "immutability-helper";
import { Container, Box, Grid, Typography, Stack, IconButton, Button, Alert, CircularProgress, ListItem, List, Divider, FormGroup, FormControlLabel, TextField } from '@mui/material';
import BeachSidebar from '../components/beach/sidebar/BeachSidebar';
import BeachContentHeader from '../components/shared/BeachContentHeader';
import BeachIconImage from 'src/components/admin/beach/BeachIconImage';
import { BEACH_ITEM_DATA } from 'src/data/admin-beach.data';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import AddEditBeachReservationDialog from 'src/components/beach/add-edit-beach-reservation-dialog/AddEditBeachReservationDialog';
import { useAuth } from 'src/context/AuthContext';
import { getAdminBeachList, getAdminBeachDetails } from 'src/services/api/admin-beach.api';
import moment from 'moment';
import { createCustomer, createPayment, createReservation, getHutBeachReservationsForAPositionOnADate, updateCustomer } from '../services/api/customer.api';
import AddBeachWarningDialog from '../components/admin/beach/AddBeachWarningDialog';
import { toast } from 'react-toastify';
import { deleteReservationBeach, getBeachBeachReservationsCustomersPaymentsForADate, getBeachDetail, getHutBeachReservationsForACustomer, reservationBeachDetail, updateBeachReservation } from '../services/api/beach.api';
import TableViewIcon from '@mui/icons-material/TableView';
import BeachReservationTable from 'src/components/admin/beach/BeachReservationTable';
import DatePickerComponent from 'src/components/shared/DatePickerComponent';
import { useNavigate, useParams } from 'react-router-dom';
import AdminAllBeachPage from './admin/AdminAllBeachPage';
import AddItemButton from 'src/components/shared/AddItemButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import DeleteDialog from 'src/components/shared/DeleteDialog';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Pusher from 'pusher-js';
import NoResultDialog from 'src/components/shared/NoResultDialog';
import { searchReservationByCustomer } from 'src/services/api/restaurant.api';
import { useReactToPrint } from "react-to-print";
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';


export default function BeachPage() {
    let colors = ['#EFF8FA', '#B1DEE7', '#73C3D3', '#3BA5BA', '#276E7C', '#14373E', '#E0F2F5', '#92D0DD', '#45AFC4', '#22606D', '#0A1B1F', '#A2D7E2', '#3697AB', '#18454E', '#C1E4EB', '#31899B', '#050E10', '#54B6C9', '#D0EBF0', '#1D525D', '#2C7C8C', '#0F292F', '#69BED0', '#83CAD8'];
    const [selectedBox, setSelectedBox] = useState([]);
    const [warning, setWarning] = useState(null);
    const [moving, setMoving] = useState(false);
    const [isAdminBook, setIsAdminBook] = useState(false);
    const [spotsMoved, setSpotsMoved] = useState([]);
    const [spotMovedSelected, setSpotMovedSelected] = useState(null);
    const [reservationsToUpdate, setReservationsToUpdate] = useState([]);
    const navigate = useNavigate();
    const [isDeleted, setIsDeleted] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);

    const { id = null } = useParams();

    const [selectedEditItem, setSelectedEditItem] = useState(null);
    const [modifiedItem, setModifiedItem] = useState(null);
    const [date, setDate] = useState(new Date());
    const [isBookAvailable, setIsBookAvailable] = useState(false);
    const [isResetReservationData, setIsResetReservationData] = useState(false);
    const [openBookingForm, setOpenBookingForm] = useState(null);
    const [sunbedFullDayAvailableCount, setSunbedFullDayAvailableCount] = useState(0);
    const [reservationSelected, setReservationSelected] = useState(null);
    const [reservationInformation, setReservationInformation] = useState('');
    const [doubleSunbedFullDayAvailableCount, setDoubleSunbedFullDayAvailableCount] = useState(0);
    const [beach, setBeach] = useState(null);
    const [getLoading, setGetLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isZoom, setIsZoom] = useState(false);
    const [viewType, setViewType] = useState("list");
    const { user, isSearchClickValue, setSearchGlobal, setSearchClick } = useAuth();
    const [isNoResult, setIsNoResult] = useState(false);

    const getSearchReservation = useCallback(async (value) => {
        if (value) {
            let params = { search: value, date: moment(date).format("YYYY-MM-DD"), type: "beach" }
            const searchParams = new URLSearchParams(params);
            const res = await searchReservationByCustomer(searchParams);
            if (res && res.status && res.data && res.data.length > 0) {
                const selectedReservation = res.data[0];
                if (selectedReservation) {
                    const spots = beach.plan;
                    for (let i = 0; i < spots.length; i++) {
                        for (let j = 0; j < spots[i].length; j++) {
                            if (spots[i][j]?.reservationId == selectedReservation?.beach_reservation[0]?.id) {
                                handleDisplayDetails(i, j);
                            }
                        }
                    }
                }
            } else {
                setIsNoResult(true);
            }
        }
    }, [date, beach]);

    useEffect(() => {
        if (isSearchClickValue) {
            getSearchReservation(isSearchClickValue)
        }
        return () => {
            setIsNoResult(false);
            setSearchGlobal('');
            setSearchClick(null)
        };
    }, [isSearchClickValue]);

    useEffect(() => {
        if (id) {
            setIsAdminBook(true)
        } else {
            setIsAdminBook(false)
        }
    }, [id]);

    useEffect(() => {
        const pusher = new Pusher("aee380cc21208c12d218", {
            cluster: 'ap2'
        })
        const channel1 = pusher.subscribe('reservation-beach');
        channel1.bind('reservation-beach', function (res) {
            const data = res.data;
            if (data && data.id && data.date && date) {
                let selectedDate = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD")
                if (moment(data.date).isSame(selectedDate) && beach.id == data.id && data.type == "b2c") {
                    getAllHutBeach(data.date, data.id)
                }
            }
        });
        return (() => {
            pusher.unsubscribe('reservation-beach')
        })
    }, [beach]);


    const getBeachBeachReservationsCustomersPayments = async (beachId, dateObj) => {
        const resCustomersPaymentsData = await getBeachBeachReservationsCustomersPaymentsForADate(beachId, moment(dateObj).format("YYYY-MM-DD"));
        if (resCustomersPaymentsData && resCustomersPaymentsData.status) {
            const reservationsCustomersPayments = resCustomersPaymentsData.data.map((ele) => {
                ele.details = ele.details ? JSON.parse(ele.details) : [];
                return ele;
            })
            return reservationsCustomersPayments;
        }
        return []
    }

    const getAllHutBeach = async (dateObj, idData) => {
        const hutId = user?.id;
        setGetLoading(true);
        if (idData) {
            // admin book beach page
            const resBeach = await getAdminBeachDetails(idData);
            if (resBeach.status && resBeach.data) {
                let activeBeach = resBeach.data;
                if (activeBeach && activeBeach.plan && activeBeach.status == "active") {
                    let planData = activeBeach.plan ? JSON.parse(activeBeach.plan)?.plan : [];
                    if (planData.length > 0) {
                        planData = planData.filter((ele) => { return { ...ele, available: true } })
                    }
                    const scheduleData = activeBeach.schedule ? JSON.parse(activeBeach.schedule)?.schedule : [];
                    const latestBeach = { ...activeBeach, plan: planData, schedule: scheduleData }
                    await setBeach(latestBeach);
                    await refreshReservationsCustomersPayments(latestBeach, dateObj)
                    setGetLoading(false)
                } else {
                    setBeach(null)
                    setGetLoading(false)
                }
            }
        } else {
            const resBeach = await getAdminBeachList({}, hutId);
            if (resBeach.status && resBeach.data) {
                const beaches = resBeach.data;
                let activeBeach = null;
                for (let i = 0; i < beaches.length; i++) {
                    if (beaches[i].status === 'active') {
                        if ((beaches[i].schedule) && (beaches[i].plan)) {
                            const scheduleData = beaches[i].schedule ? JSON.parse(beaches[i].schedule)?.schedule : [];
                            for (let j = 0; j < scheduleData.length; j++) {
                                if (scheduleData[j] === moment(date).format('dddd').toLowerCase()) {
                                    activeBeach = beaches[i]
                                }
                            }
                        }
                    }
                }
                if (activeBeach) {
                    let planData = activeBeach.plan ? JSON.parse(activeBeach.plan)?.plan : [];
                    if (planData.length > 0) {
                        for (let i = 0; i < planData.length; i++) {
                            for (let j = 0; j < planData[i].length; j++) {
                                planData[i][j]['available'] = true
                            }
                        }
                    }
                    const scheduleData = activeBeach.schedule ? JSON.parse(activeBeach.schedule)?.schedule : [];
                    const latestBeach = { ...activeBeach, plan: planData, schedule: scheduleData }
                    await setBeach(latestBeach);
                    await refreshReservationsCustomersPayments(latestBeach, dateObj)
                    setGetLoading(false)
                } else {
                    setBeach(null)
                    setGetLoading(false)
                }
            }
        }
        setGetLoading(false)
    };

    const refreshReservationsCustomersPayments = async (beachSelectedObj, dateObj) => {
        let reservationsCustomersPayments = undefined;
        if (beachSelectedObj) {
            if (beachSelectedObj.plan) {
                // Get an object with reservations, customers and payments
                // This array is order by date and id. This is important because we want the last object overiding the previous one when there is an out/nosho and the sunbed can be reserved again.
                let _gridPlans = [...beachSelectedObj.plan];
                reservationsCustomersPayments = await getBeachBeachReservationsCustomersPayments(beachSelectedObj.id, dateObj);
                if (reservationsCustomersPayments) {
                    // Add loyalty to this object
                    for (let i = 0; i < reservationsCustomersPayments.length; i++) {
                        let reservationsResponse = await getHutBeachReservationsForACustomer(beachSelectedObj.id, reservationsCustomersPayments[i].customerId)
                        if (reservationsResponse && reservationsResponse.status && reservationsResponse.data) {
                            reservationsCustomersPayments[i].loyalty = reservationsResponse.data.length
                        }
                    }
                    // Add spot id to this object

                    for (let i = 0; i < reservationsCustomersPayments.length; i++) {
                        for (let j = 0; j < reservationsCustomersPayments[i].details.length; j++) {
                            reservationsCustomersPayments[i].details[j].id = _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y]?.id
                        }
                    }
                    // We have a color palette for each group but we duplicate it if there are to many reservations
                    while (reservationsCustomersPayments.length > colors.length) {
                        colors = colors.concat(colors.slice(0))
                    }

                    // For each reservations we display the spots reserved
                    for (let i = 0; i < reservationsCustomersPayments.length; i++) {
                        for (let j = 0; j < reservationsCustomersPayments[i].details.length; j++) {
                            let reservationId = reservationsCustomersPayments[i].id;
                            let type = _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y]?.type
                            let reservationTYpe = reservationsCustomersPayments[i].details[j].status
                            let newType = type.substring(0, type.lastIndexOf("_")) + "_" + reservationTYpe
                            if (reservationTYpe == "reserved_half_day") {
                                let halfDayType = reservationsCustomersPayments[i].details[j].half_day_type;
                                if (halfDayType) {
                                    newType += `_${halfDayType}`;
                                }
                            }
                            // If "In", we set the right type (and icon) and available to false
                            if (reservationsCustomersPayments[i].status === 'in') {
                                newType += "_in"
                                _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].available = false
                            } else if (reservationsCustomersPayments[i].status === 'out') {
                                // If "Out", we set to the old type and available to true (the spot can be reserved again)
                                newType = type
                                _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].available = true
                            }
                            // If "Noshow", we set to the old type and available to true (the spot can be reserved again)
                            else if (reservationsCustomersPayments[i].status === 'noshow') {
                                newType = type
                                _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].available = true
                            }
                            // If we don't have any status, we set available to false
                            else {
                                // TODO for now it's always "unpaid" because se don't manage payment
                                const statusImage = reservationsCustomersPayments[i].payment ? reservationsCustomersPayments[i].payment.status : "unpaid";
                                newType += "_" + statusImage;
                                _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].available = false
                            }
                            // New type
                            _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].type = newType
                            _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].originalType = type.substring(0, type.lastIndexOf("_"))
                            // We set a color group
                            _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].color = colors[i]
                            _gridPlans[reservationsCustomersPayments[i].details[j].position.x][reservationsCustomersPayments[i].details[j].position.y].reservationId = reservationId
                        }
                    }
                    setBeach(prevState => ({
                        ...prevState,
                        plan: [..._gridPlans],
                    }));

                    await refreshAvailabilities(_gridPlans);
                }
            }
        }
    }

    const refreshAvailabilities = useCallback(async (beachSelectedPlan) => {
        let sunbedFullDayAvailableCountData = 0
        let doubleSunbedFullDayAvailableCountData = 0
        let doubleSunbedHalfDayAvailableCount = 0;
        let sunbedHalfDayAvailableCount = 0;

        const spots = beachSelectedPlan

        if (spots.length > 0) {
            if (spots) {
                for await (const [i, IValue] of Object.entries(spots)) {
                    for await (const [j, JValue] of Object.entries(IValue)) {
                        if (spots[i][j].type.startsWith("double_sunbed") && !spots[i][j].type.includes("unavailable")) {
                            doubleSunbedFullDayAvailableCountData++
                        } else if (spots[i][j].type.startsWith("sunbed") && !spots[i][j].type.includes("unavailable")) {
                            sunbedFullDayAvailableCountData++
                        }
                    }

                }
            }
            // We substract the reservations (sunbed reserved)
            // Note : we can't do this with reservation object because spots can be moved and be reserved again (out or noshow status)
            for await (const [row, IValue] of Object.entries(spots)) {
                for await (const [col, colValue] of Object.entries(spots[row])) {
                    const spot = spots[row][col];
                    if (!spot.available && (spot.type.startsWith("double_sunbed") || spot.type.startsWith("sunbed"))) {
                        if (spot.type.startsWith("double_sunbed")) {
                            if (spot.type.includes("reserved_half_day") || spot.type.includes("reserved_full_day")) {
                                doubleSunbedFullDayAvailableCountData--
                            }
                        } else if (spot.type.startsWith("sunbed")) {
                            if (spot.type.includes("reserved_half_day") || spot.type.includes("reserved_full_day")) {
                                sunbedFullDayAvailableCountData--
                            }
                        }
                    }
                }
            }
        }
        setSunbedFullDayAvailableCount(sunbedFullDayAvailableCountData);
        setDoubleSunbedFullDayAvailableCount(doubleSunbedFullDayAvailableCountData);
    }, []);

    useEffect(() => {
        if (id) {
            getAllHutBeach(date, id)
        } else {
            getAllHutBeach(date)
        }
    }, [date, user, id]);

    const handleChangeDate = useCallback((item) => {
        const latestDate = moment(item).endOf('day').toDate();
        setDate(latestDate);
    }, []);

    const handleNew = useCallback((type = null) => {
        if (isBookAvailable && selectedBox.length > 0) {
            setOpenBookingForm("add")
        } else {
            if (type == "add") {
                toast.error("Veuillez sélectionner un élément");
            } else {
                setIsBookAvailable((prevState) => !prevState);
            }
        }
    }, [isBookAvailable, selectedBox]);

    const handleOnClickBox = useCallback(async (rowIndex, columnIndex) => {
        if (isBookAvailable) {
            let _gridArray = [...beach.plan];
            const existingVal = _gridArray[rowIndex][columnIndex]
            _gridArray[rowIndex][columnIndex] = { ...existingVal, selected: existingVal?.selected ? !existingVal?.selected : true };
            const index = selectedBox.findIndex((item) => item.rowIndex === rowIndex && item.columnIndex === columnIndex);

            if (index !== -1) {
                const latestFacilityList = update(selectedBox, {
                    $splice: [[selectedBox.indexOf({ rowIndex, columnIndex }), 1]],
                });
                setSelectedBox(latestFacilityList);
            } else {
                setSelectedBox([...selectedBox, { rowIndex, columnIndex }]);
            }
            await setBeach(prevState => ({
                ...prevState,
                plan: [..._gridArray],
            }));
        }
    }, [isBookAvailable, selectedBox, beach, setBeach]);

    /* Check if there is already a reservation (concurrency problem) */
    const checkIfAlreadyReservedOnADate = async (date, details, beachId) => {
        // Sometimes the UI is not refresh and n people can reserved the same spot from distinc devices (concurrency problems)
        var alreadyReserved = false
        for (let i = 0; i < details.length; i++) {
            let reservationOnThisSpot = await getHutBeachReservationsForAPositionOnADate(beachId, details[i].position.x, details[i].position.y, date, user?.id);
            if (reservationOnThisSpot.status && reservationOnThisSpot.data.length > 0) {
                // Reservations can have Out or Noshow status, so we only check if there are reservations without status or with status equal to In
                if ((reservationOnThisSpot.data.status !== 'out') && (reservationOnThisSpot.data.status !== 'noshow')) {
                    alreadyReserved = true;
                    break
                }
            }
        }
        return alreadyReserved
    }

    const refresh = useCallback(async () => {
        setReservationSelected(null)
        setReservationInformation(null);
        setSelectedEditItem(null);
        setSelectedBox([]);
        setIsBookAvailable(false);
        setLoading(false);
        setOpenBookingForm(false)
        await getAllHutBeach(date, id)
    }, [user, date, id]);

    const reserveOnADate = async (Rdate, reservationDetails, comment, source, beachId, customerId, paymentId, values) => {
        let { isMailSend = false, half_day = false, half_day_type = null } = values
        let newReservation = {
            date: Rdate,
            details: JSON.stringify(reservationDetails),
            comment: comment,
            source: source,
            beachId: beachId,
            customerId: customerId,
            paymentId: paymentId,
            isMailSend: isMailSend,
            half_day: half_day,
            half_day_type: half_day_type
        }
        const res = await createReservation(newReservation);
        if (res.status && res.data) {
            toast.success("Réservation créée avec succès");
        }
    }
    const getBeachById = useCallback(async (id) => {
        const beachData = await getBeachDetail(id);
        if (beachData && beachData.status && beachData.data) {
            let activeBeach = beachData.data;
            const planData = activeBeach.plan ? JSON.parse(activeBeach.plan)?.plan : [];
            const scheduleData = activeBeach.schedule ? JSON.parse(activeBeach.schedule)?.schedule : [];
            activeBeach = { ...activeBeach, plan: planData, schedule: scheduleData }
            return activeBeach;
        }
        return null
    }, [])
    const getActiveBeachOnADate = useCallback(async (dateData) => {
        const hutId = user.id
        let latestBeach = null;
        let beachesRes = await getAdminBeachList({}, hutId);
        if (beachesRes.status && beachesRes.data.length > 0) {
            const beaches = beachesRes.data
            for await (const [i, IValue] of Object.entries(beaches)) {
                if (beaches[i].status === 'active') {
                    const activeBeach = beaches[i];
                    const planData = activeBeach.plan ? JSON.parse(activeBeach.plan)?.plan : [];
                    const scheduleData = activeBeach.schedule ? JSON.parse(activeBeach.schedule)?.schedule : [];
                    if ((planData) && (scheduleData)) {
                        for (let j = 0; j < scheduleData.length; j++) {
                            if (scheduleData[j] === moment(dateData).format('dddd').toLowerCase()) {
                                latestBeach = { ...activeBeach, plan: planData, schedule: scheduleData }
                            }
                        }
                    }
                }
            }
        }

        return latestBeach
    }, [user]);

    const reserve = useCallback(async (values, action) => {
        setLoading(true)
        if (beach) {
            if (beach.plan) {
                if (values.id) {
                    let customer = {
                        firstname: values.firstname,
                        lastname: values.lastname,
                        phone: values.phone,
                        email: values.email
                    }
                    await updateCustomer(values.customerId, customer);
                    let reservation = {
                        comment: values.comment,
                        customerId: values.customerId
                    }
                    await updateBeachReservation(values.id, reservation);
                    setOpenBookingForm("null")
                    setSelectedEditItem(null);
                    setLoading(false);
                    refresh();
                    // to do :: update code here
                } else {
                    let customer = null;
                    let newCustomer = {
                        firstname: values.firstname,
                        lastname: values.lastname,
                        phone: values.phone,
                        email: values.email
                    }
                    if (values.customerId) {
                        // update customer here
                        const resCustomerUpdate = await updateCustomer(values.customerId, newCustomer);
                        if (resCustomerUpdate.status && resCustomerUpdate.data) {
                            customer = resCustomerUpdate.data;
                        }
                    } else {
                        // create customer here if not exists
                        newCustomer.notifications = true
                        newCustomer.language = 'fr'
                        newCustomer.status = ''
                        newCustomer.member = false
                        newCustomer.hutId = user.id
                        const resCustomerCreate = await createCustomer(newCustomer);
                        if (resCustomerCreate.status && resCustomerCreate.data) {
                            customer = resCustomerCreate.data;
                        }
                    }

                    /* Manage payment TODO */
                    const newPayment = {
                        type: 'on_site',
                        status: 'unpaid',
                        flag: 1,
                    }
                    const payment = await createPayment(newPayment)

                    /* Manage Reservation */
                    // Multiple reservation
                    if (values.multiple) {
                        // Get the current spots selected
                        let reservationDetails = [];
                        for await (const [i, IValue] of Object.entries(beach.plan)) {
                            for await (const [j, JValue] of Object.entries(IValue)) {
                                if (beach.plan[i][j].selected === true) {
                                    reservationDetails.push({
                                        position: { x: i, y: j, col: j, row: i },
                                        type: beach.plan[i][j].type,
                                        status: values.half_day ? "reserved_half_day" : "reserved_full_day",
                                        half_day_type: values.half_day_type
                                    })
                                }
                            }
                        }
                        // We need to check if this multiple reservation is possible or not
                        let ok = true
                        let errorMessage = ''
                        // First check: the start date can be different fron the current date
                        // A customer can select spots on the current date but reserve fo another date range
                        // Thus we check if the beach plan is the same between the current date and the start date
                        // If not we display an error message
                        let startDate = values.dates[0];
                        let dates = values.dates;
                        if (date !== startDate) {
                            let currentDateBeach = await getActiveBeachOnADate(date)
                            let startDateBeach = await getActiveBeachOnADate(startDate)
                            // currentDateBeach and currentDateBeach can be undefined (no active beach on the day)
                            if ((currentDateBeach) && (startDateBeach)) {
                                if (currentDateBeach.id !== startDateBeach.id) {
                                    ok = false
                                    errorMessage += 'Vous avez sélectionné des emplacements le ' + moment(date).format("DD-MM-YYYY") + ' mais votre réservation débute à une autre date. Or le plan de plage n\'est plus le même le ' + moment(startDate).format("DD-MM-YYYY") + '.<br/>'
                                    const modalErrorMessage = "L'un des emplacements vient d'être réservé par une autre personne au même instant. Merci d'effectuer une nouvelle réservation sur d'autres emplacements."
                                    setWarning(modalErrorMessage);
                                }
                            }
                        }
                        // Second check: if a spot is not available on a date (in the date range)
                        for await (const [i, IValue] of Object.entries(dates)) {
                            let alreadyReserved = await checkIfAlreadyReservedOnADate(moment(dates[i]).format("YYYY-MM-DD"), reservationDetails, beach.id);
                            if (alreadyReserved) {
                                ok = false
                                errorMessage += 'Une des places est non disponible le ' + moment(dates[i]).format("DD-MM-YYYY") + '.<br/>'
                            }
                        }
                        if (errorMessage) {
                            setWarning(errorMessage);
                            setLoading(false)
                            return false;
                        }

                        // Third check: if a spot is not the same type (beach plan can change)
                        // Fourth check: if there is no active beach plan
                        for await (const [i, IValue] of Object.entries(dates)) {
                            let activeBeach = await getActiveBeachOnADate(dates[i]);
                            if (activeBeach) {
                                for await (const [j, IValue] of Object.entries(reservationDetails)) {
                                    // Test if the beach plan have at least the same size (lines + columns)
                                    if ((activeBeach.plan.length >= reservationDetails[j].position.x) && (activeBeach.plan[0].length >= reservationDetails[j].position.y)) {
                                        if (activeBeach.plan[reservationDetails[j].position.x][reservationDetails[j].position.y].type != reservationDetails[j].type) {
                                            ok = false
                                            errorMessage += 'Une des places n\'est plus du même type le ' + moment(dates[i]).format("DD-MM-YYYY") + ' (plan de plage différent).<br/>'
                                        }
                                    } else {
                                        ok = false
                                        errorMessage += 'Il n\'y a pas d\'emplacement pour l\'une des places le ' + moment(dates[i]).format("DD-MM-YYYY") + ' (plan de plage différent).<br/>'
                                    }
                                }
                            } else {
                                ok = false
                                errorMessage += 'Il n\'y a pas de plan de plage actif le ' + moment(dates[i]).format("DD-MM-YYYY") + '.<br/>'
                            }
                        }
                        if (errorMessage) {
                            setWarning(errorMessage);
                            setLoading(false)
                            return false;
                        }
                        if (ok) {
                            for await (const [i, IValue] of Object.entries(dates)) {
                                let activeBeach = await getActiveBeachOnADate(dates[i]);
                                await reserveOnADate(dates[i], reservationDetails, values?.comment, 'direct', activeBeach.id, customer.id, payment.id, values)
                            }
                            setLoading(false);
                            setOpenBookingForm(false);
                            action.resetForm();
                            await refresh();
                        }
                    } else {  // Classic reservation
                        let reservationDetails = [];
                        for await (const [i, IValue] of Object.entries(beach.plan)) {
                            for await (const [j, JValue] of Object.entries(IValue)) {
                                if (beach.plan[i][j].selected === true) {
                                    reservationDetails.push({
                                        position: { x: i, y: j, col: j, row: i },
                                        type: beach.plan[i][j].type,
                                        status: values.half_day ? "reserved_half_day" : "reserved_full_day",
                                        half_day_type: values.half_day_type
                                    })
                                }
                            }
                        }
                        let alreadyReserved = await checkIfAlreadyReservedOnADate(moment(date).format("YYYY-MM-DD"), reservationDetails, beach.id)
                        if (alreadyReserved.status && alreadyReserved.data.length > 0) {
                            const modalErrorMessage = "L'un des emplacements vient d'être réservé par une autre personne au même instant. Merci d'effectuer une nouvelle réservation sur d'autres emplacements."
                            setLoading(false);
                            setWarning(modalErrorMessage);
                        } else {
                            await reserveOnADate(date,
                                reservationDetails,
                                values?.comment,
                                'direct',
                                beach.id,
                                customer.id,
                                payment.id,
                                values
                            )
                            setLoading(false);
                            setOpenBookingForm(false);
                            action.resetForm();
                            await refresh();
                        }
                    }
                }
            }
            setLoading(false)
        }
        setLoading(false)
    }, [beach, date, refresh, user]);

    const handleFormSubmit = useCallback(async (values, action) => {
        await reserve(values, action);
    }, [reserve]);

    const enabledSpots = async () => {
        if (beach) {
            if (beach.plan) {
                const spots = beach.plan;
                for (let i = 0; i < spots.length; i++) {
                    for (let j = 0; j < spots[i].length; j++) {
                        spots[i][j].disabled = spots[i][j].available ? false : true
                    }
                }
                setBeach(prevState => ({
                    ...prevState,
                    plan: [...spots],
                }));
            }
        }
    }

    const disableSpots = async () => {
        if (beach) {
            if (beach.plan) {
                const spots = beach.plan;
                for (let i = 0; i < spots.length; i++) {
                    for (let j = 0; j < spots[i].length; j++) {
                        spots[i][j].disabled = true
                    }
                }
                setBeach(prevState => ({
                    ...prevState,
                    plan: [...spots],
                }));
            }
        }
    }

    const handleDisplayDetails = useCallback(async (line, column) => {
        if (beach) {
            const spots = beach.plan;
            // Disable all spots (sunbed and double sundeb)
            if ((!spots[line][column].available) && ((spots[line][column].type.startsWith("double_sunbed")) || (spots[line][column].type.startsWith("sunbed")))) {
                await disableSpots()
            }
            const reservationsCustomersPayments = await getBeachBeachReservationsCustomersPayments(beach.id, date);
            // Get reservations details
            for (let i = 0; i < reservationsCustomersPayments.length; i++) {
                for (let j = 0; j < reservationsCustomersPayments[i].details.length; j++) {
                    // The one selected : line, column
                    if ((reservationsCustomersPayments[i].details[j].position.x == line) && (reservationsCustomersPayments[i].details[j].position.y == column)) {
                        // If not out or noshow
                        if ((reservationsCustomersPayments[i].status !== 'out') && (reservationsCustomersPayments[i].status !== 'noshow')) {
                            setReservationSelected(reservationsCustomersPayments[i])
                            // Title
                            //this.reservationInformationsTitle = "Réservation Nº " + reservationsCustomersPayments[i].id
                            // Date
                            let infos = "Faite le " + moment(reservationsCustomersPayments[i].createdAt).format('DD/MM/YYYY')
                            infos += "<br/>"
                            infos += "Pour le <strong>" + moment(reservationsCustomersPayments[i].date).format('DD/MM/YYYY') + "</strong>"
                            infos += "<br/><br/>"
                            // Name
                            infos += "<strong>" + ([reservationsCustomersPayments[i]?.customer?.firstname || '', reservationsCustomersPayments[i]?.customer.lastname || ''].join(' ').trim() || '-') + "</strong>"
                            // Phone
                            if (reservationsCustomersPayments[i]?.customer && reservationsCustomersPayments[i]?.customer.phone) {
                                infos += "<br/>"
                                infos += reservationsCustomersPayments[i]?.customer.phone
                            }
                            // Email
                            if (reservationsCustomersPayments[i]?.customer.email) {
                                infos += "<br/>"
                                infos += reservationsCustomersPayments[i]?.customer.email
                            }
                            // Comment
                            if (reservationsCustomersPayments[i]?.customer.comment) {
                                infos += "<br/><br/>"
                                infos += reservationsCustomersPayments[i]?.customer.comment
                            }
                            // Spots count
                            infos += "<br/><br/>"
                            let spotCount = ''
                            if (reservationsCustomersPayments[i].details.length === 1) {
                                spotCount = reservationsCustomersPayments[i].details.length + " place réservée : "
                            } else if (reservationsCustomersPayments[i].details.length > 1) {
                                spotCount = reservationsCustomersPayments[i].details.length + " places réservées : "
                            }
                            infos += spotCount
                            // Spots (type, status, id/emplacement) and enable them
                            infos += "<ul>"
                            const spotsData = beach.plan;
                            for (let k = 0; k < reservationsCustomersPayments[i].details.length; k++) {
                                let type = ''
                                if (reservationsCustomersPayments[i].details[k].type.startsWith("double_sunbed")) {
                                    type = "Bed"
                                } else if (reservationsCustomersPayments[i].details[k].type.startsWith("sunbed")) {
                                    type = "Transat"
                                }
                                let status = ''
                                if (reservationsCustomersPayments[i].details[k].status === "reserved_full_day") {
                                    status = "journée"
                                } else if (reservationsCustomersPayments[i].details[k].status === "reserved_half_day") {
                                    status = "½ journée"
                                    if (reservationsCustomersPayments[i].details[k].half_day_type && reservationsCustomersPayments[i].details[k].half_day_type == "firstHalf") {
                                        status += ` (First half)`
                                    }
                                    if (reservationsCustomersPayments[i].details[k].half_day_type && reservationsCustomersPayments[i].details[k].half_day_type == "secondHalf") {
                                        status += ` (Second half)`
                                    }
                                }
                                let location = ''
                                if (spots[reservationsCustomersPayments[i].details[k].position.x][reservationsCustomersPayments[i].details[k].position.y].id) {
                                    location = spots[reservationsCustomersPayments[i].details[k].position.x][reservationsCustomersPayments[i].details[k].position.y].id
                                } else {
                                    location = "[" + (reservationsCustomersPayments[i].details[k].position.x + 1) + ", " + (reservationsCustomersPayments[i].details[k].position.y + 1) + "]"
                                }
                                infos += "<li>" + type + " " + status + " " + location + "</li>"
                                // Enabled this spot
                                spotsData[reservationsCustomersPayments[i].details[k].position.x][reservationsCustomersPayments[i].details[k].position.y].disabled = false
                            }
                            setBeach(prevState => ({
                                ...prevState,
                                plan: [...spotsData],
                            }));
                            infos += "</ul>"
                            // Loyalty
                            let reservations = await getHutBeachReservationsForACustomer(beach.id, reservationsCustomersPayments[i].customerId)
                            let loyalty = reservations?.data.length || 0
                            infos += "Fidélité : " + loyalty
                            // Type (not managed TODO v2)
                            let type = ''
                            if (reservationsCustomersPayments[i].type === "online") {
                                infos += "<br/><br/>"
                                type = "en ligne"
                                let status = ''
                                if (reservationsCustomersPayments[i].pstatus === "unpaid") {
                                    status = "<span style='color:var(--beachbooker-orange)'>non payée<span>"
                                } else if (reservationsCustomersPayments[i].pstatus === "paid") {
                                    status = "<span style='color:green'>payée<span>"
                                }
                                infos += "Paiement " + type
                                infos += "<br/>État : " + status
                            } else if (reservationsCustomersPayments[i].type === "on_site") {
                                // TODO ?
                            }
                            setReservationInformation(infos)
                        }
                    }
                }
            }
        }
    }, [beach, date]);

    const handleHeaderClick = useCallback(async (value) => {
        await updateBeachReservation(reservationSelected.id, { status: value });
        refresh()
    }, [reservationSelected]);

    const handleEditReservation = useCallback((row) => {
        setOpenBookingForm("add");
        setSelectedEditItem(row)
    }, []);

    const movingButtonClick = () => {
        if (!moving) {
            // Initialize
            setSpotsMoved([])
            setSpotMovedSelected(null);
            // User is moving
            setMoving(true)

        }
    }

    const moveOut = async (line, column, destinationRow, destinationCol) => {
        if (beach) {
            if (beach.plan) {
                var reservationConcerned = undefined
                // Foreach reservations
                let reservationsCustomersPayments = await getBeachBeachReservationsCustomersPayments(beach.id, date);
                const spots = beach.plan;
                let _gridArray = [...spots];
                let movedObject = null
                let spotsMovedData = [...spotsMoved];

                for (let i = 0; i < reservationsCustomersPayments.length; i++) {
                    if ((reservationsCustomersPayments[i].status !== 'out') && (reservationsCustomersPayments[i].status !== 'noshow')) {
                        for (let j = 0; j < reservationsCustomersPayments[i].details.length; j++) {
                            let spotAlreadyMoved = false;
                            let alreadyMovedSpot = null;
                            let alreadyMovedSpotIndex = null;

                            for (let k = 0; k < spotsMoved.length; k++) {
                                if ((spotsMoved[k].position.row == line) && (spotsMoved[k].position.col == column)) {
                                    spotAlreadyMoved = true;
                                    alreadyMovedSpot = spotsMoved[k]
                                    alreadyMovedSpotIndex = k;
                                }
                            }

                            if ((reservationsCustomersPayments[i].details[j].position.x == line) && (reservationsCustomersPayments[i].details[j].position.y == column)) {
                                if (!spotAlreadyMoved) {
                                    reservationConcerned = reservationsCustomersPayments[i]
                                    movedObject = {
                                        reservationid: reservationConcerned.id,
                                        name: reservationConcerned?.customer.firstname + " " + reservationConcerned?.customer.lastname,
                                        position: { x: line, y: column, col: destinationCol, row: destinationRow },
                                        type: spots[line][column].type,
                                        id: spots[line][column].id,
                                        color: spots[line][column].color
                                    }
                                    spotsMovedData.push(movedObject)
                                    let beachData = await getBeachById(beach.id)
                                    _gridArray[line][column].type = beachData.plan[line][column].type;
                                    _gridArray[line][column].available = true
                                    _gridArray[line][column].color = ''

                                    setBeach(prevState => ({
                                        ...prevState,
                                        plan: [..._gridArray],
                                    }));

                                    setSpotsMoved([...spotsMovedData])
                                }
                            }
                            if (spotAlreadyMoved) {
                                let beachData = await getBeachById(beach.id)
                                _gridArray[line][column].type = beachData.plan[line][column].type;
                                _gridArray[line][column].available = true
                                _gridArray[line][column].color = ''

                                movedObject = {
                                    ...alreadyMovedSpot,
                                    position: { x: line, y: column, col: destinationCol, row: destinationRow },
                                }
                                spotsMovedData[alreadyMovedSpotIndex] = movedObject
                                setSpotsMoved([...spotsMovedData])
                            }
                        }
                    }
                }
                if (movedObject) {
                    return movedObject;
                }
            }
        }
    }

    // const spotMovedClick = (spotMoved, index) => {
    //     setSpotMovedSelected({ ...spotMoved, ...index })
    // }

    const moveIn = async (newLine, newColumn, spotMovedSelectedData) => {
        let latestSpotMovedSelected = spotMovedSelectedData ? spotMovedSelectedData : spotMovedSelected;
        if (beach) {
            if (latestSpotMovedSelected) {
                let reservationsToUpdateAlreadyExist = false
                let index = ''
                for (let i = 0; i < reservationsToUpdate.length; i++) {
                    if (reservationsToUpdate[i].id === latestSpotMovedSelected.reservationid) {
                        reservationsToUpdateAlreadyExist = true
                        index = i
                    }
                }
                let reservation = undefined
                if (reservationsToUpdateAlreadyExist) {
                    reservation = reservationsToUpdate[index].reservation
                } else {
                    let reservationData = await reservationBeachDetail(latestSpotMovedSelected.reservationid)
                    if (reservationData && reservationData.status && reservationData.data) {
                        let reservationDetails = reservationData.data.details ? JSON.parse(reservationData.data.details) : null;
                        reservation = { ...reservationData.data, details: reservationDetails }
                    }
                }
                if (reservation && reservation.details.length) {
                    let reservationDetails = reservation.details;
                    for (let i = 0; i < reservationDetails.length; i++) {
                        if ((reservationDetails[i].position.x == latestSpotMovedSelected.position.x) && (reservationDetails[i].position.y == latestSpotMovedSelected.position.y)) {
                            reservationDetails[i].position.x = newLine
                            reservationDetails[i].position.y = newColumn
                            break;
                        }
                    }
                    let reservationsToUpdateLatest = [...reservationsToUpdate]
                    if (!reservationsToUpdateAlreadyExist) {
                        reservationsToUpdateLatest.push({ id: latestSpotMovedSelected.reservationid, reservation: { ...reservation, details: reservationDetails } })
                    } else {
                        reservationsToUpdateLatest[index] = { id: latestSpotMovedSelected.reservationid, reservation: { ...reservation, details: reservationDetails } }
                    }
                    let spotsMovedUpdated = [...spotsMoved];
                    for (let i = 0; i < spotsMovedUpdated.length; i++) {
                        if ((spotsMovedUpdated[i].position.x == latestSpotMovedSelected.position.x) && (spotsMovedUpdated[i].position.y == latestSpotMovedSelected.position.y)) {
                            spotsMovedUpdated.splice(i, 1)
                        }
                    }
                    setReservationsToUpdate(reservationsToUpdateLatest);
                    // setSpotsMoved(spotsMovedUpdated);
                    setSpotMovedSelected(null);
                    const spots = beach.plan;
                    spots[newLine][newColumn].type = latestSpotMovedSelected.type
                    spots[newLine][newColumn].available = false;
                    spots[newLine][newColumn].color = latestSpotMovedSelected.color;
                    setBeach(prevState => ({
                        ...prevState,
                        plan: [...spots],
                    }));
                }

            }
        }
    }

    const movingValidateButtonClick = async () => {
        // Update the database witch all changes
        if (reservationsToUpdate.length > 0) {
            for (let i = 0; i < reservationsToUpdate.length; i++) {
                let newReservation = { details: JSON.stringify(reservationsToUpdate[i].reservation.details) }
                await updateBeachReservation(reservationsToUpdate[i].id, newReservation)
            }
        }
        movingCancelButtonClick()
    }

    const movingCancelButtonClick = () => {
        setReservationsToUpdate([]);
        setMoving(false);
        setSpotMovedSelected(null);
        setSpotsMoved([]);
        refresh();
    }

    const handleZoom = useCallback(() => {
        setIsZoom((prevState) => !prevState);
    }, []);

    const handleDeleteReservation = useCallback(async (id = null) => {
        let beachReserveId = id ? id : reservationSelected.id;
        const res = await deleteReservationBeach(beachReserveId);
        if (res && res.status) {
            toast.success("Supprimer avec succès")
        }
        setIsResetReservationData((prevState) => !prevState)
        setIsDeleted(null)
        refresh()
    }, [reservationSelected]);

    const handleUpdateBooking = useCallback(async (selectedData) => {
        // 123
        console.log(selectedData, 'sema');
        //setModifiedItem(null);
        let reservationDetails = [];
        for await (const [i, IValue] of Object.entries(beach.plan)) {
            for await (const [j, JValue] of Object.entries(IValue)) {
                if (beach.plan[i][j].selected === true) {
                    console.log(beach.plan[i][j]);
                    reservationDetails.push({
                        position: { x: i, y: j, col: j, row: i },
                        type: beach.plan[i][j].type,
                        status: modifiedItem?.half_day ? "reserved_half_day" : "reserved_full_day",
                        half_day_type: modifiedItem?.half_day_type
                    })
                }
            }
        }
        let alreadyReserved = await checkIfAlreadyReservedOnADate(moment(date).format("YYYY-MM-DD"), reservationDetails, beach.id)
        if (alreadyReserved.status && alreadyReserved.data.length > 0) {
            const modalErrorMessage = "L'un des emplacements vient d'être réservé par une autre personne au même instant. Merci d'effectuer une nouvelle réservation sur d'autres emplacements."
            setLoading(false);
            setWarning(modalErrorMessage);
        } else {
            setLoading(true);
            let reservation = {
                details: JSON.stringify(reservationDetails),
                comment: modifiedItem?.comment,
                date: moment(modifiedItem?.date).format("YYYY-MM-DD")
            }
            await updateBeachReservation(modifiedItem?.id, reservation);
            toast.success("Réservation mise à jour avec succès.");
            setModifiedItem(null)
            refresh()
        }
    }, [selectedBox, modifiedItem]);

    const handleEditBooking = useCallback(async (selectedData) => {
        // 123
        setModifiedItem(selectedData)
        if (selectedData && selectedData.details.length > 0) {
            enabledSpots()
            setReservationSelected(null)
            setReservationInformation(null);
            setSelectedEditItem(null);
            setSelectedBox([]);
            setLoading(false);
            setOpenBookingForm(false)
            setIsBookAvailable((prevState) => !prevState);

            let reservationDetails = selectedData.details;
            const selectedBoxes = [];
            for (let i = 0; i < reservationDetails.length; i++) {
                let rowIndex = Number(reservationDetails[i].position.x);
                let columnIndex = Number(reservationDetails[i].position.y)
                let _gridArray = [...beach.plan];
                const existingVal = _gridArray[rowIndex][columnIndex];
                _gridArray[rowIndex][columnIndex] = { ...existingVal, type: _gridArray[rowIndex][columnIndex]?.type, selected: true, available: true, disabled: false };
                selectedBoxes.push({ rowIndex, columnIndex })
                await setBeach(prevState => ({
                    ...prevState,
                    plan: [..._gridArray],
                }));
            }
            setSelectedBox(selectedBoxes)
            console.log(selectedBox, 'selectedBox latest');
        }
    }, [beach]);

    const handleDeleteDialog = useCallback((id) => {
        setIsDeleted(id)
    }, []
    );

    const onDragEnd = async (results) => {
        const { source, destination } = results;
        if (source && destination && source.droppableId && destination.droppableId) {
            const sourceArr = source.droppableId.split("-");
            const destinationArr = destination.droppableId.split("-");
            if (sourceArr && sourceArr.length && destinationArr.length > 0) {
                let sourceRow = sourceArr[1];
                let sourceCol = sourceArr[2];
                let destinationRow = destinationArr[1];
                let destinationCol = destinationArr[2];
                let gridPlans = beach?.plan;
                let destiNationObject = gridPlans[destinationRow][destinationCol]
                if (destiNationObject.type && !destiNationObject.type.includes('unavailable') && (destiNationObject.type.startsWith("double_sunbed") || destiNationObject.type.startsWith("sunbed"))) {
                    const rest = await moveOut(sourceRow, sourceCol, destinationRow, destinationCol)
                    if (rest) {
                        await moveIn(destinationRow, destinationCol, rest)
                    }
                }
            }
        }
        return null
    };
    const printMapRef = useRef(null);
    const reactToPrintContent = useCallback(() => {
        return printMapRef.current;
    }, [printMapRef]);

    const handleBeforePrint = useCallback(() => {
        setPrintLoading(true)
    }, []);

    const handleAfterPrint = useCallback(() => {
        setPrintLoading(false)
    }, []);

    const handlePrintMap = useReactToPrint({
        ...viewType == "table" && {
            pageStyle: `@media print {
            @page {
              size: 400mm 400mm;
              margin: 0;
            }
          }`},
        content: reactToPrintContent,
        documentTitle: "beachMap",
        copyStyles: true,
        // onBeforeGetContent: handleOnBeforeGetContent,
        onBeforePrint: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true
    });

    return (
        <>
            <Helmet>
                <title> Beach | Beach Booker - Pro </title>
            </Helmet>
            <Container maxWidth={false} sx={{ padding: "0 !important" }}>
                {user && user.superadmin && !id ? (
                    <AdminAllBeachPage />
                ) : (
                    <Box sx={{ minHeight: `calc(100vh - 134px) !important`, display: 'flex' }}>
                        <BeachSidebar>
                            <Stack direction="row" alignItems="center" justifyContent="center">
                                <Scrollbar>
                                    {modifiedItem && (
                                        <>
                                            <Box direction={"row"}>
                                                <div><b>RESERVATION NO : {modifiedItem?.id} </b></div>
                                                <div>&nbsp;</div>
                                            </Box>
                                            <Stack direction={"row"} sx={{ mt: 2 }} size="small" justifyContent={"center"}>
                                                <DatePicker
                                                    value={modifiedItem?.date ? moment(modifiedItem?.date, "YYYY-MM-DD").toDate() : null}
                                                    onChange={(date) => setModifiedItem(prevState => ({
                                                        ...prevState,
                                                        date: date,
                                                    }))}
                                                    label="Booking Date"
                                                    size="small"
                                                    renderInput={(params) => <TextField size='small' {...params} />}
                                                />
                                            </Stack>
                                            <Stack direction={"row"} sx={{ mt: 2 }} size="small" justifyContent={"center"}>
                                                <TextField
                                                    value={modifiedItem?.comment || null}
                                                    onChange={(e) => setModifiedItem(prevState => ({
                                                        ...prevState,
                                                        comment: e.target.value,
                                                    }))}
                                                    label="Comment"
                                                    size="small"
                                                />
                                            </Stack>
                                            <Stack direction={"row"} sx={{ mt: 2 }} size="small" justifyContent={"center"}>
                                                <Button variant="contained" onClick={() => handleUpdateBooking(modifiedItem)}>Mise à jour</Button>
                                            </Stack>
                                            <Stack direction={"row"} sx={{ mt: 2 }} size="small" justifyContent={"center"}>
                                                <Button variant="contained" onClick={() => {
                                                    setModifiedItem(null)
                                                    refresh()
                                                }}>Retour</Button>
                                            </Stack>
                                        </>
                                    )}
                                    {!modifiedItem && (<>
                                        {reservationSelected ? (<>
                                            <Box direction={"row"}>
                                                <div><b>RESERVATION NO : {reservationSelected?.id} </b></div>
                                                <div>&nbsp;</div>
                                                {reservationInformation && (<div dangerouslySetInnerHTML={{ __html: reservationInformation }} />)}
                                            </Box>
                                            <Stack direction={"row"} sx={{ mt: 2 }} size="small" justifyContent={"center"}>
                                                <Button variant="contained" onClick={refresh}>Retour</Button>
                                            </Stack>
                                            <Stack direction={"row"} sx={{ mt: 2 }} size="small" justifyContent={"center"}>
                                                <Button variant="contained" onClick={() => handleEditBooking(reservationSelected)}>Modifier</Button>
                                            </Stack>
                                            <Stack direction={"row"} sx={{ mt: 2 }} size="small" justifyContent={"center"}>
                                                <Button variant="contained" onClick={() => handleDeleteDialog(reservationSelected?.id)}>Supprimer</Button>
                                            </Stack>
                                        </>
                                        ) : (
                                            <>
                                                {moving ? (
                                                    <Box>
                                                        <div>
                                                            <div class="mt-3 text-center">
                                                                <Button
                                                                    onClick={() => movingValidateButtonClick()}
                                                                    size="small"
                                                                    className="mr-2"
                                                                    variant="contained"
                                                                >
                                                                    Valider
                                                                </Button>
                                                                <Button
                                                                    onClick={() => movingCancelButtonClick()}
                                                                    size="small"
                                                                    className="ml-2"
                                                                    variant="contained"
                                                                    sx={{ ml: 2 }}
                                                                >
                                                                    Annuler
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Box>
                                                ) : (
                                                    <>
                                                        <AddItemButton
                                                            handleNew={handleNew}
                                                            handleCancel={refresh}
                                                            isBookAvailable={isBookAvailable}
                                                        />
                                                        <Stack item sx={{ mt: 2 }}>
                                                            <DatePickerComponent
                                                                handleChangeDate={handleChangeDate}
                                                                date={date}
                                                                className='date-range-beach'
                                                            />
                                                        </Stack>
                                                        <Stack item sx={{ mt: 2 }}>
                                                            <Typography color="#fff">DISPONIBILITÉS</Typography>
                                                            <Divider sx={{ width: "50px", height: "5px", background: "#fff" }}></Divider>
                                                        </Stack>
                                                        <Stack item sx={{ mt: 2 }}>
                                                            <Typography variant="body1"><span style={{ color: "#fff" }}>Beds :</span> {doubleSunbedFullDayAvailableCount || 0}</Typography>
                                                            <Typography variant="body1"><span style={{ color: "#fff" }}>Transats :</span> {sunbedFullDayAvailableCount || 0}</Typography>
                                                        </Stack>
                                                        {!isAdminBook && (
                                                            <Stack item sx={{ mt: 4, textAlign: "center" }}>
                                                                <Stack direction={"row"} spacing={1}>
                                                                    {viewType === "list" ? (
                                                                        <IconButton onClick={() => setViewType("table")}>
                                                                            <TableViewIcon sx={{ color: "#000" }} fontSize="large" />
                                                                        </IconButton>
                                                                    ) : (
                                                                        <IconButton onClick={() => setViewType("list")}>
                                                                            <ListIcon sx={{ color: "#000" }} fontSize="large" />
                                                                        </IconButton>
                                                                    )}
                                                                    <IconButton onClick={handleZoom}>{isZoom ? <FullscreenIcon fontSize="large" sx={{ color: "#000" }} /> : <FitScreenIcon fontSize="large" sx={{ color: "#000" }} />}</IconButton>
                                                                    <IconButton onClick={() => movingButtonClick()}><SwapHorizIcon sx={{ color: "#000" }} fontSize="large" /></IconButton>
                                                                </Stack>
                                                                {user && beach && user.id == beach.hutId && (
                                                                    <Stack direction={"row"} sx={{ mt: 2 }} justifyContent={"center"}>
                                                                        <Button onClick={() => navigate(`/admin/hut/beach/${beach.id}`)} size='small' variant="contained" >Modifier</Button>
                                                                    </Stack>
                                                                )}
                                                                {/* {viewType === "list" && ( */}
                                                                <Stack direction={"row"} sx={{ mt: 2 }} justifyContent={"center"}>
                                                                    <LoadingButton loading={printLoading} onClick={handlePrintMap} size='small' variant="contained">Imprimer</LoadingButton>
                                                                </Stack>
                                                                {/* )} */}
                                                            </Stack>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                    )}
                                </Scrollbar>
                            </Stack>
                        </BeachSidebar>

                        <BeachContentHeader
                            sx={{ mt: 10.5, ml: 0 }}
                            reservationSelected={reservationSelected}
                            handleHeaderClick={handleHeaderClick}
                        >
                            {!beach && !getLoading && (
                                <Grid container direction={"row"}>
                                    <Grid item xs={12}>
                                        <Alert variant="filled" color="warning">Il n'y a aucun plan de plage actif sur cette journée.</Alert>
                                    </Grid>
                                </Grid>
                            )}
                            {beach && (
                                <div ref={printMapRef}>
                                    <Grid container direction={"row"} sx={{ mt: 1, ml: 1, pr: "30px" }}>
                                        {viewType === "list" &&
                                            <Grid item xs={12} sx={{ zoom: isZoom ? "115%" : "100%" }}>
                                                {getLoading && (
                                                    <Box sx={{ display: 'flex', mt: 20, ml: 20 }}>
                                                        <CircularProgress />
                                                    </Box>
                                                )}
                                                <DragDropContext onDragEnd={onDragEnd}>
                                                    {
                                                        !getLoading
                                                        && beach?.plan.length
                                                        && beach?.plan.map((row, rowIndex) => (
                                                            <Grid
                                                                container
                                                                wrap="nowrap"
                                                                sx={{ overflow: 'auto' }}
                                                                direction={"row"}
                                                                spacing={0.5}
                                                            >
                                                                {row.map((column, columnIndex) => {
                                                                    let imgSrc;
                                                                    let isClickable = true;
                                                                    let isAvailable = false
                                                                    if (column.hasOwnProperty('available') && column.available == false) {
                                                                        isClickable = false;
                                                                        isAvailable = true;
                                                                        imgSrc = "beachbooker_beach_" + column.type + ".svg";
                                                                    } else {
                                                                        imgSrc = !modifiedItem ? _.find(BEACH_ITEM_DATA, { name: column.type })?.img : "beachbooker_beach_" + column.type + ".svg";
                                                                    }
                                                                    return (
                                                                        <Droppable isDropDisabled={!isClickable} droppableId={`row-${rowIndex}-${columnIndex}`} index={columnIndex}>
                                                                            {(provided, snapshot) => (
                                                                                <Grid
                                                                                    {...provided.droppableProps}
                                                                                    ref={provided.innerRef}
                                                                                    item
                                                                                    style={{
                                                                                        background: snapshot.isDraggingOver
                                                                                            ? "lightblue"
                                                                                            : "inherit",
                                                                                        paddingLeft: "0px"
                                                                                    }}
                                                                                >
                                                                                    <BeachIconImage
                                                                                        isDragOptions={true}
                                                                                        {...moving && { isDragDisabledCheck: !isAvailable }}
                                                                                        rowIndex={rowIndex}
                                                                                        columnIndex={columnIndex}
                                                                                        width={50}
                                                                                        height={50}
                                                                                        src={imgSrc}
                                                                                        name={column.type}
                                                                                        className={`${column.disabled && column.disabled === true ? "disabled" : null}`}
                                                                                        sx={{
                                                                                            border: "none",
                                                                                            background: column.selected ? "#ec6935" : "inherit",
                                                                                            ...!isBookAvailable && { cursor: "not-allowed" },
                                                                                            ...(moving || isAvailable) && { background: column.color },
                                                                                            ...(!column.disabled && reservationInformation) && { background: "#ec6935" },
                                                                                        }}
                                                                                        handleOnClick={() => {
                                                                                            if (column.type && (column.type.startsWith("double_sunbed") || column.type.startsWith("sunbed"))) {
                                                                                                if (!isClickable) {
                                                                                                    if (moving) {
                                                                                                        // if (column.available) {
                                                                                                        //     moveIn(rowIndex, columnIndex, column)
                                                                                                        // } else {
                                                                                                        //     moveOut(rowIndex, columnIndex, column)
                                                                                                        // }
                                                                                                    } else if (!modifiedItem) {
                                                                                                        handleDisplayDetails(rowIndex, columnIndex)
                                                                                                    }
                                                                                                } else {
                                                                                                    if (moving && column.available && spotMovedSelected) {
                                                                                                        // moveIn(rowIndex, columnIndex, column)
                                                                                                    } else {
                                                                                                        handleOnClickBox(rowIndex, columnIndex)
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <Typography
                                                                                        fontSize={".7em"}
                                                                                        textAlign={"center"}
                                                                                        sx={{ mt: "-5px", color: "#2c3e50" }}
                                                                                    >
                                                                                        {column.id} &nbsp;
                                                                                    </Typography>
                                                                                </Grid>
                                                                            )}
                                                                        </Droppable>
                                                                    )
                                                                })}
                                                            </Grid>
                                                        ))}
                                                </DragDropContext>
                                            </Grid>
                                        }
                                        {viewType === "table" &&
                                            <BeachReservationTable
                                                date={date}
                                                isResetReservationData={isResetReservationData}
                                                beach={beach}
                                                handleClickDelete={handleDeleteReservation}
                                                handleEditReservation={handleEditReservation}
                                            />
                                        }
                                    </Grid>
                                </div>
                            )}
                        </BeachContentHeader>
                    </Box >
                )}
            </Container >
            {openBookingForm === "add" && (
                <AddEditBeachReservationDialog
                    loading={loading}
                    date={date}
                    selectedEditItem={selectedEditItem}
                    handleFormSubmit={handleFormSubmit}
                    onClose={() => {
                        refresh();
                    }}
                />
            )
            }
            {
                warning && (
                    <AddBeachWarningDialog
                        message={warning}
                        onClose={() => setWarning(null)}
                    />
                )
            }
            {
                isDeleted && (
                    <DeleteDialog
                        title='Supprimer la réservation'
                        message='Voulez-vous vraiment supprimer ?'
                        onClose={() => {
                            setIsDeleted(null)
                        }}
                        handleClickDelete={() => {
                            handleDeleteReservation(isDeleted)
                        }}
                    />
                )
            }
            {
                isNoResult && (
                    <NoResultDialog
                        title='Recherche'
                        message="Il n'y a aucun résultat pour cette recherche"
                        onClose={() => {
                            setIsNoResult(false)
                        }}
                    />
                )
            }
        </>
    );
}
