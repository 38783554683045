import PropTypes from 'prop-types';
import { useState } from 'react';
import FormDialog from '../../dialog/FormDialog';
import { Button, TextField } from '@mui/material';
import { useEffect } from 'react';


function AddBeachValueDialog({ onClose, handleFormSubmit, loading, selectedValue }) {
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (selectedValue && selectedValue.value) {
            setValue(selectedValue.value);
        }
    }, [selectedValue]);
    
    return (
        <FormDialog
            handleClose={onClose}
            header={`Spécifier l'identifiant de l'objet`}
            maxWidth={"xs"}
            width={"sm"}
            fullWidth
            withAction={(
                <Button
                    type="submit"
                    variant='contained'
                    onClick={() => handleFormSubmit(value)}
                    loading={loading}
                >
                    D'accord
                </Button>
            )}
        >
            <TextField
                fullWidth
                size="small"
                value={value}
                placeholder='Identifiant (Nº)'
                onChange={(e) => setValue(e.target.value)}
            />
        </FormDialog>
    );
}

export default AddBeachValueDialog;

AddBeachValueDialog.propTypes = {
    onClose: PropTypes.func,
    handleFormSubmit: PropTypes.func,
};
