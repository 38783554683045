import { useCallback, useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Container, Stack, Typography } from '@mui/material';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { citiesList } from '../../services/admin-city.service';
import AddEditCityForm from '../../components/admin/city/AddEditCityForm';
import BackButton from '../../components/shared/BackButton';
import { createCityAPI, deleteCityAPI, updateCityAPI } from 'src/services/api/admin.api';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';
import { toast } from 'react-toastify';


export default function AdminCities() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const formRef = useRef(null);

    const getCities = useCallback(async () => {
        setData([]);
        const res = await citiesList();
        if (res.status && res.data) {
            setData(res.data);
        }
    }, []);

    useEffect(() => {
        getCities();
    }, [getCities]);

    const onSaveData = useCallback(async (values, action) => {
        console.log(action, 'action');
        const citiesData = values.cities;
        setLoading(true)
        if (citiesData.length > 0) {
            for await (const [key, value] of Object.entries(citiesData)) {
                const service = value;
                if (service.id && service.deleted === true) {
                    // delete data
                    await deleteCityAPI(service.id);
                } else if (service.id && !service.deleted) {
                    // update data
                    const inputValue = _.pick(service, ["name", "country", "key"])
                    await updateCityAPI(inputValue, service.id);
                } else {
                    const inputValue = _.pick(service, ["name", "country", "key"])
                    await createCityAPI(inputValue);
                    // add Data
                }
            }
        }
        toast.success("enregistré avec succès")
        getCities()
        setLoading(false)
    }, [getCities]);

    const handleSubmit = useCallback(() => {
        const form = formRef?.current;
        if (form.isValid) {
            form.submitForm();
        }
    }, [formRef]);

    return (
        <>
            <Helmet>
                <title> Cities | Beach Booker - Pro </title>
            </Helmet>
            <Container maxWidth={false} sx={{mt:5}}>
                <Stack direction={"row"}>
                    <Typography variant='h3' sx={{ mb: 1 }}>Villes</Typography>
                </Stack>
                <Grid container direction={'row'}>
                    <Grid item xs={6}>
                        <AddEditCityForm
                            data={data}
                            onSubmit={onSaveData}
                            ref={formRef}
                        />
                    </Grid>
                    <Grid item xs={6} flexGrow={1}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <BackButton />
                            <LoadingButton
                                startIcon={<SaveAsIcon />}
                                variant="contained"
                                loading={loading}
                                onClick={handleSubmit}
                            >
                                Sauvegarder les modifications
                            </LoadingButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
