import React from 'react';
import { Grid, Typography } from '@mui/material';
import { AppWidgetSummary } from 'src/sections/@dashboard/app';
import { PieChart, Pie, Legend, Cell, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import DateRangeInput from './DateRangeInput';

export const RestaurantPanel = ({ barChart, handleChangeDate, dateRange, totalData, allTotal, selectedTab }) => {
  const COLORS = ['rgba(180, 222, 230, 1)', 'rgba(105, 190, 208, 1)', 'rgba(236, 105, 53, 1)'];

  const getTotalCount = (type, type2 = null) => {
    if (selectedTab === 'beach') {
      return totalData[type] || 0;
    } else if (selectedTab === 'restaurant') {
      return totalData[type2] || 0;
    } else {
      return totalData[type] + totalData[type2] || 0;
    }
  };

  return (
    <>
      <Grid container mt={1}>
        <Grid item xs={12} display="flex" justifyContent="center" alignItems={'center'}>
          <Typography>Sélectionnez la plage de dates &nbsp;&nbsp;</Typography>
          <DateRangeInput value={dateRange} handleChange={handleChangeDate} />
        </Grid>
      </Grid>

      <Grid container mt={3} spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary sx={{ background: '#69bed0' }} title="Nombre de clients" total={totalData.totalClient} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            sx={{ background: '#ec6935' }}
            //title="Number of bookings taken"
            title="Nombre de réservations prises"
            //total={getTotalCount('total_booking_taken', 'total_res_booking_taken')}
            total={getTotalCount('total_b2b_booking', 'total_res_b2b_booking') + getTotalCount('total_b2c_booking', 'total_res_b2c_booking') + totalData.newHutReservationsDirect}
            color="info"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            sx={{ background: '#69bed0' }}
            //title="Number of booking validated"
            title="Nombre de réservation validée"
            total={getTotalCount('total_booking_validation', 'total_res_booking_validation')}
            color="info"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            sx={{ background: '#ec6935' }}
            //title="Total number of B2B bookings"
            title="Nombre total de réservations B2B"
            total={getTotalCount('total_b2b_booking', 'total_res_b2b_booking')}
            color="info"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            sx={{ background: '#69bed0' }}
            //title="Total number of B2C bookings"
            title="Nombre total de réservations B2C"
            total={getTotalCount('total_b2c_booking', 'total_res_b2c_booking')}
            color="info"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            sx={{ background: '#ec6935' }}
            //title="Total number of booked item"
            title={selectedTab === 'restaurant' ? "Nombre total de couverts" : selectedTab === "both" ? "couverts / transats" : "Nombre total de transats"}
            total={getTotalCount('total_booked', 'total_res_booked')}
            color="info"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            sx={{ background: '#69bed0' }}
            //title="Number of booking taken in direct (Walk-in)"
            title="Nombre de réservations prises en direct (Walk-in)"
            total={totalData.newHutReservationsDirect}
            color="info"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            sx={{ background: '#ec6935' }}
            //title="Number of booking via marque blanche"
            title="Nombre de réservations via marque blanche"
            total={totalData.newHutReservationsWebsite}
            color="warning"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <AppWidgetSummary
            sx={{ background: '#ec6935' }}
            //title={"Item / number of person"}
            title={"Article / nombre de personne"}
            total={totalData.bedAvg}
            color="error"
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            sx={{ background: '#69bed0' }}
            title={
              selectedTab === 'restaurant' ? 'Total number of couverts(place) booked' : 'Total number of booked item'
            }
            total={totalData.totalBad}
            color="warning"
          />
        </Grid> */}
        {/*         
        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            sx={{ background: '#69bed0' }}
            title={
              selectedTab === 'restaurant' ? 'Total number of couverts(place) booked' : 'Total number of booked item'
            }
            total={totalData.totalBad}
            color="warning"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            sx={{ background: '#ec6935' }}
            title={selectedTab === 'restaurant' ? 'Item / number of person' : 'Item / number of person'}
            total={totalData.bedAvg}
            color="error"
          />
        </Grid> */}
      </Grid>

      <Grid container mt={3} justifyContent={'space-evenly'}>


        {/* <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            sx={{ background: '#69bed0' }}
            title="Réservations prises via Beach Booker"
            total={totalData.newHutReservationsWebsite}
            color="error"
          />
        </Grid> */}
      </Grid>

      <Grid container mt={3} spacing={3} justifyContent={'center'} direction={'row'} alignContent={'center'}>
        <Grid item xs={12} md={6} lg={6}>
          <Typography variant="h6" sx={{ px: 3, py: 1 }}>REPARTITION DU NOMBRE DE RESERVATIONS </Typography>
          {totalData.directChartData && totalData.directChartData.length > 0 && (
            <PieChart width={550} height={500}>
              <Pie
                data={totalData.directChartData}
                dataKey="value"
                nameKey="name"
                cx="60%"
                cy="50%"
                innerRadius={100}
                outerRadius={200}
                fill="#82ca9d"
                label
              >
                {totalData.directChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Legend />
            </PieChart>
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Typography variant="h6" sx={{ px: 3, py: 1 }}>TAUX D’OCCUPATION </Typography>
          <BarChart width={900} height={600} data={totalData.barChartData} >
            <Bar barSize={10} dataKey="value" fill="#5ac5d0" unit="%" />
            <CartesianGrid stroke="#ccc" strokeDasharray="2 2" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip
              formatter={(value, name, props) => [value, "Occupation"]}
              shared={true}
            />
          </BarChart>
        </Grid>
      </Grid>
    </>
  );
};
