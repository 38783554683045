/* eslint-disable no-undef */
import React from 'react'
import { useDrag } from 'react-dnd'
import BeachIconImage from './BeachIconImage'

export const MovableItemBeach = ({ id, name, imgData, type, handleDropItem }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: 'pet',
        item: { imgData, name, type },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            handleDropItem({ ...item, ...dropResult })
        }
    })
    const opacity = isDragging ? 0.1 : 1
    return (
        <div className='pet-card' ref={dragRef} style={{ opacity }}>
            <BeachIconImage
                width={75}
                height={60}
                src={imgData}
                name={name}
                key={name}
            />
        </div>
    )
}