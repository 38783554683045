/* eslint-disable eqeqeq */
import { Helmet } from 'react-helmet-async';
import { useCallback, useEffect, useState } from 'react';
import { Grid, Container, Button, Stack, Box } from '@mui/material';
import AddEditCustomerDialog from '../components/customer/AddEditCustomerDialog';
import { CheckBoxColumn, DataTable, NameColumn } from '../components/dataTable';
import { createCustomer, deleteCustomer, getCustomerTableList, sendCustomerFileEmail, updateCustomer } from '../services/api/customer.api';
import { useAuth } from '../context/AuthContext';
import { pick } from 'lodash';
import { toast } from 'react-toastify';
import ActionMoreMenu from 'src/components/shared/ActionMoreMenu';
import CustomerDetails from 'src/components/customer/CustomerDetails';
import DeleteDialog from 'src/components/shared/DeleteDialog';
import { CSVLink } from "react-csv";
import { fDate } from 'src/utils/formatTime';
import NoteColumnClient from 'src/components/dataTable/NoteColumnClient';


export default function CustomerPage() {
    const [action, setAction] = useState(null);
    const [queryFilter, setQueryFilter] = useState();
    const [selectedItem, setSelectedItem] = useState();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [exportData, setExportData] = useState([]);


    const handleEdit = useCallback(
        (row) => {
            setSelectedItem(row);
            setAction("add")
        },
        [setSelectedItem, setAction]
    );

    const handleOnCloseDialog = useCallback((value) => {
        setAction(null);
        setSelectedItem(null);
    }, []);



    const handleDelete = useCallback(
        (id) => {
            setIsDeleted(id)
        }, []
    );

    const handleCheckBoxChange = useCallback(async (e, row, type) => {
        console.log(e.target.checked);
        await updateCustomer(row.id, { [type]: e.target.checked })
        toast.success('Enregistré avec succès');
    }, []);

    const columns = [
        {
            name: 'lastname',
            label: 'Nom',
            isSearchable: true,
            isSortable: true,
        },
        {
            name: 'firstname',
            label: 'Prénom',
            isSearchable: true,
            isSortable: true,
        },
        {
            name: 'phone',
            label: 'Téléphone',
            isSearchable: false,
            isSortable: true,
            render: (row) => <NameColumn name={`${row.phone}`} />
        },
        {
            name: 'email',
            label: 'Email',
            isSearchable: false,
            isSortable: true,
            render: (row) => <NameColumn name={`${row.email}`} />
        },
        {
            name: 'loyalty',
            label: 'Fidélité',
            isSearchable: false,
            isSortable: false,
            render: (row) => {
                return (row?.beach_reservation_count + row?.restaurant_reservation_count) || 0
            }
        },
        {
            name: 'comment',
            label: 'Commentaire client',
            isSortable: false,
            render: (row) =>
                <NoteColumnClient
                    withSave={true}
                    value={row?.comment || null}
                    row={row}
                />,
        },
        {
            name: 'noshow',
            label: 'No show',
            isSearchable: false,
            isSortable: false,
        },
        {
            key: 'member',
            label: 'Membre',
            isSearchable: false,
            isSortable: false,
            render: (row) => <CheckBoxColumn key={row.id} handleChange={(e) => handleCheckBoxChange(e, row, "member")} checked={`${row.member}`} />,
        },
        {
            key: 'restaurant_reservation_exists',
            label: 'Restaurant',
            isSortable: false,
            render: (row) => <CheckBoxColumn checked={`${row.restaurant_reservation_exists}`} />,
        },
        {
            key: 'beach_reservation_exists',
            label: 'Plage',
            isSortable: false,
            render: (row) => <CheckBoxColumn checked={`${row.beach_reservation_exists}`} />,
        },

        {
            name: '',
            label: 'Action',
            props: { align: 'center' },
            render: (row) => (
                <ActionMoreMenu
                    row={row}
                    onClickDelete={handleDelete}
                    onClickEdit={handleEdit}
                />
            ),
        }
    ];

    const handleDataTableChange = useCallback(
        async (filter) => {
            setQueryFilter(filter);
            if (user) {
                let userId = user?.id
                if (user.superadmin) {
                    userId = null
                }
                const res = await getCustomerTableList(userId, { ...filter });
                if (res && res.data && res.data.data) {
                    setData(res.data.data);
                    setTotal(res.data.total);
                    const exportData = res.data.data.map(user => {
                        const data = pick(user, ['id', 'firstname', "lastname", "email", "phone"])
                        return { ...data, "Membre": user.member ? "Oui" : "Non", "Date de crÃ©ation": fDate(user.createdAt), "Date de modification": fDate(user.updatedAt) };
                    });
                    setExportData(exportData || []);
                } else {
                    setData([])
                    setTotal(0)
                    setExportData([]);
                }
            }
        },
        [user]
    );


    useEffect(() => {
        if (queryFilter) {
            handleDataTableChange(queryFilter);
        }
    }, [handleDataTableChange, queryFilter]);

    const renderDetailRow = useCallback((row) => (<CustomerDetails row={row} />), []);

    const handleClickDelete = useCallback(async (id) => {
        const res = await deleteCustomer(id);
        if (res && res.status) {
            toast.success("Supprimé avec succès");
            handleDataTableChange(queryFilter);
        }
        setIsDeleted(null);
    }, [queryFilter]);


    const handleFormSubmit = useCallback(
        async (values, action) => {
            try {
                const inputValue = pick(values, ["lastname",
                    "firstname",
                    "phone",
                    "email",
                    "notifications",
                    "language",
                    "status",
                    "hutId",
                    "comment"
                ]);
                if (values.id) {
                    setLoading(true);
                    const res = await updateCustomer(values.id, inputValue)
                    setLoading(false)
                    if (res.status) {
                        setData(data.map((item) => item.id === values.id ? res.data : item));
                        toast.success(res.message);
                    } else {
                        setLoading(false);
                        toast.error(res.message);
                    }
                } else {
                    setLoading(true)
                    const res = await createCustomer(inputValue)
                    setLoading(false)
                    if (res.status) {
                        setData(prevArray => [...prevArray, res.data])
                        toast.success(res.message);
                    } else {
                        toast.error(res.message);
                    }
                }
                setAction(null)
            } catch (error) {
                toast.error(error.message);
            }
        },
        [data]
    );

    const sendCustomerFile = useCallback(async () => {
        if (user) {
            let formData = new FormData()
            formData.append('hutId', user.id)
            formData.append('to', user.login)
            formData.append('subject', user.name + ' - Votre fichier client')
            formData.append('html', '<br/>Vous trouverez ci-joint votre fichier client.')
            await sendCustomerFileEmail(formData)
        }
    }, [user]);

    return (
        <>
            <Helmet>
                <title> Customer | Beach Booker - Pro </title>
            </Helmet>
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container direction={"row"} justifyContent={"space-between"}>
                            <Grid item>
                                <Button onClick={() => {
                                    setSelectedItem(null);
                                    setAction("add")
                                }} variant="contained">Ajouter un client</Button>
                            </Grid>
                            <Grid item>
                                <Stack direction="row" spacing={2}>
                                    {exportData && (
                                        <CSVLink
                                            className="csv-button"
                                            filename={`beach_booker_fichier_client.csv`}
                                            data={exportData}
                                        >
                                            <Button variant="contained">Télécharger le fichier client</Button>
                                        </CSVLink>
                                    )}

                                    <Button variant="contained" onClick={sendCustomerFile}>Recevoir le fichier client par email</Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <DataTable
                            data={data}
                            columns={columns}
                            initialLoading
                            totalRow={total}
                            collapsible
                            onChange={handleDataTableChange}
                            renderDetailRow={(row) => renderDetailRow(row)}
                        />
                    </Grid>
                </Grid>
            </Container>
            {action == "add" &&
                <AddEditCustomerDialog
                    selectedItem={selectedItem}
                    onClose={handleOnCloseDialog}
                    handleFormSubmit={handleFormSubmit}
                    loading={loading}
                />
            }
            {isDeleted && (
                <DeleteDialog
                    title='Suppression de la réservation'
                    message='Souhaitez-vous vraiment supprimer cette réservation ?'
                    onClose={() => {
                        setIsDeleted(null)
                    }}
                    handleClickDelete={() => {
                        handleClickDelete(isDeleted)
                    }}
                />
            )}
        </>
    );
}
