import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Field, Form, Formik } from 'formik';
import {
  FormControl,
  FormLabel,
  MenuItem,
  Grid,
  InputAdornment,
  Select as MUISelect,
} from '@mui/material';
import { object, string } from 'yup';
import { TextField, Select } from 'formik-mui';
import Iconify from 'src/components/iconify/Iconify';
import EditorField from 'src/components/form/formik/EditorField';
import BackButton from 'src/components/shared/BackButton';
import { LoadingButton } from '@mui/lab';
import EditorFieldWithLanguage from 'src/components/form/formik/EditorFieldWithLanguage';
import NamesField from 'src/components/form/formik/NamesField';
import { checkJsonData } from 'src/utils/common';

const AddEditInformationForm = forwardRef((props, ref) => {
  const { values: selectedData, handleSubmit, cities } = props;
  const defaultValues = {
    id: '',
    name: '',
  };

  const validationSchema = object().shape({
    login: string().email().required(),
    cityId: string().required(),
  });

  const lifeBoatIcon = () => {
    return (
      <InputAdornment position="start">
        <Iconify icon="entypo:lifebuoy" />
      </InputAdornment>
    );
  };

  const lngLatJSONToString = (json) => {
    if (!json) return ''
    let jsonObj = JSON.parse(json)
    if (jsonObj) {
      const lngLat = jsonObj.coordinates
      const lng = lngLat[0]
      const lat = lngLat[1]
      return `${lat}, ${lng}`
    }

  }

  return (
    <Formik
      enableReinitialize
      innerRef={ref}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialValues={{
        ...defaultValues,
        ...selectedData,
        ...{ name: selectedData && selectedData.name ? selectedData.name : null },
        ...{
          lngLatPoint: selectedData && selectedData.geometry ? lngLatJSONToString(selectedData.geometry) : null,
          status: selectedData && selectedData?.status ? selectedData?.status : null,
          language: selectedData && selectedData?.language ? selectedData?.language : null,
          cityId: selectedData && selectedData?.cityId ? selectedData?.cityId : null,
          descriptions: selectedData && selectedData.descriptions ? JSON.parse(selectedData?.descriptions) : null,
          shortDescriptions: selectedData && selectedData.shortDescriptions ? JSON.parse(selectedData?.shortDescriptions) : null,
          names: checkJsonData(selectedData, "names")
        },
      }}
    >
      {({ getFieldProps, values }) => (
        <Form autoComplete="off" noValidate>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>Nom de la paillote *</FormLabel>
                <Field
                  size="small"
                  component={NamesField}
                  name="names"
                  fullWidth
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>City</FormLabel>
                <Field
                  startAdornment={lifeBoatIcon()}
                  size="small"
                  value={values?.cityId}
                  component={Select}
                  name="cityId"
                  fullWidth
                  required
                >
                  {cities &&
                    cities?.length > 0 &&
                    cities?.map((city, index) => (
                      <MenuItem value={city.id} key={`city_${index}`}>
                        {city.name}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>{`Email `}</FormLabel>
                <Field
                  type="email"
                  size="small"
                  component={TextField}
                  name="login"
                  fullWidth
                  InputProps={{
                    startAdornment: lifeBoatIcon(),
                  }}
                  required
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>Phone</FormLabel>
                <Field size="small" component={TextField} name="phone" fullWidth required />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>Geopoint (format: lat, lng)</FormLabel>
                <Field
                  size="small"
                  component={TextField}
                  name="lngLatPoint"
                  placeholder={'46.737120, 2.903679'}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>Short Description</FormLabel>
                <Field
                  size="small"
                  component={EditorFieldWithLanguage}
                  name="shortDescriptions"
                  fullWidth
                  multiline={true}
                  InputProps={{
                    startAdornment: lifeBoatIcon(),
                  }}
                  rows={2}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>Description</FormLabel>
                <Field
                  size="small"
                  component={EditorFieldWithLanguage}
                  name="descriptions"
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>Opening Period</FormLabel>
                <Field size="small" component={EditorField} name="openingPeriod" fullWidth />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>Pricing Text</FormLabel>
                <Field size="small" component={EditorField} name="pricingText" fullWidth />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>Source Website URL</FormLabel>
                <Field
                  size="small"
                  component={TextField}
                  name="websiteUrl"
                  fullWidth
                  InputProps={{
                    startAdornment: lifeBoatIcon(),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>Facebook URL</FormLabel>
                <Field
                  size="small"
                  component={TextField}
                  name="facebookUrl"
                  fullWidth
                  InputProps={{
                    startAdornment: lifeBoatIcon(),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>Instagram URL</FormLabel>
                <Field
                  size="small"
                  component={TextField}
                  name="instagramUrl"
                  fullWidth
                  InputProps={{
                    startAdornment: lifeBoatIcon(),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>Address Line 1</FormLabel>
                <Field
                  size="small"
                  component={TextField}
                  name="addressLine1"
                  fullWidth
                  InputProps={{
                    startAdornment: lifeBoatIcon(),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>Address Line 2</FormLabel>
                <Field
                  size="small"
                  component={TextField}
                  name="addressLine2"
                  fullWidth
                  InputProps={{
                    startAdornment: lifeBoatIcon(),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>Zip</FormLabel>
                <Field
                  size="small"
                  component={TextField}
                  name="zip"
                  fullWidth
                  InputProps={{
                    startAdornment: lifeBoatIcon(),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>Status</FormLabel>
                <MUISelect size="small" value={values.status} component={Select} name="status" fullWidth required>
                  <MenuItem value={'active'}>Active</MenuItem>
                  <MenuItem value={'inactive'}>In Active</MenuItem>
                </MUISelect>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>Language</FormLabel>
                <Field size="small" value={values.language} component={Select} name="language" fullWidth required>
                  <MenuItem value={'fr'}>FR</MenuItem>
                  <MenuItem value={'en'}>US</MenuItem>
                </Field>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>Date de création</FormLabel>
                <Field
                  size="small"
                  component={TextField}
                  name="createdAt"
                  fullWidth
                  disabled={true}
                  InputProps={{
                    startAdornment: lifeBoatIcon(),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>No booking Text</FormLabel>
                <Field size="small" component={EditorField} name="no_booking_text" fullWidth />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>Google Business link</FormLabel>
                <Field
                  size="small"
                  component={TextField}
                  name="business_link"
                  fullWidth
                  InputProps={{
                    startAdornment: lifeBoatIcon(),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction={'row'} justifyContent={'space-between'} sx={{ mt: 4, mb: 2 }}>
            <Grid item>
              <BackButton />
            </Grid>
            <Grid item>
              <LoadingButton type="submit" variant="contained">
                Save changes
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
});

export default AddEditInformationForm;

AddEditInformationForm.propTypes = {
  values: PropTypes.object,
  handleSubmit: PropTypes.func,
  cities: PropTypes.array,
};
