import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Grid, Container, Box, AppBar, Toolbar, Typography, Button, Alert } from '@mui/material';
import AccountPopover from '../layouts/dashboard/header/AccountPopover';
import { useAuth } from 'src/context/AuthContext';

export default function WelcomePage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const handleOnclickBox = useCallback(
    (linkTo) => {
      console.log(linkTo, 'linkTo');
      navigate(`/${linkTo}`);
    },
    [navigate]
  );

  return (
    <>
      <Helmet>
        <title> Page d'accueil | Beach Booker - Pro </title>
      </Helmet>
      <Container maxWidth={false} sx={{ minHeight: '100vh', padding: '0px !important', overflow: 'hidden', flexGrow:1 }}>
        <AppBar position="static" sx={{ background: '#fff', height: '76px', boxShadow: 'none', color: '#000' }}>
          <Toolbar>
            <Typography sx={{mr:2}}>&nbsp;</Typography>
            <Typography variant="h6" sx={{ flexGrow: 1 }} textAlign="center">
              <Box
                component="img"
                display={'inline'}
                alt="logo"
                src="/assets/beachbooker_header_home_logo.png"
                sx={{
                  mt: 10,
                  marginLeft: "185px !important",
                  width: '100px',
                  height: '100px'
                  // "@media screen and (min-width: 1600px)": {
                  //   ml: "3.5%",                    
                  // },
                  // "@media screen and (min-width: 1450px)": {
                  //   ml: "3.6%",                    
                  // },
                  // "@media screen and (min-width: 1365px)": {
                  //   ml: "4%",                    
                  // },
                  // "@media screen and (min-width: 1091px)": {
                  //   ml: "5%",                    
                  // },
                  // "@media screen and (min-width: 937px)": {
                  //   ml: "8%",                    
                  // },
                  
                }}
              />
            </Typography>
            <Button color="inherit" textAlign="end">
              <Typography sx={{ fontSize: '1.5rem', mr: 1, textTransform: 'uppercase', fontWeight: 700 }}>
                {user?.name && user.name}
              </Typography>
              <AccountPopover />
            </Button>
          </Toolbar>
        </AppBar>
        <Container maxWidth={false} sx={{ mt: 5, display: { sm: 'block', md: 'none' } }}>
          <Grid container direction={'row'} justifyContent={'center'} alignContent={'center'} sx={{ mt: 20 }}>
            <Grid item xs={12}>
              <Alert variant="filled" color="warning" icon={false}>
                Merci d'utiliser votre tablette en mode paysage pour accéder à cette page.
              </Alert>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={false} sx={{ mt: 3, display: { xs: 'none', md: 'block' } }}>
          <Grid
            container
            spacing={4}
            direction={'row'}
            justifyContent="center"
            sx={{ textAlign: 'center !important', alignItems: 'center' }}
          >
            <Grid item xs={6} sx={{ position: 'relative' }}>
              <Box
                component={'a'}
                onClick={() => handleOnclickBox('beach')}
                sx={{ textAlign: 'center', width: '100%' }}
                fullWidth
              >
                <img src="/assets/home/beachbooker_home_beach.png" className="fit-image" alt="Beach Booker - Beach" />
                <div style={{ paddingBottom: '18px' }} className="centered">
                  <img src="/assets/home/beachbooker_home_icon_beach.svg" alt="Beach" />
                  <p className="separator" />
                  <div className="title">Plage</div>
                </div>
              </Box>
            </Grid>

            <Grid item xs={6} sx={{ position: 'relative' }}>
              <Box component={'a'} onClick={() => handleOnclickBox('restaurant')} sx={{ textAlign: 'center' }}>
                <img
                  src="/assets/home/beachbooker_home_restaurant.png"
                  className="fit-image"
                  alt="Beach Booker - Restaurant"
                />
                <div style={{ paddingBottom: '18px' }} className="centered">
                  <img src="/assets/home/beachbooker_home_icon_restaurant.svg" alt="Restaurant" />
                  <p className="separator" />
                  <div className="title">Restaurant</div>
                </div>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={4}
            direction={'row'}
            justifyContent="center"
            sx={{ textAlign: 'center !important', alignItems: 'center', mt: 5 }}
          >
            <Grid
              item
              xs={4}
              className="d-flex justify-content-center sub-separator pointer"
              onClick={() => handleOnclickBox('customers')}
            >
              <img src="/assets/home/beachbooker_home_icon_customers.svg" className="icon" alt="Clients" />
              <div className="align-self-center">
                <div className="sub-title" to="/customers">
                  CLIENTS
                </div>
                <div className="sub-border" />
              </div>
            </Grid>
            <Grid
              item
              xs={4}
              className="d-flex justify-content-center sub-separator pointer"
              onClick={() => handleOnclickBox('statistics')}
            >
              <img src="/assets/home/beachbooker_home_icon_statistics.svg" className="icon" alt="Clients" />
              <div className="align-self-center">
                <div className="sub-title" to="/customers">
                  STATISTIQUES
                </div>
                <div className="sub-border" />
              </div>
            </Grid>
            <Grid
              item
              xs={4}
              className="d-flex justify-content-center sub-separator pointer"
              onClick={() => handleOnclickBox('marketing')}
            >
              <img src="/assets/home/beachbooker_home_icon_marketing.svg" className="icon" alt="Clients" />
              <div className="align-self-center">
                <div className="sub-title" to="/customers">
                  MARKETING
                </div>
                <div className="sub-border" />
              </div>
            </Grid>
          </Grid>
        </Container>
        <Box
          sx={{
            width: '100%',
            position: 'fixed',
            bottom: 0,
            justifyContent: 'space-between',
            display: 'flex',
            background: '#000',
            color: '#fff',
          }}
        >
          <Toolbar>
            <Typography>V.1</Typography>
          </Toolbar>
          <Toolbar>
            <Typography>@2020</Typography>
          </Toolbar>
        </Box>
      </Container>
    </>
  );
}
