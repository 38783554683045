import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography, Button } from '@mui/material';
import ChangePasswordForm from '../components/settings/ChangePasswordForm';

export default function SettingsPage() {

    return (
        <>
            <Helmet>
                <title> Paramètres | Beach Booker - Pro </title>
            </Helmet>
            <Container maxWidth="xl">
                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography>Vous pouvez changer votre mot de passe grâce au formulaire ci-contre.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Pour cela vous aurez besoin de votre ancien mot de passe (celui courant) et d'un nouveau mot de passe d'au moins 6 caractères.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>Idéalement, choisissez un mot de passe <strong>d’au moins 6 caractères de types différents </strong> (majuscules, minuscules, chiffres, caractères spéciaux).</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained"> Revenir sur la page précédente</Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        <ChangePasswordForm />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
