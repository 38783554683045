import api from "./api";

const getCustomerList = async (params) => {
    const response = await api.get(`api/customer-list`, { params });
    return response;
};

const getCustomerTableList = async (hutId, params) => {
    if (hutId) {
        const response = await api.get(`api/customer-list/${hutId}`, { params });
        return response;
    } else {
        const response = await api.get(`api/customer-list`, { params });
        return response;
    }

};

const createCustomer = async (body) => {
    const response = await api.post(`api/create-customer`, body);
    return response;
};

const updateCustomer = async (id, body) => {
    const response = await api.put(`api/update-customer/${id}`, body);
    return response;
};

const deleteCustomer = async (id) => {
    const response = await api.delete(`api/delete-customer/${id}`);
    return response;
};

const createPayment = async (body) => {
    const response = await api.post(`api/create-payment`, body);
    return response;
};

const createReservation = async (body) => {
    const response = await api.post(`api/create-reservation`, body);
    return response;
};

const searchCustomerList = async (hutId, params) => {
    if (hutId) {
        const response = await api.get(`api/search-customer/${hutId}`, { params });
        return response;
    } else {
        const response = await api.get(`api/search-customer`, { params });
        return response;
    }

};

const getHutBeachReservationsForAPositionOnADate = async (beachId, x, y, date, hutId) => {
    const response = await api.get(`api/check-reservation/${beachId}/${x}/${y}/${date}`);
    return response;
}

const customerReservationsList = async (customerId) => {
    const response = await api.get(`api/customer-reservations-list/${customerId}`);
    return response;
}

const filterCustomerList = async (hutId, params) => {
    const response = await api.get(`api/search-customer/${hutId}`, { params });
    return response;
};

const searchMarketingCustomerList = async (hutId, params) => {
    const response = await api.get(`api/search-marketing/${hutId}`, { params });
    return response;
};

const updateMarketing = async (id, body) => {
    const response = await api.put(`api/update-marketing/${id}`, body);
    return response;
};

const sendMarketingMail = async (body) => {
    const response = await api.post(`api/send-mail`, body);
    return response;
};

const createMarketing = async (body) => {
    const response = await api.post(`api/create-marketing`, body);
    return response;
};

const bodyListMarketing = async (hutId) => {
    const response = await api.get(`api/list-marketing/${hutId}`);
    return response;
}

const sendCustomerFileEmail = async (body) => {
    const response = await api.post(`api/email-customer-file`, body);
    return response;
};


export {
    sendMarketingMail,
    sendCustomerFileEmail,
    createMarketing,
    bodyListMarketing,
    searchMarketingCustomerList,
    updateMarketing,
    filterCustomerList,
    customerReservationsList,
    getCustomerTableList,
    getHutBeachReservationsForAPositionOnADate,
    createPayment,
    getCustomerList,
    createCustomer,
    updateCustomer,
    searchCustomerList,
    createReservation,
    deleteCustomer
};