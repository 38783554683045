/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import update from "immutability-helper";
import { Container, Box, Stack, Button, TextField, Checkbox, FormGroup, Grid, Typography, IconButton } from '@mui/material';
import BeachEditSidebar from '../../components/admin/beach/edit-beach/BeachEditSidebar';
import { getAdminRestaurantDetails, updateAdminRestaurant } from '../../services/api/admin-restaurant.api';
import Iconify from '../../components/iconify/Iconify';
import { INITIAL_SPOT, INITIAL_SPOTS, SCHEDULE_DATA } from 'src/data/admin-beach.data';
import AddBeachWarningDialog from '../../components/admin/beach/AddBeachWarningDialog';
import { toast } from 'react-toastify';
import ScheduleCheckbox from 'src/components/admin/beach/ScheduleCheckbox';
import RestaurantEditContentHeader from 'src/components/admin/restaurant/edit-restaurant/RestaurantEditContentHeader';
import AddRestaurantItemDialog from 'src/components/admin/restaurant/AddRestaurantItemDialog';
import AddRestaurantValueDialog from 'src/components/admin/restaurant/AddRestaurantValueDialog';
import { BEACH_RESTAURANT_ITEM_DATA } from 'src/data/admin-restaurant.data';
import { MovableItem } from 'src/components/admin/restaurant/MovableItem';
import { DropItem } from 'src/components/admin/restaurant/DropItem';
import DragDropSidebar from 'src/components/admin/restaurant/DragDropSidebar';
import CloseIcon from '@mui/icons-material/Close';
import InputFileUpload from 'src/components/shared/InputFileUpload';


export default function AdminEditRestaurantPage() {
    const { id } = useParams();
    const [isAddItem, setIsAddItem] = useState(false);
    const [isAddValue, setIsAddValue] = useState(false);
    const [isWarning, setIsWarning] = useState(false);
    const [isDragAndDrop, setIsDragAndDrop] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [spots, setSpots] = useState([]);
    const [schedule, setSchedule] = useState([]);
    const [selectedBox, setSelectedBox] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);

    const getRestaurantDetails = useCallback(async (id) => {
        const res = await getAdminRestaurantDetails(id);
        if (res.status && res.data) {
            const planData = res.data.plan != null ? JSON.parse(res.data.plan)?.plan : INITIAL_SPOTS;
            const _spotPlanArray = planData
            if (planData.length > 0) {
                for await (const [i, IValue] of Object.entries(planData)) {
                    for await (const [j, JValue] of Object.entries(IValue)) {
                        _spotPlanArray[i][j].selected = false;
                    }
                }
            }
            const scheduleData = res.data.schedule != null ? JSON.parse(res.data.schedule)?.schedule : [];
            setSpots(_spotPlanArray);
            setSchedule(scheduleData);
            setSelectedItem(res.data)
        }
    }, []);

    useEffect(() => {
        getRestaurantDetails(id)
    }, [getRestaurantDetails, id]);

    const handleRow = useCallback((type) => {
        if (type == "add") {
            if (spots.length > 0) {
                const newRow = []
                for (let i = 0; i < spots[0].length; i++) {
                    newRow.push(INITIAL_SPOT);
                }
                setSpots((prevHistory) => [...prevHistory, newRow]);
            }
        } else {
            setSpots((previousArr) => (previousArr.slice(0, -1)));
        }
    }, [spots]);

    const handleColumn = useCallback((type) => {
        if (spots.length > 0) {
            const latestSpot = spots.map((row) => {
                if (type == "add") {
                    const newRows = [...row, INITIAL_SPOT];
                    return newRows;
                } else {
                    const newRows = row.slice(0, -1)
                    return newRows;
                }
            });
            setSpots(latestSpot);
        }
    }, [spots]);

    const handleSave = useCallback(async () => {
        const updatedValue = selectedItem;
        updatedValue.plan = { plan: spots };
        updatedValue.schedule = { schedule };
        setLoading(true)
        const res = await updateAdminRestaurant(updatedValue, id)
        setLoading(false)
        if (res.status) {
            toast.success("enregistré avec succès")
            setSelectedBox([]);
        }
    }, [selectedItem, spots, schedule, id]);

    const handleSelectItem = useCallback((value) => {
        setIsAddItem(false)
        if (selectedBox.length > 0) {
            let _gridArray = [...spots];
            selectedBox.map(({ rowIndex, columnIndex }) => {
                const existingVal = _gridArray[rowIndex][columnIndex]
                _gridArray[rowIndex][columnIndex] = { ...existingVal, type: value, selected: false };
            });
            setSpots(_gridArray);
        }
        setSelectedBox([])
    }, [selectedBox, spots]);

    const handleSelectValue = useCallback((value) => {
        setIsAddValue(false)
        if (selectedValue) {
            let _gridArray = [...spots];
            _gridArray[selectedValue.rowIndex][selectedValue.columnIndex].id = value;
            setSpots(_gridArray);
            setSelectedValue(null);
        } else {
            let isNumber = false
            if (value && !isNaN(value)) {
                isNumber = true
            }
            if (selectedBox.length > 0) {
                let _gridArray = [...spots];
                let latestValue = isNumber ? Number(value) : value;
                selectedBox.map(({ rowIndex, columnIndex }) => {
                    const existingVal = _gridArray[rowIndex][columnIndex];
                    if (isNumber) {
                        if ((existingVal.type.startsWith("dining_table_2_seater") || existingVal.type.startsWith("dining_table_4_seater") || existingVal.type.startsWith("dining_table_6_seater"))) {
                            _gridArray[rowIndex][columnIndex] = { ...existingVal, id: latestValue, selected: false, checked: false };
                            latestValue++;
                        } else {
                            _gridArray[rowIndex][columnIndex] = { ...existingVal, id: null, selected: false, checked: false };
                        }
                    } else {
                        _gridArray[rowIndex][columnIndex] = { ...existingVal, id: value ? value : null, selected: false, checked: false };
                    }
                });
                setSpots(_gridArray);
            }
        }
        setSelectedBox([])
    }, [selectedBox, spots, selectedValue]);

    const handleEditValue = (rowIndex, columnIndex, value) => {
        setIsAddValue(true)
        setSelectedValue({ rowIndex, columnIndex, value })
    }

    const handleAddSelectItem = useCallback((type) => {
        if (selectedBox.length > 0) {
            if (type == "item") {
                setIsAddItem(true)
            } else {
                setIsAddValue(true)
            }
        } else {
            setIsWarning(true)
        }
    }, [selectedBox]);

    const handleOnClickBox = useCallback((rowIndex, columnIndex) => {
        let _gridArray = [...spots];
        const existingVal = _gridArray[rowIndex][columnIndex]
        _gridArray[rowIndex][columnIndex] = { ...existingVal, selected: existingVal?.selected ? !existingVal?.selected : true };
        const index = selectedBox.findIndex((item) => item.rowIndex === rowIndex && item.columnIndex === columnIndex);

        if (index !== -1) {
            const latestFacilityList = update(selectedBox, {
                $splice: [[selectedBox.indexOf({ rowIndex, columnIndex }), 1]],
            });
            setSelectedBox(latestFacilityList);
        } else {
            setSelectedBox([...selectedBox, { rowIndex, columnIndex }]);
        }
        setSpots(_gridArray);
    }, [selectedBox, spots]);


    const handleChangeSchedule = useCallback((checked, value) => {
        let _gridSchedule = [...schedule];
        if (checked && !_gridSchedule.includes(value)) {
            _gridSchedule.push(value);
        } else {
            _gridSchedule.splice(_gridSchedule.indexOf(value), 1);
        }
        setSchedule(_gridSchedule);
    }, [schedule]);

    const handleAll = useCallback(async (checked) => {
        let _spotArray = [...spots];
        let _selectedBoxArray = [];
        if (_spotArray.length > 0) {

            for await (const [i, IValue] of Object.entries(_spotArray)) {
                for await (const [j, JValue] of Object.entries(IValue)) {
                    _spotArray[i][j].selected = checked == "unSelect" ? false : true;
                }
            }

            for await (const [i, IValue] of Object.entries(_spotArray)) {
                for await (const [j, JValue] of Object.entries(IValue)) {
                    if (_spotArray[i][j].selected == true) {
                        _selectedBoxArray.push({ rowIndex: i, columnIndex: j })
                    }
                }
            }
            setSelectedBox(_selectedBoxArray);
            setSpots(_spotArray);
        }
    }, [spots]);

    const handleSelectAllRow = useCallback(async (e, rowIndex) => {
        let checked = e.target.checked;
        let _selectedBoxArray = [];
        let _spotArray = [...spots];
        // let selectedRow = _spotArray[rowIndex];

        for await (const [i, IValue] of Object.entries(_spotArray)) {
            for await (const [j, JValue] of Object.entries(IValue)) {
                if (i == rowIndex) {
                    _spotArray[i][j] = { ..._spotArray[i][j], selected: checked }
                }
            }
        }

        for await (const [i, IValue] of Object.entries(_spotArray)) {
            for await (const [j, JValue] of Object.entries(IValue)) {
                if (_spotArray[i][j].selected == true) {
                    _selectedBoxArray.push({ rowIndex: i, columnIndex: j })
                }
            }
        }
        setSelectedBox(_selectedBoxArray);
        setSpots(_spotArray);
    }, [spots]);

    const handleSelectAllColumn = useCallback(async (e, rowIndex, columnIndex) => {
        let checked = e.target.checked;
        let _selectedBoxArray = [];
        let _spotArray = [...spots];

        for await (const [i, IValue] of Object.entries(_spotArray)) {
            for await (const [j, JValue] of Object.entries(IValue)) {
                if (columnIndex == j) {
                    _spotArray[i][j] = { ..._spotArray[i][j], selected: checked };
                }
            }
        }
        for await (const [i, IValue] of Object.entries(_spotArray)) {
            for await (const [j, JValue] of Object.entries(IValue)) {
                if (_spotArray[i][j].selected == true) {
                    _selectedBoxArray.push({ rowIndex: i, columnIndex: j })
                }
            }
        }
        setSelectedBox(_selectedBoxArray);
        setSpots(_spotArray);
    }, [spots]);

    const handleDropItem = useCallback(async ({ rowIndex, columnIndex, id, name }) => {
        let _gridArray = [...spots];
        const existingVal = _gridArray[rowIndex][columnIndex]
        _gridArray[rowIndex][columnIndex] = { ...existingVal, type: name, selected: false };
        setSpots(_gridArray);
    }, [spots]);

    const handleIsDragAndDrop = useCallback(() => {
        setIsDragAndDrop((preState) => !preState);
    }, []);

    return (
        <>
            <Helmet>
                <title>Modifier la plage | Beach Booker - Pro </title>
            </Helmet>
            <Container maxWidth={false} sx={{ padding: "0 !important" }}>
                <Box sx={{ minHeight: `calc(100vh - 134px) !important`, display: 'flex' }}>
                    <DragDropSidebar
                        isDragAndDrop={isDragAndDrop}
                    >
                        <Stack direction={"row"}>
                            <Stack item>
                                <Typography variant="h6">Sélectionnez l'icône Glisser-déposer </Typography>
                            </Stack>
                            <Stack item>
                                <IconButton onClick={() => setIsDragAndDrop(false)}><CloseIcon /></IconButton>
                            </Stack>
                        </Stack>
                        <Box display={"flex"} sx={{ padding: 2 }}>
                            <Grid container spacing={1}>
                                {BEACH_RESTAURANT_ITEM_DATA.map((ele) => {
                                    return ele.name != "initial_state" ?
                                        <Grid item xs={4}>
                                            <MovableItem
                                                imgData={ele.img}
                                                name={ele.name}
                                                key={ele.name}
                                                type={ele.type}
                                                handleDropItem={handleDropItem}
                                            />
                                        </Grid> : null
                                })}
                            </Grid>
                        </Box>
                    </DragDropSidebar>

                    <BeachEditSidebar>
                        <Stack direction={"column"} spacing={2} sx={{ mt: 1 }}>
                            <Stack item>
                                <Button size="small" variant="contained" onClick={() => handleAddSelectItem("item")}>Spécifier un élément</Button>
                            </Stack>
                            <Stack item>
                                <Button size="small" variant="contained" onClick={() => handleAddSelectItem("value")}>Spécifier un numéro</Button>
                            </Stack>
                        </Stack>
                        <Stack item sx={{ mt: 2 }}>
                            <TextField
                                sx={{ background: "#fff" }}
                                size="small"
                                name={"name"}
                                value={selectedItem?.name || ''}
                                onChange={(e) => setSelectedItem(prevState => ({
                                    ...prevState,
                                    name: e.target.value,
                                }))}
                            />
                        </Stack>
                        <Stack item sx={{ mt: 2 }}>
                            <FormGroup row={true} >
                                {SCHEDULE_DATA.map(({ value, label }) => (
                                    <ScheduleCheckbox
                                        value={value}
                                        schedule={schedule}
                                        label={label}
                                        status={selectedItem?.status}
                                        handleOnClick={handleChangeSchedule}
                                    />
                                ))}
                            </FormGroup>
                        </Stack>

                        <Stack direction={"column"} sx={{ mt: 2 }} spacing={2}>
                            <Stack item>
                                <Typography alignContent={"center"} sx={{ fontSize: ".8em" }} component={"span"}>
                                    <Iconify width={15} icon="eva:radio-button-on-fill" />
                                    Sélectionnez les zones sur le plan de plage.
                                </Typography>
                            </Stack>
                            <Stack item>
                                <Typography alignContent={"center"} sx={{ fontSize: ".8em" }} component={"span"}>
                                    <Iconify width={15} icon="eva:radio-button-on-fill" />
                                    &nbsp;Cliquez sur le bouton Spécifier un élément et sélectionnez l'objet souhaité.
                                </Typography>
                            </Stack>
                            <Stack item>
                                <Typography alignContent={"center"} sx={{ fontSize: ".8em" }} component={"span"}>
                                    <Iconify width={15} icon="eva:radio-button-on-fill" />
                                    &nbsp;Spécifier un identifiant.
                                </Typography>
                            </Stack>
                            <Stack item>
                                <Typography alignContent={"center"} sx={{ fontSize: ".8em" }} component={"span"}>
                                    <Iconify width={15} icon="eva:radio-button-on-fill" />
                                    &nbsp;Ajouter ou supprimer des lignes ou/et des colonnes à votre plan de plage.
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack direction={"column"} sx={{ mt: 2 }} spacing={2}>
                            <InputFileUpload
                                value={selectedItem?.pdf_file}
                                handleOnChange={(e) => {
                                    setSelectedItem(prevState => ({
                                        ...prevState,
                                        menu: e.target.files[0],
                                    }))
                                }}
                            />
                        </Stack>

                    </BeachEditSidebar>

                    <RestaurantEditContentHeader
                        handleIsDragAndDrop={handleIsDragAndDrop}
                        handleRow={handleRow}
                        handleColumn={handleColumn}
                        handleSave={handleSave}
                        loading={loading}
                        handleAll={handleAll}
                    >
                        {spots.length && spots.map((row, rowIndex) => (
                            <Grid container direction={"row"} spacing={0.5} alignItems={"center"} width={"100%"}>
                                <Grid item sx={{ mt: 1, height: 50 }}>
                                    <Typography sx={{ ...rowIndex == 0 && { mt: 2.5 } }}>
                                        <Checkbox onClick={(e) => handleSelectAllRow(e, rowIndex)} size='small' disableRipple></Checkbox>
                                        {rowIndex + 1}
                                    </Typography>
                                </Grid>
                                {row.map((column, columnIndex) => {
                                    return (
                                        <>
                                            <Grid item sx={{ minHeight: "45px", paddingLeft: "0px !important", mb: 0.8 }}>
                                                {rowIndex == 0 && (
                                                    <Checkbox onClick={(e) => handleSelectAllColumn(e, rowIndex, columnIndex)} size='small' disableRipple></Checkbox>
                                                )}
                                                <DropItem
                                                    type={column.type}
                                                    id={column.id}
                                                    rowIndex={rowIndex}
                                                    columnIndex={columnIndex}
                                                    selected={column.selected}
                                                    handleOnClick={() => handleOnClickBox(rowIndex, columnIndex)}
                                                    handleEditValue={handleEditValue}
                                                />
                                            </Grid>
                                        </>
                                    )
                                }
                                )}
                            </Grid>
                        ))}
                    </RestaurantEditContentHeader>
                </Box>
            </Container >
            {isAddItem && (
                <AddRestaurantItemDialog
                    handleFormSubmit={handleSelectItem}
                    onClose={() => setIsAddItem(false)}
                />
            )
            }
            {isAddValue && (
                <AddRestaurantValueDialog
                    selectedValue={selectedValue}
                    handleFormSubmit={handleSelectValue}
                    onClose={() => setIsAddValue(false)}
                />
            )}
            {isWarning && (
                <AddBeachWarningDialog onClose={() => setIsWarning(false)} />
            )}
        </>
    );
}
