import api from "./api";

const getAdminGalleryList = async (hutId) => {
    const response = await api.get(`api/image-list/${hutId}`);
    return response;
};

const createAdminImage = async (body) => {
    const response = await api.post(`api/image-upload`, body, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response;
};

const deleteAdminImage = async (id) => {
    const response = await api.delete(`api/delete-image/${id}`);
    return response;
};

export {
    getAdminGalleryList,
    createAdminImage,
    deleteAdminImage
};