import PropTypes from 'prop-types';
import { useState } from 'react';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import FormDialog from '../../dialog/FormDialog';
import { Box, Tab, Grid } from '@mui/material';
import RestaurantIconImage from './RestaurantIconImage';
import _ from 'lodash';
import { BEACH_RESTAURANT_ITEM_DATA } from 'src/data/admin-restaurant.data';



function AddRestaurantItemDialog({ onClose, handleFormSubmit, loading }) {
    const [value, setValue] = useState('Counter');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <FormDialog
            handleClose={onClose}
            header={`Choisissez l'objet à ajouter`}
            maxWidth={"md"}
            width={"md"}
            fullWidth
            withAction={(
                <LoadingButton
                    type="submit"
                    variant='contained'
                    onClick={() => handleFormSubmit("empty")}
                    loading={loading}
                >
                    Save
                </LoadingButton>
            )}
        >
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Counter" value="Counter" />
                        <Tab label="Table" value="Table" />                        
                        <Tab label="Vide" value="Vide" />
                    </TabList>
                </Box>
                <TabPanel value="Counter">
                    <Grid container spacing={2} direction={"row"}>
                        {_.filter(BEACH_RESTAURANT_ITEM_DATA, { type: "Counter" }).map((ele) => (
                            <RestaurantIconImage
                                src={ele.img}
                                name={ele.name}
                                key={ele.name}
                                handleOnClick={handleFormSubmit}
                            />
                        ))}
                    </Grid>
                </TabPanel>
                <TabPanel value="Table">
                    <Grid container spacing={2} direction={"row"}>
                        {_.filter(BEACH_RESTAURANT_ITEM_DATA, { type: "Table" }).map((ele) => (
                            <RestaurantIconImage
                                src={ele.img}
                                name={ele.name}
                                key={ele.name}
                                handleOnClick={handleFormSubmit}
                            />
                        ))}
                    </Grid>
                </TabPanel>
                <TabPanel value="Vide">
                    <Grid container spacing={2} direction={"row"}>
                        {_.filter(BEACH_RESTAURANT_ITEM_DATA, { type: "Vide" }).map((ele) => (
                            <RestaurantIconImage
                                src={ele.img}
                                name={ele.name}
                                key={ele.name}
                                handleOnClick={handleFormSubmit}
                            />
                        ))}
                    </Grid>
                </TabPanel>
            </TabContext>
        </FormDialog>
    );
}

export default AddRestaurantItemDialog;

AddRestaurantItemDialog.propTypes = {
    onClose: PropTypes.func,
    handleFormSubmit: PropTypes.func,
};
