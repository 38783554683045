import { Box, Drawer, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

const DRAWER_WIDTH = 320;
const COLLAPSE_WIDTH = 200;
export default function BeachSidebar({ children }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isCollapse = !isMobile



    return (
        <Drawer
            open
            variant="persistent"
            sx={{
                background: "#69bed0",
                width: DRAWER_WIDTH,
                transition: theme.transitions.create('width'),
                '& .MuiDrawer-paper': {
                    position: 'static',
                    width: DRAWER_WIDTH
                },
                ...(isCollapse && {
                    width: COLLAPSE_WIDTH,
                    '& .MuiDrawer-paper': {
                        width: COLLAPSE_WIDTH,
                        position: 'static',
                        transform: 'none !important',
                        visibility: 'visible !important',
                        background: "#69bed0"
                    }
                })
            }}
        >
            <Box sx={{ py: 2, px: 1 }}>
                {children}
            </Box>
        </Drawer>
    );
}
