import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { AuthProvider } from './context/AuthContext';
import DialogsProvider from './hooks/Dialogs/Provider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
// ----------------------------------------------------------------------

export default function App() {

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <DialogsProvider>
            <ScrollToTop />
            <StyledChart />
            <LocalizationProvider dateAdapter={AdapterMoment}>
            <AuthProvider>
              <Router />
            </AuthProvider>
            </LocalizationProvider>
          </DialogsProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
