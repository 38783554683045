import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';
import { LoadingButton } from '@mui/lab';
import FormDialog from '../../dialog/FormDialog';
import AddEditCustomerForm from './AddEditCustomerForm';


function AddEditCustomerDialog({ onClose, selectedItem, handleFormSubmit, loading }) {
    const formRef = useRef(null);


    const handleSubmitFormTrigger = useCallback(
        () => {
            const form = formRef?.current;
            if (form.isValid) {
                form.submitForm();
            }
        },
        [formRef],
    )


    return (
        <FormDialog
            handleClose={onClose}
            header={`${selectedItem?.id ? "Modifier" : "Créer"} Cliente`}
            maxWidth={"sm"}
            width={"sm"}
            fullWidth
            withAction={(
                <LoadingButton
                    type="submit"
                    variant='contained'
                    onClick={handleSubmitFormTrigger}
                    loading={loading}                    
                >
                    Sauvegarder
                </LoadingButton>
            )}
        >
            <AddEditCustomerForm
                onSubmit={handleFormSubmit}
                ref={formRef}
                values={selectedItem}
            />
        </FormDialog>
    );
}

export default AddEditCustomerDialog;

AddEditCustomerDialog.propTypes = {
    selectedItem: PropTypes.object,
    onClose: PropTypes.func,
    handleFormSubmit: PropTypes.func,
};
