import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { pick } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Grid, Container, Button, Stack, IconButton, Link } from '@mui/material';
import { CheckBoxColumn, DataTable, LanguageColumn, NamesColumn, StatusColumn } from '../../components/dataTable';
import { getAllAdminList } from '../../services/admin-service.service';
import { citiesList } from '../../services/admin-city.service';
import { createAdminUser, deleteAdminUser, updateAdminUser } from '../../services/admin.service';
import AddEditAdminDialog from '../../components/admin/AddEditAdminDialog';
import ActionMoreMenu from '../../components/shared/ActionMoreMenu';
import { updateHutAdminFromList } from 'src/services/api/admin.api';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';


export default function AdminPage() {
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [cities, setCities] = useState([]);
    const [action, setAction] = useState(null);
    const [loading, setLoading] = useState(false);

    const getCitiesData = useCallback(async () => {
        const resCity = await citiesList();
        if (resCity && resCity.data) {
            setCities(resCity.data);
        }
    }, []);

    useEffect(() => {
        getCitiesData()
    }, []);

    const handleEdit = useCallback(
        (row) => {
            setSelectedItem(row);
            setAction("add");
        },
        [setSelectedItem]
    );

    const handleOnCloseDialog = useCallback((value) => {
        setAction(null);
        setSelectedItem(null);
    }, []);

    const handleDelete = async (id) => {
        const color = window.confirm("Voulez-vous vraiment supprimer l'utilisateur ?");
        if (color === true) {
            const res = await deleteAdminUser(id);
            if (res.status) {
                setData(data.filter(item => item.id !== id));
                toast.success('Supprimé avec succès');
            } else {
                toast.error('Erreur lors de la suppression');
            }
        }
    };

    const handleCheckBoxChange = useCallback(async (e, row, type) => {
        console.log(e.target.checked);
        await updateAdminUser({ [type]: e.target.checked }, row.id)
        toast.success('Enregistré avec succès');
    }, []);

    const columns = [
        {
            name: 'names',
            label: 'Nom',
            isSearchable: true,
            isSortable: false,
            render: (row) => {
                return (
                    <Link href="#" onClick={() => navigate(`/admin/hut/${row.id}`)} underline="hover" color={'inherit'}>
                        {row.names && row.names?.fr}
                    </Link>
                );
            },
        },
        {
            name: 'login',
            label: 'Connexion',
            isSearchable: false,
            isSortable: true,
        },
        {
            name: 'city.name',
            label: 'Ville',
            isSearchable: false,
            isSortable: false,
        },
        {
            name: 'login',
            label: 'Geopoint',
            isSearchable: false,
            isSortable: false,
            render: (row) => {
                if (row.geometry && row.geometry) {
                    const arrayGeometry = row.geometry ? JSON.parse(row.geometry) : null;
                    const latLng = arrayGeometry && arrayGeometry.coordinates ? arrayGeometry.coordinates.slice().reverse() : null
                    let link = `https://google.com/maps/search/${latLng.join(',')}/@${latLng.join(',')},13z`;
                    if (latLng) {
                        return (<IconButton disableRipple onClick={() => window.open(link, '_blank')} fontSize='12'><CheckCircleIcon /></IconButton>);
                    } else {
                        return (<IconButton disableRipple fontSize='12'><CancelIcon /></IconButton>);
                    }
                } else {
                    return (<IconButton disableRipple fontSize='12'><CancelIcon /></IconButton>);
                }
            }
        },
        {
            name: 'admin',
            label: 'Administrateur',
            isSearchable: false,
            isSortable: false,
            render: (row) => <CheckBoxColumn key={row.id} handleChange={(e) => handleCheckBoxChange(e, row, "admin")} checked={`${row.admin}`} />,
        },
        {
            name: 'isPartner',
            label: 'Partenaire',
            isSearchable: false,
            isSortable: false,
            render: (row) => <CheckBoxColumn key={row.id} handleChange={(e) => handleCheckBoxChange(e, row, "isPartner")} checked={`${row.isPartner}`} />,
        },
        {
            name: 'notifications',
            label: 'Avis',
            isSearchable: false,
            isSortable: false,
            render: (row) => <CheckBoxColumn key={row.id} handleChange={(e) => handleCheckBoxChange(e, row, "notifications")} checked={`${row.notifications}`} />,
        },
        {
            name: 'language',
            label: 'Langue',
            isSortable: true,
            render: (row) => <LanguageColumn language={`${row.language}`} />,

        },
        {
            name: 'status',
            label: 'Statut',
            isSortable: true,
            render: (row) => <StatusColumn status={`${row.status}`} />,
        },
        {
            name: '',
            label: 'Action',
            props: { align: 'center' },
            render: (row) => (
                <ActionMoreMenu
                    row={row}
                    onClickDelete={handleDelete}
                    onClickEdit={handleEdit}
                />
            ),
        },
    ];

    const handleDataTableChange = useCallback(async (filter) => {
        const res = await getAllAdminList(filter);
        if (res && res.data && res.data.data) {
            setData(res.data.data);
            setTotal(res.data.total)
        } else {
            setData([])
            setTotal(0)
        }
    }, []);

    const handleFormSubmit = useCallback(
        async (values, action) => {
            const inputValue = pick(values, ['login', 'cityId']);
            if (values.name) {
                const names = { fr: values.name }
                inputValue.names = JSON.stringify(names);
            }
            try {
                if (values.id) {
                    setLoading(true);
                    const updateValue = pick(values, ['login', 'cityId', "admin", "isPartner", "notifications", "language"]);
                    if (values.name) {
                        const names = { fr: values.name }
                        updateValue.names = JSON.stringify(names);
                    }
                    const res = await updateHutAdminFromList(updateValue, values.id)
                    setLoading(false)
                    if (res.status) {
                        setData(data.map((item) => item.id === values.id ? res.data : item));
                        toast.success(res.message);
                    } else {
                        toast.error(res.message);
                    }
                } else {
                    setLoading(true)
                    const res = await createAdminUser({ ...inputValue, password: "123456" }) // TO:DO Save code here;
                    setLoading(false)
                    if (res.status) {
                        setData(prevArray => [...prevArray, res.data])
                        toast.success(res.message);
                        setAction(null)
                    } else {
                        toast.error((res.data.length > 0 && res.data[0]) || res.message);
                    }
                }

            } catch (error) {
                toast.error(error.message);
            }
        },
        [data]
    );

    const handleAdd = useCallback(() => {
        setSelectedItem(null);
        setAction("add")
    }, [setAction]);

    return (
        <>
            <Helmet>
                <title> Customer | Beach Booker - Pro </title>
            </Helmet>
            <Container maxWidth={false} sx={{ mt: 5 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container direction={'row'} justifyContent={'space-between'}>
                            <Grid item>
                                <Stack direction="row" spacing={2}>
                                    <Button onClick={handleAdd} variant="contained">
                                        Ajouter une paillote
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item>
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        variant="contained"
                                        onClick={() => navigate("/admin/services")}
                                    >
                                        Services généraux
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => navigate("/admin/cities")}
                                    >
                                        Villes générales
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <DataTable
                            data={data}
                            columns={columns}
                            initialLoading
                            totalRow={total}
                            onChange={handleDataTableChange}
                        />
                    </Grid>
                </Grid>
            </Container>

            {action === 'add' &&
                <AddEditAdminDialog
                    loading={loading}
                    cities={cities}
                    selectedItem={selectedItem}
                    onClose={handleOnCloseDialog}
                    handleFormSubmit={handleFormSubmit}
                />
            }
        </>
    );
}
