import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';
import AddEditBeachReservationForm from './AddEditBeachReservationForm';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    position: 'fixed',
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, background: "#b4dee6" }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function AddEditBeachReservationDialog({ onClose, selectedEditItem, handleFormSubmit, date, loading, type = "beach" }) {
    const formRef = useRef(null);

    const handleSubmitFormTrigger = useCallback(
        () => {
            const form = formRef?.current;
            if (form.isValid) {
                form.submitForm();
            }
        },
        [formRef],
    )

    return (
        <>
            <BootstrapDialog
                maxWidth="sm"
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={true}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
                    Réservation
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <AddEditBeachReservationForm
                        type={type}
                        date={date}
                        onSubmit={handleFormSubmit}
                        ref={formRef}
                        values={selectedEditItem}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>Annuler</Button>
                    <LoadingButton variant="contained" loading={loading} onClick={handleSubmitFormTrigger}>
                        Sauvegarder
                    </LoadingButton>
                </DialogActions>
            </BootstrapDialog>
        </>
    );
}

export default AddEditBeachReservationDialog;

AddEditBeachReservationDialog.propTypes = {
    selectedItem: PropTypes.object,
    onClose: PropTypes.func,
    handleFormSubmit: PropTypes.func,
    cities: PropTypes.array,
    date: PropTypes.any,
    loading: PropTypes.bool,
};
