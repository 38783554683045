/* eslint-disable eqeqeq */
import PropTypes from 'prop-types';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { FormControl, FormControlLabel, Checkbox, Grid, Radio, RadioGroup, Stack, Select, MenuItem } from '@mui/material';
import { number, object, string } from 'yup';
import { TextField } from 'formik-mui';
import moment from 'moment/moment';
import DateRangeField from 'src/components/form/formik/DateRangeField';
import CustomerAutocomplete from '../CustomerAutocomplete';


const AddEditBeachReservationForm = forwardRef((props, ref) => {
    const [timeHour, setTimeHour] = useState(12);
    const [timeMinutes, setTimeMinutes] = useState(`00`);


    const {
        values: selectedEditItem,
        onSubmit,
        date,
        type = "beach"
    } = props;


    useEffect(() => {
        if (selectedEditItem && selectedEditItem.reservation_time) {
            let timeArr = selectedEditItem.reservation_time.split(":");
            setTimeHour(timeArr[0] || "12")
            setTimeMinutes(timeArr[1] || `00`)
        }
    }, [selectedEditItem]);

    const defaultValues = {
        id: selectedEditItem?.id || "",
        firstname: selectedEditItem?.customer.firstname || '',
        lastname: selectedEditItem?.customer.lastname || '',
        phone: selectedEditItem?.customer.phone || '',
        email: selectedEditItem?.customer.email || '',
        comment: selectedEditItem?.comment || '',
        customerId: selectedEditItem?.customer.id || '',
        reservation_time: selectedEditItem?.reservation_time ? selectedEditItem?.reservation_time : null || '12:00',
        walkIn: selectedEditItem?.customer.lastname === 'Walk-in' ? true : false,
        isMailSend: selectedEditItem?.isMailSend ?? true,
        half_day: selectedEditItem?.half_day ? true : false,
        half_day_type: selectedEditItem?.half_day_type ? selectedEditItem?.half_day_type : "firstHalf",
        multiple: false,
        dates: [],
        reservationRange: {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    };


    const validationSchema = object().shape({
        firstname: string().label("Prénom").required("Merci de saisir un prénom"),
        lastname: string().label("Nom").required("Merci de saisir un nom"),
        email: string().email("Merci de saisir un email valide").required(),
        phone: number().label("Email").typeError("Merci de saisir un numéro valide").nullable(),
        ...type == "restaurant" && { reservation_time: string().label("Heure de réservation").required("Merci de saisir un Heure de réservation"), }
    });

    const handleWalkIn = useCallback((e) => {
        if (e.target.checked && ref.current) {
            ref.current.setFieldValue("firstname", moment().format('[le] DD/MM/YYYY [à] hh:mm') || '')
            ref.current.setFieldValue("lastname", "Walk-in")
        }
    }, [ref]);

    const handleOnchangeCustomer = useCallback((e, selectedValue) => {
        ref?.current.setFieldValue("firstname", selectedValue?.firstname || '')
        ref?.current.setFieldValue("lastname", selectedValue?.lastname || '')
        ref?.current.setFieldValue("email", selectedValue?.email || '')
        ref?.current.setFieldValue("phone", selectedValue?.phone || '')
        ref?.current.setFieldValue("comment", selectedValue?.comment || '')
        ref?.current.setFieldValue("customerId", selectedValue?.id || '')

        if (ref?.current) {
            setTimeout(() => ref?.current.setFieldTouched("firstname", true));
            setTimeout(() => ref?.current.setFieldTouched("lastname", true));
            setTimeout(() => ref?.current.setFieldTouched("email", true));
            setTimeout(() => ref?.current.setFieldTouched("phone", true));
            setTimeout(() => ref?.current.setFieldTouched("reservation_time", true));
            ref.current.setErrors(null)
        }

    }, [ref]);

    const handleChangeDatepicker = useCallback((values) => {
        let endDate;
        let startDate = moment(values.startDate).toDate()
        if (values.endDate) {
            endDate = moment(values.endDate).toDate()
        } else {
            endDate = moment(date).add(6, 'days').toDate()
        }
        // Get all the days between two dates
        let dates = []
        let currentDate = moment(startDate)
        let lastDate = moment(endDate)
        dates.push(currentDate.clone().toDate())
        while (currentDate.add(1, 'days').diff(lastDate) <= 0) {
            dates.push(currentDate.clone().toDate())
        }
        ref.current.setFieldValue("dates", dates)
    }, [date, ref]);

    const handleChangeMultiple = useCallback((e) => {
        if (e.target.checked) {
            let currentDate = moment()
            ref.current.setFieldValue("dates", [currentDate.clone().toDate()])
        } else {
            ref.current.setFieldValue("dates", [])
        }
    }, [ref]);

    return (
        <Formik
            enableReinitialize={false}
            innerRef={ref}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={defaultValues}
        >
            {({ getFieldProps, values, errors, touched, setFieldValue }) => (
                <Form autoComplete="off" noValidate>
                    <Grid spacing={3} container>
                        <Grid item xs={12}>
                            <CustomerAutocomplete
                                handleOnchangeCustomer={handleOnchangeCustomer}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Field
                                    size="small"
                                    component={TextField}
                                    name="firstname"
                                    placeholder="Prénom *"
                                    fullWidth
                                    disabled={values.walkIn}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Field
                                    size="small"
                                    component={TextField}
                                    name="lastname"
                                    placeholder="Nom *"
                                    fullWidth
                                    disabled={values.walkIn}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Field
                                    size="small"
                                    component={TextField}
                                    name="phone"
                                    placeholder="Téléphone"
                                    fullWidth
                                // disabled={values.walkIn}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Field
                                    size="small"
                                    component={TextField}
                                    name="email"
                                    type="email"
                                    placeholder="E-mail"
                                    fullWidth
                                // disabled={values.walkIn}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Field
                                    size="small"
                                    component={TextField}
                                    multiple={true}
                                    minRows={2}
                                    name="comment"
                                    placeholder="Commentaire"
                                    fullWidth
                                    disabled={values.walkIn}
                                />
                            </FormControl>
                        </Grid>
                        {type == "restaurant" &&
                            <Grid item xs={12}>

                                <Stack direction={"row"} sx={{ mt: "5px", mb: "10px" }}>
                                    <Select
                                        size="small"
                                        sx={{
                                            width: "80px",
                                            background: '#fff',
                                            border: 'none',
                                            height: '40px',
                                            color: "#000",
                                            borderRadius: '12px',
                                            "& fieldset.MuiOutlinedInput-notchedOutline": {
                                                borderTopRightRadius: "0px",
                                                borderBottomRightRadius: "0px"
                                            }
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    fontSize: '16px',
                                                    fontFamily: '"TT Norms Pro Regular",Helvetica,sans-serif!important',
                                                    color: '#06133a',
                                                    '& .MuiMenuItem-root': {
                                                        padding: 2,
                                                    },
                                                },
                                            },
                                            variant: 'menu',
                                            transformOrigin: { vertical: 'top', horizontal: 'center' },
                                            getContentAnchorEl: null,
                                            disableScrollLock: true,
                                        }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={timeHour}
                                        onChange={(e) => {
                                            setTimeHour(e.target.value);
                                            setFieldValue("reservation_time", `${e.target.value}:${timeMinutes}`)
                                        }}
                                        shrink={false}
                                    >
                                        {(function (rows, i, len) {
                                            while (++i <= len) {
                                                rows.push(<MenuItem sx={{ height: "30px" }} disableGutters={true} dense={true} value={i}>{i}</MenuItem>)
                                            }
                                            return rows;
                                        })([], 11, 22)}
                                    </Select>
                                    <Select
                                        sx={{
                                            width: "80px",
                                            color: '#000',
                                            border: 'none',
                                            height: '40px',
                                            borderRadius: '12px',
                                            "& fieldset.MuiOutlinedInput-notchedOutline": {
                                                borderTopLeftRadius: "0px 0px",
                                                borderBottomLeftRadius: "0px",
                                                borderLeft: "0px"
                                            }
                                        }}
                                        inputProps={{ sx: { color: "#000 !important" } }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    fontSize: '16px',
                                                    fontFamily: '"TT Norms Pro Regular",Helvetica,sans-serif!important',
                                                    color: '#06133a',
                                                    '& .MuiMenuItem-root': {
                                                        padding: 2,
                                                    },
                                                },
                                            },
                                            variant: 'menu',
                                            transformOrigin: { vertical: 'top', horizontal: 'center' },
                                            getContentAnchorEl: null,
                                            disableScrollLock: true,
                                        }}
                                        size="small"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={timeMinutes}
                                        onChange={(e) => {
                                            setTimeMinutes(e.target.value)
                                            setFieldValue("reservation_time", `${timeHour}:${e.target.value}`)
                                        }}
                                        shrink={false}
                                    >
                                        <MenuItem sx={{ height: "30px" }} disableGutters={true} dense={true} value={`00`}>00</MenuItem>
                                        <MenuItem sx={{ height: "30px" }} disableGutters={true} dense={true} value={15}>15</MenuItem>
                                        <MenuItem sx={{ height: "30px" }} disableGutters={true} dense={true} value={30}>30</MenuItem>
                                        <MenuItem sx={{ height: "30px" }} disableGutters={true} dense={true} value={45}>45</MenuItem>
                                    </Select>
                                </Stack>


                                {/* <FormControl>
                                    <Field
                                        size="small"
                                        component={TimeField}
                                        name="reservation_time"
                                        placeholder={"Temps"}
                                        className={`${errors?.reservation_time && touched?.reservation_time ? 'is-invalid' : ''}`}
                                    />
                                </FormControl> */}
                            </Grid>
                        }
                        {type != "restaurant" && (
                            <>
                                <Grid item xs={12} sx={{ paddingTop: 0.3 }}>
                                    <FormControlLabel
                                        label="Réservations sur plusieurs jours"
                                        control={<Checkbox
                                            size="small"
                                            {...getFieldProps('multiple')}
                                            checked={values.isReservation}
                                            disabled={values.walkIn}
                                            onClick={handleChangeMultiple}
                                        />}
                                    />
                                    {values.multiple && (
                                        <Field
                                            size="small"
                                            component={DateRangeField}
                                            name="reservationRange"
                                            handleChange={handleChangeDatepicker}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} sx={{ paddingTop: 0.3 }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            size="small"
                                            {...getFieldProps('walkIn')}
                                            onClick={handleWalkIn}
                                        />}
                                        label="Walk-in"
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ paddingTop: 0.3 }}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            size="small"
                                            {...getFieldProps('half_day')}
                                        />}
                                        label="Demi-Journée"
                                    />
                                    {values.half_day && (
                                        <FormControlLabel
                                            sx={{ ml: 2 }}
                                            control={
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="half_day_type"
                                                    {...getFieldProps('half_day_type')}
                                                >
                                                    <FormControlLabel value="firstHalf" control={<Radio />} label="Matinée" />
                                                    <FormControlLabel value="secondHalf" control={<Radio />} label="Après-Midi" />
                                                </RadioGroup>
                                            }
                                        />
                                    )}
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} sx={{ paddingTop: 0.2 }}>
                            <FormControlLabel
                                disableTypography
                                control={
                                    <Checkbox
                                        size="small"
                                        defaultChecked={defaultValues?.isMailSend}
                                        {...getFieldProps('isMailSend')}
                                    />}
                                label="Envoyer un e-mail de confirmation avec lien"
                            />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
});

export default AddEditBeachReservationForm;

AddEditBeachReservationForm.propTypes = {
    values: PropTypes.object,
    onSubmit: PropTypes.func,
    cities: PropTypes.array,
};
