import { forwardRef, useEffect, useState } from 'react';
import RestoreIcon from '@mui/icons-material/Restore';
import PropTypes from 'prop-types';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Stack, IconButton, Button, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField } from 'formik-mui';
import SelectIconComponent from './SelectIconComponent';

const AddEditServiceForm = forwardRef(({
    onSubmit,
    data
}, ref) => {

    const defaultTeammate = {
        icon: '',
        name: '',
        id: '',
        deleted: false
    };
    const defaultValues1 = {
        id: '',
        services: [{ ...defaultTeammate }]
    };

    const [defaultValues, setDefaultValues] = useState(defaultValues1)

    useEffect(() => {
        setDefaultValues(prevState => ({
            ...prevState,
            services: data,
        }));
    }, [data]);

    return (
        <Formik
            enableReinitialize
            onSubmit={onSubmit}
            innerRef={ref}
            initialValues={defaultValues}
        >
            {({ values, setFieldValue }) => (
                <Form autoComplete="off" noValidate>
                    <FieldArray
                        name="services"
                        render={({ remove, push, index }) => (
                            <Stack spacing={2} key={`service_key_${index}`}>
                                <>
                                    {values.services.length > 0 &&
                                        values.services.map((element, index) => (
                                            <Stack key={`service_key_inner${index}`} sx={{ padding: 3, borderRadius: 2, background: !element.deleted ? "#f1f1f1" : "#ff50503a" }} direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                                {element.icon && (
                                                    <Box
                                                        component="img"
                                                        src={`/assets/service/${element.icon}.svg`}
                                                    />
                                                )}
                                                <SelectIconComponent
                                                    name={`services.${index}.icon`}
                                                />
                                                <Field
                                                    size={"small"}
                                                    component={TextField}
                                                    name={`services.${index}.name`}
                                                    placeholder="value"
                                                    type="text"
                                                    fullWidth
                                                    required
                                                />
                                                {!element.deleted && (
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="close"
                                                        onClick={() => {
                                                            if (window.confirm("Voulez-vous vraiment supprimer?") === true) {
                                                                setFieldValue(`services.${index}.deleted`, true)
                                                            }
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                )}
                                                {element.deleted && (
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="close"
                                                        onClick={() => setFieldValue(`services.${index}.deleted`, false)}
                                                    >
                                                        <RestoreIcon />
                                                    </IconButton>
                                                )}
                                            </Stack>
                                        ))}

                                    <Stack direction={'row'} sx={{ mb: 2 }}>
                                        <Button variant="outlined" sx={{ mb: 2 }} onClick={() => push(defaultTeammate)}>
                                            Ajouter un item
                                        </Button>
                                    </Stack>
                                </>
                            </Stack>
                        )}
                    />
                </Form>
            )}
        </Formik>
    )
})

export default AddEditServiceForm;

AddEditServiceForm.propTypes = {
    onSubmit: PropTypes.func,
    data: PropTypes.array,
};
