import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Iconify from '../iconify/Iconify';
import { Box, TextField } from '@mui/material';

const ProfileDetail = ({ label, value, onChange, touched, error }) => (
  <>
    <Box display="flex" justifyContent="center" alignItems="center">
      <Iconify icon="entypo:lifebuoy" />
      <Typography sx={{ ml: 1, mr: 1 }}>{label} : </Typography>
    </Box>
    {/* <Typography><strong>{value}</strong></Typography> */}
    <Box flex={1}>
      <TextField
        size={'small'}
        onChange={onChange}
        error={!value && touched}
        helperText={touched && error}
        disabled={label === 'Date de création' || label === 'Type'}
        value={value}
        fullWidth
      />
    </Box>
  </>
);

export default ProfileDetail;

ProfileDetail.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};
