import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Field, Form, Formik } from 'formik';
import { FormControl, Grid } from '@mui/material';
import { object, string } from 'yup';
import { TextField } from 'formik-mui';
import { useAuth } from 'src/context/AuthContext';

const AddEditCustomerForm = forwardRef((props, ref) => {
    const { user } = useAuth();
    const {
        values,
        onSubmit,
    } = props;

    const defaultValues = {
        id: '',
        lastname: '',
        firstname: '',
        phone: '',
        email: '',
        notifications: true,
        language: 'fr',
        status: 'active',
        hutId: user?.id
    };

    const validationSchema = object().shape({
        lastname: string().label("Nom de la paillote").required(),
        firstname: string().label("Prénom de la paillote").required(),
        email: string().email().required(),
    });


    return (
        <Formik
            enableReinitialize
            innerRef={ref}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{ ...defaultValues, ...values }}
        >
            {() => (
                <Form autoComplete="off" noValidate>
                    <Grid spacing={3} container direction={"row"} sx={{ mt: 0.2 }}>
                        <Grid item xs={6}>
                            <Field
                                size={"small"}
                                component={TextField}
                                name="lastname"
                                label="Nom"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Field
                                    size={"small"}
                                    component={TextField}
                                    name="firstname"
                                    placeholder="Prénom*"
                                    fullWidth
                                    required
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Field
                                    size={"small"}
                                    component={TextField}
                                    name="phone"
                                    placeholder="Téléphone"
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Field
                                    size={"small"}
                                    component={TextField}
                                    name="email"
                                    placeholder="E-mail*"
                                    fullWidth
                                    required
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Field
                                    size={"small"}
                                    component={TextField}
                                    name="comment"
                                    placeholder="Commentaire"
                                    multiline={true}
                                    rows={3}
                                    fullWidth
                                    required
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik >
    );
});

export default AddEditCustomerForm;

AddEditCustomerForm.propTypes = {
    values: PropTypes.object,
    onSubmit: PropTypes.func,
    cities: PropTypes.array,
};
