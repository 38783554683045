import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useCallback, useEffect, useState } from 'react';
import { Grid, Container, Button, Stack } from '@mui/material';
import { DataTable, ScheduleColumn } from '../../components/dataTable';
import { createAdminRestaurant } from '../../services/api/admin-restaurant.api';
import { toast } from 'react-toastify';
import { useAuth } from 'src/context/AuthContext';
import AddRestaurantDialog from 'src/components/admin/restaurant/add-restaurant-dialog/AddRestaurantDialog';
import { getAdminAllRestaurants } from 'src/services/api/admin.api';

export default function AdminAllRestaurantPage() {
    const { user } = useAuth();
    const [action, setAction] = useState(null);
    const [queryFilter, setQueryFilter] = useState();
    const [data, setData] = useState();
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const getDataList = useCallback(async (queryFilter) => {
        setLoading(true)
        delete queryFilter.page
        const res = await getAdminAllRestaurants(queryFilter, user?.id);
        if (res && res.data && res.data.data) {
            setData(res.data.data);
            setTotal(res.data.total);
        } else {
            setData([]);
            setTotal(0);
        }
        setLoading(false)
    }, [user]);

    useEffect(() => {
        if (queryFilter) {
            getDataList(queryFilter);
        }
    }, [getDataList, queryFilter]);

    const handleOnCloseDialog = useCallback(() => {
        setAction(null);
    }, []);

    const handleBook = useCallback((id) => {
        navigate(`/restaurant/${id}`);
    }, [navigate]);

    const columns = [
        {
            name: 'name',
            label: 'Nom',
            isSearchable: true,
            isSortable: true,
        },
        {
            name: 'status',
            label: 'Statut',
            isSearchable: false,
            isSortable: true,
            render: (row) => {
                return `Plan ${row.status}`;
            },
        },
        {
            name: 'schedule',
            label: 'Planification',
            isSearchable: false,
            isSortable: true,
            render: (row) => <ScheduleColumn row={row} />,
        },
        {
            name: '',
            label: 'Action',
            props: { align: 'center' },
            render: (row) => (
                <Stack direction={'row'} spacing={2} justifyContent="center">
                    <Button size="small" onClick={() => handleBook(row.id)} variant="outlined">
                        Livre
                    </Button>
                </Stack>
            ),
        },
    ];

    const handleDataTableChange = useCallback((filter) => {
        setQueryFilter(filter);
    }, []);

    const handleFormSubmit = useCallback(
        async (values) => {
            const res = await createAdminRestaurant(values);
            console.log(res, 'res');
            if (res && res.status_code == 404) {
                toast.error(res.message);
            } else {
                if (res.status) {
                    toast.success(res.message);
                    getDataList(queryFilter);
                    handleOnCloseDialog();
                } else {
                    toast.error(res.error);
                }
            }
        },
        [getDataList, handleOnCloseDialog, queryFilter]
    );

    return (
        <>
            <Helmet>
                <title> All Restaurant | Beach Booker - Pro </title>
            </Helmet>
            <Container maxWidth={false} sx={{ mt: 5 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DataTable
                            data={data}
                            columns={columns}
                            initialLoading={loading}
                            totalRow={total}
                            onChange={handleDataTableChange}
                        />
                    </Grid>
                </Grid>
            </Container>
            {action === 'add' && <AddRestaurantDialog handleFormSubmit={handleFormSubmit} onClose={handleOnCloseDialog} />}
        </>
    );
}
