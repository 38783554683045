// @mui
import { styled } from '@mui/material/styles';
import { ListItemButton } from '@mui/material';

// ----------------------------------------------------------------------

export const StyledNavItem = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  textAlign: "center",  
}));
