import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { customerReservationsList } from 'src/services/api/customer.api';
import { fDate } from 'src/utils/formatTime';


function CustomerDetails({ row }) {
    const [beachBookings, setBeachBookings] = useState([]);
    const [restaurantBookings, setRestaurantBookings] = useState([]);

    const geDetailsOfCustomer = useCallback(async (rowData) => {
        const resData = await customerReservationsList(row.id);
        if (resData.status && resData.data) {
            if (resData.data.reservation_beach_list) {
                setBeachBookings(resData.data.reservation_beach_list)
            }
            if (resData.data.reservation_restaurant_list) {
                setRestaurantBookings(resData.data.reservation_restaurant_list)
            }
        }
    }, []);

    useEffect(() => {
        geDetailsOfCustomer(row)
    }, [geDetailsOfCustomer, row]);

    return (
        <Box sx={{ display: "flex", padding: 4 }}>
            <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                <Stack>
                    Client ajouté le {fDate(row.createdAt)}
                </Stack>
                <Stack>
                    {beachBookings.length > 0 && beachBookings.map((item) => (
                        <small>
                            <strong>Réservation Nº {item.id}</strong>.
                            Réservé le {fDate(item.createdAt)} pour le {fDate(item.date)}.
                            {item.details ? JSON.parse(item.details).length : null} personne(s).
                        </small>
                    ))}
                    {restaurantBookings.length > 0 && restaurantBookings.map((item) => (
                        <small>
                            <strong>Réservation Nº {item.id}</strong>.
                            Réservé le {fDate(item.createdAt)} pour le {fDate(item.date)}.
                            {item.details ? JSON.parse(item.details).length : null} personne(s).
                        </small>
                    ))}
                </Stack>
            </Stack>
        </Box>
    );
}

export default CustomerDetails;

CustomerDetails.propTypes = {
    row: PropTypes.any,
};
