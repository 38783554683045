import { Checkbox } from '@mui/material';
import PropTypes from 'prop-types';

export default function CheckBoxColumn({ checked, handleChange, key }) {
    // eslint-disable-next-line eqeqeq
    const checkedChk = checked && checked == "true" ? true : false;
    return (
        <Checkbox
            key={key}
            onChange={handleChange}
            defaultChecked={checkedChk} size="small"
        />
    );
}

CheckBoxColumn.propTypes = {
    checked: PropTypes.any,
};
