import { Helmet } from 'react-helmet-async';
import { Box, Tabs, Tab, Typography, Grid, CircularProgress } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { DashboardStatistics } from 'src/services/admin-statistics';
import { PropTypes } from 'prop-types';
import { RestaurantPanel } from 'src/components/statistics';
import { useAuth } from 'src/context/AuthContext';
import moment from 'moment';
import { getAdminBeachList } from 'src/services/api/admin-beach.api';
import { getBeachBeachReservationsCustomersPaymentsForADate } from 'src/services/api/beach.api';
import _ from 'lodash';
import StatisticsBookingTable from 'src/components/statistics/StatisticsBookingTable';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function StatisticsPage() {
    const { user } = useAuth();
    const [selectedTab, setSelectedTab] = useState("both");
    const [bookingData, setBookingData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [dateRange, setDateRange] = useState({
        startDate: moment(new Date()).subtract(30, 'days').toDate(),
        endDate: moment(new Date()).toDate(),
        key: 'selection',
    });

    const handleChangeDate = useCallback(async (values) => {
        setDateRange(values)
    }, []);

    const [totalData, setTotalData] = useState({
        totalClient: 0,
        totalReservation: 0,
        totalBad: 0,
        bedAvg: 0,
        newHutReservationsDirect: 0,
        newHutReservationsWhitelabel: 0,
        newHutReservationsWebsite: 0,
        directChartData: [],
        barChartData: []
    });

    const getOccupancyData = async (direct_charts) => {
        let dates = []
        let currDate = moment(dateRange.startDate).startOf('day')
        let lastDate = moment(dateRange.endDate).startOf('day')
        dates.push(currDate.clone().toDate())
        while (currDate.add(1, 'days').diff(lastDate) <= 0) {
            dates.push(currDate.clone().toDate())
        }


        let labels = []
        let data = []

        for (let i = 0; i < dates.length; i++) {
            labels.push(moment(dates[i]).format('DD/MM'))

            // For each day we need to get the active beach plan
            let beach = undefined
            const resBeach = await getAdminBeachList({}, user.id);
            let activeBeach = null;
            if (resBeach.status && resBeach.data) {
                const beaches = resBeach.data;
                for (let i = 0; i < beaches.length; i++) {
                    if (beaches[i].status === 'active') {
                        if ((beaches[i].schedule) && (beaches[i].plan)) {
                            const scheduleData = beaches[i].schedule ? JSON.parse(beaches[i].schedule)?.schedule : [];
                            for (let j = 0; j < scheduleData.length; j++) {
                                if (scheduleData[j] === moment(dates[i]).format('dddd').toLowerCase()) {
                                    activeBeach = beaches[i]
                                }
                            }
                        }
                    }
                }
                if (activeBeach) {
                    const planData = activeBeach.plan ? JSON.parse(activeBeach.plan)?.plan : [];
                    const scheduleData = activeBeach.schedule ? JSON.parse(activeBeach.schedule)?.schedule : [];
                    activeBeach = { ...activeBeach, plan: planData, schedule: scheduleData }
                }
            }

            let totalSpots = 0
            if (activeBeach) {
                if (activeBeach.plan) {
                    for (let j = 0; j < activeBeach.plan.length; j++) {
                        for (let k = 0; k < activeBeach.plan[j].length; k++) {
                            if (activeBeach.plan[j][k].type.startsWith("double_sunbed")) {
                                totalSpots++
                            } else if (activeBeach.plan[j][k].type.startsWith("sunbed")) {
                                totalSpots++
                            }
                        }
                    }
                }

                let reservations = []

                // const resData = reservations = await getBeachBeachReservationsCustomersPaymentsForADate(activeBeach.id, moment(dates[i]).format("YYYY-MM-DD"));
                // if (resData.status && resData.data) {
                //     reservations = resData.data;
                // }
                // console.log(reservations, 'reservations');
                // totalReservations = reservations.length

                let totalSunbedReserved = 0;
                const resData = reservations = _.filter(direct_charts, { date: moment(dates[i]).format("YYYY-MM-DD") });

                if (resData.length > 0) {
                    for (let j = 0; j < reservations.length; j++) {
                        totalSunbedReserved += reservations[j].details ? JSON.parse(reservations[j].details).length : 0
                        let valueData = ((totalSunbedReserved / totalSpots) * 100).toFixed(2)
                        data.push({ date: moment(dates[i]).format('DD/MM'), value: valueData })
                    }
                } else {
                    data.push({ date: moment(dates[i]).format('DD/MM'), value: 0 })
                }
            } else {
                data.push([])
            }
        }
        setTotalData(prevState => ({
            ...prevState,
            barChartData: data,
        }));
    }

    const findNumberOfPerson = useCallback((row) => {
        let seat = 0
        if (row.length > 0) {
            // eslint-disable-next-line array-callback-return
            row.map((ele) => {
                if (ele && ele.type) {
                    const seatNumber = ele.type.replace(/[^0-9]/g, "");
                    if (seatNumber) {
                        seat = seat + Number(seatNumber)
                    }
                }
            })
        }
        return seat
    }, [])

    const getBookingDetails = (row) => {
        if (row?.beach) {
            const detailsData = row.details ? JSON.parse(row.details) : [];
            if (detailsData.length > 0) {
                return (
                    <ul>
                        {detailsData.map((ele) => (
                            <li>
                                {ele.type.startsWith('double_sunbed') && (
                                    <span>Bed </span>
                                )}
                                {ele.type.startsWith('sunbed') && (
                                    <span>Transat </span>
                                )}
                                {ele.status == "reserved_full_day" && (
                                    <span>journée </span>
                                )}

                                {ele.status == "reserved_half_day" && (
                                    <span>½ journée </span>
                                )}
                            </li>
                        ))}
                    </ul>
                )
            } else {
                return null
            }
        } else {
            const detailsData = row.details ? JSON.parse(row.details) : [];
            if (detailsData.length > 0) {
                return findNumberOfPerson(detailsData)
            } else {
                return null
            }
        }

    }

    const getStaticsData = useCallback(async () => {
        setBookingData([])
        setTotalData({
            totalClient: 0,
            totalReservation: 0,
            totalBad: 0,
            bedAvg: 0,
            newHutReservationsDirect: 0,
            newHutReservationsWhitelabel: 0,
            newHutReservationsWebsite: 0,
            directChartData: [],
            barChartData: []
        })
        setLoading(true);
        const response = await DashboardStatistics({
            id: user.id,
            type: selectedTab,
            start_date: moment(dateRange.startDate).format("YYYY-MM-DD"),
            end_date: moment(dateRange.endDate).format("YYYY-MM-DD"),
        });

        setLoading(false);
        if (response.status) {
            if (response.data && response.data.total_count) {
                const totalCountRes = response.data.total_count;
                const direct_chart = response.data.direct_chart || [];
                const allBookData = [...response.data?.reservation_beach_data, ...response.data?.reservation_res_data]
                const updatedData = allBookData.map((ele) => {
                    let serviceFees = _.sumBy(ele?.order_price, function (o) { return Number(o.oneItemServiceCharge) || 0 }) || 0
                    let oneItemPrice = _.sumBy(ele?.order_price, function (o) { return Number(o.oneItemPrice) || 0 }) || 0
                    return {
                        ...ele,
                        beachName: ele?.beach?.name || ele?.restaurant?.name,
                        firstname: ele?.customer?.firstname,
                        lastname: ele?.customer?.lastname,
                        serviceFees: serviceFees,
                        fees: oneItemPrice,
                        isWalkIn: ele.walkIn ? true : false,
                        isMarqueBlanche: ele.source !== 'direct' ? true : false,
                        isb2b: ele.source === 'direct' ? true : false,
                        isRestaurant: !ele.beach,
                        bookingItem: getBookingDetails(ele)
                    }
                });
                setBookingData(updatedData)
                let totalReservationRes = totalCountRes.reservation_beach.length + totalCountRes.reservation_restaurant.length
                let totalHutReservationsSunbedsBeach = 0;
                let totalHutReservationsSunbedsRes = 0;

                let newHutReservationsDirect = 0
                let newHutReservationsWhitelabel = 0
                let newHutReservationsWebsite = 0
                let newHutReservationsSunbeds = 0

                for await (const ele of totalCountRes.reservation_beach) {
                    if (ele.details && ele.details != null) {
                        let detailsArr = JSON.parse(ele.details);
                        totalHutReservationsSunbedsBeach += detailsArr.length
                    }

                    if (ele.source === 'direct') {
                        newHutReservationsDirect++
                    } else if (ele.source === 'whitelabel') {
                        newHutReservationsWhitelabel++
                    } else {
                        newHutReservationsWebsite++
                    }
                }

                for await (const ele of totalCountRes.reservation_restaurant) {
                    if (ele.details && ele.details != null) {
                        let detailsArr = JSON.parse(ele.details);
                        totalHutReservationsSunbedsRes += detailsArr.length
                    }

                    if (ele.source === 'direct') {
                        newHutReservationsDirect++
                    } else if (ele.source === 'whitelabel') {
                        newHutReservationsWhitelabel++
                    } else {
                        newHutReservationsWebsite++
                    }
                }

                let totalBad = totalHutReservationsSunbedsBeach + totalHutReservationsSunbedsRes;

                let totalAverageSunbedsPerReservation = Math.round((totalBad / totalReservationRes) * 1e2) / 1e2
                if (isNaN(totalAverageSunbedsPerReservation)) {
                    totalAverageSunbedsPerReservation = 0
                }

                let directChartData = [
                    {
                        name: "Direct",
                        value: newHutReservationsDirect
                    },
                    {
                        name: "Marque blanche",
                        value: newHutReservationsWhitelabel
                    },
                    {
                        name: "Beach Booker",
                        value: newHutReservationsWebsite
                    },
                ]
                setTotalData({
                    ...response.data,
                    totalReservation: totalReservationRes,
                    totalBad: totalBad,
                    totalClient: totalCountRes.total_customer,
                    bedAvg: totalAverageSunbedsPerReservation,
                    newHutReservationsDirect,
                    newHutReservationsWhitelabel,
                    newHutReservationsWebsite,
                    directChartData: directChartData
                })
                getOccupancyData(direct_chart);
            } else {
                setBookingData([])
                setTotalData({
                    totalClient: 0,
                    totalReservation: 0,
                    totalBad: 0,
                    bedAvg: 0,
                    newHutReservationsDirect: 0,
                    newHutReservationsWhitelabel: 0,
                    newHutReservationsWebsite: 0,
                    directChartData: [],
                    barChartData: []
                })
            }
        }
    }, [user, dateRange, selectedTab]);

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        getStaticsData()
    }, [user, dateRange, selectedTab]);
    
    return (
        <>
            <Helmet>
                <title> Statistics | Beach Booker - Pro </title>
            </Helmet>
            <Box margin="auto" width="100%" mt={3}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectedTab} onChange={handleChangeTab} variant="fullWidth" aria-label="basic tabs example">
                            <Tab label="Restaurant" value={"restaurant"} {...a11yProps("restaurant")} />
                            <Tab label="Plage" value={"beach"} {...a11yProps("beach")} />
                            <Tab label="Les deux" value={"both"} {...a11yProps("both")} />
                        </Tabs>
                    </Box>
                    {loading && (
                        <Box sx={{ display: 'flex', mt: 20, ml: 20, justifyContent: "center" }}>
                            <CircularProgress />
                        </Box>
                    )}
                    {!loading && (
                        <>
                            <TabPanel value={selectedTab} index={"beach"}>
                                <RestaurantPanel
                                    handleChangeDate={handleChangeDate}
                                    dateRange={dateRange}
                                    totalData={totalData}
                                    selectedTab={selectedTab}
                                />
                            </TabPanel>
                            <TabPanel value={selectedTab} index={"restaurant"}>
                                <RestaurantPanel
                                    handleChangeDate={handleChangeDate}
                                    dateRange={dateRange}
                                    totalData={totalData}
                                    selectedTab={selectedTab}
                                />
                            </TabPanel>
                            <TabPanel value={selectedTab} index={"both"}>
                                <RestaurantPanel
                                    handleChangeDate={handleChangeDate}
                                    dateRange={dateRange}
                                    totalData={totalData}
                                    selectedTab={selectedTab}
                                />
                            </TabPanel>
                            {bookingData.length > 0 && (
                                <Grid item xs={6} md={6}>
                                    <StatisticsBookingTable
                                        bookingData={bookingData}
                                    />
                                </Grid>
                            )}
                        </>
                    )}
                </Box>

            </Box>
        </>
    );
}
