import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import moment from 'moment';


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'firstName',
        disablePadding: true,
        label: 'Prénom',
        sx: { textAlign: "center" },
        disableSortBy: true
    },
    {
        id: 'lastname',
        disablePadding: true,
        label: 'Nom de famille',
        sx: { textAlign: "center" },
        disableSortBy: true
    },
    {
        id: 'id',
        disablePadding: true,
        label: 'Numéro de réservation',
        sx: { textAlign: "center" },
        disableSortBy: true
    },
    {
        id: 'date',
        disablePadding: false,
        label: 'Date de réservation',
        sx: { textAlign: "center" }
    },
    {
        id: 'bookingItem',
        disablePadding: false,
        label: 'Contenu de la réservation',
        sx: { textAlign: "center" }
    },
    {
        id: 'serviceFees',
        numeric: true,
        disablePadding: false,
        label: 'Frais de service',
        sx: { textAlign: "center" }
    },
    {
        id: 'comment',
        disablePadding: false,
        label: 'Commentaire de la réservation',
        sx: { textAlign: "center" }
    },
    {
        id: 'fees',
        numeric: false,
        disablePadding: false,
        label: 'Total',
        sx: { textAlign: "center" }
    },
    {
        id: 'isWalkIn',
        numeric: false,
        disablePadding: false,
        label: 'Entrer',
        sx: { textAlign: "center" }
    },
    {
        id: 'isMarqueBlanche',
        numeric: false,
        disablePadding: false,
        label: 'Marque blanche',
        sx: { textAlign: "center" }
    },
    {
        id: 'isb2b',
        disablePadding: false,
        label: 'B2B',
        sx: { textAlign: "center" }
    },
    {
        id: 'isRestaurant',
        disablePadding: false,
        label: 'Restaurant/Plage',
        sx: { textAlign: "center" }
    },
];

const DEFAULT_ORDER = 'asc';
const DEFAULT_ORDER_BY = 'firstname';
const DEFAULT_ROWS_PER_PAGE = 25;

function StatisticsBookHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (newOrderBy) => (event) => {
        onRequestSort(event, newOrderBy);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ ...headCell.sx }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

StatisticsBookHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    setSelected: PropTypes.any.isRequired,
    selected: PropTypes.any.isRequired,
};

function StatisticsBookToolbar(props) {
    const { numSelected } = props;
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 && (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            )}
        </Toolbar>
    );
}

StatisticsBookToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function StatisticsBookingTable({ bookingData }) {
    const [order, setOrder] = React.useState(DEFAULT_ORDER);
    const [orderBy, setOrderBy] = React.useState(DEFAULT_ORDER_BY);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [visibleRows, setVisibleRows] = React.useState(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
    const [paddingHeight, setPaddingHeight] = React.useState(0);

    React.useEffect(() => {
        let rowsOnMount = stableSort(
            bookingData,
            getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY),
        );

        rowsOnMount = rowsOnMount.slice(
            0 * DEFAULT_ROWS_PER_PAGE,
            0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE,
        );

        setVisibleRows(rowsOnMount);
    }, [bookingData]);

    const handleRequestSort = React.useCallback(
        (event, newOrderBy) => {
            const isAsc = orderBy === newOrderBy && order === 'asc';
            const toggledOrder = isAsc ? 'desc' : 'asc';
            setOrder(toggledOrder);
            setOrderBy(newOrderBy);

            const sortedRows = stableSort(bookingData, getComparator(toggledOrder, newOrderBy));
            const updatedRows = sortedRows.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            );

            setVisibleRows(updatedRows);
        },
        [order, orderBy, page, rowsPerPage],
    );

    // const handleSelectAllClick = (event) => {
    //     if (event.target.checked) {
    //         const newSelected = bookingData.map((n) => n.name);            
    //         return;
    //     }
    //     setSelected([]);
    // };

    // const handleClick = (event, name) => {
    //     const selectedIndex = selected.indexOf(name);
    //     let newSelected = [];

    //     if (selectedIndex === -1) {
    //         newSelected = newSelected.concat(selected, name);
    //     } else if (selectedIndex === 0) {
    //         newSelected = newSelected.concat(selected.slice(1));
    //     } else if (selectedIndex === selected.length - 1) {
    //         newSelected = newSelected.concat(selected.slice(0, -1));
    //     } else if (selectedIndex > 0) {
    //         newSelected = newSelected.concat(
    //             selected.slice(0, selectedIndex),
    //             selected.slice(selectedIndex + 1),
    //         );
    //     }

    //     setSelected(newSelected);
    // };

    const handleChangePage = React.useCallback(
        (event, newPage) => {
            setPage(newPage);

            const sortedRows = stableSort(bookingData, getComparator(order, orderBy));
            const updatedRows = sortedRows.slice(
                newPage * rowsPerPage,
                newPage * rowsPerPage + rowsPerPage,
            );

            setVisibleRows(updatedRows);

            // Avoid a layout jump when reaching the last page with empty rows.
            const numEmptyRows =
                newPage > 0 ? Math.max(0, (1 + newPage) * rowsPerPage - bookingData.length) : 0;

            const newPaddingHeight = (dense ? 33 : 53) * numEmptyRows;
            setPaddingHeight(newPaddingHeight);
        },
        [order, orderBy, dense, rowsPerPage],
    );

    const handleChangeRowsPerPage = React.useCallback(
        (event) => {
            const updatedRowsPerPage = parseInt(event.target.value, 10);
            setRowsPerPage(updatedRowsPerPage);

            setPage(0);

            const sortedRows = stableSort(bookingData, getComparator(order, orderBy));
            const updatedRows = sortedRows.slice(
                0 * updatedRowsPerPage,
                0 * updatedRowsPerPage + updatedRowsPerPage,
            );

            setVisibleRows(updatedRows);

            // There is no layout jump to handle on the first page.
            setPaddingHeight(0);
        },
        [order, orderBy],
    );

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    //const isSelected = (name) => selected.indexOf(name) !== -1;

    return (
        <Box sx={{ width: '100%', padding: "10px 50px" }}>
            <Typography variant='h4'>Liste de réservation</Typography>
            <Paper sx={{ width: '100%', mt: 1 }}>
                {/* <StatisticsBookToolbar /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <StatisticsBookHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={bookingData.length}
                        />
                        <TableBody>
                            {visibleRows
                                ? visibleRows.map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell align="center">{row?.firstname}</TableCell>
                                            <TableCell align="center">{row?.lastname}</TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                align='center'
                                            >
                                                {row?.id}
                                            </TableCell>
                                            <TableCell align="center">{moment(row?.date, "YYYY-MM-DD").format("DD/MM/YYYY")}</TableCell>
                                            <TableCell align="center">{row?.bookingItem}</TableCell>
                                            <TableCell align="center">{row.serviceFees}</TableCell>
                                            <TableCell align="center">{row?.comment}</TableCell>
                                            <TableCell align="center">{row.fees}</TableCell>
                                            <TableCell align="center">{row?.isWalkIn ? "Oui" : "Non"}</TableCell>                                           
                                            <TableCell align="center">{row?.isMarqueBlanche ? "Oui" : "Non"}</TableCell>                                           
                                            <TableCell align="center">{row?.isb2b ? "Oui" : "Non"}</TableCell>                                           
                                            <TableCell align="center">{row?.isRestaurant ? "Restaurant" : "Plage"}</TableCell>                                           
                                            
                                        </TableRow>
                                    );
                                })
                                : null}
                            {paddingHeight > 0 && (
                                <TableRow
                                    style={{
                                        height: paddingHeight,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={bookingData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
